// This worker provides the timing service for scales being played and for the metronome.
// From https://github.com/cwilso/metronome

const worker = () => {

	let timerID = null;
	let interval = 100;

	function tick() {
		// console.log('metronomeWorker tick');
		postMessage("tick");
	}

	onmessage = function (e) {
		if (e.data === "start") {
			timerID = setInterval(tick, interval);
			console.log("metronomeWorker - START - timerID, interval: ", timerID, interval);
		}
		else if (e.data.interval) {
			interval = e.data.interval;
			console.log("metronomeWorker - SET INTERVAL - timerID, new interval: ", timerID, interval);
			if (timerID) {
				clearInterval(timerID);
				// timerID = setInterval(function () { console.log('metronomeWorker tick (b)'); postMessage("tick"); }, interval)
				timerID = setInterval(tick, interval);
			}
		}
		else if (e.data === "stop") {
			console.log("metronomeWorker: STOP - timerID: ", timerID);
			clearInterval(timerID);
			timerID = null;
		}
	};

	postMessage('hi there');

}



// Following hacked from react metronome - cos there's an issue using web workers with create-react-app
let code = worker.toString()
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'))

const blob = new Blob([code], { type: 'application/javascript' })
const workerScript = URL.createObjectURL(blob)

export default workerScript
