import React from 'react';
import { IconContext } from 'react-icons';

import {
    AiFillCaretDown as CaretDown,
    AiFillCaretUp as CaretUp,
    AiFillCaretRight as CaretRight,
    AiOutlineHome as Home,
} from 'react-icons/ai';
import {
    BiSortDown as Sort,
    BiMessageDetail as Contact,
} from 'react-icons/bi';
import {
    BsEyeFill as Eye,
    BsEyeSlashFill as EyeSlash,
    BsSearch as Search,
    BsX as Close,
    BsGrid3X3Gap as Keys,
    BsQuestionCircle as Help,
    BsExclamationCircle as Error,
    BsExclamationTriangle as Warning,
    BsInfoCircle as Info,
    BsVolumeUp as Sound,
    BsListOl as List,
    // BsGrid as Types,
    BsMusicNoteList as Types,
    BsChevronUp as ChevronUp,
    BsChevronDown as ChevronDown,
} from 'react-icons/bs';
import {
    FaArrowUp as ArrowUp,
    FaArrowDown as ArrowDown,
    FaAngleDoubleRight as DoubleArrowRight,
    FaAngleDoubleLeft as DoubleArrowLeft,
    // FaShare as Share,
    FaCircleNotch as SpinnerImport,
    FaBars as Menu,
    FaFacebookSquare as Facebook,
} from 'react-icons/fa';
import {
    FiPrinter as Print
} from 'react-icons/fi';
import {
    GiTrumpet as Instrument
} from 'react-icons/gi';
import {
    GrUndo as Flip
} from 'react-icons/gr';
import {
    IoIosMore as More,
    IoMdCheckmark as Tick,
    IoMdPlay as Play,
    IoMdSquare as Stop,
    IoMdSettings as Settings,
    IoMdShare as Share,
} from 'react-icons/io';
// import {
//     ImTree as Related,
// } from 'react-icons/im';
import {
    RiLightbulbLine as Example,
} from 'react-icons/ri';
import {
    VscMortarBoard as Teacher,
} from 'react-icons/vsc';

// import { Arpeggio } from './Arpeggio';
import { IconMusic as Music } from './IconMusic';

const Wrapper = (props) => (
    <IconContext.Provider value={{ className: `react-icon-adjust ${props.className || ''}`, style: props.style }}>
        {props.children}
    </IconContext.Provider>
);

// export const ArpeggioIcon = ({ className, style, ...props }) => <Arpeggio className={`${className}`} style={style} />
export const ArrowDownIcon = (props) => <Wrapper {...props}><ArrowDown /></Wrapper>
export const ArrowUpIcon = (props) => <Wrapper {...props}><ArrowUp /></Wrapper>
export const CaretDownIcon = (props) => <Wrapper {...props}><CaretDown /></Wrapper>
export const CaretRightIcon = (props) => <Wrapper {...props}><CaretRight /></Wrapper>
export const CaretUpIcon = (props) => <Wrapper {...props}><CaretUp /></Wrapper>
export const ChevronUpIcon = (props) => <Wrapper {...props}><ChevronUp /></Wrapper>
export const ChevronDownIcon = (props) => <Wrapper {...props}><ChevronDown /></Wrapper>
// export const ChromaticIcon = () => <Wrapper {...props}><MdCallMade /></Wrapper>
// export const Circle5thsIcon = () => <Wrapper {...props}><IoMdRefresh /></Wrapper>
export const CloseIcon = (props) => <Wrapper {...props}><Close /></Wrapper>
export const ContactIcon = (props) => <Wrapper  {...props}><Contact /></Wrapper>
export const DoubleArrowLeftIcon = () => <DoubleArrowLeft />
export const DoubleArrowRightIcon = () => <DoubleArrowRight />
export const ErrorIcon = (props) => <Wrapper {...props}><Error /></Wrapper>
export const ExampleIcon = (props) => <Wrapper {...props}><Example /></Wrapper>  // obsolete???
export const FacebookIcon = (props) => <Wrapper className='facebook' {...props}><Facebook /></Wrapper>
export const FlipIcon = (props) => <Wrapper className='gr-undo-flip-icon' {...props}><Flip /></Wrapper>
export const HelpIcon = (props) => <Wrapper {...props}><Help /></Wrapper>
export const HideIcon = (props) => <Wrapper {...props} className='eye-icon'> <EyeSlash /></Wrapper >
export const HomeIcon = (props) => <Wrapper  {...props}><Home /></Wrapper>
export const InfoIcon = (props) => <Wrapper  {...props}><Info /></Wrapper>
export const InstrumentIcon = (props) => <Wrapper {...props}><Instrument /></Wrapper>
export const KeysIcon = (props) => <Wrapper {...props}><Keys /></Wrapper>
export const ListIcon = (props) => <Wrapper {...props}><List /></Wrapper>
export const MenuIcon = (props) => <Wrapper {...props}><Menu /></Wrapper>
export const MoreIcon = () => <More />
export const MusicIcon = ({ className, style, ...props }) => <Music className={`${className}`} style={style} />
// export const MusicIcon = (props) => <Wrapper {...props}><Music /></Wrapper>
export const PlayIcon = (props) => <Wrapper {...props}><Play /></Wrapper>
export const PrintIcon = (props) => <Wrapper {...props}><Print /></Wrapper>
// export const RelatedIcon = (props) => <Wrapper {...props}><Related /></Wrapper>
export const SearchIcon = (props) => <Wrapper {...props}><Search /></Wrapper>
export const SettingsIcon = (props) => <Wrapper {...props}><Settings /></Wrapper>
export const ShareIcon = (props) => <Wrapper {...props}><Share /></Wrapper>
export const ShowIcon = (props) => <Wrapper {...props} className='eye-icon'> <Eye /></Wrapper >
export const SortIcon = (props) => <Wrapper {...props}><Sort /></Wrapper>
export const SoundIcon = (props) => <Wrapper {...props}><Sound /></Wrapper>
export const SpacerIcon = () => <svg viewBox="0 0 512 512" height="1em" width="1em"></svg>    // for aligning menu items when there are check marks on some of them
export const Spinner = () => <Wrapper className='loading-spinner'><SpinnerImport /></Wrapper>
export const StopIcon = (props) => <Wrapper {...props}><Stop /></Wrapper>
export const TeacherIcon = (props) => <Wrapper {...props}><Teacher /></Wrapper>
export const TickIcon = () => <Tick />
export const TypesIcon = (props) => <Wrapper {...props}><Types /></Wrapper>
export const WarningIcon = (props) => <Wrapper {...props}><Warning /></Wrapper>
