import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { formatAccidentalsJSX } from '../utils/MusicSymbolsFormatting';
import isEqual from 'lodash/isEqual';
import { isPhonePortrait } from '../utils/miscUtils';
import groupBy from 'lodash/groupBy';


const OptionControl = ({
    optionName,
    currValue,
    onClick,
    optionsArray,
    groupByKey,
    dropdownToggleDefault = 'Select',
    explanationText,
    optionStyle = 'buttons',
    containerClassName,
    dropdownClassName,
    ...otherProps
}) => {

    /*
    Now used by settings sidebar and by scale actions.

    optionName    // e.g. 'Fingerings'
    currValue     // e.g. showNoteNames
    
    each option item:
        {
            label,   // e.g. 'On'
            value,   // e.g. true
        }

    optionStyle   // 'buttons' or 'dropdown'
    
    */

    const groupings = groupByKey ? groupBy(optionsArray, groupByKey) : null;

    const OptionButtons = () => (
        optionsArray.map(option => (
            <button
                key={option.label}
                className={"text-button" + (currValue === option.value ? " active" : "")}
                onClick={onClick}
            >
                {formatAccidentalsJSX(option.label)}
            </button>
        ))
    )

    const OptionDropdown = () => {

        const [dropdownOpen, setDropdownOpen] = useState(false);
        const toggleDropdown = () => { setDropdownOpen(!dropdownOpen) };
        const currOption = optionsArray.find(e => e.value === currValue) || {};

        const DropdownItemFromOption = ({ option, index }) => {
            const { label, shortLabel, value, divider, ...otherOptionData } = option;
            return (
                divider ?
                    <DropdownItem divider />
                    :
                    <DropdownItem
                        className={`dd-item ${(currValue === value ? 'active' : '')}`}
                        value={value}
                        onClick={onClick}
                        {...otherOptionData}
                    >
                        {formatAccidentalsJSX(label)}
                    </DropdownItem>
            )
        }


        return (
            <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                tag='span'
            >
                <DropdownToggle
                    className={dropdownClassName}
                    caret
                >
                    {
                        formatAccidentalsJSX(
                            (isPhonePortrait() ? currOption.shortLabel || currOption.label : currOption.label)
                            || dropdownToggleDefault
                        )
                    }
                </DropdownToggle>

                <DropdownMenu
                    modifiers={{    // set a maxHeight - grabbed from https://github.com/reactstrap/reactstrap/issues/998#issuecomment-386235241
                        setMaxHeight: {
                            enabled: true,
                            order: 890,
                            fn: (data) => {
                                return {
                                    ...data,
                                    styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: 310,
                                    },
                                };
                            },
                        },
                    }}
                >
                    {groupByKey ?
                        Object.keys(groupings).map((groupByValue, index) => (
                            <React.Fragment key={groupByValue}>
                                {index !== 0 && <DropdownItem divider />}
                                <DropdownItem
                                    key={groupByValue}
                                    header
                                >
                                    {groupByValue}
                                </DropdownItem>
                                {groupings[groupByValue].map((option, i) => (
                                    <DropdownItemFromOption
                                        key={`${option.label}-${i}`}  // i because divider 'options' don't have labels
                                        option={option}
                                        index={i}
                                    />
                                ))}
                            </React.Fragment>
                        ))
                        : optionsArray.map((option, i) => (
                            <DropdownItemFromOption
                                key={`${option.label}-${i}`}  // i because divider 'options' don't have labels
                                option={option}
                                index={i}
                            />
                        ))
                    }
                </DropdownMenu>
            </Dropdown>
        )
    }

    return (
        <div
            className={containerClassName}
            {...otherProps}
        >
            {optionName && <div className='option-name'>{formatAccidentalsJSX(optionName)}:</div>}
            {optionStyle === 'dropdown' ?
                <OptionDropdown />
                : <OptionButtons />}
            {explanationText && <div className='option-explanation'>{explanationText}</div>}
        </div>
    )

}

function areEqual(prev, next) {

    const equal = (
        next.optionName === prev.optionName
        && next.currValue === prev.currValue
        // && next.onClick === prev.onClick
        && isEqual(next.optionsArray, prev.optionsArray)
        && next.explanationText === prev.explanationText
        && next.optionStyle === prev.optionStyle
        && next.containerClassName === prev.containerClassName
        && next.dropdownClassName === prev.dropdownClassName
        && isEqual(next.otherProps, prev.otherProps)
    )

    return equal;
}


export default React.memo(OptionControl, areEqual);