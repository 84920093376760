export const typeableLookup = {
    'b': { unicode: '\u266D', className: 'flat-sign' },
    '#': { unicode: '\u266F', className: 'sharp-sign' },
    'n': { unicode: '\u266E', className: 'natural-sign' },
    '##': { unicode: '\uD834\uDD2A', className: 'double-sharp' },
    'bb': { unicode: '\uD834\uDD2B', className: 'double-flat' },
}



export const convertFlatSharpNaturalToTypeable = (str) => {
    return str.replace(/♭/g, 'b')
        .replace(/♯/g, '#')
        .replace(/♮/g, 'n')
        .replace(/𝄪/g, '##')
        .replace(/𝄫/g, 'bb');
}

export const pitchClassDisplayString = (pitchClass) => {
    const alterationDisplay = typeableLookup[pitchClass.substr(1)];
    if (typeof alterationDisplay === 'undefined') return pitchClass;  // Not in lookup, just return

    return pitchClass[0] + alterationDisplay.unicode;
}


function lowercaseApartFromPitches(string) {
    const res = string
        .toLowerCase()
        .replace(/\b([abcdefg])(b+)\b/g, function (match, p1, p2) {   // handle flats (i.e. keep the 'b' meaning flat in lowercase)
            return p1.toUpperCase() + p2;
        })
        .replace(/\b([abcdefg])([#]?)\b/g, function (match, p1) {     // handle everything else
            return p1.toUpperCase();
        })
        ;
    return res;
}

export const convertStringToQueryURL = (string, { includePath = true, toStandardCase = true } = {}) => {
    const typeableString = convertFlatSharpNaturalToTypeable(string);
    const caseCorrectedString = toStandardCase
        ? lowercaseApartFromPitches(typeableString)
        : typeableString;
    const res = (includePath ? '/search?' : '')
        + 'q='
        + encodeURIComponent(caseCorrectedString)
            .replace(/%20/g, '+')
        ;
    return res;
}
