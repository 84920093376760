import { Note } from "@tonaljs/tonal";


const sevenFingerings = [123, 13, 23, 12, 1, 2, 0];

const trumpet = [
    ...sevenFingerings,            // up to C4
    ...sevenFingerings,            // up to G4
    ...sevenFingerings.slice(2),   // to C5
    ...sevenFingerings.slice(3),   // to E5
    ...sevenFingerings.slice(4),   // to G5
    ...sevenFingerings.slice(2),   // to C6
    ...sevenFingerings.slice(3),   // to E6
    ...sevenFingerings.slice(4),   // to G6 (useful for single horn)
    ...sevenFingerings.slice(2),   // to C7
]

const tbnLookup = {
    123: 7, 13: 6, 23: 5, 12: 4, 1: 3, 2: 2, 0: 1
};

const trombone = trumpet.map(f => tbnLookup[f]);
trombone[Note.midi('F#4') - Note.midi('E2')] = '+3';
trombone[Note.midi('G4') - Note.midi('E2')] = '+2';


const fingerings = {
    trumpet, trombone, doubleHorn: [
        '123', '13', '23', '12', '1', '2', '0', '123', '13', '23', '12', '1', '2', '0', '23', '12', '1', '2', '0', '12', '1', '2', '0', '1', '2', '0', ['T2', '23'], ['T', '12'], ['T1', '1'], ['T2', '2'], ['T', '0'], 'T23', 'T12', 'T1', 'T2', 'T', 'T2', 'T', 'T23', 'T12', 'T1', 'T2', 'T',
    ],
    bassTrombone: [
        ['6', 'T1'], '5', '4', '3', '2', '1', 'TT6', ['T♭7', 'TT♭4'], ['T♭5', 'TT♭2'], ['T♭4', 'TT1'], 'T♭3', ['7', 'T2'], ['6', 'T1'], '5', '4', '3', '2', '1', ['7', 'T2'], ['6', 'T1'], '5', '4', '3', ['2', '7'], ['1', '6'], '5', '4', '3', ['2', '6'], ['1', '5'], '4', ['3', '6'], ['2', '5'], ['1', '4'], '3', '2', '1', '3', '+2', '+3', '2', '1', '4', ['3', '1'], '2', '1',
    ],
    ebTubaNonCompensating: [
        '1234', '134', '234', '124', ['24', '123'], ['4', '13'], '23', '12', '1', '2', '0', ['24', '123'], ['4', '13'], '23', '12', '1', '2', '0', '23', '12', '1', '2', '0', '12', '1', '2', '0', '1', '2', '0', '23', '12', '1', '2', '0', '12', '1', '2', '0',
    ],
    ebTubaCompensating: [
        '134', '234', '124', '14', ['24', '123'], ['4', '13'], '23', '12', '1', '2', '0', ['24', '123'], ['4', '13'], '23', '12', '1', '2', '0', '23', '12', '1', '2', '0', '12', '1', '2', '0', '1', '2', '0', '23', '12', '1', '2', '0', '12', '1', '2', '0',
    ],
}

fingerings.bbTubaNonCompensating = [
    ...fingerings.ebTubaNonCompensating
];

fingerings.bbTubaCompensating = [
    ...fingerings.ebTubaCompensating
];

export { fingerings };
