import React, { useEffect, useState, useRef } from 'react';
import { MusicIcon, PrintIcon, ListIcon, SoundIcon, ShareIcon, CaretDownIcon, CaretRightIcon, InstrumentIcon, TeacherIcon } from '../assets/icons';
import INSTRUMENTS from '../data/instruments';
import { Flat } from '../utils/MusicSymbolsFormatting';
import { useWidthDetector } from '../utils/useWidthDetector';
import ScaleCpt from './ScaleCpt';
import { isTabletOrPhone } from '../utils/miscUtils';
import { Link } from 'react-router-dom';

const printExampleThumbnail = require('../assets/print-example-0-3-17-200.png');


const WhatYouCanDo = ({ setShowPrintExample }) => {

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        if (!isOpen & isTabletOrPhone()) {
            // on a phone scroll down so that the 'what else..?' is at the top
            setTimeout(
                () => { whatElseRef.current.scrollIntoView({ block: "start", inline: "nearest", behavior: 'smooth' }) }
                , 10
            );
        }
        setIsOpen(!isOpen);
    }

    const whatElseRef = useRef(null);

    const [dimensions, setDimensions] = useState({ width: 0 });
    const containerRef = useRef();
    useWidthDetector(containerRef, setDimensions);

    const exampleScaleHeader = {
        id: "60-maj",
        tonicPC: "C",
        basetype: "MAJ",
        renderedType: "MAJ",
        octave: 4,
        range: "8",
        showChord: "SCALE",
    };

    return (
        <React.Fragment>
            <div className='landing-page-container'>
                <div className='landing-page-block'>
                    <p>Use scale-lookup to:</p>
                    <div className='info-card'>
                        <div className='icon-box'>
                            <MusicIcon className='icon-huge' />
                        </div>
                        <h2>Look up a scale</h2>

                        <div
                            className='content'
                            ref={containerRef}  // for determining width, for scale display
                        //                         (If put it down there then it doesn't exist when this cpt is first rendered;
                        //                          might as well use this as all .content areas are same width.)
                        >
                            {/* <p>Each scale is shown in music notation and with note names.</p> */}
                            {/* <p>Just type a starting note and a scale type in the search bar.</p> */}
                            <p>
                                For example, type <Link to='/search?q=C+major'>C major</Link> or <Link to='/search?q=F%23+minor'>F# minor</Link> or <Link to='/search?q=Bb+blues%2C'>Bb blues</Link> in the search bar.
                            </p>
                        </div>
                    </div>

                    <div className='info-card'>
                        <div className='icon-box'>
                            <InstrumentIcon className='icon-huge' />
                        </div>
                        <h2>Add an instrument</h2>

                        <div className='content'>
                            <p>&hellip;To set the clef and range, and to show fingerings (if available yet&hellip;)</p>
                            <div className='examples'>
                                For example, type:
                                <ul>
                                    <li><Link to='/search?q=D+minor+recorder'>D minor, recorder</Link></li>
                                    <li><Link to='/search?q=G+lydian+piano'>G lydian, piano</Link></li>
                                    <li><Link to='/search?q=Bb+chromatic%2C+trombone'>Bb chromatic, trombone</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div
                        id='what-else'   // scroll target for 'what else..?'
                        ref={whatElseRef}
                    />
                    <button
                        onClick={toggle}
                    >
                        {isOpen ? <CaretDownIcon /> : <CaretRightIcon />} What else can I do?
                    </button>

                    {isOpen && <React.Fragment>

                        <div className='info-card'>
                            <div className='icon-box'>
                                <SoundIcon className='icon-huge' />
                            </div>
                            <h2>Hear each scale played</h2>

                            <div className='content'>
                                <p>Click the Play button, or click individual notes to hear them:</p>
                                <div className='notation-example'>
                                    <ScaleCpt
                                        instrument={INSTRUMENTS.find(i => i.name === 'treble-clef')}
                                        gradeHeader={{ MIN: { subtypes: [] } }}
                                        scaleHeader={exampleScaleHeader}
                                        // handleScaleAction={handleScaleAction}
                                        indexInList={0}
                                        availableWidth={dimensions.width + 20}  // ??? I think extra 20 is ok cos don't need 'spacer' grid-areas..
                                        //                                       ..but not exactly sure! Anyway it looks ok on phone/ipad/desktop.
                                        showHeader={false}
                                    />
                                </div>
                                <p>If your search includes an instrument then the sounding pitch will be transposed appropriately.</p>
                            </div>
                        </div>

                        {/* <div className='info-card'>
                            <div className='icon-box'>
                                <ArpeggioIcon className='icon-huge' />
                            </div>
                            <div>
                                Explore chords and arpeggios for each scale.
                            </div>
                        </div> */}

                        <div className='info-card'>
                            <div className='icon-box'>
                                <ListIcon className='icon-huge' />
                            </div>
                            <h2>Look up a list of scales</h2>

                            <div className='content'>
                                <div className='examples'>
                                    Examples (click!):
                                    <ul>
                                        <li><Link to='/search?q=bb+d+major+a+minor+trumpet'>B<Flat />, D major, A minor, trumpet</Link></li>
                                        <li><Link to='/search?q=dorian+piano'>dorian scale in all keys, piano</Link></li>
                                        <li><Link to='/search?q=modes+of+b+major+tenor+sax'>modes of B major, tenor sax</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='info-card'>
                            <div className='icon-box'>
                                <PrintIcon className='icon-huge' />
                            </div>
                            <div className='card-heading'>
                                <h2>Print a scale sheet</h2>
                                <TeacherBadge />
                            </div>

                            <div className='content'>
                                <p>You can set up which note names and/or fingerings you want to show.</p>
                                <p>Click the thumbnail below to see an example:</p>
                                <button
                                    className='image-as-button'
                                    onClick={() => { setShowPrintExample(true) }}
                                >
                                    <img
                                        className='print-example-thumbnail'
                                        src={printExampleThumbnail}
                                        alt="Thumbnail of PDF scale sheet"
                                    />
                                </button>
                                <p>(This works best using Chrome at the moment.)</p>
                            </div>
                        </div>

                        <div className='info-card'>
                            <div className='icon-box'>
                                <ShareIcon className='icon-huge' />
                            </div>
                            <div className='card-heading'>
                                <h2>Share a scale (or a list)
                            </h2>
                                <TeacherBadge />
                            </div>

                            {/* <p>If you're a teacher you could share a list with a student which specifies their instrument. </p> */}

                            <div className='content'>
                                <p>Just copy the url and paste into an email or message.</p>
                                <p>(But at the moment changes to ranges won't be shared in the link.)</p>
                            </div>
                        </div>

                    </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}


function TeacherBadge() {
    return (
        <div className='teacher-badge'>
            <TeacherIcon /> Useful for teachers
        </div>
    )
}

export function FullScreenImage({ src, alt, dismiss }) {

    useEffect(() => {
        return dismiss;    // make sure to reset
    }, [dismiss]);

    return (
        <React.Fragment>
            <div
                className='image-backdrop'
                onClick={dismiss}
            >
                <div className='image-full-screen'>
                    <img
                        src={src}
                        alt={alt}
                        onClick={dismiss}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}



export default WhatYouCanDo;