import React, { useRef } from 'react';

import { NavLink, NavItem, Nav, Collapse } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';

import { TypesIcon, HomeIcon, CloseIcon, InfoIcon, SettingsIcon, ContactIcon, PrintIcon, ChevronUpIcon, ChevronDownIcon } from '../assets/icons';
import { Link } from 'react-router-dom';
import Overlay from './Overlay';
import SettingsPane from './SettingsPane';
import AboutPane from './AboutPane';
import ContactPane from './ContactPane';
import UnderConstruction from './UnderConstruction';
import ValidScaleTypesPane from './ValidScaleTypesPane';


const Sidebar = ({ sidebarActiveTab, setSidebarActiveTab, handlePrintRequest }) => {

    const dismissSidebar = () => {
        setSidebarActiveTab(null);
    }

    const ref = {
        SETTINGS: useRef(null),
        SCALE_TYPES: useRef(null),
        ABOUT: useRef(null),
        CONTACT: useRef(null),
    };

    // const collapseProps = {  // ??? doesn't work..?!
    //     // timeout: 1000
    //     // timeout: {
    //     //     appear: 1000,
    //     //     enter: 100,
    //     //     exit: 100
    //     // }
    // }

    const toggleCollapse = e => {
        const clickedId = e.currentTarget.name;
        if (clickedId !== sidebarActiveTab) {
            setSidebarActiveTab(clickedId);
            setTimeout(
                () => ref[clickedId].current.scrollIntoView({ block: "start", inline: "nearest", behavior: 'smooth' })
                , 300    // need a large delay presumably to allow the expand to complete first
            );
            // ??? scrolling is slightly shoddy if e.g. open Scale Types, then scroll down and tap Settings.
            // cos I think transitions (collapse + expand) are not necessarily finished when the scroll happens.
            // fix by setting the timeouts?
        }
        else {
            setSidebarActiveTab('NONE');
        }
    }

    const handlePrintClick = () => {
        dismissSidebar();
        setTimeout(handlePrintRequest, 10);
    }


    return (
        <React.Fragment>
            {sidebarActiveTab &&
                <Overlay
                    className='sidebar-open'
                    dismiss={dismissSidebar}
                />
            }

            <CSSTransition
                classNames="slide-in-from-right"
                in={!!sidebarActiveTab}
                timeout={900}
                unmountOnExit
            >

                <div id='settings-window'
                    className={(!!sidebarActiveTab ? 'open' : 'closed')}
                >

                    <Nav>
                        <NavItem>
                            <NavLink
                                className="sidebar-close-button"
                                onClick={dismissSidebar} >
                                <CloseIcon />
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <div className='sidebar-pane'>
                        <UnderConstruction />
                    </div>

                    <Link
                        className={`sidebar-section-button`}
                        to={`/`}
                    >
                        <HomeIcon />Home
                    </Link>

                    <button
                        className={`sidebar-section-button d-block d-xl-none`}  // for desktops (xl) print is already on main navbar so don't repeat here
                        onClick={handlePrintClick}
                    >
                        <PrintIcon />Print (save PDF scale sheet)
                    </button>

                    <button
                        className={`sidebar-section-button ${sidebarActiveTab === 'SCALE_TYPES' ? 'active' : ''}`}
                        name='SCALE_TYPES'
                        onClick={toggleCollapse}
                        ref={ref.SCALE_TYPES}
                    >
                        <TypesIcon />List of scale types
                        <AppropriateCaret isOpen={sidebarActiveTab === 'SCALE_TYPES'} />
                    </button>
                    <Collapse
                        // {...collapseProps} 
                        isOpen={sidebarActiveTab === 'SCALE_TYPES'}
                    >
                        <div className='sidebar-pane active' >
                            <ValidScaleTypesPane
                                setSidebarActiveTab={setSidebarActiveTab}
                            />
                        </div>
                    </Collapse>

                    <button
                        className={`sidebar-section-button ${sidebarActiveTab === 'SETTINGS' ? 'active' : ''}`}
                        name='SETTINGS'
                        onClick={toggleCollapse}
                        ref={ref.SETTINGS}
                    >
                        <SettingsIcon />Settings
                        <AppropriateCaret isOpen={sidebarActiveTab === 'SETTINGS'} />
                    </button>
                    <Collapse
                        //  {...collapseProps} 
                        isOpen={sidebarActiveTab === 'SETTINGS'}
                    >
                        <div
                            className='sidebar-pane active'
                        >
                            <SettingsPane />
                        </div>
                    </Collapse>



                    <button
                        className={`sidebar-section-button ${sidebarActiveTab === 'ABOUT' ? 'active' : ''}`}
                        name='ABOUT'
                        onClick={toggleCollapse}
                        ref={ref.ABOUT}
                    >
                        <InfoIcon />About
                        <AppropriateCaret isOpen={sidebarActiveTab === 'ABOUT'} />
                    </button>
                    <Collapse
                        //  {...collapseProps} 
                        isOpen={sidebarActiveTab === 'ABOUT'}
                    >
                        <div
                            className='sidebar-pane active'
                        >
                            <div>
                                <AboutPane
                                    setSidebarActiveTab={setSidebarActiveTab}
                                />
                            </div>
                        </div>
                    </Collapse>

                    <button
                        className={`sidebar-section-button ${sidebarActiveTab === 'CONTACT' ? 'active' : ''}`}
                        name='CONTACT'
                        onClick={toggleCollapse}
                        ref={ref.CONTACT}
                    >
                        <ContactIcon />Contact
                        <AppropriateCaret isOpen={sidebarActiveTab === 'CONTACT'} />
                    </button>
                    <Collapse
                        //  {...collapseProps} 
                        isOpen={sidebarActiveTab === 'CONTACT'}
                    >
                        <div className='sidebar-pane active'>
                            <div>
                                <ContactPane />
                            </div>
                        </div>
                    </Collapse>

                    <SidebarFooter />

                </div>
            </CSSTransition>
        </React.Fragment>
    )
}

function AppropriateCaret({ isOpen }) {
    return <div className='appropriate-caret'>
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </div>
}


function SidebarFooter() {
    return (
        <div className='sidebar-pane'>
            <div className='sidebar-footer'>
                <div>
                    This software &copy; 2021 Angus Moncrieff, all rights reserved &bull; v{process.env.REACT_APP_VERSION}
                </div>
            </div>
        </div>
    )
}

export default Sidebar;