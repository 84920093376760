export const setPrinting = (printing = true) => ({
    type: 'SET_PRINTING',
    printing
});

export const setAudio = (audio) => ({
    type: 'SET_AUDIO',
    audio
});

export const setPlayer = (player) => ({
    type: 'SET_PLAYER',
    player
});

export const setPlayingScaleId = (playingScaleId = null) => ({
    type: 'SET_PLAYING_SCALE_ID',
    playingScaleId
});

export const setTourToRun = (tourToRun = null) => ({
    type: 'SET_TOUR_TO_RUN',
    tourToRun
});

