import React, { useState } from 'react';
import { ErrorIcon, WarningIcon, InfoIcon, CloseIcon } from '../assets/icons';


const FeedbackBar = ({ searchFeedback, showSearchOnPhone }) => {

    const { errors, warnings, info } = searchFeedback;
    const [show, setShow] = useState(true);

    let feedbackType, icon;

    if (errors[0]) {
        feedbackType = 'error';
        icon = <ErrorIcon />;
    }
    else if (warnings[0]) {
        feedbackType = 'warning';
        icon = <WarningIcon />;
    }
    else if (info[0]) {
        feedbackType = 'info';
        icon = <InfoIcon />;
    }

    return (
        feedbackType && show ?
            <div
                className={`search-feedback-strip ${showSearchOnPhone ? 'avoid-phone-search-bar' : ''} ${feedbackType} print-hide`}
                data-testid='feedback-strip'
            >
                <span>
                    <span className='mr-1'>{icon}</span>
                    {searchFeedback.errors[0] || searchFeedback.warnings[0] || searchFeedback.info[0]}
                </span>
                <button
                    className='close-button'
                    onClick={() => setShow(false)}
                >
                    <CloseIcon />
                </button>
            </div>
            : null
    )
}


export default FeedbackBar;
