import { createStore, combineReducers } from 'redux';
import settingsReducer from '../reducers/settings.js';
import sessionReducer from '../reducers/session.js';
import { loadState, saveState } from './localStorage';
import { settingsReducerDefaultState } from '../reducers/settings';
import throttle from 'lodash/throttle';


export default () => {

    let persistedState = loadState() || {};

    // Following is to avoid trouble if local storage is missing one of the settings (as would happen
    // if I released a new version which introduced a new setting.)

    // In particular, might need to fill in gaps in instrumentPrefs - i.e. if the new version of the app
    // introduces varieties for an instrument; without this code the whole instPrefs object will either
    // be the default one or will be the persisted one (which is incomplete). 000314
    const instrumentPrefsCombined = {
        ...settingsReducerDefaultState.instrumentPrefs,
        ...persistedState?.settings?.instrumentPrefs
    }

    persistedState.settings = {
        ...settingsReducerDefaultState,
        ...persistedState?.settings,
        instrumentPrefs: instrumentPrefsCombined
    };

    // showFingerings is now a 3-state string (000275, 000077). Following prevents crash for any returning users who might
    // have old local storage with the old boolean value.
    if (typeof persistedState.settings.showFingerings === 'boolean') {
        persistedState.settings.showFingerings = persistedState.settings.showFingerings ? 'AVAILABLE' : '';
    }
    if (typeof persistedState.settings.showNoteNames === 'boolean') {
        persistedState.settings.showNoteNames = persistedState.settings.showNoteNames ? 'AVAILABLE' : '';
    }

    console.log('persistedState:', persistedState);
    const store = createStore(
        combineReducers({
            settings: settingsReducer,
            session: sessionReducer
        }),
        persistedState
    );

    store.subscribe(throttle(
        () => {
            saveState({
                settings: store.getState().settings
            });
            console.log(store.getState().settings);
        }, 1000)
    );

    return store;
}
