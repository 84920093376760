import React from 'react';
import { Link } from 'react-scroll';
import { numberOfCharsInJSX } from '../utils/miscUtils';
import { formatAccidentalsJSX } from '../utils/MusicSymbolsFormatting';

const GroupNav = ({
    currGroupBy,
    group,
    className: classNameProp,
    tabIndex,
    onClick,
}) => {

    const groupNavJSX = formatAccidentalsJSX(group.shortName || group.name);
    const disabled = group.scaleHeaders.length === 0;

    const chars = numberOfCharsInJSX(groupNavJSX)
    const isPitch = ['TONIC'].includes(currGroupBy);
    const condensed = isPitch ? chars > 4
        : true     // in fact let's always condense     // allowShorten && chars > 9 ? 'condensed' : ''
        ;

    const className = `${classNameProp} ${isPitch ? 'pitch' : ''} ${condensed ? 'condensed' : ''}`;

    return (disabled ?
        (
            <div className={className + ' disabled'}>
                {groupNavJSX}
            </div>
        )
        : (
            <Link
                to={group.id}
                href=''
                containerId='scale-list-container'
                smooth
                duration={300}
                className={className}
                tabIndex={tabIndex || 0}
                key={group.id}
                value={group.id}
                onClick={onClick}
            >
                {groupNavJSX}
            </Link>
        )
    );
};

export default GroupNav;