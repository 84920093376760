import React from 'react';
import Fuse from 'fuse.js';

import { CIRCLE_5THS } from '../logic/musicStructures';
import { searchTokenList, tokenWordList } from '../data/searchTokenList';
// import memoize from 'lodash/memoize';
import escapeRegExp from 'lodash/escapeRegExp';
// import { logArrayElements } from '../utils/DevUtils';
import { Flat, formatAccidentalsJSX, Sharp } from '../utils/MusicSymbolsFormatting';

import { color } from '../utils/DevUtils';



// const findMatchingTokens = memoize(_findMatchingTokens);  // ???358 put back 
// const tokenize = memoize(_tokenize);

const fuseOptions = {
    includeScore: true,
    distance: 10,
};
const fuse = new Fuse(tokenWordList, fuseOptions);

const searchExamples = [
    { menuHeader: 'Examples of searches:', disabled: true },
    { type: 'example', label: 'C major' },
    { type: 'example', label: 'Bb minor', description: <span>&ndash; Use b (small B) for flat sign <Flat /></span> },
    { type: 'example', label: 'F# chromatic', description: <span>&ndash; Use # (hash) for sharp sign <Sharp /></span> },
    { type: 'example', label: 'd maj', description: `\u2013 abbreviations OK` },
    { type: 'example', label: 'C maj trombone', description: '\u2013 instr. for clef & sounding pitch' },
    { type: 'example', label: 'A loc, E alt, G mel', description: '\u2013 look up several scales' },
    { type: 'example', label: 'C, F, Bb major', description: '\u2013 one scale from various notes' },
    { type: 'example', label: 'E maj, min', description: '\u2013 different scales, same starting note' },
    { type: 'example', label: 'major', description: '\u2013 no starting note: scale in 12 keys' },
    { type: 'example', label: 'modes of C major', description: `\u2013 get modes of a scale` },
    { type: 'example', label: 'modes of major', description: '\u2013 all modes, all keys (12×7 scales)' },
];

export function getSuggestions(freeText, callback) {

    const results = parseFreeText(freeText, true)   // true = include punctuated labels
        .concat(searchExamples)
        ;

    if (typeof callback === 'function') callback('', results);
    else return results;

}

export function parseFreeText(freeText, addLabels = false) {

    console.log(...color('yellow', 'parseFreeText'));
    // console.log('mw START, query:', freeText);

    let results = [];

    if (!!freeText) {

        const clauses = freeText
            .replace(/;/g, ',')             // replace semicolons with commas (they mean the same thing)
            .replace(/\(/g, '')             // ignore parentheses 000427
            .replace(/\)/g, '')             //    "
            .replace(/,{2,}/g, ',')         // remove excess repeated commas
            .split(',')                     // split into clauses
            .map(c => c.trim())             // leading/trailing spaces
            .filter(el => !!el)             // remove empty elements
            .map(text => ({ text }))
            ;

        // console.log('xxx', clauses)

        // ???358 could just do a loop which handles all but last one differently
        // i.e. just take top result
        // and build up the precedingClauses thing as we go (will be able to lose that reduce below..)
        clauses.forEach(clause => {
            const wordArray = clause.text
                .trim()                        // trim leading/following whitespace
                .replace(/ {2,}/g, ' ')        // remove excess repeated spaces
                .concat('\u000A')              // mark the end (EOL)
                .split(' ')                    // split into words
                ;
            clause.results = _tokenizeWordBased(wordArray);   // ???358 call in a way that says 'top result only' for all but final clause?
            // oh but i need to do all the work before I know which one that is.. oh well

            // POSSIBLY THIS NEW CLAUSES CODE CAN BE STREAMLINED?
            // REMOVE COMMA-TYPE STUFF FROM LOWER DOWN: ALL DEALT WITH HERE
            // GET BACK INTO FIXING THOSE TESTS INSTANCES - E.G. MAJ PEN
            // can make it work with correct 'composite' weight - but that breaks c maj
            // can I make that composite penalty not apply to tonics?

        });

        const precedingClauses = clauses.slice(0, clauses.length - 1)
            .reduce((acc, currClause) => {
                return {
                    tokens: acc.tokens.concat(currClause.results[0].tokens),
                    score: 1,               // irrelevant: we're just using top result for each clause
                }
            }, { tokens: [] }
            );

        if (clauses.length > 0) {
            let lastClauseResults = clauses[clauses.length - 1].results;
            // if (lastClauseResults.length === 0) lastClauseResults.push(nonMatch);

            // Now stitch together the clause results
            results = lastClauseResults.map(lastClauseResult => {
                // like concat all the top results from earlier clauses, finally appending the current result for final clause jesus
                return {
                    tokens: precedingClauses.tokens.concat(lastClauseResult.tokens),
                    score: lastClauseResult.score,
                    debug: lastClauseResult.debug
                }
            });
        }

    }

    if (addLabels) {
        // Make up nice labels including appropriate punctuation for display as suggestions.
        results = results.map(result => {
            const tokenDesc = result.tokens[result.tokens.length - 1].description;
            return {
                ...result,
                label: buildStringFromTokens(result.tokens),
                description: tokenDesc ? <span>{formatAccidentalsJSX(tokenDesc)}</span> : undefined,
            }
        });
    }

    if (!results.length) results.push({ label: 'No suggestions', disabled: true });

    // console.log('358 352', results)

    return results;

}


export function buildStringFromTokens(tokens) {
    return tokens
        .filter(token => (token.score !== undefined ? token.score : 1) > 0)    // ignore non-matches (but pass thru if 'score' is undefined)
        .map((token, i) => {
            let connector = ' ';
            const prevTokenType = tokens[i - 1]?.tokenType;
            if (i === 0) connector = '';
            else if (token.tokenType === 'instrument') connector = ', ';
            else if (token.tokenType === prevTokenType) connector = ', ';
            else if (token.tokenType === 'tonic' && prevTokenType === 'scaletype') connector = ', ';
            return connector + token.label;
        })
        .join('')
        ;
}

// ???358 grammar-based

// const grammars = [
//     ['tonic', 'scaletype'],                     // could repeat
//     ['tonic', 'scaletype', 'instrument'],
//     ['tonic(s)-type(s)', 'instrument'],         // ???
//     ['modes', 'scaletype'],                // but only a modeable one
//     ['modes', 'tonic', 'scaletype']
// ];

// can reduce results so that consecutive tonics are collated 
// consecutive types too

/*
So as we're going along, if current reduced form = 'tonic'  => suggest some scaletypes

repeats: if enter 'c maj c' then *don't* suggest major again!

*/



const nonMatch = { tokens: [], score: 0 };

const WORD_MATCH_CUTOFF = 9;   // drop word interpretations which score worse than this below the best word match
const MAX_RESULTS = 12;


function getWordMatches(string) {

    let searchResults = [], fuzzyResults = [];
    const EOL_REGEX = /\n/;
    const isEndOfQuery = EOL_REGEX.test(string);
    const allowMultiple = isEndOfQuery;
    const trimmedString = string
        .replace(EOL_REGEX, '')
        .toLowerCase()
        ;

    const pitchResults = getPitchMatches(trimmedString, allowMultiple);
    // console.log('358 pitch matches', pitchResults);

    if (!pitchResults[0] || isEndOfQuery) {   // yes want multiple results if at end of query (like where user might be typing..)

        fuzzyResults = fuse.search(trimmedString)
            .map(match => ({
                word: match.item,
                score: (1 - match.score) * 100,
                //             debug: `${res.score}→${formatScore(score)}`
            }))
            ;

        // console.log('fuse', fuzzyResults);

    }

    searchResults = pitchResults.concat(fuzzyResults)
        .sort((i, j) => j.score - i.score)
        ;

    const bestScore = searchResults[0]?.score || 0;
    searchResults = searchResults
        .filter(r => r.score >= bestScore - WORD_MATCH_CUTOFF)
        .slice(0, MAX_RESULTS)
        ;

    // console.log('358 WORDS:', searchResults)

    return searchResults;
}

// ???358 mw
// if this works then the matching fns shouldn't return 'tokens' any more
// and perhaps don't need special handling for pitches? I've already put them back in searchTokensList
// I guess the point about pitches is that they *must* be 100% match, (apart from if still typing/unfinished)
// oh: also that something that *is* a pitch will not be matched additionally unless it's at the end.
// 'A maj' just means 'A Major', but 'maj a' could hint 'Major Aeolian'

export function getPitchMatches(string, allowMultiple = true) {   // ???358 poss don't ultimately need to export

    // ???358 works, but this logic for Eb before E# - is it ok?
    // nicer to do by adjusting the score?

    // order A B C D E F G, but then by 'likeliness'
    // e.g. enter E, it suggests E Eb E#
    //      enter C, it suggests C C# Cb
    // i.e. for flat and sharp notes, order by distance from 'middle' natural note (D)
    const indexOfD = CIRCLE_5THS.indexOf('D');
    const tonicsForSearch = CIRCLE_5THS.slice(CIRCLE_5THS.indexOf('Abb'), CIRCLE_5THS.indexOf('C##') + 1)
        .sort((p, q) => (
            (p.length === 1 ? p.charCodeAt() : Math.abs(CIRCLE_5THS.indexOf(p) - indexOfD) * 100)
            - (q.length === 1 ? q.charCodeAt() : + Math.abs(CIRCLE_5THS.indexOf(q) - indexOfD) * 100)
        ))
        .map(pitchClass => ({             // package up as a token
            label: pitchClass,
            tokenType: 'tonic',
            value: pitchClass.replace(/#/g, 'sh'),
        }))
        ;

    const pitchClassMatcher = allowMultiple ?
        new RegExp(`^${escapeRegExp(string)}`, 'i')      // if C entered then match with C# and Cb
        : new RegExp(`^${escapeRegExp(string)}$`, 'i')     // or not: just return an exact match
    const matches = tonicsForSearch.filter(t => pitchClassMatcher.test(t.label))
        .map(t => ({
            tokens: [t],
            word: t.label.toLowerCase(),    // ???358 mw 
            // ??? I think the point is that I've done this in a quick/hacky way; can streamline a lot
            // i.e. tonicsForSearch doesn't need to set up those token objects
            // ..ah in fact getSearchTokenFromValue currently uses this fn and expects tokens to come back
            // I think the point is good though: clarify how pitch matching works
            // This is included in ???370
            score: 105 - (t.label.length - string.length),  // pitches score higher (hence 105); but score C over C#/Cb
            debug: `♩`
        }))
        ;

    // console.log('358 getPitchMatches', string, matches[0]?.tokens[0])
    // logArrayElements({ arr: matches, prefix: 'pitch-matches', callback: item => (`${item.word}`) })

    return matches;
}


function _tokenizeWordBased(wordArray) {

    const wordInterpretations = interpretWords(wordArray);
    // console.log('mw WORD INTERPS:', wordInterpretations);

    const tokenMatches = getTokenMatches(wordInterpretations);
    // console.log('mw TOKEN MATCHES:', tokenMatches)

    // Now build up the interpretations of the entire query, i.e. build up each interpretation using the tokenMatches
    // like pieces of a jigsaw.
    let queryInterpretations = combineTokenMatches(tokenMatches)
        .map(queryTokens => {
            const score = queryTokens.reduce((acc, curr, i) => {
                acc += curr.score - (i > 0 ? 1 : 0);   // -1: favour fewer tokens with better coverage; i.e. fewer tokens covering same query words wins
                return acc;
            }, 0);
            const str = queryTokens.reduce((acc, curr) => {
                acc += `${curr.label},`;
                return acc;
            }, '') + formatScore(score);
            return {
                tokens: queryTokens,
                score,
                str,
                debug: formatScore(score),
            }
        })
        .sort((i, j) => (j.score - i.score))
        ;

    // drop interpretations which are way below best (see 000380)
    // const cutoffScore = queryInterpretations[0].score * 0.6;
    const cutoffScore = Math.max(queryInterpretations[0].score - 150, 0);
    queryInterpretations = queryInterpretations
        .filter(i => i.score > cutoffScore)
        ;

    // logArrayElements({
    //     arr: queryInterpretations, prefix: 'mw ENTIRE', callback: item => (`${item.str} ${item.score}`)
    // });

    return queryInterpretations;




    // interpret each word, including handling ellision (like 'cmaj')
    function interpretWords(wordArray) {

        // const wordInterpretations = wordArray.map(word => {
        //     return getWordMatches(word);
        // })

        let wordInterpretations = [];

        wordArray.forEach(word => {
            const matches = getWordMatches(word);
            wordInterpretations.push(matches);

            let best = {
                pitchMatch: null,
                scaleTypeMatch: null,
                score: matches[0]?.score || 0
            };

            if (best.score < 90) {
                // Best score is not great - try for ellided 'pitch-word' match, like 'cmaj'
                // No longer do I assume that the word will be a scale type (though that makes the most sense)..
                // - however, variable names still 'scaleType-' cos I can't think of a more generic name which is clear ('postWord'?)

                for (let tonicChars = 1; tonicChars < 4; tonicChars++) {       // allow up to 3 chars for pitch (e.g. Bbb)
                    const pitchMatch = getPitchMatches(word.substring(0, tonicChars), false)[0] || nonMatch;

                    if (pitchMatch.score >= 100) {        // only do this for perfect pitch class
                        const scaleTypeTestString = word.substring(tonicChars);
                        const scaleTypeMatches = getWordMatches(scaleTypeTestString);

                        for (let scaleTypeMatch of scaleTypeMatches) {
                            if (scaleTypeMatch.score < best.score) break;   // no improvement 

                            best = {
                                pitchMatch,
                                scaleTypeMatch,
                                score: scaleTypeMatch.score
                                //     debug: `${formatScore(score)} ellision (${formatScore(scaleTypeMatch.score)} ${scaleTypeTestString})`
                            };
                        }
                    }
                }
                if (!!best.scaleTypeMatch) {   // we identified a good pair
                    // remove original matches with 'cmaj' (arguably could leave them, but might get messy cos of new extra word posn.)
                    wordInterpretations.pop();
                    // insert the match for pitch, and then the match for 'scale type' (in a newly added word posn.)
                    // From here on they'll just be treated as separate well-matched words.
                    wordInterpretations.push([best.pitchMatch], [best.scaleTypeMatch]);
                }
            }
        })

        return wordInterpretations;
    }



    function getTokenMatches(wordInterpretations) {
        // tokenMatches = array, where each element contains an array of tokens which match and which start from the nth word.
        // e.g. 'c maj' --> tokenMatches[0] = ['', 'C']
        //                  tokenMatches[1] = ['', 'Major', 'Major Pentatonic']
        // '' means no-match - i.e. any word could always be interpreted as being junk.
        // In reality, each item is not just a string, but an object, like:
        //   { label, length, score }
        //
        // ???379 I think I can make this function work much more efficiently - but don't know that I need to..

        // Set up blank token matches - i.e. with a single token - a 'nonmatch' - in each position
        let tokenMatches = wordInterpretations.map(w => ([{ label: '', numWords: 1, score: 0 }]));    // one set of token matches per word position

        for (let wordPosn = 0; wordPosn < wordInterpretations.length; wordPosn++) {

            for (let token of searchTokenList) {
                const tokenWords = token.labelCleaned.split(' ');
                let tokenScore = 0, queryWordsConsumed = 0;

                for (let j = 0; (j < tokenWords.length); j++) {
                    const tokenWord = tokenWords[j];

                    if (wordPosn + j >= wordInterpretations.length) {                          // ran out of query words..
                        // console.log('xx', token.labelCleaned, tokenWords.length, queryWordsConsumed)
                        tokenScore -= (tokenWords.length - queryWordsConsumed) / 10;    // so score it down according to word count difference
                        break;
                    }

                    const interpretationIndex = wordInterpretations[wordPosn + j]
                        .map(int => int.word)
                        .indexOf(tokenWord);

                    if (interpretationIndex === -1) {
                        tokenScore = 0;
                        break;
                    }

                    // Yes we have a match for this token word.
                    queryWordsConsumed++;
                    const matchedWord = wordInterpretations[wordPosn + j][interpretationIndex];
                    // console.log('mw', matchedWord);
                    tokenScore += matchedWord.score;
                }

                if (tokenScore > 0) {
                    // console.log('mw redirect', token, token.baseLabel, searchTokenList.filter(t => t.label === token.baseLabel))
                    const tokenMatched = token.redirectToLabel ?
                        searchTokenList.filter(t => t.label === token.redirectToLabel)[0]
                        : token;
                    const newTokenMatch = {
                        ...tokenMatched,
                        numWords: queryWordsConsumed,
                        score: tokenScore,
                    };

                    // Avoid duplicate token matches from this position (can be caused by 'redirects').
                    // e.g. if enter 'blue' then you'll match with 'blues minor' and 'blues' (which is a redirect to 'blues minor')
                    const existingDupTokenMatchIndex = tokenMatches[wordPosn].findIndex(m => m.label === tokenMatched.label);
                    const existingDupTokenMatch = tokenMatches[wordPosn][existingDupTokenMatchIndex];

                    if (existingDupTokenMatch) {
                        if (tokenScore > existingDupTokenMatch.score) {
                            tokenMatches[wordPosn][existingDupTokenMatchIndex] = newTokenMatch;    // update the previous match, new one is better
                        }
                        // else do nothing: duplicate match which is no improvement on existing one
                    }
                    else {
                        tokenMatches[wordPosn].push(newTokenMatch);
                    }

                }
            }
        }

        return tokenMatches;
    }
}



// ??? memoize!!

function combineTokenMatches(tokenMatches) {
    // tokenMatches contains one item per query word.
    // tokenMatches[0]: array of all matching tokens (however many words) which start at word 0
    // tokenMatches[1]: array..                                        ...which start at word 1 etc.

    // console.log('mw combineTokenMatches', tokenMatches[0]);

    let results = [];

    if (tokenMatches.length === 0) {
        return results;
    }

    // filter out all but winning token which start at first word, and which don't go to the last word
    // (cos we want multiple suggestions for the last bit of query).
    // logArrayElements({
    //     arr: tokenMatches[0], prefix: 'mw tokenMatches[0] (in)',
    //     callback: item => (`${item.label} ${item.score}`)
    // });
    for (let numWords = 1; numWords < tokenMatches.length; numWords++) {
        const tokensToFilter = tokenMatches[0].filter(t => t.numWords === numWords);
        const tokensToLeave = tokenMatches[0].filter(t => t.numWords !== numWords);
        const best = tokensToFilter.reduce((best, curr) => {
            best = curr.score > best.score ? curr : best;
            return best;
        }, { label: '', score: 0 });
        tokenMatches[0] = tokensToFilter.filter(t => t.label === best.label)
            .concat(tokensToLeave);
    }

    // But in all positions - even last word - throw away non-match if there is a scoring 1-word match in that position
    if (tokenMatches[0].filter(t => t.numWords === 1 && t.score > 0)[0]) {   // there IS a scoring 1-word match..
        tokenMatches[0] = tokenMatches[0].filter(t => !(t.score === 0));     // ..so remove the corresponding non-match
    }

    // logArrayElements({
    //     arr: tokenMatches[0], prefix: 'mw tokenMatches[0] (filt)',
    //     callback: item => (`${item.label} ${item.score}`)
    // });

    if (tokenMatches.length === 1) {
        results = tokenMatches[0].map(t => [t]);
    }
    else {
        // go through the token matches for the first word..
        for (let tokenMatch of tokenMatches[0]) {

            // tokenMatch should be like { label, numWords, score }
            // console.log('mw tokenMatch:', tokenMatch)

            // look at the point *after* this token
            const tokenMatchesToDo = tokenMatches.slice(tokenMatch.numWords);

            if (tokenMatchesToDo.length) {

                const resultsAfterCurrentToken = combineTokenMatches(tokenMatchesToDo);
                // console.log('mw CURRTOKEN', tokenMatch.label, 'RES AFTER:', resultsAfterCurrentToken)

                for (let afterResult of resultsAfterCurrentToken) {
                    results.push([
                        tokenMatch,
                        ...afterResult,
                    ]);
                }
            }
            else {
                results.push([tokenMatch]);
            }
        }
    }

    return results;
}



function formatScore(score) {
    return Math.round(100 * score) / 100;
}