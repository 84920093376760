import INSTRUMENTS from '../data/instruments.js';
import { circle5thsSlice } from '../logic/musicStructures';
import { pitchClassDisplayString } from './MusicSymbolsFormattingNode';
// import { pitchClassDisplayString } from '../scripts/utilsNode';




const defaultInstName = 'treble-clef';  // ??? ideally fetch from default store

const screenshotCollections = [
    {            // common scale types we'll screenshot in every key, treble and bass clefs
        instruments: [          // Instrument strings should be the instrument name (i.e. id)
            '',                 // default = treble clef
            'bass-clef',
        ],
        scaleTypes: [           // Scale type needs to be the display name
            'Aeolian',
            'Altered',
            'Bebop Dominant',
            'Bebop Major',
            'Blues (major)',
            'Blues (minor)',
            'Chromatic',
            'Diminished 7th',
            'Dominant 7th',
            'Dorian',
            'Harmonic Minor',
            'Ionian',
            'Locrian',
            'Lydian',
            'Lydian Dominant',
            'Major',
            'Major Pentatonic',
            'Melodic Minor',
            'Minor',
            'Minor Pentatonic',
            'Mixolydian',
            'Natural Minor',
            'Phrygian',
            'Phrygian Dominant',
            'Spanish Phrygian',
            'Super Locrian',
            'Whole Tone',
        ],
        tonics: circle5thsSlice('Cb', 'C#')
    },
    {            // common instruments which have fingerings: do the obvious scale types
        instruments: [
            'descant-recorder',
            'flute',
            'soprano-sax',
            'alto-sax',
            'tenor-sax',
            'baritone-sax',
            'horn',
            'trumpet',
            'Eb-cornet',
            'trombone',
            'trombone-treble',
            'baritone',
            'Eb-tuba',
            'Bb-tuba',
        ],
        scaleTypes: [           // Scale type needs to be the display name
            // 'Aeolian',
            // 'Altered',
            // 'Blues (minor)',
            'Chromatic',
            // 'Dorian',
            // 'Harmonic Minor',
            // 'Ionian',
            // 'Locrian',
            // 'Lydian',
            // 'Lydian Dominant',
            'Major',
            // 'Major Pentatonic',
            // 'Melodic Minor',
            'Minor',
            // 'Minor Pentatonic',
            // 'Mixolydian',
            // 'Natural Minor',
            // 'Phrygian',
            // 'Whole Tone',
        ],
        tonics: circle5thsSlice('Cb', 'C#')
    },
];

let screenshotItems = [
    // Can add in any specific ones you want here (perhaps just for testing purposes...)
    // { search: 'C major, flute', pageTitle: 'C Major - Flute' },
];

screenshotCollections.forEach(coll => {

    const insts = coll.instruments || [''];

    insts.forEach(instName => {
        coll.scaleTypes.forEach(type => {
            coll.tonics.forEach(tonic => {

                const instDisplayName = INSTRUMENTS.find(i => i.name === (instName || defaultInstName)).displayName;
                const instSearchString = instName.replace(/-/g, ' ');  // e.g. 'bass clef' instead of 'bass-clef'

                screenshotItems.push({
                    search: `${tonic} ${type.toLowerCase()}${instName ? `, ${instSearchString}` : ''}`,
                    pageTitle: `${pitchClassDisplayString(tonic)} ${type} - ${instDisplayName}`,
                });
            });
        });
    });
});


export { screenshotItems };