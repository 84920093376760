import { getPitchByCircleIndex, getCircleIndex } from "./musicStructures";

export const pitchIndex = {
    C: 1,
    D: 2,
    E: 3,
    F: 4,
    G: 5,
    A: 6,
    B: 7
}

export const chromaticIndex = ({ pitchClass, ignoreOctave = false }) => {
    // return number for pitch class - i.e. C = 0, C# or Db = 1, etc.
    // Pass ignoreOctave: true if you want return to be 0 to 11, so e.g. 
    // chromaticIndex ({'B#', ignoreOctave: false})   // returns 12
    // chromaticIndex ({'B#', ignoreOctave: true})    // returns 0

    const pitchChromIndex = {
        C: 0,
        D: 2,
        E: 4,
        F: 5,
        G: 7,
        A: 9,
        B: 11
    };

    let chromaticIndex = pitchChromIndex[pitchClass[0]];
    for (let i = 1; i < pitchClass.length; i++) {
        if (pitchClass[i] === "b") chromaticIndex--;
        else if (pitchClass[i] === "#") chromaticIndex++;
        else if (pitchClass[i] === "x") chromaticIndex += 2;  // double sharp
    }

    return ignoreOctave ?
        (chromaticIndex + 12) % 12   // + 12 else Cb returns -1
        : chromaticIndex;
}



export const midiPitch = (note) => {

    // const note = (typeof noteParam === 'string') ?
    //     { pitchClass: 'x', octave: 99 }
    //     : noteParam;

    return 12 + note.octave * 12 + chromaticIndex({ pitchClass: note.pitchClass, ignoreOctave: false });
}


// respell a given note - # notes as b and vice-versa.
// Cb <-> B
// Fb <-> E
export const enharmonic = (pitchClass) => {
    const i = getCircleIndex(pitchClass);
    if (i > 0) return getPitchByCircleIndex(i - 12);    // 0 = index of C
    if (i <= 0) return getPitchByCircleIndex(i + 12);
}

export const enharmonicEqual = (pitchClass1, pitchClass2) => (
    chromaticIndex({ pitchClass: pitchClass1, ignoreOctave: true }) === chromaticIndex({ pitchClass: pitchClass2, ignoreOctave: true }));

// Respell simpler: i.e. fix double flats/sharps, and E#, B#, Cb, Fb.
// But e.g. Gb/F# and A#/Bb will be left as is.
// Triple sharps are just made one 'step' simpler - else some convoluted scales can get messy (C## altered) - 000381
// Call by pitch class or by circle index - each returns in same format.
export const spellSimple = ({ pitchClass, circleIndex }) => {

    return pitchClass ? spellSimplePitchClass(pitchClass)
        : spellSimpleCircleIndex(circleIndex);

    function spellSimpleCircleIndex(circleIndex) {
        const circleIndexESharp = getCircleIndex('E#');
        const circleIndexCb = getCircleIndex('Cb');
        return (circleIndex >= circleIndexESharp) ? circleIndex - 12
            : (circleIndex <= circleIndexCb) ? circleIndex + 12
                : circleIndex;
    }

    function spellSimplePitchClass(pitchClass) {
        return getPitchByCircleIndex(spellSimpleCircleIndex(getCircleIndex(pitchClass)));
    }
}

export function spellForDirection({ circleIndex, direction }) {
    let res = spellSimple({ circleIndex });
    const indexOfBb = getCircleIndex('Bb');
    const indexOfFsharp = getCircleIndex('F#');
    return (direction === 'ASC' && res <= indexOfBb) ? res + 12
        : (direction === 'DESC' && res >= indexOfFsharp) ? res - 12
            : res;
}


export const highestOctaveBelowStaff = (pitchClass, staffClefType = "treble") => {
    let lowestPitch, defaultOctave;
    switch (staffClefType) {
        case "treble":
            defaultOctave = 4;
            lowestPitch = "E";
            break;
        case "bass":
            lowestPitch = "G";
            defaultOctave = 2;
            break;
        default:
            throw new Error('Unknown staffClefType: ' + staffClefType);
    }
    return defaultOctave - (pitchIndex[pitchClass] > pitchIndex[lowestPitch]);
}

export const lowestOctaveForInstrument = (pitchClass, instrument, syllabus = 'generic') => {
    // Return lowest octave where the given pitch class is playable on given instrument.
    // Pass in syllabus for lowest note applicable to that - e.g. 'abrsm' or 'generic'
    // ??? might this be broken for Cb? Do i care?
    return instrument.lowestNote[syllabus].octave + (chromaticIndex({ pitchClass }) < chromaticIndex({ pitchClass: instrument.lowestNote[syllabus].pitchClass }));
}

export const highestOctaveForInstrument = (pitchClass, instrument) => {
    return instrument.highestNote.octave - (chromaticIndex({ pitchClass }) > chromaticIndex({ pitchClass: instrument.highestNote.pitchClass }));
}

export const stepsAboveMiddleLine = (pitchClassOrLetter, octave, clef, octaveShift = 0) => {
    // where notehead sits on the staff, 0 = middle line, 1 = third space from bottom etc.
    const clefAdjustment = {
        treble: 0,
        alto: 6,       // i.e. any note on alto clef appears 6 steps higher than it would on treble clef
        tenor: 8,
        bass: 12,
    };

    return pitchIndex[pitchClassOrLetter[0]]
        + 7 * octave
        - 35
        + clefAdjustment[clef]
        + octaveShift * -7
        ;
}


export const alter = ({ note, alteration, showNaturalSign = true }) => {
    let pitchClass = note.pitchClass;

    let alterationTally = pitchClass.slice(1).split("").reduce((tally, sign) => {
        if (sign === 'b') tally--;
        else if (sign === '#') tally++;
        return tally;
    }, 0);

    alterationTally += alteration;
    pitchClass = pitchClass[0];  // extract just the letter
    if (alterationTally === 0 && showNaturalSign) pitchClass += 'n';
    else if (alterationTally > 0) pitchClass += '#'.repeat(alterationTally);
    else pitchClass += 'b'.repeat(-alterationTally);

    return { ...note, pitchClass };
}

// export const legerLinesBelow = (pitchClass, octave, clef) => {
//     return Math.max(stepsAboveMiddleLine(pitchClass, octave, clef) * -0.5 - 2, 0);
// }


// 
// seems nowhere uses interval now

// // slightly odd to get the number from CIRCLE_5_INTERVALS, and then to ignore it..
// // but slightly awkward to determine that e.g. A3 to C4 is a -3, not a -10 cos the octave goes from 3 to 4..
// export const interval = (bottom, top) => {

//     const invert = (interval) => {
//         const invertLookup = {
//             P: 'P',
//             M: '-',
//             '-': 'M',
//             '+': 'o',
//             'o': '+',
//             'D': 'd',   // D is double augmented, d is double diminished
//             'd': 'D'
//         };
//         return invertLookup[interval[0]] + (9 - parseInt(interval[1]));
//     }

//     console.log(`finding interval between ${bottom.name} & ${top.name}`);

//     const circleDifference = CIRCLE_5THS.indexOf(top.pitchClass) - CIRCLE_5THS.indexOf(bottom.pitchClass);
//     let interval;
//     if (circleDifference < 0) interval = invert(CIRCLE_5_INTERVALS[-circleDifference]);
//     else interval = CIRCLE_5_INTERVALS[circleDifference];

//     return interval[0] + (pitchIndex[top.pitchClass[0]] - pitchIndex[bottom.pitchClass[0]] + 1
//         + 7 * (top.octave - bottom.octave));
// }




// // Lowest point of note in staff step units. Takes account of notehead 'height' and stem
// // but not accidental any more (000051).
// // Assumes stem direction is default (ie. middle line or higher => stem down)
// export const bottom = ({ pitchClass, octave, clef }) => {
//     const s = stepsAboveMiddleLine({ pitchClass, octave, clef });
//     return s - Math.max(
//         s >= 0 ? element.STEM.height : 0,         // stem if stem down
//         element.NOTEHEAD.height / 2               // or half a notehead
//     );
// }

// export const top = ({ pitchClass, octave, clef }) => {
//     const s = stepsAboveMiddleLine({ pitchClass, octave, clef });
//     return s + Math.max(
//         s < 0 ? element.STEM.height : 0,    // stem if stem up
//         // this.scaleDegree === 1 && this.stepsAboveMiddleLine === 2   // allow for stem up from 4th line top note 
//         //     ? element.STEM.height                                   // e.g. look at trumpet gr2 D major arp
//         //     : 0,
//         element.NOTEHEAD.height / 2                                 // or half a notehead
//     );
// }
