import { scaleTypeObject } from "./scaleTypes";
import { scaleTypeSets } from "./scaleTypeSets";
import { joinPretty } from '../utils/miscUtils';

export const composeListMetaData = (searchType, tonicTypeGroups, instrument) => {

    console.log('ttt', tonicTypeGroups)

    let groupingTitles = [], groupingDescriptions = [];
    const isComplexList = tonicTypeGroups.length > 1 &&
        tonicTypeGroups.some(g => g.tonics.length > 1 || g.tonics[0] === 'ALL_KEYS' || g.scaleTypes.length > 1);    // (i.e. > 1 set, and 1 of the sets has > 1 tonics and/or types)
    const hasAllKeys = tonicTypeGroups.some(g => g.tonics.some(t => t === 'ALL_KEYS'));   // if there's 'all keys' anywhere in list

    tonicTypeGroups.forEach((grouping, i) => {
        // for (let grouping of tonicTypeGroups) {

        const isAllKeys = grouping.tonics.includes('ALL_KEYS');

        const groupPlural = (isAllKeys || grouping.tonics.length > 1 || grouping.scaleTypes.length > 1) ? 's' : '';

        const tonicsTitle = joinPretty(grouping.tonics);
        const tonicsDescription = joinPretty(grouping.tonics, { lastSeparator: ' & ' });

        const typesTitle = joinPretty(
            grouping.scaleTypes.map(st => {
                const type = scaleTypeObject(st) || scaleTypeSets[st];
                return (isAllKeys && searchType !== 'modes') ? type.pluralName : (type.displayName || type.pluralName)
            })
            // , { lastSeparator: ' & ' }
        );
        const typesDescription = joinPretty(
            grouping.scaleTypes.map(st => {
                const type = scaleTypeObject(st) || scaleTypeSets[st];
                return type.displayName || type.pluralName;
            }),
            { lastSeparator: ' and ' });

        let groupingTitle, groupingDescription;
        if (isAllKeys) {
            groupingTitle = `${typesTitle}`;
            groupingDescription = `${typesDescription}${!typesDescription.match(/ scale/i) ? ` scale${groupPlural}` : ''} in all keys`;
            // don't add 'scales' if already present (e.g. 'Pentatonic Scales' and other scale type sets)
        }
        else {
            groupingTitle = `${tonicsTitle} ${typesTitle}`;
            groupingDescription = `${tonicsDescription} ${typesDescription}`
        }

        if (i === tonicTypeGroups.length - 1) {    // last grouping
            const listPlural = (i === 0 && grouping.tonics.length === 1 && grouping.scaleTypes.length === 1) ? '' : 's';
            // add word 'scales' unless
            //    (a) all keys somewhere in list
            //        - cos this reads weird: 'Major and minor scales in all keys, and E & F dorian scales notated' 
            //        - better is this: 'Major and minor scales in all keys, and E & F dorian notated' 
            // or (b) search type is modes
            //        - cos this reads weird: 'Modes of C & D Major scales notated...'
            // or (c) there's a scale type set - but I'm determining this by presence of word 'scale' or 'scales'
            if (!hasAllKeys && searchType !== 'modes' && !groupingDescription.match(/ scale/i)) {
                groupingDescription += ` scale${listPlural}`;
            }
        }

        groupingTitles.push(groupingTitle);
        groupingDescriptions.push(groupingDescription);
    });

    let listTitle = joinPretty(groupingTitles, { separator: isComplexList ? '; ' : ', ' });
    // let pageTitle = listTitle + (instrument ? ` \u2013 ${instrument.displayName}` : '');

    const titleLong = joinPretty(groupingDescriptions, { lastSeparator: isComplexList ? ', and ' : ' and ' })
        + ` notated ${instrument.family.match(/^clefs/i) ? `in ${instrument.displayName.toLowerCase()}`
            : `for ${instrument.displayName}`
        }.`;
    const description =
        `Interactive music notation, with audio playback${instrument.fingering ? `, ${instrument.fingeringsName || 'fingerings'}` : ''} and note names available, plus configurable range.`
        ;

    if (searchType === 'modes') {
        listTitle = 'Modes of ' + listTitle;
        // pageTitle = 'Modes of ' + pageTitle;
        // metaDescription = 'Modes of ' + metaDescription;
    }


    // const pageTitleRegex = new RegExp(convertFlatSharpNaturalToTypeable(pageTitle).replace('\u2013', '-'), 'i');

    // let screenshotItem = screenshotItems.find(s => {       // look for exact match screenshot item (including instrument)
    //     return s.pageTitle.match(pageTitleRegex);
    // });
    // if (!screenshotItem) {
    //     const titleWithClef = listTitle + (instrument ? ` \u2013 ${instrument.clef} clef` : '');
    //     const pageTitleRegex = new RegExp(convertFlatSharpNaturalToTypeable(titleWithClef).replace('\u2013', '-'), 'i');
    //     screenshotItem = screenshotItems.find(s => {       // ...or get screenshot item for the right clef
    //         return s.pageTitle.match(pageTitleRegex);
    //     });
    // }

    // const image = screenshotItem ? screenshotPath(screenshotItem.search) : undefined;

    // ??? 
    // maybe pass title, instrument, etc.
    // and let MetaDecorator construct the tags
    // e.g. list title = title, page title = title + instrument
    // 
    // and pass descOpener (which says the scale) and descMain (starts with 'Interactive music notation')
    // ..so then the description (for google) can include the scale desc, but FB og:desc can skip it.
    //
    // i.e. don't get too involved with intricacies of the various meta tags here!

    return {
        listTitle,
        titleLong,
        // pageTitle,
        description,
        // image
    };

}
