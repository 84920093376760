import React, { useState } from 'react';
import { connect } from 'react-redux';
import Joyride, { EVENTS, ACTIONS, STATUS } from 'react-joyride';
import { setTourToRun } from '../actions/session';
import { SettingsIcon } from '../assets/icons';
// import { BrandIcon } from './Brand';



const FeatureTour = ({ tourToRun, dispatch }) => {

    const [stepIndex, setStepIndex] = useState(0);

    const [tourSteps] = useState({
        MAIN_TOUR: [
            // {
            //     disableBeacon: true,
            //     disableOverlay: true,
            //     target: '#help-button',
            //     // placement: 'center',
            //     styles: {
            //         backgroundColor: 'pink',
            //         width: 100,
            //     },
            //     content: (
            //         <div>
            //             <BrandIcon width={50} />
            //             <TourPar> Take the tour...?</TourPar>
            //         </div >),
            // },
            {
                disableBeacon: true,
                floaterProps: { disableAnimation: true, },
                target: '#search-bar-input',
                content: (
                    <div>
                        <p>Type in the scale(s) you want to look up here and press Enter.</p>
                        <p>To see some examples of searches, open the suggestions box by clicking the little triangle on the right.</p>
                        <p>You can specify an instrument in your search so that the clef and range will be set appropriately.</p>
                    </div>),
            },
            {
                // disableBeacon: true,
                // floaterProps: { disableAnimation: true, },
                // placementBeacon: 'left',
                target: '.notation-box',
                content: <div>
                    <p>Click on a note to hear it.</p>
                    <p>The sounding pitch is transposed appropriately for the current instrument.</p>
                    <p className='tip-note'>(At the moment, all instruments use a piano sound.)</p>
                </div>
            },
            {
                // disableBeacon: true,
                // floaterProps: { disableAnimation: true, },
                // placementBeacon: 'right',
                target: '.note-names-and-fingerings',
                content: (<div>
                    <p>Note names and fingerings are shown here.</p>
                    <p>Click each one to switch them on and off individually, or switch off altogether in the settings <SettingsIcon /> (top right).</p>
                    <p className='tip-note'>(Fingerings are not yet available for all instruments.)</p>
                </div>)
            },
            {
                // disableBeacon: true,
                // floaterProps: { disableAnimation: true, },
                target: '.scale-actions-bar',
                content: (
                    <div>
                        <p>Use these controls to play the scale, set the octave and range, explore chords within the scale, etc.</p>
                        <p>Set the playback tempo and style (straight or swing) in the settings <SettingsIcon /></p>
                    </div>),
            },
            {
                // disableBeacon: true,
                // floaterProps: { disableAnimation: true, },
                target: '#settings-button',
                placement: 'left',
                // styles: {
                //     options: {
                //         // backgroundColor: 'pink',
                //         // transform: 'translate(210px, 120px)',
                //         // marginTop: '40px',
                //     },
                // },
                content: <div>
                    <p>Click here to set your main instrument, playback tempo, and other settings.</p>
                    {/* <p>SeThis sets clef, range and sounding pitch appropriately, and allows you to see fingerings for many instruments (but not all of them yet...)</p> */}
                </div>
            },
        ],


        // ???337: currently there's no way of starting this tour!
        // ..and it needs work since 000259, 000265
        NAV_SIDEBAR_TOUR: [
            //??? I need to look at this tour on mobile as it's quite different
            // ...probably need a separate tour - or more like a separate version of the nav tour (from the user's pov)
            {
                target: '#grid-toggle',
                content: (
                    <div>
                        <p>Click here to open/close the 'keys grid'</p>
                    </div>),
            },
            {
                target: '.newgrid',
                content: (
                    <div>
                        <p>Click on any of the coloured boxes to jump to that scale.</p>
                        {/* <p>(Starting notes which aren't in your current set of scales are disabled. To add them to the list, just add the tonic(s) to your text in the search bar.)</p> */}
                    </div>),
            },
            {
                target: '#groupby-menu',
                content: (
                    <div>
                        <p>Scales can be grouped by scale type (i.e. major, minor, etc.), by starting note or &ndash; for 'modes of' lookups &ndash; by the 'parent' scale.</p>
                    </div>),
            },
            // {
            //     target: '#sortby-menu',
            //     content: (
            //         <div>
            //             <p>Click on these to jump to each group of scales</p>
            //         </div>),
            // },
            {
                target: '.group-header>.actions-bar',
                content: (
                    <div>
                        <p>You can set range, etc. for all scales in a group here.</p>
                    </div>),
            },
            {
                target: '.list-header .actions-bar:not(.fingerings)',
                content: (
                    <div>
                        <p>Set range, etc. for all scales in the list here.</p>
                    </div>),
            },
        ],
    });


    // ??? to do:
    // o open the keys grid on their behalf? Or ask them to do it?
    // o determine when/how this tour is triggered
    //   - i guess I keep the beacons?
    //   - need to impose it on them once only
    // o need a mechanism to control which tour is shown
    //   - i.e. dynamically set the 'steps' prop based on..
    // o need a menu option to retrigger this tour if they want

    // o need to highlight the main tour
    //   - i think maybe let's just go back to beacons; they can always skip it



    const joyrideCallback = (data) => {
        const { index, status, action, type } = data;

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        }
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || action === ACTIONS.CLOSE) {
            dispatch(setTourToRun(null));
            setStepIndex(0);
        }
    };

    return (
        <Joyride
            run={!!tourToRun}
            // run={true}
            steps={tourSteps[tourToRun]}
            stepIndex={stepIndex}
            callback={joyrideCallback}
            continuous
            showSkipButton
            showProgress  // ??? questionable if some targets might not be found..? Maybe just on main tour?
            spotlightClicks
            disableScrolling   // ??? need on mobile?   
            // locale={{ skip: 'Exit tour' }}
            styles={{
                options: {
                    primaryColor: 'hsl(180, 35%, 35%)',
                    zIndex: 3000,
                },
            }}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        tourToRun: state.session.tourToRun,
    }
}

export default connect(mapStateToProps)(FeatureTour);