import React from 'react';

import ScrollTarget from './ScrollTarget';
import GroupActionsBar from './GroupActionsBar';
import { formatAccidentalsJSX } from '../utils/MusicSymbolsFormatting';
import { unanimousValueForProp } from '../utils/miscUtils';
import { getChordNames } from '../logic/chordTypes';

export const chordTitle = (scaleHeaders) => {
    const allChordType = unanimousValueForProp(scaleHeaders, 'showChord');
    const chordTitle = !allChordType || allChordType === 'SCALE'
        ? ''
        : ` \u2013 ${getChordNames(allChordType).displayName}`;      // \u2013 is ndash
    return chordTitle;
}


const GroupHeader = ({ group, gradeHeader, instrument, handleScaleAction, groupsCount }) => {

    const { scaleHeaders } = group;
    const multipleScales = scaleHeaders.length > 1;

    return (
        <React.Fragment>
            <ScrollTarget
                id={group.id}
            />
            <div className='group-header'>
                {groupsCount > 1 &&
                    <h2 className='group-title'>
                        {formatAccidentalsJSX(group.name)}
                        {chordTitle(scaleHeaders)}
                        {/* {allArpeggio && multipleScales && ' \u2013 Arpeggios'} */}
                    </h2>
                }
                <div className='message'>{group.message}</div>
                {groupsCount > 1 && multipleScales &&
                    <GroupActionsBar
                        text='Set for group:'
                        gradeHeader={gradeHeader}
                        instrument={instrument}
                        scaleHeaders={scaleHeaders}
                        handleScaleAction={handleScaleAction}
                    />
                }
            </div >
        </React.Fragment>
    )
}


export default GroupHeader;