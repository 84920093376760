const sessionReducerDefaultState = {
    tourToRun: null,
    printing: false,
    audio: null,
    player: null,
    playingScaleId: 'LOADING_AUDIO',
};

export default (state = sessionReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_TOUR_TO_RUN':
            return {
                ...state,
                tourToRun: action.tourToRun
            };
        case 'SET_PRINTING':
            return {
                ...state,
                printing: action.printing
            };
        case 'SET_AUDIO':
            return {
                ...state,
                audio: action.audio
            };
        case 'SET_PLAYER':
            return {
                ...state,
                player: action.player
            };
        case 'SET_PLAYING_SCALE_ID':
            return {
                ...state,
                playingScaleId: action.playingScaleId
            };
        default:
            return state;
    }
}
