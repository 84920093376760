import { INTERVALS, simplify, makePassing } from './musicStructures';
import { relMajor } from './scaleUtils';
import { pitchClassDisplayString } from '../utils/MusicSymbolsFormatting';
import { circle5thsSlice } from '../logic/musicStructures';
import { bulletSuffixes } from '../logic/bulletSuffixes.js';

const { m2nd, M2nd, a2nd, m3rd, M3rd, P4th, d5th, a4th, P5th, a5th, m6th, M6th, d7th, m7th, M7th, P8th } = INTERVALS;


// ??? do this, so I can remove same from ScaleBullet.js:
// export const bulletClassName = {
//     diminished: (tonicPC) => `diminished-${chromaticIndex({ pitchClass: tonicPC }) % 3}`,
// }



export const tonics = {
    major: {
        preferred: circle5thsSlice('Db', 12),
        flippable: true,
        // flippable: ["Cb", "Gb", "Db", "B", "F#", "C#"],  // only allow flip keys w 5/6/7 sharps/flats
    },
    dominant: {
        preferred: circle5thsSlice('Ab', 12),     // relative to major
        flippable: true,
        // flippable: ["Gb", "Db", "Ab", "F#", "C#", "G#"]
    },
    minor: {
        preferred: circle5thsSlice('Eb', 12),   // prefer Eb minor over D#
        flippable: true,
        // flippable: ["Ab", "Eb", "Bb", "G#", "D#", "A#"],
    },
    melodicMinor: {
        preferred: circle5thsSlice('Ab', 12),   // favour Ab mel min over G# so as to avoid double sharp
        flippable: true,
        // flippable: ["Cb", "Gb", "Db", "B", "F#", "C#"],
    },
};


const FUNDAMENTAL_SCALE_TYPES = [        // will be types which are defined completely independently - e.g. major
    {
        id: 'MAJ',
        displayName: "Major",
        abbreviatedName: "Maj",
        formula: [M2nd, M3rd, P4th, P5th, M6th, M7th, P8th],
        tonics: tonics.major,
        relativeMajorDistanceIn5ths: 0,
        relative: {
            basetype: 'MIN',
            fifths: 3,
        },
        modes: ['IONIAN', 'DORIAN', 'PHRYGIAN', 'LYDIAN', 'MIXO', 'AEOLIAN', 'LOCRIAN'],  // This one hard-coded cos NAT is canonical and defined as mode of MAJ, but AEOLIAN should be used in mode contexts
    },
    {
        id: 'MIN',
        class: 'COMPOSITE',           // not exactly a fundamental!
        displayName: "Minor",
        abbreviatedName: "min",
        suffix: bulletSuffixes.minor,
        formula: [M2nd, m3rd, P4th, P5th, m6th, m7th, P8th], // natural minor
        tonics: tonics.minor,
        relativeMajorDistanceIn5ths: -3,
        relative: {
            basetype: 'MAJ',
            fifths: -3,
        },
        modes: false,
    },
    {
        id: 'CHR',
        displayName: "Chromatic",
        abbreviatedName: "chr",
        formula: [makePassing(m2nd), makePassing(M2nd), makePassing(m3rd), makePassing(M3rd), makePassing(P4th), makePassing(a4th), makePassing(P5th), makePassing(m6th), makePassing(M6th), makePassing(m7th), makePassing(M7th), P8th],
        suffix: bulletSuffixes.chromatic,
        tonics: {
            // preferred: CHROMATIC_SPELLING.familiar
            // ??? Ordering of header bullets needs to match order of scales - see 000023
            // ...defaultTonics
            preferred: circle5thsSlice('Eb', 12),
        },
        chords: [],
        modes: false,
    },
    {
        id: 'DOM7',
        displayName: "Dominant 7th",
        title: function ({ tonicPC }) {
            return (
                `${this.displayName} in the key of ${pitchClassDisplayString(relMajor(tonicPC, 'DOM7'))}`
            )
        },
        shortName: 'Dom. 7th',
        suffix: bulletSuffixes.dom7,
        formula: [M3rd, P5th, m7th, P8th],
        tonics: tonics.dominant,
        chords: [],
        relativeMajorDistanceIn5ths: -1,
        relative: {
            basetype: 'MAJ',
            fifths: -1,
            text: 'Associated major'
        },
        nameVariations: ['dom7']
    },
    {
        id: 'DIM7',
        displayName: "Diminished 7th",
        shortName: 'Dim. 7th',
        suffix: bulletSuffixes.diminished,
        formula: [m3rd, d5th, d7th, P8th],
        tonics: {
            preferred: ["A", "E", "B", "F#", "C#", "G#", "D#", "A#", "E#", "B#", "G", "D"]
            // preferred: CIRCLE_5THS.slice(CIRCLE_5THS.indexOf('A'), CIRCLE_5THS.indexOf('C##') + 1),
        },
        chords: [],
        // relative???
        //   DIM7 => assume this is viio of minor, then return that minor's rel major
        // case ScaleType.DIM7:

        //     //??? THIS DOESN"T WORK FOR F dim 7
        //     // AND SHOULD THAT BE E# anyway?!?
        //     // console.log(key, basetype, 'rel maj: ' + CIRCLE_5THS[(CIRCLE_5THS.indexOf(key) - 8)])
        //     res = CIRCLE_5THS[(CIRCLE_5THS.indexOf(tonicPC) - 8 + 12) % 12];
        //     break;
        // // %12 is questionable - don't want it if index is say 13, only want it if it's negative
        // // maybe question is 'why am I handling F dim 7 anyway? should I have internally converted it to E#?'
        nameVariations: ['dim7']
    },
    {
        id: 'WT',
        displayName: "Whole Tone",
        abbreviatedName: 'wt',
        pluralName: 'Whole Tone scales',
        suffix: bulletSuffixes.augmented,
        formula: [simplify(M2nd), simplify(M3rd), simplify(a4th), simplify(a5th), simplify(m7th), P8th],
        tonics: {
            preferred: tonics.major.preferred,
        },
        modes: false,
    },
    {
        id: 'MAJPENT',
        displayName: 'Major Pentatonic',
        shortName: 'Major Pent.',
        abbreviatedName: 'M.p.',
        formula: [M2nd, M3rd, P5th, M6th, P8th],
        tonics: tonics.major,
        relativeMajorDistanceIn5ths: 0,
        relative: {
            basetype: 'MINPENT',
            fifths: 3,
        },
    },
    {
        id: 'DIM-H-W',
        displayName: 'Diminished (Half/Whole)',
        shortName: 'Dim. h-W',
        abbreviatedName: 'dim-hW',
        pluralName: 'Diminished (Half/Whole) scales',
        suffix: bulletSuffixes.dom7,
        formula: [simplify(m2nd), simplify(m3rd), M3rd, a4th, P5th, M6th, m7th, P8th],
        tonics: {
            preferred: tonics.major.preferred,
        },
        modes: ['DIM-H-W', 'DIM-W-H', 'DIM-H-W', 'DIM-W-H', 'DIM-H-W', 'DIM-W-H', 'DIM-H-W', 'DIM-W-H'],
        matchWeight: 0.95
    },
    {
        id: 'BLUES',
        displayName: 'Blues (minor)',
        shortName: 'Blues (min)',
        abbreviatedName: 'bl.',
        pluralName: 'Blues scales (minor)',
        suffix: bulletSuffixes.minor,
        formula: [m3rd, P4th, makePassing(a4th), P5th, m7th, P8th],
        tonics: tonics.minor,
        relativeMajorDistanceIn5ths: -3,
        relative: {
            basetype: 'MAJBLUES',
            fifths: -3,
            // tonic: 3,  // ??? don't think this will work - should rework so it's more flexible // er.. what actually *is* this?! was just a typo i think..
        },
        nameVariations: ['Blues', 'Minor Blues'],
        // nameForSorting: 'Blue0'      // put this ahead of other blues scales  // ??? nameForSorting is obsolete atm
    },
    {
        id: 'HAR',
        displayName: "Harmonic Minor",
        optionName: 'Harmonic',        // used for minor types buttons
        shortName: "Har. min",
        abbreviatedName: 'har',
        subtypeOf: 'MIN',
        suffix: bulletSuffixes.minor,
        formula: [M2nd, m3rd, P4th, P5th, m6th, M7th, P8th],
        tonics: tonics.minor,
        relativeMajorDistanceIn5ths: -3,
        relative: {
            basetype: 'MAJ',
            fifths: 3,
        },
        nameVariations: ['harmonic']
    },
    {
        id: 'MEL',
        displayName: "Melodic Minor",
        optionName: 'Melodic',        // used for minor types buttons
        shortName: "Mel. min",
        abbreviatedName: 'mel',
        subtypeOf: 'MIN',
        suffix: bulletSuffixes.minor,
        formula: [M2nd, m3rd, P4th, P5th, M6th, M7th, P8th],
        tonics: tonics.melodicMinor,
        relativeMajorDistanceIn5ths: -3,
        relative: {
            basetype: 'MAJ',
            fifths: 3,
        },
        nameVariations: ['melodic', 'Jazz Minor', 'jazz']
    },
    {
        id: 'BEBOPMAJ',
        displayName: 'Bebop Major',
        shortName: 'Bebop Maj.',
        formula: [M2nd, M3rd, P4th, P5th, makePassing(a5th), M6th, M7th, P8th],
        tonics: tonics.major,
        relativeMajorDistanceIn5ths: 0,
        relative: {
            basetype: 'MAJ',
            fifths: 0,
            text: 'Parallel Major'
        },
        modes: false,
        nameVariations: ['major bebop'],
    },
    {
        id: 'BEBOPDOM',
        displayName: 'Bebop Dominant',
        shortName: 'Bebop Dom.',
        suffix: bulletSuffixes.dom7,
        formula: [M2nd, M3rd, P4th, P5th, M6th, m7th, makePassing(M7th), P8th],
        tonics: tonics.dominant,
        chords: [],
        relativeMajorDistanceIn5ths: -1,
        relative: {
            basetype: 'MIXO',
            fifths: 0,
            text: 'Parallel Mixolydian'
        },
        modes: false,
        nameVariations: ['dominant bebop', 'Bebop Mixolydian', 'mixolydian bebop']
    },
    {
        id: 'BEBOPDOR',
        displayName: 'Bebop Dorian',
        shortName: 'Bebop Dor.',
        suffix: bulletSuffixes.minor,
        formula: [M2nd, m3rd, makePassing(M3rd), P4th, P5th, M6th, m7th, P8th],
        tonics: tonics.minor,  // ???230 I guess technically this should copy from dorian (but it's the same atm)
        relativeMajorDistanceIn5ths: -2,
        relative: {
            basetype: 'DORIAN',
            fifths: 0,
            text: 'Parallel Dorian'
        },
        modes: false,
        nameVariations: ['Dorian Bebop'],
    },
    {
        id: 'BEBOPMEL',
        displayName: 'Bebop Melodic Minor',
        shortName: 'Bebop mel.',
        suffix: bulletSuffixes.minor,
        formula: [M2nd, m3rd, P4th, P5th, makePassing(a5th), M6th, M7th, P8th],
        tonics: tonics.melodicMinor,
        relativeMajorDistanceIn5ths: -3,
        relative: {
            basetype: 'MEL',
            fifths: 0,
            text: 'Parallel Melodic Minor'
        },
        modes: false,
        nameVariations: ['Bebop Melodic', 'melodic bebop', 'melodic minor bebop'],
    },
];


// FUNDAMENTAL_SCALE_TYPES.BEBOPHAR = {
//     ...FUNDAMENTAL_SCALE_TYPES.HAR,
//     displayName: 'Bebop Harmonic Minor',
//     shortName: 'Bebop Har. Min.',
//     formula: [M2nd, m3rd, P4th, P5th, m6th, chromPassing, M7th, P8th],
//     modes: false
// };



const MODES_AND_SYNONYMS = [            // minimal info for the named modes - i.e. display name etc.
    {
        id: 'IONIAN',
        canonicalTypeId: 'MAJ',
        class: 'SYNONYM',        // It's equivalence with MAJ trumps the fact of it being a mode
        displayName: "Ionian",
        modeOf: 'MAJ',
        modeNum: 1,
    },
    {
        id: 'DORIAN',
        displayName: 'Dorian',
        abbreviatedName: 'dor',
        modeOf: 'MAJ',
        modeNum: 2,
    },
    {
        id: 'PHRYGIAN',
        displayName: 'Phrygian',
        abbreviatedName: 'phr',
        modeOf: 'MAJ',
        modeNum: 3,
        nameVariations: ['fridge'],
    },
    {
        id: 'LYDIAN',
        displayName: 'Lydian',
        abbreviatedName: 'lyd',
        modeOf: 'MAJ',
        modeNum: 4
    },
    {
        id: 'MIXO',
        displayName: 'Mixolydian',
        abbreviatedName: 'mixo',
        modeOf: 'MAJ',
        modeNum: 5,
    },
    {
        id: 'NAT',
        displayName: "Natural Minor",
        optionName: 'Natural',        // used for minor types buttons
        shortName: "Natural min.",
        subtypeOf: 'MIN',
        modeOf: 'MAJ',
        modeNum: 6,
    },
    {
        id: 'AEOLIAN',
        class: 'SYNONYM',
        canonicalTypeId: 'NAT',
        displayName: 'Aeolian',
        abbreviatedName: 'aeol',
    },
    {
        id: 'LOCRIAN',
        displayName: 'Locrian',
        abbreviatedName: 'loc',
        modeOf: 'MAJ',
        modeNum: 7,
    },
    {
        id: 'DORIAN-b2',                    // handle lower case Id??? i.e. url query string
        displayName: 'Dorian ♭2',
        abbreviatedName: 'd♭2',
        pluralName: 'Dorian ♭2 scales',
        modeOf: 'MEL',
        modeNum: 2,
        // synonyms: ['Phrygian #6']   // ???373
        // ??? btw #6 or n6? Wikipedia says # but seems questionable to me
    },
    {
        id: 'LYDIAN-AUG',
        displayName: 'Lydian Augmented',
        pluralName: 'Lydian Augmented scales',
        shortName: 'Lydian Aug.',
        abbreviatedName: 'lyd+',
        modeOf: 'MEL',
        modeNum: 3,
    },
    {
        id: 'LYDIAN-DOM',
        displayName: 'Lydian Dominant',
        shortName: 'Lydian Dom.',
        abbreviatedName: 'lyd7',
        modeOf: 'MEL',
        modeNum: 4,
    },
    {
        id: 'OVERTONE-SCALE',
        canonicalTypeId: 'LYDIAN-DOM',
        class: 'SYNONYM',
        displayName: 'Overtone Scale',
        shortName: 'Overtone Sc.',
        nameVariations: ['overtone'],
    },
    {
        id: 'MIXO-b6',
        displayName: 'Mixolydian ♭6',
        pluralName: 'Mixolydian ♭6 scales',
        shortName: 'Mixo. ♭6',
        abbreviatedName: 'mx♭6',
        modeOf: 'MEL',
        modeNum: 5,
    },
    {
        id: 'LOCRIAN-n2',
        displayName: 'Locrian ♮2',
        abbreviatedName: 'loc♮2',
        tokenDescription: '(♮2)',
        pluralName: 'Locrian ♮2 scales',
        modeOf: 'MEL',
        modeNum: 6,
    },
    {
        id: 'AEOLIAN-b5',
        canonicalTypeId: 'LOCRIAN-n2',
        class: 'SYNONYM',
        displayName: 'Aeolian ♭5',
        abbreviatedName: 'aeo♭5',
        pluralName: 'Aeolian ♭5 Scales',
    },
    {
        id: 'ALTERED',
        displayName: 'Altered',
        abbreviatedName: 'alt',
        pluralName: 'Altered scales',
        modeOf: 'MEL',
        modeNum: 7,
        formula: [simplify(m2nd), simplify(a2nd), M3rd, d5th, m6th, m7th, P8th],  // override what comes from mel min cos want major 3rd not dim 4th
    },
    {
        id: 'DIM-WHOLE',
        canonicalTypeId: 'ALTERED',
        class: 'SYNONYM',
        displayName: 'Diminished Whole Tone',
        shortName: 'Dim. WT',
        pluralName: 'Diminished Whole Tone Scales',
    },
    {
        id: 'SUPER-LOC',
        canonicalTypeId: 'ALTERED',
        class: 'SYNONYM',
        displayName: 'Super Locrian',
        shortName: 'Super Loc.',
    },
    {
        id: 'LOCRIAN-n6',
        displayName: 'Locrian ♮6',
        abbreviatedName: 'loc♮6',
        tokenDescription: '(♮6)',
        pluralName: 'Locrian ♮6 scales',
        modeOf: 'HAR',
        modeNum: 2,
    },
    {
        id: 'IONIAN-AUG',
        displayName: 'Ionian Augmented',
        abbreviatedName: 'ion+',
        pluralName: 'Ionian Augmented scales',
        shortName: 'Ionian Aug.',
        modeOf: 'HAR',
        modeNum: 3,
        nameVariations: ['augmented ionian', 'major augmented', 'augmented major'],
    },
    {
        id: 'DORIAN-s11',
        displayName: 'Dorian ♯11',
        abbreviatedName: 'd♯11',
        pluralName: 'Dorian ♯11 scales',
        modeOf: 'HAR',
        modeNum: 4,
    },
    {
        id: 'DORIAN-s4',
        canonicalTypeId: 'DORIAN-s11',
        class: 'SYNONYM',
        displayName: 'Dorian ♯4',
        pluralName: 'Dorian ♯4 scales',
        matchWeight: 0.9,
    },
    {
        id: 'PHRYGIAN-DOM',
        displayName: 'Phrygian Dominant',
        shortName: 'Phryg Dom.',
        modeOf: 'HAR',
        modeNum: 5,
    },
    {
        id: 'SPANISH-PHRYGIAN',
        canonicalTypeId: 'PHRYGIAN-DOM',
        displayName: 'Spanish Phrygian',
        shortName: 'Spanish Phr.',
        nameVariations: ['spanish'],
    },
    {
        id: 'LYDIAN-s2',
        displayName: 'Lydian ♯2',
        abbreviatedName: 'lyd♯2',
        pluralName: 'Lydian ♯2 scales',
        modeOf: 'HAR',
        modeNum: 6,
    },
    {
        id: 'LYDIAN-s9',
        canonicalTypeId: 'LYDIAN-s2',
        class: 'SYNONYM',
        displayName: 'Lydian ♯9',
        pluralName: 'Lydian ♯9 scales',
    },
    {
        id: 'ULTRA-LOC',
        displayName: 'Ultra Locrian',
        shortName: 'Ultra Loc.',
        modeOf: 'HAR',
        modeNum: 7,
    },
    {
        id: 'SUPER-LOC-bb7',
        canonicalTypeId: 'ULTRA-LOC',
        class: 'SYNONYM',
        displayName: 'Super Locrian ♭♭7',    // use diminished symbol, or 'dim'? either? But bb should be available for typing.. ???
        shortName: 'Sup Loc ♭♭7',
    },
    {
        id: 'MINPENT',
        displayName: 'Minor Pentatonic',
        abbreviatedName: 'm.p.',
        shortName: 'Minor Pent.',
        modeOf: 'MAJPENT',
        modeNum: 5
    },
    {
        id: 'MAJBLUES',
        displayName: 'Blues (major)',
        shortName: 'Blues (maj)',
        abbreviatedName: 'M-bl',
        pluralName: 'Blues scales (major)',
        modeOf: 'BLUES',
        modeNum: 2,
        nameVariations: ['major blues'],
    },
    {
        id: 'DIM-W-H',
        displayName: 'Diminished (Whole/Half)',
        shortName: 'Dim. W-h',
        abbreviatedName: 'dimWh',
        pluralName: 'Diminished (Whole/Half) scales',
        // suffix: bulletSuffixes.diminished,
        modeOf: 'DIM-H-W',
        modeNum: 2,
        formula: [M2nd, m3rd, P4th, d5th, m6th, d7th, M7th, P8th],
    },
    {
        id: 'SYM-DIM',
        canonicalTypeId: 'DIM-H-W',
        class: 'SYNONYM',
        displayName: 'Symmetric Diminished (Half/Whole)',
        shortName: 'Sym Dim h-W',
        pluralName: 'Symmetric Diminished (½-W) Scales',
        nameVariations: ['symmetric diminished'],
    },
];

// Populate modes list for principal types.
// NB. If a scale should have fewer modes than notes in scale then put it in manually on the type.
// E.g. symmetric diminished.
FUNDAMENTAL_SCALE_TYPES
    .filter(type => type.modes === undefined)
    .forEach(type => {
        type.modes = [];
        for (let i = 1; i <= type.formula.length; i++) {
            const modeId = MODES_AND_SYNONYMS
                .filter(m => m.modeOf === type.id && m.modeNum === i)
                .map(m => m.id)[0];
            type.modes.push(
                modeId ||                   // the mode id if it exists..
                (i === 1 ? type.id           // ..if not then the parent scale id if 1st mode
                    : `${type.id}-m${i}`     // ..or else an 'anonymous' id
                )
            );
        }
    });


// Fill in the class..
FUNDAMENTAL_SCALE_TYPES.forEach(type => {
    type.class = type.class || 'FUNDAMENTAL';
});
MODES_AND_SYNONYMS.forEach(type => {
    type.class = type.class || (!!type.modeOf ? 'MODE' : 'SYNONYM');
});


const SCALE_TYPES = FUNDAMENTAL_SCALE_TYPES.concat(MODES_AND_SYNONYMS)

// Fill in the canonical type...
SCALE_TYPES.forEach(type => {
    type.canonicalTypeId = type.canonicalTypeId || type.id;
});



export { SCALE_TYPES };

export const SCALE_TYPES_CANONICAL = SCALE_TYPES
    .filter(type => ['FUNDAMENTAL', 'MODE'].includes(type.class))   // not synonyms, not composites
    ;