import { chromaticIndex } from './noteUtils';


export const groupBys = {
    TYPE: {
        groupByName: 'Scale Type',
        getGroupId: (scaleHeader) => `GRP-${scaleHeader.basetype}`,
    },
    TONIC: {
        groupByName: 'Starting Note',
        getGroupId: (scaleHeader) => `GRP-${chromaticIndex({ pitchClass: scaleHeader.tonicPC, ignoreOctave: true })}`,
    },
    MODES_OF: {
        groupByName: 'Modes of',
        getGroupId: (scaleHeader) => `GRP-${scaleHeader.modeOf.tonicPC}-${scaleHeader.modeOf.basetype}`,
    },
};

// ??? this is necessary so dropdown still looks ok when there's no query,
// o its structure is daft.



// Return the current effective group by setting.
// I.e. If list of modes then use groupByForModes *only if it is set to MODES_OF*
// else, defer to main group by setting.
export const currGroupByInEffect = ({ listOfModes, groupByForModes, groupBy: mainGroupBy }) => {
    return listOfModes && groupByForModes === 'MODES_OF' ? groupByForModes : mainGroupBy;
}
