import React from 'react';
import queryString from 'query-string';
import castArray from 'lodash/castArray';

import { parseFreeText, buildStringFromTokens } from '../logic/parseFreeText';
import { getSearchTokenFromValue } from '../data/searchTokenList';
import { toDoListForGrade } from './buildScaleList';
import INSTRUMENTS from '../data/instruments';
import { Link } from 'react-router-dom';
import { scaleTypeObject } from './scaleTypes';
import { pitchClassDisplayString, formatAccidentalsJSX, tonicTokensJSX } from '../utils/MusicSymbolsFormatting';
import { composeListMetaData } from './composeListMetaData';
import { joinPretty } from '../utils/miscUtils';

const parseSearchParams = ({ searchText, gradeHeader: gradeHeaderIn, defaultInstName, setShowSettings }) => {

    /*

    clear tonic-type staging area
    clear grade-inst staging area
    let searchType = null;
    loop through param keys in order
        get type of next param
        if tonic then 
            if searchType = 'grade' then ignore tonic
            else if staging area doesn't have tonic then write it there ('it' will be an array)
            else if it does then
                { 
                    processStagingArea (to todo list) (and clear it)
                    if searchType not set then searchType = 'regular'  // i.e. tonics and types
                }
            put tonic in staging area
        [all above same for type]
        else if modes then
            if searchType is set OR there are multiple types in staging then { // multiples includes 2 and a type 'family'
            
            // 🤔 I'm not sure about this
            // a) why not do modes of for multiple types if that's what they ask for?
            //    - many types is their problem - just need for keys grid to not crash
            //    - weird that I define 'modeable'
            // b) and should I even somehow allow modes of for subsequent groups?

                processStagingArea if nec
                ignore mode token
            }
            else {
                put in staging area
                set searchType = 'modes'
            }
        else if grade then
            if searchType is set then {
                processStagingArea if nec
                ignore grade token
            }
            else {
                searchType = 'grade'
                put grade in gradeinstStaging
            }
        else if inst then
            if inst is set then ignore
            else {
                set inst
                put inst in inst-grade staging
            }
    end loop
    if inst-grade staging ready then process it (poss use default inst)
    ditto tonic-type 
            // think both won't happen cos of code above
    */







    /*

    ##     ##    ###    #### ##    ## 
    ###   ###   ## ##    ##  ###   ## 
    #### ####  ##   ##   ##  ####  ## 
    ## ### ## ##     ##  ##  ## ## ## 
    ##     ## #########  ##  ##  #### 
    ##     ## ##     ##  ##  ##   ### 
    ##     ## ##     ## #### ##    ## 

    */



    let scalesToDoList = [],      // analogous to a grade in gradeData: i.e. a set of scale headers (more or less) to build
        gradeHeader = { ...gradeHeaderIn },
        grade,
        instrument = {},
        search = { tokens: [], text: '' },
        searchFeedback = {
            errors: [],
            warnings: [],
            info: []
        };
    ;

    let searchType = null,         // how we interpret the query: list, grade or modes
        stagingArea = {
            tonicTokens: [],
            scaleTypeTokens: [],
            modesOfToken: {},
            gradeToken: {},
            instToken: {}
        };

    let tonicTypeGroups = [];     // a record of how the tonics and types couplings were interpreted
    //                               Each element in array = one or more tonics AND one or more types.
    //                               Used for title/description.

    const queryParsedArray = parseQueryString(searchText);
    // console.log('352', queryParsedUpper, queryParsedArray);

    // loop through param keys in order
    for (let { key, labels } of queryParsedArray) {

        if (key === 'tonic') {
            if (searchType === 'grade') {
                createTokens({ tokenType: key, labels, ignored: true });  // ignore these tonics
                feedback(`A grade search can't be combined with tonics or scale types.`);
            }
            else {
                // If there are already tonics staged then deal with them first
                if (stagingArea.tonicTokens.length) {
                    if (!searchType) searchType = 'list';
                    processAndClearListStagingArea();
                }

                if (searchType === 'modes' && scalesToDoList.length) {
                    // There were already tonics which had 'modes of' applied, so ignore current lot.
                    createTokens({ tokenType: key, labels, ignored: true });
                    feedback(`'Modes of' can only apply to one set of tonics (starting notes).`);
                }
                else {
                    // So far so good, put the tonic(s) in the staging area
                    stagingArea.tonicTokens = createTokens({ tokenType: key, labels });

                    // if there's an 'all tonics' token then ignore other specific tonics
                    if (labels.length > 1 && labels.includes('ALL')) {
                        for (let t of stagingArea.tonicTokens) {
                            if (t.value !== 'ALL') t.ignored = true;
                        }
                        feedback(`'All tonics' requested so ignoring specific one(s).`)
                    }
                }
            }
        }


        else if (key === 'scaletype') {
            if (searchType === 'grade') {
                createTokens({ tokenType: key, labels, ignored: true });  // ignore these tonics
                feedback(`A grade search can't be combined with tonics or scale types.`);
            }
            else {
                // If there are already types staged then deal with them first
                if (stagingArea.scaleTypeTokens.length) {
                    if (!searchType) searchType = 'list';
                    processAndClearListStagingArea();
                }

                if (searchType === 'modes') {
                    // if (scalesToDoList.length) {
                    //     // There were already types which had 'modes of' applied, so ignore current lot.
                    //     createTokens({ tokenType: key, labels, ignored: true });  // ignore
                    //     feedback(`'Modes of' only works with a single scale type.`);
                    // }

                    // use the first scale type specified..
                    // ??? but it should be the first MODEABLE type!
                    // ??? (also we should ignore type sets)
                    stagingArea.scaleTypeTokens = createTokens({ tokenType: key, labels: [labels[0]] });

                    if (labels.length > 1) {           // ...and ignore extras
                        createTokens({ tokenType: key, labels: labels.slice(1), ignored: true });
                        feedback(`'Modes of' only works with a single scale type.`);
                    }
                }
                else {
                    if (labels.length > 1 && !searchType) {
                        // multiple scale types => regard this as a 'list' search
                        searchType = 'list';
                    }
                    stagingArea.scaleTypeTokens = createTokens({ tokenType: key, labels });  // returns array of tidied values
                }
            }
        }


        //     else if modes then
        //         if searchType is set OR there are multiple types in staging then { // multiples includes 2 and a type 'family'
        //             processStagingArea if nec
        //             ignore mode token
        //         }
        //         else {
        //             put in staging area
        //             set searchType = 'modes'
        //         }

        else if (key === 'modes') {
            if (searchType) {
                createTokens({ tokenType: key, labels, ignored: true });  // ignore 'Modes of'
                if (searchType === 'grade') feedback(`'Modes of' can not be combined with a grades search.`);
                else if (searchType === 'list') feedback(`'Modes of' only works with a single scale type.`);
                // also validate against composite scale types!???
            }
            else {
                searchType = 'modes';
                stagingArea.modesOfToken = createTokens({ tokenType: key, labels })[0];
                // ??? bit extreme, but what if multiple modesof?
            }
        }


        else if (key === 'grade') {
            if (searchType) {
                createTokens({ tokenType: key, labels, ignored: true });  // ignore
                if (searchType === 'grade') feedback(`You can only query one grade at a time.`);
                else if (searchType === 'list') feedback(`A grade query can not be combined with specific scales.`);
                else if (searchType === 'modes') feedback(`A grade query can only be combined with an instrument - nothing else.`);
            }
            else {
                searchType = 'grade';
                stagingArea.gradeToken = createTokens({ tokenType: key, labels: [labels[0]] })[0];

                if (labels.length > 1) {           // multiple grades - ignore
                    createTokens({ tokenType: key, labels: labels.slice(1), ignored: true });
                    feedback(`You can only query one grade at a time.`)
                }
            }
        }


        //     else if inst then
        //         if inst is set then ignore
        //         else {
        //             set inst
        //             put inst in inst-grade staging
        //         }
        else if (key === 'instrument') {
            labels.forEach(value => {
                if (stagingArea.instToken.value) {
                    createTokens({ tokenType: key, labels: [value], ignored: true });
                    feedback(`You can only specify one instrument.`)
                }
                else {
                    stagingArea.instToken = createTokens({ tokenType: key, labels: [value] })[0];
                }
            });
        }
    }     // end loop

    // ??? maybe should validate instname
    instrument = INSTRUMENTS.filter(i => i.name === (stagingArea.instToken.value || defaultInstName))[0];

    // Sometimes identifying search as type 'list' is by absence of 'Modes of'.
    // In other words, searchType might still be null:
    if (searchType === null) searchType = 'list';

    // Finished going through all the parameters, process what we need to in the staging area.
    if (searchType === 'grade') {
        processGradeStagingArea();
    }
    else {                      // 'list' or 'modes'

        // query 'modes of' => do you want modes of major? - DONE ok
        // query 'modes of C' => do you want modes of C major...?
        //   ø no: I've assumed major
        // ??? don't think this works atm

        if (searchType === 'modes') {

            const scaletypeToken = search.tokens.filter(t => t.tokenType === 'scaletype' && !t.ignored)[0] || {};
            const scaletypeObj = scaleTypeObject(scaletypeToken.value);
            // const tonicPC = search.tokens.filter(t => t.tokenType === 'tonic' && !t.ignored)[0]?.label;
            const tonicTokens = search.tokens.filter(t => t.tokenType === 'tonic' && !t.ignored);

            // const interpolateTonic = tonicPC => (tonicPC ? tonicPC + ' ' : '');
            const interpolateTonicsInURL = tonicTokens => (
                tonicTokens
                    .map(t => t.label.replace(/#/g, '%23'))
                    .join('+')
                + '+'
            );

            // Check there is a scale type
            if (!scaletypeToken.value) {
                feedback(
                    <span>No scale type entered so showing modes of {tonicTokensJSX(tonicTokens)}major.</span>
                    , 'info');  // 000432
            }
            // Check that the scale type has modes
            else if (!scaletypeObj.modes) {
                scaletypeToken.ignored = true;
                if (scaletypeToken.value === 'MIN') {
                    const tonicsURLString = interpolateTonicsInURL(tonicTokens);
                    feedback(
                        <span>
                            Which version of minor do you want modes for? Perhaps&nbsp;
                            <Link to={`/search?q=modes+of+${tonicsURLString}harmonic+minor`}>harmonic minor</Link> or&nbsp;
                            <Link to={`/search?q=modes+of+${tonicsURLString}melodic+minor`}>melodic minor</Link>?
                        </span>
                        , 'error'
                    )
                }
                else {
                    feedback(`There are no modes for scales of type ${scaletypeObj.displayName.toLowerCase()}.`, 'error')
                }
            }
        }
        processAndClearListStagingArea();
        gradeHeader.MIN = { subtypes: ['NAT', 'HAR', 'MEL'] };

        if (searchType === 'list') {
            scalesToDoList.forEach((current, i, array) => {
                let duplicates = [];
                const currType = scaleTypeObject(current.type);
                // Note that items in to do list might be for a scale types SET - this validation doesn't
                // really apply to those..
                array.slice(i + 1)
                    .forEach(item => {
                        const otherType = scaleTypeObject(item.type);
                        console.log('types >> ', current.type, item.type)
                        if (
                            (otherType?.canonicalTypeId === currType?.canonicalTypeId)   // optional chained in case of scale-type-set
                            && (otherType?.id !== currType?.id)                          // ..same
                        ) {
                            duplicates.push(otherType.displayName);
                        }
                    });
                if (duplicates.length) {
                    feedback(<span>Note that {formatAccidentalsJSX(joinPretty([currType.displayName].concat(duplicates), { lastSeparator: ' and ' }))} are different names for the same scale.`</span>, 'warning');
                }
            });
        }
        if (searchText && !scalesToDoList.length) feedback(`Your query didn't bring back any scales.`, 'error');
        // ???337ish if there's something helpful I can offer a link to.. search examples..?

        if (!searchFeedback.errors.length) {

            const metaData = composeListMetaData(searchType, tonicTypeGroups, instrument);

            gradeHeader = {
                ...gradeHeader,
                ...metaData
            };
            // feedback(gradeHeader.metaDescription, 'warning');
        }
    }

    // if it was a 'specific' url query string (like tonic=C&scaletype=MAJ) then construct a text version for search bar
    search.text = search.text || buildStringFromTokens(search.tokens);

    // console.log('todo list, search, searchFeedback ', scalesToDoList, search, searchFeedback);

    return { scalesToDoList, gradeHeader, grade, instrument, search, searchFeedback };




    /*
    
    ######## ##    ## ########      #######  ########    ##     ##    ###    #### ##    ## 
    ##       ###   ## ##     ##    ##     ## ##          ###   ###   ## ##    ##  ###   ## 
    ##       ####  ## ##     ##    ##     ## ##          #### ####  ##   ##   ##  ####  ## 
    ######   ## ## ## ##     ##    ##     ## ######      ## ### ## ##     ##  ##  ## ## ## 
    ##       ##  #### ##     ##    ##     ## ##          ##     ## #########  ##  ##  #### 
    ##       ##   ### ##     ##    ##     ## ##          ##     ## ##     ##  ##  ##   ### 
    ######## ##    ## ########      #######  ##          ##     ## ##     ## #### ##    ## 
    
    */



    function createTokens({ tokenType, labels, ignored = false }) {
        let newTokens = [];
        for (let value of labels) {
            const token = getSearchTokenFromValue({ value, tokenType });
            // console.log('358a', value, token);
            newTokens.push({
                ...token,
                ignored
            });
        }
        search.tokens.push(...newTokens);
        return newTokens;
    }


    function feedback(message, severity = 'warning') {
        // currently only the first message of the most important type (err/warn/info)
        // is actually displayed (in <SearchBar>).
        if (severity === 'info') searchFeedback.info.push(message);
        else if (severity === 'warning') searchFeedback.warnings.push(message);
        else searchFeedback.errors.push(message);
    }

    function processAndClearListStagingArea() {

        let { tonicTokens, scaleTypeTokens, modesOfToken } = stagingArea;
        stagingArea.tonicTokens = [];
        stagingArea.scaleTypeTokens = [];
        stagingArea.modesOfToken = {};

        if (!tonicTokens.length && !scaleTypeTokens.length) return false;  // no tonics or types

        if (!tonicTokens.length) {
            if (!scalesToDoList.length) {
                // first tonic/type pair - but no type so assume:
                tonicTokens = [getSearchTokenFromValue({ tokenType: 'tonic', value: 'ALL' })];
                feedback(`Showing for all keys.`, 'info')
            }
            else {
                // not the first tonic/type pair. No tonic so let's ignore the type.
                scaleTypeTokens = scaleTypeTokens.map(t => t.ignored = true);
                feedback(`Ignoring scale types where no tonic specified.`);
            }
        }
        if (!scaleTypeTokens.length) {
            if (!scalesToDoList.length) {
                // first tonic/type pair - but no type so assume:
                scaleTypeTokens = [getSearchTokenFromValue({ tokenType: 'scaletype', value: 'MAJ' })];
                feedback(
                    <span>
                        Showing major scale(s) &ndash; or enter another scale type, such as&nbsp;
                    <Link to={`/search${searchText}+minor`}>minor</Link> or&nbsp;
                    <Link to={`/search${searchText}+chromatic`}>chromatic</Link>...
                    </span>
                    , 'info')
            }
            else {
                // not the first tonic/type pair. No type so let's ignore the tonics.
                tonicTokens = tonicTokens.map(t => t.ignored = true);
                feedback(`Ignoring tonics which have no scale type.`);
            }
        }

        const activeScaleTypeTokens = scaleTypeTokens.filter(st => !st.ignored),
            activeTonicTokens = tonicTokens.filter(st => !st.ignored);

        for (let scaleTypeToken of activeScaleTypeTokens) {
            for (let tonicToken of activeTonicTokens) {
                scalesToDoList.push({
                    tonic: tonicToken.value === 'ALL' ? 'ALL_KEYS' : tonicToken.label,  // label: e.g. 'F#' (value has Fsh so not helpful)
                    range: '8',
                    type: scaleTypeToken.value,     // value: e.g. 'MAJ'
                    startOctave: 'RANGE_OF_INSTRUMENT',
                    modesOfFlag: (modesOfToken.value === 'Y' && !modesOfToken.ignored) ? true : false
                });
            }
        }

        if (activeScaleTypeTokens.length && activeTonicTokens.length) {
            // Keep a record of the tonic/type pair cos it makes setting list title easier.
            tonicTypeGroups.push({
                tonics: tonicTokens
                    .filter(t => !t.ignored)
                    .map(tonicToken => tonicToken.value === 'ALL' ? 'ALL_KEYS' : pitchClassDisplayString(tonicToken.label)),
                scaleTypes: scaleTypeTokens
                    .filter(st => !st.ignored)
                    .map(scaleTypeToken => scaleTypeToken.value),
            });
        }

        return true;
    }

    function processGradeStagingArea() {
        // ??? validate instrument name?
        const { gradeToken } = stagingArea;

        if (!instrument.grades.includes(parseInt(gradeToken.value))) {
            if (instrument.name === 'treble-clef' || instrument.name === 'bass-clef') {
                feedback(
                    <span>Enter an instrument, or set your main instrument in&nbsp;
                    <button className='link-like-button' onClick={() => setShowSettings(true)}>settings</button>.
                </span>
                    ,
                    'error'
                );
            }
            else {
                feedback(
                    <span>
                        <span>There's no {gradeToken.label} for {formatAccidentalsJSX(instrument.displayName)}.</span>
                        {(instrument.otherGrades) &&
                            <span> Do you want&nbsp;
                                <Link to={`/search?q=${instrument.otherGrades}+grade+${gradeToken.value}`}>
                                    {INSTRUMENTS.filter(i => i.name === instrument.otherGrades)[0].displayName} Grade {gradeToken.value}?
                                </Link>
                            </span>}
                    </span>
                    ,
                    'error'
                );
            }
        }
        else {

            grade = stagingArea.gradeToken.value;

            // set up the scales to do list for the grade:
            const o = toDoListForGrade({ instrument, grade: gradeToken.value, gradeHeader });
            scalesToDoList = o.toDoList;
            gradeHeader = { ...o.gradeHeader };
        }

    }


    function parseQueryString(searchText) {
        console.log('parseQueryString')
        const queryStringOptions = { arrayFormat: 'comma', sort: false };
        // console.log('352', queryString.parse(searchText, queryStringOptions))

        // parse query string into an object with everything uppercase..
        let queryParsed = queryString.parse(searchText, queryStringOptions);
        // let queryParsedUpper = queryString.parse(searchText.toUpperCase(), queryStringOptions);

        // console.log('352', queryParsedUpper);
        // ??? It's weird that parse free text is passing back a string! Just pass an array of tokens - that's what we want ultimately!
        // i.e. fix this and following code should simplify..
        // ???370
        search.text = queryParsed.q || '';
        const freeTextParsed = queryString.parse(parseFreeTextTEMP(search.text), queryStringOptions);
        // const freeTextParsed = queryString.parse(parseFreeText(queryParsedUpper.Q).toUpperCase(), queryStringOptions);
        delete queryParsed.q;

        queryParsed = {
            ...queryParsed,
            ...freeTextParsed
        }

        // and convert the keys to lower case and force every value to be an array
        const queryParamsAsArray = Object.entries(queryParsed).map(([k, v]) => ({ keyWithSuffix: k.toLowerCase(), labels: castArray(v) }));

        let result = [], previousKey = null;

        // Go through array and group together any adjacent items with the same key (i.e. token type)
        queryParamsAsArray.forEach(({ keyWithSuffix, labels }) => {
            const key = keyWithSuffix.replace(/-q*[0-9]+$/, '');    // Strip suffix - free text search params have '-q123' (i.e. extra 'q')
            if (previousKey === key) {
                result[result.length - 1].labels.push(...labels);
                // this syntax: https://stackoverflow.com/questions/45949135/how-to-concat-arrays-in-js-in-mutating-way
            }
            else {
                result.push({ key, labels });
                previousKey = key;
            }
        })

        // console.log('352', result)

        return result;
    };


}


function parseFreeTextTEMP(freeText) {
    // ???370 I changed parseFreeText to return array in order to provide multiple suggestions to search bar
    // this effects the previous version which returns a string..
    // done half-arsed like this cos ultimately it will make more sense to return tokens anyway..
    console.log('parseFreeTextTEMP')

    let res = '', i = 0;
    const bestMatch = {
        tokens: [], score: 1,
        ...parseFreeText(freeText)[0]    // ???perf so this is doing all that work over again?
    };

    // console.log('352 bestMatch', bestMatch)
    bestMatch.tokens.forEach(token => {
        if (token) {
            // append a suffix and include 'q' to distinguish from old-style query params (like 'tonic-1=C')
            // poss can bin all this if just having free text (though consider that I've sent old-style links
            // to people..)
            res += `&${token.tokenType}-q${i}=${token.value}`;
            i++;
        }
    })

    return res;
}



export default parseSearchParams;
