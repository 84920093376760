import React from 'react';
import { connect } from 'react-redux';

import { enharmonicEqual } from '../logic/noteUtils';
import { sortGroups, sortScalesWithinGroup } from '../logic/scaleUtils';

import ScaleBullet from './ScaleBullet';
import GroupNav from './GroupNav';
import { CSSTransition } from 'react-transition-group';
import { color } from '../utils/DevUtils';
// import GroupByTitle from './GroupByTitle';
// import { scaleTypeObject } from '../logic/scaleTypes';

const Grid = ({
    isOpen,
    scaleHeaderList,
    currGroupBy,
    scaleGroups,
    handleGridClick,  // anything extra to do when they click a bullet (such as close the grid on phones)
    containerClassName,

    sortScalesBy,
}) => {

    console.log(...color('greenyellow', '<Grid>'));

    // ignore empty groups (relic of piano keys 000256):
    //
    // Removing: Arguably could be useful as a safeguard - but the width of the grid container doesn't do this so would be wrong.
    // const scaleGroups = scaleGroupsProp.filter(group => group.scaleHeaders.length > 0);

    // const currGroupBy = currGroupByInEffect({ listOfModes, groupByForModes, groupBy });
    const columnOrGrid = scaleHeaderList.length > 12 ? 'grid' : 'column';

    return (
        <CSSTransition
            classNames="slide-in-from-left"
            in={isOpen}
            timeout={400}
            unmountOnExit
        >
            <div className={`nav-sidebar-container ${containerClassName} print-hide`}>
                {/* {scaleHeaderList[0] ?  // don't think this is achieving anything...???
                    */}
                <div className='newgrid-container'>
                    <div className={`newgrid`}>
                        {scaleGroups
                            .sort((g, h) => sortGroups(g, h, currGroupBy, sortScalesBy))
                            .map(group => {
                                const bulletScales = group.keysGridRow.sort((k, l) => sortScalesWithinGroup(k, l, currGroupBy, sortScalesBy));
                                return (
                                    <div className='keys-column' key={group.id}>
                                        <GroupNav
                                            currGroupBy={currGroupBy}
                                            group={group}
                                            onClick={handleGridClick}
                                            tabIndex='-1'
                                            className='newgrid-group-heading'
                                        />
                                        {bulletScales.map(bulletScale => {
                                            const [scale] = group.scaleHeaders.filter(scale => enharmonicEqual(scale.tonicPC, bulletScale.tonicPC) && scale.basetype === bulletScale.basetype);

                                            return scale !== undefined ?
                                                (<ScaleBullet
                                                    key={bulletScale.id}    // that's the react 'key'!
                                                    tonic={scale.tonicPC}
                                                    renderedType={scale.renderedType}
                                                    range={scale.range}
                                                    active
                                                    scaleId={scale.id}
                                                    inKeysGrid
                                                    onClick={handleGridClick}
                                                // className={bulletClassName}
                                                />)
                                                : columnOrGrid === 'grid' ?
                                                    <ScaleBullet
                                                        key={bulletScale.id}
                                                        tonic={bulletScale.tonicPC}
                                                        basetype={bulletScale.basetype}
                                                        disabled
                                                        inKeysGrid
                                                    />
                                                    : null
                                        })}
                                    </div>
                                )
                            })
                        }
                    </div >
                </div>
                {/* : null
                } */}
            </div>
        </CSSTransition>
    )
}


const mapStateToProps = (state) => {
    return {
        sortScalesBy: state.settings.sortScalesBy
    };
};

export default connect(mapStateToProps)(Grid);

