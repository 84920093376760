import React /*, { useState } */ from 'react';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import PlayControls from './PlayControls';
import ShareControl from './ShareControl';
import { MinorOptions, EnharmonicFlipControl } from './ActionControls';
import { RangeControlSingle } from './RangeControl';
import { ChordControlSingle } from './ChordControl';
// import { MoreIcon } from '../assets/icons';
import isEqual from 'lodash/isEqual';
import RelatedScalesControl from './RelatedScalesControl';


const ScaleActionsBar = (
    {
        gradeHeader = {},   // default cos fingering charts don't have a gradeHeader
        scaleHeader,
        instrument,
        playingScaleId,
        printing,
        handleScaleAction,
        handlePlayScale,
        stopPlayback
    }
) => {

    // const [scaleMenuOpen, setScaleMenuOpen] = useState(false);
    // const toggleMenu = () => setScaleMenuOpen(prev => !prev);

    const handleActionClick = (event) => {
        stopPlayback();
        handleScaleAction({ event, scaleHeaderIdsToUpdate: [scaleHeader.id] });
    }

    return (
        // !printing && !isLoading &&
        !printing &&
        <div className='scale-footer'>

            <div className='actions-bar scale-actions-bar' data-testid='scale-actions-bar'>
                <PlayControls
                    scaleId={scaleHeader.id}
                    playingScaleId={playingScaleId}
                    handlePlayScale={handlePlayScale}
                />
                {handleScaleAction &&
                    <React.Fragment>
                        <MinorOptions
                            scaleHeaders={[scaleHeader]}
                            subtypes={gradeHeader.MIN.subtypes}
                            renderedType={scaleHeader.renderedType}  // ??? actually don't need if passing scaleHeader
                            onClick={handleActionClick}
                        />
                        <RangeControlSingle
                            instrument={instrument}
                            scaleHeader={scaleHeader}
                            onClick={handleActionClick}
                        />

                        {/*
                
                // Keep this handy as ChordControl would not be relevant for grades! 000260

                 {scaleTypeObject(scaleHeader.basetype).hasArpeggio &&
                    <ArpeggioControl
                        currentArpeggio={scaleHeader.arpeggio}
                        onClick={handleActionClick}
                    />
                } */}

                        <ChordControlSingle
                            renderedType={scaleHeader.renderedType}
                            currentChord={scaleHeader.showChord}
                            onClick={handleActionClick}
                        />

                        <EnharmonicFlipControl
                            scaleHeader={scaleHeader}
                            onClick={handleActionClick}
                        />

                        <ShareControl
                            scaleHeader={scaleHeader}
                            instrument={instrument}
                        />

                        <RelatedScalesControl
                            scaleHeader={scaleHeader}
                            instrument={instrument}
                        />

                    </React.Fragment>
                }
            </div>
        </div>
    )

}


function areEqual(prev, next) {

    const res =
        // gradeHeader 
        isEqual(next.scaleHeader, prev.scaleHeader)
        && next.playingScaleId === prev.playingScaleId
        && next.printing === prev.printing
        && next.handlePlayScale === prev.handlePlayScale
        ;

    // console.log(isEqual(next.scaleHeader, prev.scaleHeader),
    //     next.handleActionClick === prev.handleActionClick,
    // );


    return res;
}

// export default ScaleActionsBar;
export default React.memo(ScaleActionsBar, areEqual);