import { SCALE_TYPES_CANONICAL } from '../logic/scaleTypesData';

// const filterScaleTypesByNoteCount = (requiredNoteCount) => {
//     return Object.entries(PRINCIPAL_SCALE_TYPES)
//         .map(([basetype, values]) => ({                              // convert to array
//             id: basetype,
//             ...values
//         }))
//         .filter(t => t.formula.length === requiredNoteCount)         // correct note count
//         .map(t => t.id);                                             // just want the id (the basetype)
// }
const filterScaleTypes = (comparison) => {
    return SCALE_TYPES_CANONICAL
        .filter((t) => comparison(t))                       // compare
        .map(t => t.id);                                    // just want the id (the basetype)
}

export const scaleTypeSets = {
    ALL: {
        pluralName: 'Primary Scale Types',
        basetypes: ['MAJ', 'MIN', 'CHR', 'DOM7', 'DIM7']
    },
    // ABRSM: {
    //     pluralName: 'ABRSM Scale Types',
    //     basetypes: [t.MAJ, t.MIN, t.CHROM, t.WHOLE, t.DOM7, t.DIM7]
    // },
    PENTATONICS: {
        pluralName: 'Pentatonic Scales',
        basetypes: ['MINPENT', 'MAJPENT'],
        nameVariations: ['pentatonics'],
    },
    BLUESES: {
        pluralName: 'Blues Scales',
        basetypes: filterScaleTypes(t => t.displayName.includes('Blues'))
    },
    BEBOPS: {
        pluralName: 'Bebop Scales',
        basetypes: filterScaleTypes(t => t.displayName.includes('Bebop')),
        nameVariations: ['Bebop']
    },
    // ??? Disabled cos needs thought: 
    // HEPTATONICS: {
    //     pluralName: 'Seven-note (Heptatonic) Scales',
    //     basetypes: filterScaleTypesByNoteCount(7)
    // }
};


