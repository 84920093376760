import React, { useState, useEffect, useRef } from "react";
import { Popover, PopoverBody, Toast, ToastBody } from "reactstrap";
import { Control } from './ActionControls';
import { ShareIcon } from '../assets/icons';
import queryString from 'query-string';

const ShareControl = ({ scaleHeader, instrument }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [copiedConfirmationOpen, setCopiedConfirmationOpen] = useState(false);
    const linkElt = useRef(null);
    const scaleId = scaleHeader.id;

    const toggleSharePopover = () => setPopoverOpen(!popoverOpen);
    const handleFocus = (event) => event.target.select();

    const scaleParams = {
        tonic: scaleHeader.tonicPC.replace(/#/g, 'sh'),   // replace # with 'sh' - previously done by lookup of token.value, but pitches are not there now
        scaletype: scaleHeader.basetype,
        instrument: instrument.name
    }

    const scaleUrl = `${window.location.origin}${window.location.pathname}?${queryString.stringify(scaleParams, { sort: false })}`;

    // If the link elt exists (i.e. the popover is open) then put focus on the link itself.
    // Without the timeout it always zaps to the top of the list - perhaps cos of the way the
    // popover is repositioned.
    useEffect(() => {
        let t;
        if (linkElt.current) {
            t = setTimeout(() => { if (linkElt.current) linkElt.current.focus() }, 10);
        }
        return function tidyup() { clearTimeout(t) }
    });

    function copyToClipboard(e) {
        linkElt.current.select();
        document.execCommand('copy');
        setCopiedConfirmationOpen(true);
        setPopoverOpen(false);
    };

    useEffect(() => {
        let t;
        if (copiedConfirmationOpen) {
            t = setTimeout(() => setCopiedConfirmationOpen(false), 5000);
        }
        return () => clearTimeout(t);
    }, [copiedConfirmationOpen]);

    return (
        <span>
            <Control
                id={"Popover-" + scaleId}
                active={popoverOpen}
            >
                <ShareIcon /> Share
            </Control>
            <Popover
                placement='bottom'
                toggle={toggleSharePopover}
                isOpen={popoverOpen}
                target={"Popover-" + scaleId}
                trigger='legacy'  // facilitates click elsewhere => close the popover
            >
                {/* <PopoverHeader>Share</PopoverHeader> */}
                <PopoverBody className='share-popover'
                >
                    <span className='share-title'>
                        Direct link to this scale:
                    </span>
                    <input
                        className='share-link'
                        type="text"
                        id={'link-' + scaleId}
                        ref={linkElt}
                        value={scaleUrl}
                        readOnly
                        onFocus={handleFocus}
                    />
                    {
                        // only display the button if the copy command exists 
                        document.queryCommandSupported('copy') &&
                        <div>
                            <button
                                className='text-button copy-link'
                                onClick={copyToClipboard}>
                                Copy link
                            </button>
                        </div>
                    }
                    <div className="btw mt-2">
                        Nb. this shares the unmodified scale &ndash; at present it doesn't retain octave changes, chord display, note names, fingerings, etc.
                    </div>
                </PopoverBody>
            </Popover>
            <Toast
                className='scales-toast copied'
                isOpen={copiedConfirmationOpen}
                fade
            >
                <ToastBody>
                    Copied to clipboard.
                </ToastBody>
            </Toast>
        </span>
    );
};



export default React.memo(ShareControl);