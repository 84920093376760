import React, { useRef, useEffect } from 'react';

const Overlay = ({ className, dismiss }) => {

    const overlayRef = useRef(null);

    useEffect(() => {
        // dismiss on scroll on touch screen
        const handleTouch = (e) => {
            e.preventDefault();
            dismiss();
        }

        const overlayDiv = overlayRef.current;
        overlayDiv && overlayDiv.addEventListener('touchstart', handleTouch);  // generates a warning - see 000445

        return function tidyup() {
            overlayDiv && overlayDiv.removeEventListener('touchstart', handleTouch)
        }
    });

    return (
        <div
            className={`overlay ${className} print-hide`}
            onClick={dismiss}
            ref={overlayRef}
        />
    );

}

export default Overlay;