import React from 'react';
import { scaleTypeObject } from "../logic/scaleTypes";

// Reorganised so can load fns from node scripts
// So I have to 'pass them through' this module (or change imports everywhere..)
import { convertFlatSharpNaturalToTypeable, convertStringToQueryURL, pitchClassDisplayString, typeableLookup } from './MusicSymbolsFormattingNode';
export { convertFlatSharpNaturalToTypeable, convertStringToQueryURL, pitchClassDisplayString, typeableLookup };

export const pitchClassJSX = (pitchClass) => {
    const alterationDisplay = typeableLookup[pitchClass.substr(1)];
    if (typeof alterationDisplay === 'undefined') return pitchClass;  // Not in lookup, just return

    return (
        <div style={{ display: 'inline-block' }}>
            {pitchClass[0]}
            <div className={`music-symbol accidental ${alterationDisplay.className}`}>
                {alterationDisplay.unicode}
            </div>
        </div>
    )
}

export const trebleClefJSX = (
    <div className="music-symbol treble-clef">
        𝄞
    </div>
)
export const bassClefJSX = (
    <div className="music-symbol bass-clef">
        𝄢
    </div>
)


export const formatAccidentalsJSX = (str) => {
    // Returns JSX of string with all accidental symbols given correct classNames.
    // Now works for double sharps and flats, and for multiple accidentals.

    /*
    Handy for copy/paste:
♭
♯
♮
𝄪
𝄫
♭♯♮𝄪𝄫
    */

    if (typeof str === 'number') return formatAccidentalsJSX(str.toString());
    if (typeof str !== 'string') return str;

    const chunks = str.split(/(♭|♯|♮|𝄪|𝄫)/g);

    return (
        <div style={{ display: 'inline-block' }}>
            {chunks.map((chunk, i) => {
                const symbol = symbolLookup[chunk];
                return (
                    typeof symbol === 'undefined' ?
                        chunk
                        : <span
                            key={i}
                            className={`music-symbol accidental ${symbol.className}`}
                        >
                            {chunk}
                        </span>
                );
            })}
        </div >
    )
}


export const Sharp = () => (formatAccidentalsJSX('♯'));
export const Flat = () => (formatAccidentalsJSX('♭'));
export const Natural = () => (formatAccidentalsJSX('♮'));


// Return comma-separated list of tonics, with correct #/b signs and spaces.
// Includes one extra (non-breaking) space at end.
// Doesn't currently deal with all-keys 'tonic'.
export function tonicTokensJSX(tonicTokens) {
    const tonicsString = tonicTokens
        .map(t => pitchClassDisplayString(t.label))
        .join(', ')
        + '\u00a0';   // nbsp

    return formatAccidentalsJSX(tonicsString);
};




export const symbolLookup = {
    '♭': { typeable: 'b', className: 'flat-sign' },
    '♯': { typeable: '#', className: 'sharp-sign' },
    '♮': { typeable: 'n', className: 'natural-sign' },
    '𝄪': { typeable: '##', className: 'double-sharp' },
    '𝄫': { typeable: 'bb', className: 'double-flat' },
}





export const composeSearchText = ({ tonicPC, basetype, renderedType, modesOf, instName }) => {
    let searchText;
    const typeObj = scaleTypeObject(renderedType || basetype);
    const scaleTypeText = typeObj.displayName.toLowerCase();
    const instText = instName
        ? `, ${instName.replace(/-/g, ' ')}`
        // ? `, ${convertFlatSharpNaturalToTypeable( INSTRUMENTS.find(inst => inst.name === instName).displayName).toLowerCase()}`
        : '';

    searchText = (tonicPC || 'ALL_KEYS') === 'ALL_KEYS'
        ? `${scaleTypeText} all keys${instText}`
        : `${tonicPC} ${scaleTypeText}${instText}`;

    if (modesOf) searchText = 'modes of ' + searchText;

    return searchText;
}