import React from 'react';
import Helmet from 'react-helmet';

import { convertStringToQueryURL } from '../utils/MusicSymbolsFormatting';
import escapeRegExp from 'lodash/escapeRegExp';
import { screenshotItems } from '../utils/screenshotItems';
import { imagesPath } from '../utils/imagesPath.js';


const MetaDecorator = ({
    canonicalUrl = `https://${window.location.host}`,
    // canonicalUrl = `https://${window.location.host.replace(/^www./, '')}`,
    title,
    description,
    ogTitle,
    ogDescription,
    image
}) => {

    console.log('canonicalurl', canonicalUrl, 'loc')

    return (
        <Helmet>

            <link rel="canonical" content={canonicalUrl} />
            {title && <title>{title}</title>}
            <meta name="description" content={description} />

            <meta property="og:url" content={canonicalUrl} />
            <meta property="og:title" content={ogTitle || title} />
            <meta property="og:description" content={ogDescription || description} />

            {image && <meta property="og:image" content={image} />}

        </Helmet>
    )
}


export const MetaDecoratorForScales = ({ listTitle, titleLong, description, instrument, ...otherProps }) => {

    if (!listTitle) {
        return null;             // don't overwrite any tags if no list title (e.g. no scales retrieved)
    }

    const pageTitle = listTitle + (instrument ? ` \u2013 ${instrument.displayName}` : '');
    // ' | scale-lookup.com' is appended below (but not used for matching screenshots)


    // goal is to get a canonical query, i.e. to equate 'c maj', 'c', 'c major' etc queries
    // Atm I'll do it with the list title, plus the instrument (unless inst is the default 'treble-clef')
    // 1. Make a version of title which includes instrument
    const titleForUrlQuery =
        listTitle.replace(/ scales?/, '')
        + (
            (!instrument || instrument.name === 'treble-clef')
                ? ''
                : ` ${instrument.name}`     // nb. using name, not display name
        );

    // 2. Convert title into a query url
    const canonicalUrl = `https://${window.location.host}`
        + convertStringToQueryURL(
            titleForUrlQuery.replace(/[,;\-\u2013()]/g, ' ').replace(/\s+/g, ' '), { toStandardCase: true }
        );


    const pageTitleRegex = regExFromTitle(pageTitle);

    let screenshotItem = screenshotItems.find(s => {       // look for exact match screenshot item (including instrument)
        return s.pageTitle.match(pageTitleRegex);
    });
    if (!screenshotItem) {
        const titleWithClef = listTitle + (instrument ? ` \u2013 ${instrument.clef} clef` : '');
        const pageTitleRegex = regExFromTitle(titleWithClef);
        screenshotItem = screenshotItems.find(s => {       // ...or get screenshot item for the right clef
            return s.pageTitle.match(pageTitleRegex);
        });
    }

    const image = screenshotItem ? imagesPath(`screenshots/${screenshotItem.search}`) : undefined;

    return (
        <MetaDecorator
            canonicalUrl={canonicalUrl}
            title={`${pageTitle} | ${process.env.REACT_APP_NAME}`}
            description={`${titleLong} ${description}`}
            ogDescription={description}
            image={image}
            {...otherProps}
        />
    );
}

function regExFromTitle(title) {
    return new RegExp('^' + escapeRegExp(title.replace('\u2013', '-')) + '$', 'i');
}

export default MetaDecorator;