
// List of notes and which grade they first appear in. 000206
// Uses SPN so we get an entry for both Bb4 and A#4 - which I want cos I want to show fingering
// for both of these.
// Doesn't bother to cover all minor types, but fingering logic should show fingering if grade
// is equal to current grade *or* if it is higher.

export const notesByGrade = {
    'trumpet': { "C4": 1, "D4": 1, "E4": 1, "F4": 1, "G4": 1, "A4": 1, "B4": 1, "C5": 1, "A3": 1, "B3": 1, "G#4": 1, "Bb3": 2, "Eb4": 2, "Bb4": 2, "F#4": 2, "C#5": 2, "D5": 2, "Ab4": 3, "Eb5": 3, "D#5": 3, "E5": 3, "C#4": 3, "F5": 4, "Db5": 4, "Ab3": 4, "Db4": 4, "A#4": 4, "F#5": 4, "Gb4": 5, "Gb5": 5, "Ab5": 5, "D#4": 5, "B#4": 5, "G#5": 5, "G3": 5, "G5": 5, "A5": 6, "F#3": 6, "G#3": 6, "A#3": 6, "E#4": 6, "E#5": 6, "F##4": 6, "F##5": 6, "Bb5": 6, "B5": 7, "C6": 7, "Cb5": 7, "A#5": 7 },
    'eb-cornet': { "C4": 1, "D4": 1, "E4": 1, "F4": 1, "G4": 1, "A4": 1, "B4": 1, "C5": 1, "A3": 1, "B3": 1, "G#4": 1, "Bb3": 2, "Eb4": 2, "Bb4": 2, "F#4": 2, "C#5": 2, "D5": 2, "Ab4": 3, "Eb5": 3, "D#5": 3, "E5": 3, "C#4": 3, "F5": 4, "Db5": 4, "Ab3": 4, "Db4": 4, "A#4": 4, "F#5": 4, "Gb4": 5, "Gb5": 5, "Ab5": 5, "D#4": 5, "B#4": 5, "G#5": 5, "G3": 5, "G5": 5, "A5": 6, "F#3": 6, "G#3": 6, "A#3": 6, "E#4": 6, "E#5": 6, "F##4": 6, "F##5": 6, "Bb5": 8, "Cb5": 8 },
    'horn': { "C4": 1, "D4": 1, "E4": 1, "F4": 1, "G4": 1, "A4": 1, "B4": 1, "C5": 1, "A3": 1, "B3": 1, "G#4": 1, "Bb3": 2, "Eb4": 2, "Bb4": 2, "C#4": 2, "F#4": 2, "A#4": 2, "C#5": 2, "D5": 2, "Ab4": 3, "D#5": 3, "E5": 3, "G3": 3, "Eb5": 4, "F5": 4, "F#5": 4, "E3": 4, "F#3": 4, "G#3": 4, "D#4": 4, "F3": 4, "Ab3": 4, "Db4": 4, "Db5": 4, "D3": 5, "Eb3": 5, "G5": 5, "E#4": 5, "E#5": 5, "C3": 6, "Db3": 6, "Gb3": 6, "Gb4": 6, "A#3": 6, "Ab5": 6, "C#3": 6, "D#3": 6, "B#3": 6, "B#4": 6, "Cb4": 6, "Cb5": 6, "A2": 6, "B2": 6, "Bb2": 7, "Gb5": 7, "F##4": 7, "F##5": 7, "G#5": 7, "E#3": 7, "C##4": 7, "C##5": 7, "A5": 8, "Bb5": 8, "A#5": 8, "B5": 8 },
    'trombone': { "Bb2": 1, "C3": 1, "D3": 1, "Eb3": 1, "F3": 1, "G3": 1, "A3": 1, "Bb3": 1, "Ab3": 1, "B3": 1, "C4": 1, "Ab2": 2, "Db3": 2, "E3": 2, "C#4": 2, "D4": 2, "F#3": 3, "Eb4": 3, "Db4": 3, "G2": 3, "A2": 3, "C#3": 3, "G#3": 3, "Gb3": 4, "D#4": 4, "E4": 4, "F4": 4, "B2": 5, "G4": 5, "F2": 5, "F#2": 5, "G#2": 5, "A#2": 5, "D#3": 5, "E#3": 5, "A#3": 5, "E#4": 5, "F#4": 5, "B#3": 6, "G#4": 6, "E2": 6, "Ab4": 6, "Gb4": 7, "A4": 7, "Bb4": 7, "Cb4": 7, "F##3": 7, "F##4": 7 },
    'trombone-treble': { "C4": 1, "D4": 1, "E4": 1, "F4": 1, "G4": 1, "A4": 1, "B4": 1, "C5": 1, "Bb4": 1, "C#5": 1, "D5": 1, "Bb3": 2, "Eb4": 2, "F#4": 2, "D#5": 2, "E5": 2, "G#4": 3, "F5": 3, "Eb5": 3, "A3": 3, "B3": 3, "Ab4": 4, "E#5": 4, "F#5": 4, "G5": 4, "C#4": 5, "D#4": 5, "A#4": 5, "A5": 5, "G3": 5, "Ab3": 5, "Db4": 5, "Db5": 5, "Ab5": 5, "G#5": 5, "G#3": 5, "Gb4": 6, "Cb5": 6, "Gb5": 6, "Bb5": 6, "F#3": 6, "A#3": 6, "E#4": 6, "F##4": 6, "F##5": 6, "B5": 7, "C6": 7, "B#4": 7, "A#5": 7 },
    'bass-trombone': { "Bb2": 1, "C3": 1, "D3": 1, "Eb3": 1, "F3": 1, "G3": 1, "A3": 1, "Bb3": 1, "Ab3": 1, "B3": 1, "C4": 1, "Ab2": 2, "Db3": 2, "E3": 2, "C#4": 2, "D4": 2, "F#3": 3, "Eb4": 3, "Db4": 3, "G2": 3, "A2": 3, "C#3": 3, "G#3": 3, "Gb3": 4, "D#4": 4, "E4": 4, "F4": 4, "B2": 5, "G4": 5, "F2": 5, "F#2": 5, "G#2": 5, "A#2": 5, "D#3": 5, "E#3": 5, "A#3": 5, "E#4": 5, "F#4": 5, "C2": 6, "D2": 6, "E2": 6, "Eb2": 6, "C#2": 6, "D#2": 6, "B#2": 6, "B#3": 6, "Gb2": 6, "Cb3": 6, "Cb4": 6, "Db2": 6, "F##3": 7, "G#4": 8, "A4": 8, "Bb4": 8, "B4": 8, "E#2": 8 },
    'eb-horn': { "C4": 1, "D4": 1, "E4": 1, "F4": 1, "G4": 1, "A4": 1, "B4": 1, "C5": 1, "A3": 1, "B3": 1, "G#4": 1, "Bb3": 2, "Eb4": 2, "Bb4": 2, "F#4": 2, "C#5": 2, "D5": 2, "Ab4": 3, "Eb5": 3, "D#5": 3, "E5": 3, "C#4": 3, "F5": 4, "Db5": 4, "Ab3": 4, "Db4": 4, "A#4": 4, "F#5": 4, "Gb4": 5, "Gb5": 5, "Ab5": 5, "D#4": 5, "B#4": 5, "G#5": 5, "G3": 5, "G5": 5, "A5": 6, "F#3": 6, "G#3": 6, "A#3": 6, "E#4": 6, "E#5": 6, "Bb5": 6, "F##4": 6, "F##5": 6, "B5": 7, "C6": 7, "Cb5": 7, "A#5": 7 },
    'baritone-euphonium': { "Bb2": 1, "C3": 1, "D3": 1, "Eb3": 1, "F3": 1, "G3": 1, "A3": 1, "Bb3": 1, "G2": 1, "A2": 1, "F#3": 1, "Ab2": 2, "Db3": 2, "Ab3": 2, "E3": 2, "B3": 2, "C4": 2, "Gb3": 3, "Db4": 3, "C#4": 3, "D4": 3, "B2": 3, "C#3": 3, "G#3": 3, "Eb4": 4, "Cb4": 4, "F#2": 4, "G#2": 4, "A#2": 4, "D#3": 4, "E#3": 4, "A#3": 4, "E4": 4, "D#4": 5, "F#4": 5, "F##3": 5, "F2": 5, "F4": 5, "E#4": 5, "G4": 6, "E2": 6, "Ab4": 6, "F##4": 6, "G#4": 6, "Gb4": 7, "A4": 7, "Bb4": 7, "B#3": 7 },
    'baritone-euph-treble': { "C4": 1, "D4": 1, "E4": 1, "F4": 1, "G4": 1, "A4": 1, "B4": 1, "C5": 1, "A3": 1, "B3": 1, "G#4": 1, "Bb3": 2, "Eb4": 2, "Bb4": 2, "F#4": 2, "C#5": 2, "D5": 2, "Ab4": 3, "Eb5": 3, "D#5": 3, "E5": 3, "C#4": 3, "F5": 4, "Db5": 4, "Ab3": 4, "Db4": 4, "A#4": 4, "F#5": 4, "Gb4": 5, "Gb5": 5, "Ab5": 5, "D#4": 5, "B#4": 5, "G#5": 5, "G3": 5, "G5": 5, "A5": 6, "F#3": 6, "G#3": 6, "A#3": 6, "E#4": 6, "E#5": 6, "Bb5": 6, "F##4": 6, "F##5": 6, "B5": 7, "C6": 7, "Cb5": 7, "A#5": 7 },
    'eb-tuba': { "Eb2": 1, "F2": 1, "G2": 1, "Ab2": 1, "Bb2": 1, "C3": 1, "D3": 1, "Eb3": 1, "C2": 1, "D2": 1, "B2": 1, "Db2": 2, "Gb2": 2, "Db3": 2, "A2": 2, "E3": 2, "F3": 2, "F#2": 3, "G#2": 3, "A#2": 3, "C#3": 3, "D#3": 3, "E#3": 3, "F#3": 3, "G3": 3, "Cb3": 3, "E2": 3, "Ab3": 4, "F##3": 4, "G#3": 4, "B1": 4, "C#2": 4, "D#2": 4, "Gb3": 4, "A3": 4, "B3": 5, "B#2": 5, "Bb1": 5, "Bb3": 5, "A#3": 5, "C4": 6, "A1": 6, "Db4": 6, "B#3": 6, "C#4": 6, "D4": 7, "Eb4": 7, "D#4": 7, "E#2": 7, "C##3": 8, "C##4": 8 },
    'bb-tuba': { "Bb1": 1, "C2": 1, "D2": 1, "Eb2": 1, "F2": 1, "G2": 1, "A2": 1, "Bb2": 1, "G1": 1, "A1": 1, "F#2": 1, "Ab1": 2, "Db2": 2, "Ab2": 2, "E2": 2, "B2": 2, "C3": 2, "Gb2": 3, "Db3": 3, "C#3": 3, "D3": 3, "B1": 3, "C#2": 3, "G#2": 3, "Eb3": 4, "Cb3": 4, "F#1": 4, "G#1": 4, "A#1": 4, "D#2": 4, "E#2": 4, "A#2": 4, "E3": 4, "D#3": 5, "F#3": 5, "F##2": 5, "F1": 5, "F3": 5, "E#3": 5, "G3": 6, "E1": 6, "Ab3": 6, "F##3": 6, "G#3": 6, "Gb3": 7, "A3": 7, "Bb3": 7, "B#2": 7 },
    'f-tuba': { "F2": 1, "G2": 1, "A2": 1, "Bb2": 1, "C3": 1, "D3": 1, "E3": 1, "F3": 1, "D2": 1, "E2": 1, "C#3": 1, "Eb2": 2, "Ab2": 2, "Eb3": 2, "B2": 2, "F#3": 2, "G3": 2, "Db3": 3, "Ab3": 3, "G#3": 3, "A3": 3, "F#2": 3, "G#2": 3, "Bb3": 4, "Gb3": 4, "Db2": 4, "Gb2": 4, "D#3": 4, "B3": 4, "A#2": 5, "E#3": 5, "A#3": 5, "C#4": 5, "Cb3": 5, "C2": 5, "C4": 5, "Db4": 5, "D4": 6, "B1": 6, "C#2": 6, "D#2": 6, "Eb4": 6, "B#2": 6, "B#3": 6, "Cb4": 6, "E4": 7, "F4": 7, "F##3": 7, "D#4": 7, "E#2": 7, "C##3": 7, "C##4": 7 },
    'c-tuba': { "C2": 1, "D2": 1, "E2": 1, "F2": 1, "G2": 1, "A2": 1, "B2": 1, "C3": 1, "A1": 1, "B1": 1, "G#2": 1, "Bb1": 2, "Eb2": 2, "Bb2": 2, "F#2": 2, "C#3": 2, "D3": 2, "Ab2": 3, "Eb3": 3, "D#3": 3, "E3": 3, "C#2": 3, "F3": 4, "Db3": 4, "Ab1": 4, "Db2": 4, "A#2": 4, "F#3": 4, "Gb2": 5, "Gb3": 5, "Ab3": 5, "D#2": 5, "B#2": 5, "G#3": 5, "G1": 5, "G3": 5, "A3": 6, "F#1": 6, "G#1": 6, "A#1": 6, "E#2": 6, "E#3": 6, "Bb3": 6, "F##2": 6, "F##3": 6, "B3": 7, "C4": 7, "Cb3": 7, "A#3": 7 },
    'eb-tuba-treble': { "C4": 1, "D4": 1, "E4": 1, "F4": 1, "G4": 1, "A4": 1, "B4": 1, "C5": 1, "A3": 1, "B3": 1, "G#4": 1, "Bb3": 2, "Eb4": 2, "Bb4": 2, "F#4": 2, "C#5": 2, "D5": 2, "Ab4": 3, "Eb5": 3, "D#5": 3, "E5": 3, "C#4": 3, "F5": 4, "Db5": 4, "Ab3": 4, "Db4": 4, "A#4": 4, "F#5": 4, "Gb4": 5, "Gb5": 5, "Ab5": 5, "D#4": 5, "B#4": 5, "G#5": 5, "G3": 5, "G5": 5, "A5": 6, "F#3": 6, "G#3": 6, "A#3": 6, "E#4": 6, "E#5": 6, "Bb5": 6, "F##4": 6, "F##5": 6, "B5": 7, "C6": 7, "Cb5": 7, "A#5": 7 },
    'bb-tuba-treble': { "C4": 1, "D4": 1, "E4": 1, "F4": 1, "G4": 1, "A4": 1, "B4": 1, "C5": 1, "A3": 1, "B3": 1, "G#4": 1, "Bb3": 2, "Eb4": 2, "Bb4": 2, "F#4": 2, "C#5": 2, "D5": 2, "Ab4": 3, "Eb5": 3, "D#5": 3, "E5": 3, "C#4": 3, "F5": 4, "Db5": 4, "Ab3": 4, "Db4": 4, "A#4": 4, "F#5": 4, "Gb4": 5, "Gb5": 5, "Ab5": 5, "D#4": 5, "B#4": 5, "G#5": 5, "G3": 5, "G5": 5, "A5": 6, "F#3": 6, "G#3": 6, "A#3": 6, "E#4": 6, "E#5": 6, "Bb5": 6, "F##4": 6, "F##5": 6, "B5": 7, "C6": 7, "Cb5": 7, "A#5": 7 },
    'f-tuba-treble': { "C4": 1, "D4": 1, "E4": 1, "F4": 1, "G4": 1, "A4": 1, "B4": 1, "C5": 1, "A3": 1, "B3": 1, "G#4": 1, "Bb3": 2, "Eb4": 2, "Bb4": 2, "F#4": 2, "C#5": 2, "D5": 2, "Ab4": 3, "Eb5": 3, "D#5": 3, "E5": 3, "C#4": 3, "F5": 4, "Db5": 4, "Ab3": 4, "Db4": 4, "A#4": 4, "F#5": 4, "Gb4": 5, "Gb5": 5, "Ab5": 5, "D#4": 5, "B#4": 5, "G#5": 5, "G3": 5, "G5": 5, "A5": 6, "F#3": 6, "G#3": 6, "A#3": 6, "E#4": 6, "E#5": 6, "Bb5": 6, "F##4": 6, "F##5": 6, "B5": 7, "C6": 7, "Cb5": 7, "A#5": 7 },
    'c-tuba-treble': { "C4": 1, "D4": 1, "E4": 1, "F4": 1, "G4": 1, "A4": 1, "B4": 1, "C5": 1, "A3": 1, "B3": 1, "G#4": 1, "Bb3": 2, "Eb4": 2, "Bb4": 2, "F#4": 2, "C#5": 2, "D5": 2, "Ab4": 3, "Eb5": 3, "D#5": 3, "E5": 3, "C#4": 3, "F5": 4, "Db5": 4, "Ab3": 4, "Db4": 4, "A#4": 4, "F#5": 4, "Gb4": 5, "Gb5": 5, "Ab5": 5, "D#4": 5, "B#4": 5, "G#5": 5, "G3": 5, "G5": 5, "A5": 6, "F#3": 6, "G#3": 6, "A#3": 6, "E#4": 6, "E#5": 6, "Bb5": 6, "F##4": 6, "F##5": 6, "B5": 7, "C6": 7, "Cb5": 7, "A#5": 7 },
}