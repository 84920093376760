import React from 'react';

import ScaleBullet from './ScaleBullet';
import { isPhonePortrait, numberOfCharsInJSX } from '../utils/miscUtils';
import { scaleFullTitleJSX } from '../logic/scaleUtils';

const ScaleHeader = ({ tonicPC, renderedType, showChord, range, message }) => {

    const narrowScreen = isPhonePortrait();
    const titleJSX = scaleFullTitleJSX({ tonicPC, renderedType, showChord, range, narrowScreen });
    const condensed = narrowScreen && numberOfCharsInJSX(titleJSX) > 42 ? 'condensed' : '';
    // 42 by trial-and-error. E.g. 'B♭ Melodic Minor – B♭ Minor triad (2 oct)' is 41 chars


    return (
        <div className='scale-header' >
            <ScaleBullet
                tonic={tonicPC}
                renderedType={renderedType}
            />
            <div className="scale-info">
                <h3 className={`scale-title ${condensed}`} data-testid='scale-title'>
                    {titleJSX}
                </h3>
                {message &&
                    <div className='scale-message'>
                        {message}
                    </div>
                }
            </div>
        </div >
    )
}

export default ScaleHeader;