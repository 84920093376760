import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import notation from '../notation/NotationConsts';
import { formatAccidentalsJSX } from '../utils/MusicSymbolsFormatting';

import { ShowIcon, HideIcon } from '../assets/icons';
import isEqual from 'lodash/isEqual';


const { NOTE_CLICK_AREA_WIDTH } = notation;

const Fingerings = ({
    instrument,
    grade,              // prev. used for 'new to this grade' logic..
    notePositions,
    disabledNotes,
    noteData,                       // ??? is there a saving to make? Don't need entire 'deep' notes?
    infoToDisplay = 'fingerings',
    instrumentPrefs,
    fingeringsAllInstances,  // false means just first instance of note in scale
    show,
    fingeringsChart,
    varietyId           // show fingerings for this, ignore instrumentPrefs (for fingering chart)
}) => {

    const [fingeringsShowing, setFingeringsShowing] = useState([]);

    useEffect(() => {
        // const notesSPN = noteData.map(n => n.SPN);
        const defaultFingeringsShowing = () => (
            // Following currently just returns an array of 1s or an array of 0s. Left in this format
            // so I can easily reinstate the 'new for grade' stuff. (000275)
            noteData.map((n, i) => {
                if (show === 'ALL' || fingeringsChart) return 1;
                else return 0;
                // else return (fingeringsAllInstances          // setting says 'both up and down'..
                //     || notesSPN.indexOf(n) === i) &&         // ..or this is first instance of this note in scale,
                //     instrument.notesByGrade[n] >= grade      // ..and it's a new note for this grade
                //     ? 1                               // show fingering
                //     : 0                               // or don't
            })
        );

        setFingeringsShowing(defaultFingeringsShowing());

    }, [show, fingeringsChart, noteData])


    // temporarily show the eye on initial render to say it's there.
    const [eyeTempClass, setEyeTempClass] = useState(null);
    useEffect(() => {
        const stagger = infoToDisplay === 'fingerings' ? 500 : 0;
        const s = setTimeout(() => setEyeTempClass('show'), 700 + stagger);
        const t = setTimeout(() => setEyeTempClass(''), 5500 + stagger);
        return function tidyup() {
            clearTimeout(s);
            clearTimeout(t);
        }
    }
        , [infoToDisplay]
    )


    // ??? Following useEffect causes a compilation warning cos it calls defaultFingeringsShowing().
    // Thing is, I'm not even sure if this is the behaviour I want. Just commenting out now to do
    // build. Think about correct behaviour both for grades and non-grade, and what the settings do.
    // cf. 000077

    // useEffect(() => {
    //     // If fingeringsAllInstances setting changes then revert to defaults
    //     // This will overwrite any user edits.. but at the moment those are kind of ephemeral anyway so meh...
    //     setFingeringsShowing(defaultFingeringsShowing());
    // }, [fingeringsAllInstances]);


    const showingCount = fingeringsShowing.filter(f => !!f).length;

    const FingeringDiagram = ({ fingeringString }) => {
        return (
            <div>
                <div className='fingering-diagram fingering-base'>
                    {instrument.fingeringBase}
                </div>
                <div className='fingering-diagram'>
                    {fingeringString}
                </div>
            </div>
        )
    }


    const handleToggleAllClick = (e) => {
        setFingeringsShowing(fingeringsShowing.map(f => showingCount < fingeringsShowing.length));
    }

    const handleFingeringClick = useCallback((e) => {
        // console.log(e.currentTarget.dataset)
        const dataset = Object.assign({}, e.currentTarget.dataset);
        const noteIndex = +dataset.noteIndex,
            fingering = dataset.fingering.split(',');
        // console.log(noteIndex, fingering)

        setFingeringsShowing(prev => prev.map((f, i) => {
            if (i === noteIndex) {
                if (fingering.length > 1) return (f + 1) % (fingering.length + 1);
                else return +!f;
            }
            else return f;
        }));
    }, []);

    const currentVariety = varietyId || instrumentPrefs[instrument.name]?.variety;
    // (removed unnecessary 'safety' code - now let's assume instrumentPrefs is complete 000314)

    return (

        !show || (infoToDisplay === 'fingerings' && !instrument.hasOwnProperty('fingering'))
            ? null     // switched off, or the instrument has no fingerings defined
            : <div
                className={`fingerings-strip ${infoToDisplay} ${instrument.family.toLowerCase()} ${instrument.fingeringClass || ''} ${eyeTempClass}`}
                data-testid={infoToDisplay}
            >
                <button
                    className={`fingering-control print-hide`}
                    tabIndex={-1}
                    onClick={handleToggleAllClick}
                >
                    {showingCount === 0 ? <HideIcon /> : <ShowIcon />}
                </button>

                {/* 
                
                Fingerings in a flexbox - cf. 000203
                
                <div
                    className='fingerings-flexbox'
                    style={{
                        left: notePositions[0].x - 10,
                        width: notePositions[notePositions.length - 1].x - notePositions[0].x + 20,
                    }}
                >
                </div> */}


                {
                    noteData.map((note, noteIndex) => {

                        const notePosition = notePositions[noteIndex];
                        const noteIsDisabled = disabledNotes.includes(noteIndex);
                        const fingering = infoToDisplay === 'note-names' ?
                            note.pitchClassJSX
                            : instrument.fingering(note.midi, currentVariety);
                        const alternateFingerings = Array.isArray(fingering);
                        const fingeringString = alternateFingerings ?
                            fingering[fingeringsShowing[noteIndex] - 1] || fingering[0]
                            : fingering;     // no options, just this one

                        const displayFingering = instrument.family === 'Woodwind' && infoToDisplay === 'fingerings' ?
                            <FingeringDiagram fingeringString={fingeringString} />
                            : formatAccidentalsJSX(fingeringString);

                        return (
                            !noteIsDisabled &&
                            <div
                                className='fingering-container'
                                key={notePosition.x}
                                style={{
                                    left: notePosition.x - NOTE_CLICK_AREA_WIDTH / 2,
                                    width: NOTE_CLICK_AREA_WIDTH
                                }}
                            >
                                <button
                                    className={'fingering'
                                        + (fingeringsShowing[noteIndex] ? ' show' : '')
                                    }
                                    tabIndex={-1}
                                    data-note-index={noteIndex}
                                    data-fingering={fingering}
                                    onClick={handleFingeringClick}
                                >
                                    {displayFingering}
                                    {alternateFingerings && <span className='alternate-fingering-caret'>{'\u25B8'}</span>}
                                </button>
                            </div>
                        )
                    })
                }
                {/* <div
                    className='fingerings-info'
                    style={{
                        left: notePositions[0].x - 8,
                    }}
                >
                    Fingerings for {instrument.displayName
                    // ??? should be the specific instrument variety
                    // e.g. horn in F / horn in Bb / F/Bb double horn
                    // 
                    // This msg currently taes up vertical space even when blank...!
                    // hmmmmmm...
                    }
                </div> */}
            </div >
    )
}


function areEqual(prev, next) {
    const res =
        next.instrument.name === prev.instrument.name
        && next.grade === prev.grade
        && next.notePositions[4] === prev.notePositions[4]   // just compare for an arbitrary note (always at least 5 notes)
        && isEqual(next.disabledNotes, prev.disabledNotes)
        && isEqual(next.noteData, prev.noteData)
        && next.infoToDisplay === prev.infoToDisplay
        && next.fingeringsAllInstances === prev.fingeringsAllInstances
        && next.varietyId === prev.varietyId
        && next.show === prev.show
        // ??? think I can rationalise variety passing/checking
        // - maybe just pass in the variety id (fingering chart yes or no) - i.e. get prefs in parent?

        && isEqual(next.instrumentPrefs, prev.instrumentPrefs)  // ??? instrumentPrefs undefined?!
        //                                                        interaction of redux and react memo??

        // ??? or should compare only relevant instrumentPrefs (as below).. (but redux/memo problem??)
        // && isEqual(next.instrumentPrefs[next.instrument.name]?.variety, prev.instrumentPrefs[prev.instrument.name]?.variety)
        ;

    // console.log('next.instrumentPrefs', next.instrumentPrefs)

    return res;
}


const mapStateToProps = (state) => {
    return {
        instrumentPrefs: state.settings.instrumentPrefs,
        fingeringsAllInstances: state.settings.fingeringsAllInstances
    };
};

export default React.memo(connect(mapStateToProps)(Fingerings), areEqual);
