import React from 'react';
import { connect } from 'react-redux';

import { formatAccidentalsJSX } from '../utils/MusicSymbolsFormatting';

import GroupActionsBar from './GroupActionsBar';
import { FingeringsSettingControl, InstVarietyControl } from './ActionControls';
import Brand from './Brand';
import { chordTitle } from './GroupHeader';

const ListHeader = ({
    gradeHeader,
    instrument,
    scaleGroups,
    scaleHeaderList,
    handleScaleAction,
    setShowSettings,
    printing,
    showFingerings,
    instrumentPrefs,
    dispatch
}) => {

    // const instVariety = (instrumentPrefs[instrument.name] || {}).variety

    const instrumentTitle = formatAccidentalsJSX(`${instrument.displayName}`);
    // const instrumentTitle = instrument.family.match(/^clefs/i) ?
    //     ''
    //     : formatAccidentalsJSX(`${instrument.displayName}`);

    return (
        <div className="list-header">
            <div className="brand-print print-show">
                <Brand />
                <div style={{ fontSize: '0.6rem', color: '#aaa' }}>&nbsp;v{process.env.REACT_APP_VERSION}</div>
            </div>
            {gradeHeader.listTitle &&
                <h1 className="list-title" data-testid='list-title'>
                    {formatAccidentalsJSX(gradeHeader.listTitle)}
                    {chordTitle(scaleHeaderList)}
                </h1>}
            {instrumentTitle &&
                <h3 className='instrument-title' data-testid='instrument-title'>{instrumentTitle}</h3>
            }
            <div
                className='actions-bar fingerings'
                style={{ marginTop: '1em', marginBottom: '-4px', lineHeight: '1.5em' }}
            >
                {instrument?.hasOwnProperty('fingering')
                    ?                              // yes fingerings exist so show control (unless printing)
                    printing
                        ? null
                        : <FingeringsSettingControl instrument={instrument} />
                    :                              // no fingerings so show appropriate message...
                    instrument?.family.match(/^clefs/i)
                        ? (
                            <div className='actions-bar-text print-hide'>
                                (For fingerings, specify an instrument or set your main instrument in&nbsp;
                                <button className='link-like-button' onClick={() => setShowSettings(true)}>settings</button>.)
                            </div>
                        )
                        : (
                            <div className='actions-bar-text print-hide'>
                                (Fingerings for {formatAccidentalsJSX(instrument.displayName)} not yet available.)
                            </div>
                        )
                }
                <InstVarietyControl
                    instrument={instrument}
                />
            </div>
            {scaleHeaderList.length > 1 &&
                <GroupActionsBar
                    text='Set for all scales:'
                    gradeHeader={gradeHeader}
                    instrument={instrument}
                    scaleHeaders={scaleHeaderList}
                    handleScaleAction={handleScaleAction}
                />
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        showFingerings: state.settings.showFingerings,
        instrumentPrefs: state.settings.instrumentPrefs
    }
}

export default connect(mapStateToProps)(ListHeader);