import React from 'react';
import { scaleTypeObject } from '../logic/scaleTypes';
import OptionControl from './OptionControl';
import { chordClasses, getChordNames } from '../logic/chordTypes';
import { uniqueItems } from '../utils/miscUtils';
import intersection from 'lodash/intersection';


const ChordControl = ({ validChords, currentChord, plural = false, onClick, ...otherProps }) => {

    const chordOptions = validChords.map(({ chordType, chordId }) => {
        const { displayName, shortName } = getChordNames(chordId || chordType);
        return {
            value: chordType,
            label: displayName,
            shortLabel: shortName,
            'data-show-chord': chordType,
            'data-highlight-notes': 'chord'
        };
    });
    chordOptions.unshift({ divider: true });
    chordOptions.unshift({ value: 'SCALE', label: plural ? 'Show scales' : 'Scale', 'data-show-chord': 'SCALE' });

    return (
        validChords.length ?
            <OptionControl
                optionStyle='dropdown'
                containerClassName='action-box'
                dropdownClassName='action-dropdown'
                optionsArray={chordOptions}
                currValue={currentChord}
                onClick={onClick}
                dropdownToggleDefault={plural ? 'Scales/chords' : ''}
                {...otherProps}
            />
            : null
    )
}

export const ChordControlSingle = ({ renderedType, ...otherProps }) => {
    const validChords = scaleTypeObject(renderedType).chords;
    return (
        <ChordControl
            validChords={validChords}
            {...otherProps}
        />
    )
}

export const ChordControlMultiple = ({ scaleHeaders, ...otherProps }) => {
    // Idea is to show basic chord types (TRIAD, 7TH_CHORD) if they are valid for ANY scale in the group
    // And to show other chord types which are valid for EVERY scale in the group
    // 
    // Done that, but hard to show the correct display name for TRIAD/7TH_CHORD - that proved to be really
    // involved, cos of the way I'm replacing chordType with TRIAD/7TH_CHORD (and that is tough to rework 
    // for changing minor type, and for setting for a whole group.)
    // Current soln. is to show 'specific' chord names if the group contains one scale type only.
    // Else if it's a mix of scale types then basics show up as 'Tonic triad' and '7th chord', even if
    // e.g. the list is major and major pent - i.e. where in fact the tonic triad could say 'Major triad'.

    let validChords = [];
    const scaleTypes = uniqueItems(scaleHeaders.map(sh => sh.renderedType));

    if (scaleTypes.length === 1) {          // all same type, so it's straightforward..
        validChords = scaleTypeObject(scaleTypes[0]).chords;
    }
    else {
        const basicChords = chordClasses.filter(chordClass =>
            scaleHeaders.some(sh =>                                   // at least one scale header
                scaleTypeObject(sh.renderedType).chords.some(shch =>  // has a chord..
                    shch.chordType === chordClass.chordType)          // ..of this type
            )
        );

        const scaleTypeChordSets =
            scaleTypes.map(st => scaleTypeObject(st).chords   // for each type, chords..
                .filter(ch => !['TRIAD', '7TH_CHORD'].includes(ch.chordType)) // ..but not 'class' ones
                .map(ch => ch.chordType)                      // and we just want the chord type
            );
        const commonChords = intersection(...scaleTypeChordSets)
            .map(chordId => {
                const { displayName, shortName } = getChordNames(chordId);
                return { chordType: chordId, displayName, shortName };
            });

        validChords = [...basicChords, ...commonChords];
    }

    const showingChordTypes = uniqueItems(scaleHeaders.map(sh => sh.showChord || ''));
    const allChordType = showingChordTypes.length === 1 ? showingChordTypes[0] : undefined;

    return (
        <ChordControl
            validChords={validChords}
            plural
            currentChord={allChordType}
            {...otherProps}
        />
    )

}

