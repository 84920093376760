import React from 'react';
import { Element } from 'react-scroll';


const ScrollTarget = (props) => {
    const { id } = props;

    // const className = groupIndex === 0 ?
    //     ''
    //     : adjustForKeysGrid
    //         ? `scroll-target-for-grid-${groupCount}`
    //         : `scroll-target-no-grid`;
    // const className = adjustForKeysGrid
    //     ? `scroll-target-for-grid-${groupCount}`
    //     : `scroll-target-no-grid`;

    return (
        <Element
            // className={className}
            id={id}
        ></Element>
    )
}


export default React.memo(ScrollTarget);