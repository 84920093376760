import React from 'react';
import { DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { setShowFingerings, setInstrumentVariety } from '../actions/settings';

import { TickIcon, SpacerIcon, FlipIcon } from '../assets/icons';
import { scaleTypeObject } from '../logic/scaleTypes';
import { pitchClassJSX } from '../utils/MusicSymbolsFormatting';
import OptionControl from './OptionControl';
import { toSentenceCase } from '../utils/miscUtils';


// generic control, so that I can reuse components for bar or dropdown menu
// ??? I.e. one big '...' dropdown for many different settings.. but I'm not actually using.
export const Control = (props) => {
    const { inDropdown, active, children, className = '', ...otherProps } = props;
    return (
        inDropdown
            ? (
                <DropdownItem
                    className={className}
                    {...otherProps}
                >
                    {active ? <TickIcon /> : <SpacerIcon />}
                &nbsp;{children}
                </DropdownItem>
            )
            : (
                <button
                    {...otherProps}
                    className={`text-button ${className} ${(active ? 'active' : '')}`}
                >
                    {children}
                </button>
            )
    )
}


// Use data-xxx={newValue} on each action control, where xxx is a valid param for a scale
// such as tonic, range, chord etc, but converted to hyphen case if necessary.
// highlightNotes defaults to name, so specify data-highlight-notes if you want something more specific.

export const MinorOptions = (props) => {
    const { scaleHeaders, subtypes, renderedType, ...otherProps } = props;

    const minorSubtypeOptions = subtypes.map(subtype => ({
        // name: 'scale-type',
        value: subtype,
        label: scaleTypeObject(subtype).optionName,
        'data-rendered-type': subtype,
        'data-highlight-notes': 'scale-type',
    }));

    return (
        scaleHeaders.every(sh => sh.basetype === 'MIN') ?
            <OptionControl
                optionStyle='dropdown'
                containerClassName='action-box'
                dropdownClassName='action-dropdown'
                dropdownToggleDefault='Minor type'
                optionsArray={minorSubtypeOptions}
                currValue={renderedType}
                {...otherProps}
            />
            : null
        // scaleHeaders.every(sh => sh.basetype === 'MIN') ?
        //         <span className="options-btn-group button-toggle" data-toggle="buttons">
        //             {/* // ??? these classes? */}
        //             {subtypes.map(minorSubtype => (
        //             <Control
        //                     {...otherProps}
        //                     name="scale-type"
        //                     // className='capitalize'
        //                     inDropdown
        //                     key={minorSubtype}
        //                     data-rendered-type={minorSubtype}
        //                     active={minorSubtype === renderedType}
        //                 >
        //                     {scaleTypeObject(minorSubtype).optionName}
        //                 </Control>
        //             ))
        //             }
        //         </span>
        //         : null
    );
}

// Might be relevant again if grades come back, so leaving here for now. 000260 000306

// export const ArpeggioControl = (props) => {
//     const { currentArpeggio, handleActionClick, ...otherProps } = props;
//     return (
//         <Control
//             {...otherProps}
//             name='arpeggio'
//             // data-arpeggio={!currentArpeggio}                  // old version
//             data-show-chord={currentArpeggio ? 'SCALE' : 'TRIAD'}   // ??? untested! something like this
//             data-highlight-notes={!currentArpeggio && 'arpeggio'}  // only do highlights when turning ON (not OFF)
//             active={currentArpeggio}
//             data-highlight-notes='arpeggio',
//         >
//             <ArpgeggioIcon /> Arpeggio
//         </Control>
//     );
// }



export const RelativeLink = (props) => {
    // currently just text, not a link!

    // ??? temp version till I update ScaleBullet (see oneNote 'Scale Footer' page)
    const ScaleBullet = (props) => (
        <span>
            ({pitchClassJSX(props.tonicPC)})
        </span>
    )

    const { tonicPC: currentTonicPC, basetype: currentBasetype } = props;
    const relative = scaleTypeObject(currentBasetype).relative;

    return (
        typeof relative !== 'undefined' && (       // not all scale types have relatives
            <div className='actions-bar-text' >
                {relative.text}&nbsp;
                <ScaleBullet
                    tonicPC={relative.tonic(currentTonicPC, currentBasetype)}
                    basetype={relative.basetype}
                />
            </div>
        )
    )
}


export const EnharmonicFlipControl = (props) => {
    const { scaleHeader, ...otherProps } = props;
    const { tonicPC, flipTonic, octave } = scaleHeader;

    const flipOctave = ['B', 'B#'].includes(tonicPC) ? octave + 1
        : ['C', 'Cb'].includes(tonicPC) ? octave - 1
            : octave;
    // ???230 DRY cf. enharmonicFlip() in note.js

    return (
        scaleHeader.flipTonic ?
            <Control
                {...otherProps}
                // name='enharmonic-flip'
                data-tonic-p-c={flipTonic}
                data-octave={flipOctave}
                data-flip-tonic={tonicPC}
                data-highlight-notes='enharmonic-flip'
            >
                <FlipIcon />Flip ({pitchClassJSX(flipTonic)})
        </Control>
            : null
    );
}


const FingeringsSettingControl = (props) => {
    const { instrument, showFingeringsSetting, dispatch } = props

    return (
        <OptionControl
            containerClassName='action-box' // 'sb-setting-box'
            dropdownClassName='action-dropdown'
            optionName={toSentenceCase(instrument.fingeringsName) || 'Fingerings'} // 'Fingerings/Slide posns.'
            optionStyle='dropdown'
            currValue={showFingeringsSetting}
            onClick={(e => dispatch(setShowFingerings(e.currentTarget.value)))}
            optionsArray={[
                { label: 'Show', value: 'ALL' },
                { label: 'Hide', value: 'AVAILABLE' },
                { label: 'Off', value: '' },
            ]}
        // explanationText='Fingerings are currently only available for most brass and some woodwind instruments.'
        />
    );
}
const mapStateToProps = (state) => {
    return {
        showFingeringsSetting: state.settings.showFingerings
    }
}

const connectedFingeringsSettingControl = connect(mapStateToProps)(FingeringsSettingControl);
export { connectedFingeringsSettingControl as FingeringsSettingControl }


const InstVarietyControl = props => {
    const { instrument, instrumentPrefs, dispatch } = props;
    const instVariety = (instrumentPrefs[instrument.name] || {}).variety
    return (
        // showFingeringsSetting && instVariety &&
        instVariety ?
            <OptionControl
                containerClassName='action-box' // 'sb-setting-box'
                optionStyle='dropdown'
                // style={{ marginLeft: '-0.9em' }}   // removed cos on print it chops the first letter; dunno why it was there anyway
                dropdownClassName='action-dropdown'
                currValue={instrumentPrefs[instrument.name].variety}
                onClick={(e => dispatch(
                    setInstrumentVariety({ instId: instrument.name, varietyId: e.currentTarget.value }
                    )))}
                optionsArray={Object.entries(instrument.varieties).map(([varietyId, varietyProps]) =>
                ({
                    label: varietyProps.displayName,
                    value: varietyId
                })
                )}
            />
            : null
    );
}
const mapStateToPropsVariety = (state) => {
    return {
        // showFingeringsSetting: state.settings.showFingerings,
        instrumentPrefs: state.settings.instrumentPrefs
    }
}

const connectedInstVarietyControl = connect(mapStateToPropsVariety)(InstVarietyControl);
export { connectedInstVarietyControl as InstVarietyControl }

