import React from 'react';
import { Link } from 'react-scroll';

import { keySigIndex, scaleFullTitleJSX } from '../logic/scaleUtils';
import { pitchClassJSX } from '../utils/MusicSymbolsFormatting';
import { scaleTypeObject } from '../logic/scaleTypes';
import { chromaticIndex } from '../logic/noteUtils';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { hasHover } from '../utils/miscUtils';

const getContainerId = () => {
    // On phones/tablets, main window is static so that address bar hiding works..
    // But this breaks react-scroll, and the fix for that is to specify no containerId in the <Link>
    // Hence:
    //    if #main-window is fixed (i.e. desktop style, with the fixed search bar header) -> containerId must be set
    //    if #main-window is static (i.e. phone style with no header) -> containerId should be null
    // (000434)
    const mainWindowElt = document.getElementById('main-window');
    const position = window.getComputedStyle(mainWindowElt).getPropertyValue('position');
    return position === 'fixed' ? 'scale-list-container' : null;
}

const ScaleBullet = (props) => {

    const { scaleId, tonic, renderedType, basetype, range, active, disabled, onClick, inKeysGrid = false, className = '' } = props;

    const type = renderedType || basetype;
    const pitchClassToDisplay = tonic;

    const bulletProps = {
        value: scaleId,
        className:
            'scale-bullet'
            + ` type-${type}`
            + (type === 'WT' ? ` WT-${chromaticIndex({ pitchClass: tonic }) % 2}` : '')
            + (['DIM7', 'DIM-W-H', 'DIM-H-W'].includes(type) ? ` diminished-${chromaticIndex({ pitchClass: tonic }) % 3}` : '')
            + (['DIM-H-W'].includes(type) ? ` diminished-${(chromaticIndex({ pitchClass: tonic }) + 1) % 3}` : '')
            // ??? those bits should really be a fetch from scaleTypeObject (of course it will be 
            // a function, taking the starting pitchclass)
            + (active ? ' active' : '')
            + (disabled ? ' disabled' : ` key_${keySigIndex(tonic, type)}`)
            + (inKeysGrid ? ' in-keys-grid' : ' full-size')
            + ` ${className}`,
        onClick: onClick,
    };

    const suffix = scaleTypeObject(type).suffix || {};

    const keyJSX = (
        <span>
            <span
                className={'bullet-letter'
                    + (suffix.char && tonic.length > 1 ? ' condensed' : '')}
            // condensed font if there's a 'type' letter AND there's a flat or sharp in the tonic
            >
                {pitchClassJSX(pitchClassToDisplay)}
            </span>
            <span
                className={'bullet-suffix ' + (suffix.className || '')}
            >
                {suffix.char}
            </span>
        </span >
    )


    return (
        inKeysGrid && !disabled ? (
            <Tooltip
                overlay={scaleFullTitleJSX({ tonicPC: tonic, renderedType, range })}
                trigger={hasHover() ? 'hover' : 'none'}
                placement='bottom'
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                // overlayInnerStyle={{
                //     padding: '4px',     // cf. etc.scss
                //     minHeight: '0px',
                //     borderRadius: '3px',
                //     lineHeight: '1',
                // }}
                align={{ offset: [0, -3] }}
                mouseLeaveDelay={0}
            >
                <Link
                    to={scaleId}
                    href=''
                    containerId={getContainerId()}
                    smooth
                    duration={300}
                    isDynamic
                    {...bulletProps}
                >
                    {keyJSX}
                </Link>
            </Tooltip >
        )
            : (<div {...bulletProps} >
                {keyJSX}
            </div>)
    )
}



export default ScaleBullet;