import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';

// import { useTraceUpdate } from '../utils/DevUtils';
import isEqual from 'lodash/isEqual';

import { ScaleForNotation } from '../logic/scale';
import { calcNotationMetrics } from '../notation/calcNotePositions';

import ScaleHeader from './ScaleHeader';
import ScaleActionsBar from './ScaleActionsBar';
import ScrollTarget from './ScrollTarget';
import VexflowCpt from './VexflowCpt';
import NoteClickAreas from './NoteClickAreas';
import NoteHalos from './NoteHalos';
import Fingerings from './Fingerings';
import { scaleTypeObject } from '../logic/scaleTypes';
import { pitchClassDisplayString } from '../utils/MusicSymbolsFormattingNode';


// ???perfdec
// VERSION where scale is NOT in state
// but set up work is skipped if isLoading is true
// Still use useMemo, which is necessary for responsive play
// but the useMemo now takes isLoading as a parameter
// (i.e. I've made a separate function to do the scale set up)
//
// In other words:
// Same as long-term committed version, apart from that it doesn't calculate the scale if isLoading is true

// // Possible todos:
// //    o do no work if availableWidth is zero?
// //    o widths data for all 1 octave scales will be the same usually (apart from when squeezed and it takes 
// //      accidentals in to account). So possibly could do these calcs once only at a higher level and pass
// //      down 'spacing templates' for each relevant range. (Recalc templates if resize happens.)
// //    o ..I guess that implies separating fn to sep. versions for vertical and horizontal metrics..?


const HIGHLIGHT_NOTE_MS = 2500; // matches css 'pulse' animation


// If receiving new props then check React.memo areEqual comparison, at bottom!!!
const ScaleCpt = ({
    instrument,
    grade,
    gradeHeader,
    scaleHeader,
    handleScaleAction,
    // numScales,
    indexInList,
    availableWidth,
    printing,
    showHeader = true,
    player,
    playingScaleId,
    swing,
    showFingeringsSetting,
    showNoteNamesSetting,
    fingeringsChart,
    varietyId,
    chromaticBeaming
}) => {


    // useEffect(() => { console.log('SCALECPT INITIAL') }, []);
    console.log('  <ScaleCpt>', scaleHeader.tonicPC)

    // const printing = true;   // nb. CSS @media won't know I'm trying to debug print!

    const urlHashScaleId = window.location.hash.substr(1);
    const LOADING_MIN_MS = 200; // min time to show cpt-level loading for

    const [isLoading, setIsLoading] = useState(true);

    const showFingerings = scaleHeader.fingerings || showFingeringsSetting,
        showNoteNames = scaleHeader.noteNames || showNoteNamesSetting;

    // Memoized so doesn't do work every time a note is played (for example).
    const { scale, scaleStaff2 } = useMemo(
        () => (setUpScale({
            tonicPC: scaleHeader.tonicPC,
            octave: scaleHeader.octave,
            basetype: scaleHeader.basetype,
            renderedType: scaleHeader.renderedType,
            range: scaleHeader.range,
            highestPC: scaleHeader.highestPC,
            highestOctave: scaleHeader.highestOctave,
            pattern: scaleHeader.pattern,                   // ???397 this isn't actually implemented yet
            showChord: scaleHeader.showChord,
            duration: scaleHeader.duration,
            clef: instrument.clef,
            altClefHigh: instrument.altClefHigh,
            altClefLow: instrument.altClefLow,
            chromaticSpelling: scaleHeader.chromaticSpelling,
            includeDescending: scaleHeader.includeDescending,
            highlightNotes: scaleHeader.highlightNotes,
            chromaticBeaming,
            isLoading
        })),
        [
            scaleHeader.tonicPC,
            scaleHeader.octave,
            scaleHeader.basetype,
            scaleHeader.renderedType,
            scaleHeader.range,
            scaleHeader.highestPC,
            scaleHeader.highestOctave,
            scaleHeader.pattern,                   // ???397 this isn't actually implemented yet
            scaleHeader.showChord,
            scaleHeader.duration,
            instrument.clef,
            instrument.altClefHigh,
            instrument.altClefLow,
            scaleHeader.chromaticSpelling,
            scaleHeader.includeDescending,
            scaleHeader.highlightNotes,
            chromaticBeaming,
            isLoading
        ]
    );

    const notationMetrics = useMemo(
        () => setUpNotationMetrics(
            isLoading,
            scale,
            scaleStaff2,
            availableWidth,
            printing,
            instrument,
            showFingerings
        ),
        [
            isLoading,
            scale,
            scaleStaff2,
            availableWidth,
            printing,
            instrument,
            showFingerings
        ]
    );


    // Following useful for setting up unit tests in scale.test.js
    // 2nd one adds in renderedType..
    // console.log(`{tonic: '${scaleHeader.tonicPC}', octave: ${scaleHeader.octave}, showChord: '${scaleHeader.showChord}', range: '${scaleHeader.range}', basetype: '${scaleHeader.basetype}', expect: '${scale.stringify()}'},`)
    // console.log(`{tonic: '${scaleHeader.tonicPC}', octave: ${scaleHeader.octave}, range: '${scaleHeader.range}', basetype: '${scaleHeader.basetype}',renderedType: '${scaleHeader.renderedType}', expect: '${scale.stringify()}'},`)

    const [actionId, setActionId] = useState();

    useEffect(
        () => {
            let t;
            if (scaleHeader.actionId !== actionId) {
                // A new action, so halos will show.
                // Update the state-held actionId to register that this one has been handled - but only after the correct time.
                t = setTimeout(
                    () => { setActionId(scaleHeader.actionId) },
                    HIGHLIGHT_NOTE_MS
                );
            }

            return function tidyup() { clearTimeout(t) }
        },
        [scaleHeader.actionId, actionId]
    );

    const [soundingHalosStaff1, setSoundingHalosStaff1] = useState([]);
    const [soundingHalosStaff2, setSoundingHalosStaff2] = useState([]);

    const setSoundingHalos = useCallback(
        h => {
            setSoundingHalosStaff1(h);
            if (scaleStaff2) setSoundingHalosStaff2(h);
        },
        [scaleStaff2]);


    const handlePlayNote = useCallback(
        (event) => {
            const noteIndex = + event.currentTarget.name;
            player.playSingleNote(scale.notes[noteIndex], noteIndex, instrument, setSoundingHalosStaff1);
        },
        [player, scale.notes, instrument]);

    const staff2Notes = scaleStaff2?.notes;
    const handlePlayNoteStaff2 = useCallback(
        event => {    // ???324 this is a bit DRY of above!
            const noteIndex = + event.currentTarget.name;
            player.playSingleNote(staff2Notes[noteIndex], noteIndex, instrument, setSoundingHalosStaff2);
        },
        [player, staff2Notes, instrument]);

    const handlePlayScale = useCallback(
        (event) => {
            if (event.currentTarget.name === 'stop') {
                player.stop();
                setSoundingHalos([]);
            }
            else if (event.currentTarget.name === 'play') {
                scale.highlights = [];   // clear chord etc. halos, else they'll re-render as each note played.
                //                          ??? is that ok??!
                player.playScale(scaleHeader.id, scale, scaleStaff2, instrument, setSoundingHalos, swing);
            }
        }
        , [player, instrument, scale, scaleStaff2, scaleHeader.id, setSoundingHalos, swing]);


    // Following so that any scale action will stop playback before changing the scale.
    const stopPlayback = useCallback(
        () => {
            player.stop();
            setSoundingHalos([]);
        },
        [player, setSoundingHalos]
    );


    useEffect(() => {
        // if url contains an anchor to this scale then render it soon as possible
        // Add indexInList to delay, else it seems that all scales load on the same thread, and therefore we
        // end up waiting longer for the first scale to appear.
        let delay = urlHashScaleId === scaleHeader.id ? 1 : indexInList + LOADING_MIN_MS;

        const loadingTimer = setTimeout(
            () => { setIsLoading(false) },
            delay);
        return function cleanup() {
            clearTimeout(loadingTimer);
        };
    },
        [
            scaleHeader.id,
            urlHashScaleId,
            indexInList,
        ]
    );

    useEffect(
        () => {
            return function unmountTidyUp() {
                player && player.stop();
                // for (const haloTimeout of haloTimeouts) clearTimeout(haloTimeout);
                // ???@@@ also need to change NotePlayer to use this array for it's timeout tracking!
                // ..errr... this array doesn't exist any more!
            }
        },
        [player]);

    const notePositions = isLoading ? null : printing ? notationMetrics.print.notes : notationMetrics.screen.notes;

    // console.timeEnd(scaleHeader.tonicPC + scaleHeader.basetype);

    return (
        <React.Fragment>
            <ScrollTarget
                id={scaleHeader.id}
            />
            <div className={'scale-box'}>
                {showHeader &&
                    <ScaleHeader
                        tonicPC={scaleHeader.tonicPC}
                        renderedType={scaleHeader.renderedType}
                        showChord={scaleHeader.showChord}
                        range={scaleHeader.range}
                        message={scaleHeader.message}
                    />
                }
                <div className="notation-cssgrid">
                    <div className="notation-box"
                        style={printing ?
                            {
                                width: notationMetrics.print.container.width,
                                height: notationMetrics.print.container.height,
                                overflow: "hidden"
                            } : {
                                width: notationMetrics.screen.container.width,
                                height: notationMetrics.screen.container.height,
                                overflow: "hidden"
                            }
                        }
                    >
                        {isLoading && !printing
                            ? (
                                <div
                                    className='loading-scale'
                                    style={{
                                        top: notationMetrics.screen.system.top,
                                        height: notationMetrics.screen.system.height,
                                    }}
                                >
                                    Loading...
                                </div>
                            )
                            : <React.Fragment>
                                <VexflowCpt
                                    key={`${scale.tonicPC}-${scale.renderedType}-${scale.clef}-${scale.octave}-${scale.range}-${scale.pattern}-${scale.highlightKey}-${scale.beamPattern}-${notationMetrics.screen.container.width}${printing && '-p'}`}  // ???324?
                                    title__000496_PUT_BACK__={`${pitchClassDisplayString(scale.tonicPC)} ${scaleTypeObject(scale.renderedType).displayName} notated in ${scale.clef} clef`}
                                    title='sense brass wiggly irritating amuck range vest bait applaud drunk fluttering glossy'
                                    // ???496 test whether google will see this tag
                                    scale={scale}
                                    scaleStaff2={scaleStaff2}
                                    vexflowMetrics={notationMetrics.vexflow}
                                    printing={printing}
                                />
                                <NoteClickAreas
                                    notePositions={notePositions}
                                    disabledNotes={scale.disabledNotes}
                                    playNote={handlePlayNote}
                                />
                                {scaleStaff2 &&
                                    <NoteClickAreas
                                        notePositions={notationMetrics.screen.notesStaff2}
                                        disabledNotes={scaleStaff2.disabledNotes}
                                        playNote={handlePlayNoteStaff2}
                                    />
                                }
                                <NoteHalos
                                    notePositions={notePositions}
                                    halos={soundingHalosStaff1.concat((scaleHeader.actionId !== actionId) ? scale.highlights : [])}
                                    disabledNotes={scale.disabledNotes}
                                />
                                {scaleStaff2 &&
                                    <NoteHalos
                                        notePositions={notationMetrics.screen.notesStaff2}
                                        halos={soundingHalosStaff2.concat((scaleHeader.actionId !== actionId) ? scaleStaff2.highlights : [])}
                                        // halos={[...soundingHalosStaff2, ...scaleStaff2.highlights]}
                                        disabledNotes={scaleStaff2.disabledNotes}
                                    />
                                }
                            </React.Fragment>
                        }
                    </div>
                    {!printing && <div className="spacer-right" />}
                    {!isLoading &&
                        <React.Fragment>
                            <div className='note-names-and-fingerings'
                                style={{ width: notationMetrics.screen.container.width }}
                            >
                                <Fingerings
                                    infoToDisplay='note-names'
                                    show={showNoteNames}
                                    instrument={instrument}
                                    grade={grade}
                                    noteData={scale.notes}
                                    notePositions={notePositions}
                                    disabledNotes={scale.disabledNotes}
                                />
                                <Fingerings
                                    infoToDisplay='fingerings'
                                    show={showFingerings}
                                    fingeringsChart={fingeringsChart}
                                    varietyId={varietyId}  // for fingerings chart
                                    instrument={instrument}
                                    grade={grade}
                                    noteData={scale.notes}
                                    notePositions={notePositions}
                                    disabledNotes={scale.disabledNotes}
                                />
                            </div>
                        </React.Fragment>

                    }
                </div>

                <ScaleActionsBar
                    gradeHeader={gradeHeader}
                    scaleHeader={scaleHeader}
                    instrument={instrument}
                    playingScaleId={playingScaleId}
                    printing={printing}
                    handleScaleAction={handleScaleAction}
                    handlePlayScale={handlePlayScale}
                    stopPlayback={stopPlayback}
                />
            </div>
        </React.Fragment >
    )
}



function setUpScale({
    tonicPC,
    octave,
    basetype,
    renderedType,
    range,
    highestPC,
    highestOctave,
    pattern,
    showChord,
    duration,
    clef,
    altClefHigh,
    altClefLow,
    chromaticSpelling,
    includeDescending,
    highlightNotes,
    chromaticBeaming,
    isLoading,
}) {

    const scale = isLoading ? {}
        : new ScaleForNotation({
            tonicPC,
            octave,
            basetype,
            renderedType,
            range,
            highestPC,
            highestOctave,
            pattern,
            showChord,
            duration,
            clef: clef === 'grand' ? 'treble' : clef,
            altClefHigh,
            altClefLow: clef === 'grand' ? 'bass' : altClefLow,
            chromaticSpelling,
            includeDescending,
            highlightNotes,
            chromaticBeaming
        });

    const scaleStaff2 =
        (isLoading || clef !== 'grand') ? null :     // skip all work if inst not a grand staff
            new ScaleForNotation({
                tonicPC,
                octave: octave - 1,
                basetype,
                renderedType,
                range,
                highestPC,
                highestOctave: highestOctave - 1,
                pattern,
                showChord,
                duration,
                clef: 'bass',
                altClefHigh: clef === 'grand' ? 'treble' : altClefHigh,
                chromaticSpelling,
                includeDescending,
                highlightNotes,
                chromaticBeaming
            });

    return {
        scale,
        scaleStaff2,
    }
}



function setUpNotationMetrics(
    isLoading,
    scale,
    scaleStaff2,
    availableWidth,
    printing,
    instrument,
    showFingerings,
) {
    const defaultNotationMetrics = {
        screen: {
            container: {
                width: 456,
                height: instrument.clef === 'grand' ? 154 : 80,
            },
            system: {
                top: 23,       // simply the dimensions of a typical scale
                height: instrument.clef === 'grand' ? 105 : 33,
            }
        },
        print: {
            container: { width: 456, height: 80 },
            system: { top: 23, height: 33 }
        }
    };
    // ???189 let's make better by doing appropriate defaults for 
    // o wider scales?? e.g. chromatics, 2 octs - base it on quick calc of how many notes? (or don't sweat it?)
    // - actually that's not an issue until a scale list can return a 2 oct scale..
    // - or if i use the same loading ind for scale actions

    return isLoading
        ? defaultNotationMetrics
        : calcNotationMetrics(
            scale,
            scaleStaff2,
            availableWidth,
            printing,
            instrument,
            showFingerings
        );
};








function areEqual(prev, next) {
    /*
    return true if passing nextProps to render would return the same result as passing prevProps to render.

    ???$$$ Maybe need to compare more props - but some are going to disappear anyway so... (e.g. halos I think.. also isPlaying?)

    instrument,
    grade,
    gradeHeader,
    scaleHeader,
    playingScaleId,
    handleScaleAction,
    setPlayingScaleId,
    shortList,
    availableWidth,
    printing,
    showFingerings,
    showNoteNames


    */

    // console.log(prev, next)
    const res = (
        next.instrument.name === prev.instrument.name
        && next.grade === prev.grade
        && isEqual(next.scaleHeader, prev.scaleHeader)
        && next.playingScaleId === prev.playingScaleId
        && next.availableWidth === prev.availableWidth
        && next.printing === prev.printing
        // && next.showFingerings === prev.showFingerings     // ??? all redux props are undefined here..?
        // && next.showNoteNames === prev.showNoteNames       // and yet 'show fingerings' still works somehow..?!
        // && next.chromaticBeaming === prev.chromaticBeaming
    );

    // console.log('areEqual', next.scaleHeader.tonicPC, next.scaleHeader.renderedType)
    // if (next.scaleHeader.tonicPC === 'C' && next.scaleHeader.renderedType === 'IONIAN') {

    //     console.log(next.instrument.name === prev.instrument.name)
    //     console.log(next.grade === prev.grade);
    //     console.log(isEqual(next.scaleHeader, prev.scaleHeader));
    //     console.log('playingScaleId', next.playingScaleId === prev.playingScaleId, next.playingScaleId, prev.playingScaleId);
    //     console.log('width', next.availableWidth === prev.availableWidth);
    //     console.log(next.printing === prev.printing);
    // console.log(next.showFingerings === prev.showFingerings, next.showFingerings);
    // console.log('showNoteNames', next.showNoteNames === prev.showNoteNames, next.showNoteNames);
    //     console.log(next.chromaticBeaming === prev.chromaticBeaming);
    // }
    // console.log(next)

    return res;
}

// attempt at tracing why cpt is updating. Not sure it can work in combo w react.memo
// 000004
// const MyButton = (props) => {
//     console.log('>>>>', props.scale.id)
//     useTraceUpdate(props)
//     return <ScaleCpt {...props} />;
// };



const mapStateToProps = (state) => {
    return {
        player: state.session.player,
        playingScaleId: state.session.playingScaleId,
        swing: state.settings.swing,
        showFingeringsSetting: state.settings.showFingerings,
        showNoteNamesSetting: state.settings.showNoteNames,
        chromaticBeaming: state.settings.chromaticBeaming,
    };
};


// console.log(ScaleCpt)
// console.log(connect(mapStateToProps)(ScaleCpt))

// export default connect(mapStateToProps)(ScaleCpt);  // ???275
export default React.memo(connect(mapStateToProps)(ScaleCpt), areEqual);
// export default connect(mapStateToProps)(ScaleCpt);
