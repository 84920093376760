import React from 'react';

import isEqual from 'lodash/isEqual';
// import { logArrayElements } from '../utils/DevUtils';


const NoteClickAreas = (props) => {

    const { notePositions, disabledNotes, playNote } = props;

    const handleClick = (event => playNote(event));

    return (
        <div className='halos-container print-hide'>
            {notePositions.map((notePosition, noteIndex) => {

                const noteIsDisabled = disabledNotes.includes(noteIndex);

                return (
                    !noteIsDisabled &&
                    <button
                        key={notePosition.x}
                        className={`note-click-area`}
                        style={notePosition.clickArea}
                        name={noteIndex}
                        onClick={handleClick}
                        tabIndex='-1'  // Prevent tabbing through notes 000171
                    >
                        <div
                            className={`note-hover`}
                            style={notePosition.hoverHighlight}
                        />
                    </button>
                )
            })}
        </div >
    )
}

function areEqual(prev, next) {

    const res =
        next.notePositions[4] === prev.notePositions[4]   // surely there will always be 5 notes
        && isEqual(next.disabledNotes, prev.disabledNotes)
        ;

    return res;

}

export default React.memo(NoteClickAreas, areEqual);
