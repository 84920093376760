import React, { Component } from 'react';

import { v4 as uuid } from 'uuid';

import Vex from 'vexflow';
import notation from '../notation/NotationConsts';
// import { logArrayElements } from '../utils/DevUtils';

const VF = Vex.Flow;

const {
    VF_ZOOM,
    CSS_ZOOM,
    ALPHA_DISABLED,
    CLEF_CHANGE_OFFSET_X,
    TOP_TEXT_POSITION_VF,
} = notation;



export default class VexflowCpt extends Component {

    constructor(props) {
        super(props);
        this.title = props.title;
        this.scale = props.scale;
        this.scaleStaff2 = props.scaleStaff2;
        this.vexflowMetrics = props.vexflowMetrics;

        // this.vfDivId = `vexflow-result-${scale.tonicNote.SPN}-${scale.type}-${scale.range}`;
        this.vfDivId = `vexflow-div-${uuid()}`;
        this.staffStyle = { lineWidth: this.vexflowMetrics.staff.lineWidth, strokeStyle: '#c5c5c5' }; // staff lines

        // console.log('svg', this.vexflowMetrics)
    }

    componentDidMount() {

        const { scale, scaleStaff2 } = this;

        this.setUpVexflow();
        this.setupTopStaff();
        this.setupNotes(scale, this.stave);
        this.disableNotes(scale);

        if (!!scaleStaff2) {
            this.setupGrandStaff(scaleStaff2);
            this.setupNotes(scaleStaff2, this.stave2, this.vexflowMetrics.notesStaff2);
            this.disableNotes(scaleStaff2);
        }

        this.vf.draw();         // render staff

        this.renderNotes(scale);
        if (!!scaleStaff2) this.renderNotes(scaleStaff2);

        this.labelSvg();

        // logArrayElements({ arr: scale.vfStaveNotes, callback: (note => (`${note.keys[0]} ${note.preFormatted} ${note.getBoundingBox()}`)), prefix: 'grand-click>' })
        // console.log('grand-click0 this.scale', this.scale.vfStaveNotes[0].getBoundingBox())

    }


    setUpVexflow() {
        // Setting up the veflow stuff...
        const svgContainer = document.getElementById(this.vfDivId);

        this.vf = new Vex.Flow.Factory({
            renderer:
            {
                context: null,
                elementId: svgContainer,
                backend: VF.Renderer.Backends.SVG,
                width: this.vexflowMetrics.system.width,
                height: this.vexflowMetrics.system.height,
                background: '#fff',
            }
        });
        this.context = this.vf.getContext();

        // this.formatter = this.vf.Formatter();
        // console.log('grand-click', this.formatter);

        // end bar line - they are rectangles so don't need a +ve stroke width
        // calling like this cos I can't figure out proper way
        this.context.setLineWidth(0.2);      // was 0, but then grand staff brace becomes too wispy...

        this.context.scale(VF_ZOOM, VF_ZOOM);
    }


    labelSvg() {
        // Put a title within the svg
        const containerDiv = document.getElementById(this.vfDivId);
        const svgElt = containerDiv.getElementsByTagName('svg')[0];
        const titleElt = document.createElement('title');
        const titleContent = document.createTextNode(this.title);
        titleElt.appendChild(titleContent);
        svgElt.prepend(titleElt);
    }

    setupTopStaff() {

        const staffMetrics = this.vexflowMetrics.staff;

        this.stave = this.vf.Stave({
            x: staffMetrics.x,
            y: staffMetrics.y,
            width: staffMetrics.width,
            options: { top_text_position: TOP_TEXT_POSITION_VF },   // vertical position for 8va lines
        })
            .addClef(this.scale.clef)
            .setStyle(this.staffStyle)
            .setBegBarType(VF.Barline.type.NONE)
            .setEndBarType(VF.Barline.type.END)   // set final bar line for one-stave system
            ;

    }


    setupGrandStaff() {

        const staff2Metrics = this.vexflowMetrics.staff2;

        this.stave2 = this.vf.Stave({
            x: staff2Metrics.x,
            y: staff2Metrics.y,
            width: staff2Metrics.width,
            options: { top_text_position: -1 },
        })
            .addClef(this.scaleStaff2.clef)
            .setStyle(this.staffStyle)
            .setBegBarType(VF.Barline.type.NONE)
            .setEndBarType(VF.Barline.type.NONE)
            ;

        this.stave.setEndBarType(VF.Barline.type.NONE);   // clear final bar line for *top* staff

        const grandStaff = { top_stave: this.stave, bottom_stave: this.stave2 };
        this.vf.StaveConnector(grandStaff).setType(VF.StaveConnector.type.BRACE);
        this.vf.StaveConnector(grandStaff).setType(VF.StaveConnector.type.SINGLE_LEFT);
        this.vf.StaveConnector(grandStaff).setType(VF.StaveConnector.type.BOLD_DOUBLE_RIGHT);

    }

    setupNotes(scale, stave, noteMetrics = this.vexflowMetrics.notes) {
        // console.log('grand-clef', scale, stave);

        scale.notes.forEach((note, noteIndex) => {
            const vfStaveNote = note.vfStaveNote;

            if (note.clefChange) {
                const clef = this.vf.ClefNote({ type: note.clef, options: { size: 'small' } });
                new VF.TickContext().addTickable(clef).preFormat().setX(noteMetrics[noteIndex].x + CLEF_CHANGE_OFFSET_X);
            }

            vfStaveNote.setStave(stave);
            this.vf.TickContext().addTickable(vfStaveNote).preFormat().setX(noteMetrics[noteIndex].x);
        });

        // 8va/8vb line - assume one per scale.
        const octaveLineFromIndex = scale.notes.findIndex(n => !!n.octaveShift);
        if (octaveLineFromIndex > -1) {
            const nextNoOctaveShiftNoteIndex = scale.notes.findIndex((n, i) => i > octaveLineFromIndex && !n.octaveShift);
            const octaveLineToIndex = nextNoOctaveShiftNoteIndex > -1 ? nextNoOctaveShiftNoteIndex : scale.notes.length;
            const octaveShift = scale.notes[octaveLineFromIndex].octaveShift;

            const octaveShiftLookup = {
                '1': { superscript: 'va', position: 'top' },
                '-1': { superscript: 'vb', position: 'bottom' },
            };

            this.vf.TextBracket({
                from: scale.vfStaveNotes[octaveLineFromIndex],
                to: scale.vfStaveNotes[octaveLineToIndex - 1],
                text: '8',
                options: {
                    ...octaveShiftLookup[octaveShift],
                    line: 3,
                    font: { size: 16 },
                },
            })
                .render_options.underline_superscript = false;
        }

        scale.vfBeams.forEach(beam => beam.calculateSlope());  // this seems to fix 000117 (though it was a bit of a stab in the dark tbh)
    }

    disableNotes(scale) {
        // if a note is disabled then it'll be partially transparent (alpha value)

        scale.notes.forEach((note, index) => {
            if (scale.disabledNotes.includes(index)) {
                note.vfStaveNote.setStyle({
                    fillStyle: `rgba(0,0,0,${ALPHA_DISABLED})`,
                    strokeStyle: 'HIDE_STEM'
                });
                note.vfStaveNote.setFlagStyle({ fillStyle: 'rgba(0,0,0,0)' });  // stop flags appearing when you flip while showing chord
            }
            else {                                // (re-)enable notes
                note.vfStaveNote.setStyle({
                    fillStyle: `#000`,
                    strokeStyle: '#000'
                });
            }
        });

    }

    renderNotes(scale) {
        // render notes
        scale.vfStaveNotes.forEach(vfStaveNote => {
            vfStaveNote.setContext(this.context).draw();
            // vfStaveNote.getBoundingBox().draw(this.context);
        });

        scale.vfBeams.forEach(b => { b.setContext(this.context).draw() }); // render beams
    }


    render() {
        const { printing, vexflowMetrics } = this.props;
        const cssZoom = printing ? vexflowMetrics.staff.cssZoomPrint : CSS_ZOOM;
        return (
            <div
                id={this.vfDivId}
                className="vexflow-div"
                style={{
                    width: Math.ceil(vexflowMetrics.system.width * VF_ZOOM),
                    height: Math.ceil(vexflowMetrics.system.height * VF_ZOOM),
                    transform: `scale(${cssZoom})`,
                    // backgroundColor: '#ffc',
                }}
                data-testid='notation'
            />
        )
    }
}