import { grades1to8, ALL_SCALES } from './Grades';
import { notesByGrade } from './notesByGrade';
import { midiPitch } from '../logic/noteUtils';
import { fingerings } from './fingerings';
import { fingeringClass } from '../notation/fingeringdiagram';

//??? to dos
// o probably automatically do 'copy insts' for any synonyms set
// o redo nameVariations as (usually) a simple wordOrder: 'fixed' - think
// ie. automate it a bit
//    - assume fluid
//    - ignored I guess if nameVariations is set


function fingeringForBrass(midi, varietyId) {
    const fingeringsSet = this.fingerings || this.varieties[varietyId].fingerings;
    return fingeringsSet[midi];
}

function fingeringForWoodwind(midi) {
    return this._fingeringEngine.getPitchText(midi + this.transpositionSounding);
}


const INSTRUMENTS = [

    // ???
    // o rename 'group' to abrsmSyllabus or something
    // ? possibly one day could rationalize all the ranges? I.e. 
    //     - specify source name, like 'lowestNote: { adlerNonProf: x, adlerProf: y, abrsm: z}
    //     - and could refer to the source on screen..
    // o rename 'name' to id (or urlName?? nah..)

    /*

 ######  ######## ########  #### ##    ##  ######    ######  
##    ##    ##    ##     ##  ##  ###   ## ##    ##  ##    ## 
##          ##    ##     ##  ##  ####  ## ##        ##       
 ######     ##    ########   ##  ## ## ## ##   ####  ######  
      ##    ##    ##   ##    ##  ##  #### ##    ##        ## 
##    ##    ##    ##    ##   ##  ##   ### ##    ##  ##    ## 
 ######     ##    ##     ## #### ##    ##  ######    ######  

    */
    {
        name: 'violin',
        group: 'violin',
        family: 'Strings',
        displayName: 'Violin',
        clef: 'treble',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "G", octave: 3 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'G', octave: 6 },  // 
        grades: [],
        nameVariations: ['Fiddle', 'vln'],
        // nameForSorting: '0-violin'
    },
    {
        name: 'viola',
        group: 'viola',
        family: 'Strings',
        displayName: 'Viola',
        clef: 'alto',
        altClefHigh: 'treble',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "C", octave: 3 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'C', octave: 6 },
        grades: [],
        nameVariations: ['vla'],
    },
    {
        name: 'cello',
        group: 'cello',
        family: 'Strings',
        displayName: 'Cello',
        altClefHigh: 'treble',
        clef: 'bass',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "C", octave: 2 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'G', octave: 5 },
        grades: [],
        nameVariations: ['Violoncello', 'vcl'],
    },
    {
        name: 'double-bass',
        group: 'double-bass',
        family: 'Strings',
        displayName: 'Double Bass',
        clef: 'bass',
        altClefHigh: 'treble',
        transpositionSounding: -12,
        lowestNote: {
            generic: { pitchClass: "E", octave: 2 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'G', octave: 4 },
        grades: [],
        nameVariations: ['Contrabass'],  // can't allow 'cb' cos that is C♭
    },
    /*

    
##      ##  #######   #######  ########  ##      ## #### ##    ## ########  
##  ##  ## ##     ## ##     ## ##     ## ##  ##  ##  ##  ###   ## ##     ## 
##  ##  ## ##     ## ##     ## ##     ## ##  ##  ##  ##  ####  ## ##     ## 
##  ##  ## ##     ## ##     ## ##     ## ##  ##  ##  ##  ## ## ## ##     ## 
##  ##  ## ##     ## ##     ## ##     ## ##  ##  ##  ##  ##  #### ##     ## 
##  ##  ## ##     ## ##     ## ##     ## ##  ##  ##  ##  ##   ### ##     ## 
 ###  ###   #######   #######  ########   ###  ###  #### ##    ## ########                                                                     
                                                                   
 */
    {
        name: 'descant-recorder',
        group: 'descant-recorder',
        family: 'Woodwind',
        displayName: 'Descant Recorder',
        clef: 'treble',
        transpositionSounding: 12,
        lowestNote: {
            generic: { pitchClass: "C", octave: 4 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'D', octave: 6 },
        grades: [],
        synonyms: ['Soprano Recorder'],
        nameVariations: ['recorder'],
        varieties: {         // just for purpose of info message atm
            baroque: {
                displayName: 'Baroque/English',
            },
            // german: {
            //     displayName: 'German',
            // },
        },
        defaultVariety: 'baroque',
        _fingeringEngine: new fingeringClass({ instrumentId: 'wind.flutes.recorder.soprano' }),
        fingering: function (midi) {
            return this._fingeringEngine.getPitchText(midi + 12);
        },
        fingeringClass: 'recorder',
        matchWeight: 0.9,
    },
    {
        name: 'treble-recorder',
        group: 'treble-recorder',
        family: 'Woodwind',
        displayName: 'Treble Recorder',
        clef: 'treble',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "F", octave: 4 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'G', octave: 6 },  // 
        grades: [],
        synonyms: ['Alto Recorder'],
        varieties: {         // just for purpose of info message atm
            baroque: {
                displayName: 'Baroque/English',
            },
            // german: {
            //     displayName: 'German',
            // },
        },
        defaultVariety: 'baroque',
        _fingeringEngine: new fingeringClass({ instrumentId: 'wind.flutes.recorder.alto' }),
        fingering: fingeringForWoodwind,
        fingeringClass: 'recorder',
    },
    {
        name: 'flute',
        group: 'flute',
        family: 'Woodwind',
        displayName: 'Flute',
        clef: 'treble',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "C", octave: 4 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'A', octave: 6 },  // 
        grades: [],
        // idForFingerings: 'winds.flutes.flute',
        _fingeringEngine: new fingeringClass({ instrumentId: 'wind.flutes.flute' }),
        fingering: fingeringForWoodwind,
        noteSpaceForFingerings: 55,
        fingeringClass: 'flute',
        // nameForSorting: '0-flute',
    },
    {
        name: 'piccolo',
        group: 'piccolo',
        family: 'Woodwind',
        displayName: 'Piccolo',
        clef: 'treble',
        transpositionSounding: 12,
        lowestNote: {
            generic: { pitchClass: "D", octave: 4 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'A', octave: 6 },
        grades: [],
        // idForFingerings: 'winds.flutes.flute',
        _fingeringEngine: new fingeringClass({ instrumentId: 'wind.flutes.flute.piccolo' }),
        fingering: fingeringForWoodwind,
        noteSpaceForFingerings: 55,
        fingeringClass: 'piccolo',
    },
    {
        name: 'oboe',
        group: 'oboe',
        family: 'Woodwind',
        displayName: 'Oboe',
        clef: 'treble',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "C", octave: 4 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'E', octave: 6 },  // 
        grades: [],
    },
    {
        name: 'Bb-clarinet',
        group: 'clarinet',
        family: 'Woodwind',
        displayName: 'Clarinet (B♭)',
        clef: 'treble',
        transpositionSounding: -2,
        lowestNote: {
            generic: { pitchClass: "E", octave: 3 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'G', octave: 6 },  // 
        grades: [],
        _fingeringEngine: new fingeringClass({ instrumentId: 'wind.reed.clarinet.bflat' }),
        // fingering: fingeringForWoodwind,   // ???351 turned off till I fix 000351 (context-dependent clarinet fingerings)
        noteSpaceForFingerings: 53,
        fingeringClass: 'clarinet',
        nameVariations: ['clarinet', 'Bb clarinet'],
    },
    {
        name: 'bassoon',
        group: 'bassoon',
        family: 'Woodwind',
        displayName: 'Bassoon',
        clef: 'bass',
        altClefHigh: 'tenor',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "Bb", octave: 1 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'Bb', octave: 4 },
        grades: [],
        nameVariations: ['bsn']
    },
    {
        name: 'soprano-sax',
        group: 'saxophone',
        family: 'Woodwind',
        displayName: 'Soprano Saxophone',
        clef: 'treble',
        transpositionSounding: -2,
        lowestNote: {
            generic: { pitchClass: "Bb", octave: 3 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'E', octave: 6 },
        grades: [],
        _fingeringEngine: new fingeringClass({ instrumentId: 'wind.reed.saxophone.soprano' }),
        fingering: fingeringForWoodwind,
        fingeringClass: 'saxophone',
        noteSpaceForFingerings: 55,
        nameVariations: ['soprano'],
    },
    {
        name: 'alto-sax',
        group: 'saxophone',
        family: 'Woodwind',
        displayName: 'Alto Saxophone',
        clef: 'treble',
        transpositionSounding: -9,
        lowestNote: {
            generic: { pitchClass: "Bb", octave: 3 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'E', octave: 6 },  // 
        grades: [],
        _fingeringEngine: new fingeringClass({ instrumentId: 'wind.reed.saxophone.alto' }),
        fingering: fingeringForWoodwind,
        fingeringClass: 'saxophone',
        noteSpaceForFingerings: 55,
        nameVariations: ['alto', 'saxophone'],
    },
    {
        name: 'tenor-sax',
        group: 'saxophone',
        family: 'Woodwind',
        displayName: 'Tenor Saxophone',
        clef: 'treble',
        transpositionSounding: -14,
        lowestNote: {
            generic: { pitchClass: "Bb", octave: 3 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'E', octave: 6 },  // 
        grades: [],
        _fingeringEngine: new fingeringClass({ instrumentId: 'wind.reed.saxophone.tenor' }),
        fingering: fingeringForWoodwind,
        fingeringClass: 'saxophone',
        noteSpaceForFingerings: 55,
        nameVariations: ['tenor'],
    },
    {
        name: 'baritone-sax',
        group: 'saxophone',
        family: 'Woodwind',
        displayName: 'Baritone Saxophone',
        clef: 'treble',
        transpositionSounding: -21,
        lowestNote: {
            generic: { pitchClass: "Bb", octave: 3 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'E', octave: 6 },  // 
        grades: [],
        _fingeringEngine: new fingeringClass({ instrumentId: 'wind.reed.saxophone.baritone' }),
        fingering: fingeringForWoodwind,
        fingeringClass: 'saxophone',
        noteSpaceForFingerings: 55,
        nameVariations: ['bari'],
        // synonyms: ['Bari']
    },
    /*

########  ########     ###     ######   ######  
##     ## ##     ##   ## ##   ##    ## ##    ## 
##     ## ##     ##  ##   ##  ##       ##       
########  ########  ##     ##  ######   ######  
##     ## ##   ##   #########       ##       ## 
##     ## ##    ##  ##     ## ##    ## ##    ## 
########  ##     ## ##     ##  ######   ######  

    */
    {
        name: 'horn',
        group: 'horn',
        family: 'Brass',
        displayName: 'Horn in F',
        clef: 'treble',
        transpositionSounding: -7,
        lowestNote: {
            generic: { pitchClass: "F#", octave: 2 },
            abrsm: { pitchClass: "A", octave: 2 },
        },
        highestNote: { pitchClass: 'B', octave: 5 },
        grades: grades1to8,
        nameVariations: ['Horn', 'French Horn', 'Horn F'],
        varieties: {
            singleHornF: {
                displayName: 'Single (F)',
                fingerings: fingerings.trumpet
            },
            doubleHorn: {
                displayName: 'Double (F/B♭)',
                fingerings: fingerings.doubleHorn
            },
        },
        defaultVariety: 'doubleHorn',
        fingering: fingeringForBrass,
    },
    {
        name: 'trumpet',
        group: 'trumpet',
        family: 'Brass',
        displayName: 'Trumpet (B♭)',
        clef: 'treble',
        transpositionSounding: -2,
        lowestNote: {
            generic: { pitchClass: "F#", octave: 3 },
            abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'C', octave: 6 },  // Highest in ABRSM grade 8 scales
        grades: grades1to8,
        fingering: fingeringForBrass,
        nameVariations: ['trumpet', 'Bb trumpet', 'tpt'],
    },
    {
        name: 'Eb-cornet',
        group: 'trumpet',
        family: 'Brass',
        displayName: 'Cornet (E♭)',
        clef: 'treble',
        transpositionSounding: -2,
        lowestNote: {
            generic: { pitchClass: "F#", octave: 3 },
            abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'Bb', octave: 5 },
        grades: grades1to8,
        useFingerings: 'trumpet',
        fingering: fingeringForBrass,
        matchWeight: 0.9,
        nameVariations: ['Eb cornet'],
    },
    {
        name: 'trombone',
        group: 'trombone',
        family: 'Brass',
        displayName: "Trombone",
        clef: 'bass',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "E", octave: 2 },
            abrsm: { pitchClass: "E", octave: 2 },
        },
        highestNote: { pitchClass: 'Bb', octave: 4 },
        grades: grades1to8,
        trebleInstName: 'trombone-treble',  // 'equivalent' treble clef inst
        fingering: fingeringForBrass,
        fingeringsName: 'slide positions',
        nameVariations: ['tenor trombone', 'tbn'],
    },
    {
        name: 'trombone-treble',
        group: 'trombone',
        family: 'Brass',
        displayName: 'Trombone (Treble clef)',
        dropdownName: 'Trombone',       // cos clef is shown by navbar button ??? no! but maybe this is obsolete
        clef: "treble",
        transpositionSounding: -14,
        lowestNote: {
            generic: { pitchClass: "F#", octave: 3 },
            abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'C', octave: 6 },
        grades: grades1to8,
        bassInstName: 'trombone',
        secondaryInst: true,       // don't put in dropdown list
        useFingerings: 'trombone',
        fingeringsName: 'slide positions',
        fingering: fingeringForBrass
    },
    {
        name: 'bass-trombone',
        group: 'bass-trombone',
        family: 'Brass',
        displayName: "Bass Trombone",
        clef: 'bass',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "F", octave: 1 },
            abrsm: { pitchClass: "C", octave: 2 },
            // ABRSM gr8 requires C major to 2 octaves, and B major to 12th - so I'm inferring that the lowest note for the purposes of exam scales is C. 000006
        },
        highestNote: { pitchClass: 'B', octave: 4 },
        grades: [6, 7, 8, ALL_SCALES],
        otherGrades: 'trombone',
        useFingerings: 'bassTrombone',
        fingering: fingeringForBrass,
        fingeringsName: 'slide positions',
        nameVariations: ['bs tbn'],
    },
    {
        name: 'Eb-horn',
        group: 'Eb-horn',
        family: 'Brass',
        displayName: 'E♭ Horn',
        nameVariations: ['tenor horn', 'alto horn'],
        clef: 'treble',
        transpositionSounding: -9,
        lowestNote: {
            generic: { pitchClass: "F#", octave: 3 },
            abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'C', octave: 6 },
        grades: grades1to8,
        // fingering: fingeringForBrass,   // don't define this if no fingerings!
        // ??? is this called an alto horn?
    },
    {
        name: 'baritone',
        group: 'baritone-euphonium',
        family: 'Brass',
        displayName: 'Baritone',
        tokenDesc: '(brass, not sax!)',
        clef: 'bass',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "E", octave: 2 },
            abrsm: { pitchClass: "E", octave: 2 },
        },
        highestNote: { pitchClass: 'Bb', octave: 4 },
        grades: grades1to8,
        // synonyms: ['euphonium'],
        trebleInstName: 'baritone-treble',
        // fingering: fingeringForBrass,
        // nameForSorting: '0-baritone'
    },
    {
        name: 'baritone-treble',
        group: 'baritone-euphonium',
        family: 'Brass',
        displayName: 'Baritone (Treble)',
        tokenDesc: '(brass, not sax!)',
        // dropdownName: 'Baritone',       // cos clef is shown by navbar button ???no it's not
        clef: "treble",
        transpositionSounding: -14,
        lowestNote: {
            generic: { pitchClass: "F#", octave: 3 },
            abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'C', octave: 6 },
        grades: grades1to8,
        // synonyms: ['baritone-treble', 'euphonium-treble'],
        bassInstName: 'baritone',
        secondaryInst: true,
        // fingering: fingeringForBrass
    },
    {
        name: 'Eb-tuba',
        group: 'tuba',
        family: 'Brass',
        displayName: "E♭ Tuba",
        clef: 'bass',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "F", octave: 1, },
            abrsm: { pitchClass: "A", octave: 1, },
        },
        highestNote: { pitchClass: 'Eb', octave: 4 },
        grades: grades1to8,
        trebleInstName: 'Eb-tuba-treble',
        // useFingerings: 'Eb-tuba-non-compensating',
        varieties: {
            ebTubaCompensating: {
                displayName: 'Compensating',
                fingerings: fingerings.ebTubaCompensating
            },
            ebTubaNonCompensating: {
                displayName: 'Non-compensating',
                fingerings: fingerings.ebTubaNonCompensating
            },
        },
        defaultVariety: 'ebTubaNonCompensating',
        fingering: fingeringForBrass,
        nameVariations: ['tuba Eb'],
    },
    {
        name: 'Bb-tuba',
        group: 'tuba',
        family: 'Brass',
        displayName: "B♭ Tuba",
        clef: 'bass',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "C", octave: 1 },
            abrsm: { pitchClass: "E", octave: 1 },
            // Lowest note using 3-valve tuba, which is what to use for scales (cf. ABRSM tuba syllabus pg10 footnote)
        },
        highestNote: { pitchClass: 'Bb', octave: 3 },
        grades: grades1to8,
        trebleInstName: 'Bb-tuba-treble',
        varieties: {
            bbTubaCompensating: {
                displayName: 'Compensating',
                fingerings: fingerings.bbTubaCompensating
            },
            bbTubaNonCompensating: {
                displayName: 'Non-compensating',
                fingerings: fingerings.bbTubaNonCompensating
            },
        },
        defaultVariety: 'bbTubaNonCompensating',
        fingering: fingeringForBrass,
        nameVariations: ['tuba Bb'],
    },
    {
        name: 'f-tuba',
        group: 'tuba',
        family: 'Brass',
        displayName: 'F Tuba',
        clef: 'bass',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "B", octave: 1, },
            abrsm: { pitchClass: "B", octave: 1, },
        },
        highestNote: { pitchClass: 'F', octave: 4 },
        grades: grades1to8,
        trebleInstName: 'f-tuba-treble',
        // fingering: fingeringForBrass,   // Reinstate when I've set up fingerings for this inst
        nameVariations: ['tuba f'],
    },
    {
        name: 'c-tuba',
        group: 'tuba',
        family: 'Brass',
        displayName: 'C Tuba',
        useListOf: 'tuba-treble',
        clef: 'bass',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "F#", octave: 1, },
            abrsm: { pitchClass: "F#", octave: 1, },
        },
        highestNote: { pitchClass: 'C', octave: 4 },
        grades: grades1to8,
        trebleInstName: 'c-tuba-treble',
        // fingering: fingeringForBrass,   // Reinstate when I've set up fingerings for this inst
        nameVariations: ['tuba c'],
    },
    {
        name: 'Eb-tuba-treble',
        group: 'tuba',
        family: 'Brass',
        displayName: "E♭ Tuba (Treble clef)",
        dropdownName: "E♭ Tuba",       // ??? obsolete?
        clef: 'treble',
        transpositionSounding: -21,
        lowestNote: {
            generic: { pitchClass: "D", octave: 3 },
            abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'C', octave: 6 },
        grades: grades1to8,
        bassInstName: 'Eb-tuba',
        secondaryInst: true,
        // useFingerings: 'Eb-tuba-non-compensating',        // ???416
        // fingering: fingeringForBrass,
    },
    {
        name: 'Bb-tuba-treble',
        group: 'tuba',
        family: 'Brass',
        displayName: "B♭ Tuba (Treble clef)",
        dropdownName: "B♭ Tuba",
        clef: 'treble',
        useListOf: 'tuba-treble',
        transpositionSounding: -26,
        lowestNote: {
            generic: { pitchClass: "F#", octave: 3 },
            abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'C', octave: 6 },
        grades: grades1to8,
        bassInstName: 'Bb-tuba',
        secondaryInst: true,
        // fingering: fingeringForBrass,          // ???416
    },
    {
        name: 'f-tuba-treble',
        group: 'tuba',
        family: 'Brass',
        displayName: 'F Tuba (Treble clef)',
        dropdownName: "F Tuba",
        clef: 'treble',
        useListOf: 'tuba-treble',
        transpositionSounding: -19,
        lowestNote: {
            generic: { pitchClass: "F#", octave: 3 },
            abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'C', octave: 6 },
        grades: grades1to8,
        bassInstName: 'f-tuba',
        secondaryInst: true,
        // fingering: fingeringForBrass,   // Reinstate when I've set up fingerings for this inst

    },
    {
        name: 'c-tuba-treble',
        group: 'tuba',
        family: 'Brass',
        displayName: 'C Tuba (Treble clef)',
        dropdownName: 'C Tuba',
        clef: 'treble',
        useListOf: 'tuba-treble',
        transpositionSounding: -24,
        lowestNote: {
            generic: { pitchClass: "F#", octave: 3 },
            abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'C', octave: 6 },
        grades: grades1to8,
        bassInstName: 'c-tuba',
        secondaryInst: true,
        // fingering: fingeringForBrass,   // Reinstate when I've set up fingerings for this inst
    },

    /*

########  ####    ###    ##    ##  #######     ######## ########  ######      
##     ##  ##    ## ##   ###   ## ##     ##    ##          ##    ##    ##     
##     ##  ##   ##   ##  ####  ## ##     ##    ##          ##    ##           
########   ##  ##     ## ## ## ## ##     ##    ######      ##    ##           
##         ##  ######### ##  #### ##     ##    ##          ##    ##           
##         ##  ##     ## ##   ### ##     ##    ##          ##    ##    ## ### 
##        #### ##     ## ##    ##  #######     ########    ##     ######  ### 

*/

    {
        name: 'piano',
        group: 'piano',
        family: 'Keyboard',
        displayName: 'Piano',
        clef: 'grand',
        transpositionSounding: 0,
        lowestNote: {
            generic: { pitchClass: "Db", octave: 3 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'C', octave: 7 },  // ???
        grades: [],
        nameVariations: ['Pianoforte', 'Keyboard', 'pno'],
    },
    {
        name: 'guitar',
        group: 'guitar',
        family: 'Plucked',
        displayName: 'Guitar',
        clef: 'treble',
        transpositionSounding: -12,
        lowestNote: {
            generic: { pitchClass: "E", octave: 3 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'E', octave: 6 },  // 
        grades: [],
        nameVariations: ['gtr']
    },
    {
        name: 'bass-guitar',
        // group: '',
        family: 'Plucked',
        displayName: 'Bass Guitar',
        clef: 'bass',
        transpositionSounding: -12,
        lowestNote: {
            generic: { pitchClass: "E", octave: 2 },
            // abrsm: { pitchClass: "F#", octave: 3 },
        },
        highestNote: { pitchClass: 'G', octave: 4 },  // 
        grades: [],
        nameVariations: ['Bass'],
    },

]

INSTRUMENTS.push({
    name: 'treble-clef',
    group: 'treble',
    family: 'Clefs (no specific instrument)',
    displayName: 'Treble clef',
    clef: 'treble',
    transpositionSounding: 0,
    lowestNote: {
        generic: { pitchClass: "C", octave: 3 },
        // abrsm: { pitchClass: "C", octave: 3 },
    },
    highestNote: { pitchClass: 'C', octave: 7 },
    grades: [],
    bassInstName: 'bass-clef',
    fingerings: false,
    nameVariations: ['Treble']
});
INSTRUMENTS.push({
    name: 'bass-clef',
    group: 'bass',
    family: 'Clefs (no specific instrument)',
    displayName: 'Bass clef',
    clef: 'bass',
    transpositionSounding: 0,
    lowestNote: {
        generic: { pitchClass: "E", octave: 1 },
        // abrsm: { pitchClass: "E", octave: 1 },
    },
    highestNote: { pitchClass: 'E', octave: 5 },
    grades: [],
    trebleInstName: 'treble-clef',
    fingerings: false,
});




for (let inst of INSTRUMENTS.filter(inst => inst.family === 'Woodwind' && inst.fingering)) {
    inst.fingeringBase = inst._fingeringEngine.base;
}


let filler = [];
for (let i = 0; i < 60; i++) filler.push(null);

for (let inst of INSTRUMENTS.filter(inst => inst.family === 'Brass')) {

    const lowestNoteMidi = midiPitch(inst.lowestNote.generic);

    if (inst.hasOwnProperty('varieties')) {
        for (let variety of Object.keys(inst.varieties)) {
            // insert filler so that the first fingering lines up with the lowest note on the instrument
            inst.varieties[variety].fingerings = [
                ...filler.slice(0, lowestNoteMidi),
                ...inst.varieties[variety].fingerings
            ]
        }
    }
    else if (!inst.hasOwnProperty('fingerings')) {
        const instFingerings = fingerings[inst.useFingerings || inst.name];

        if (instFingerings) {
            inst.fingerings = [
                ...filler.slice(0, lowestNoteMidi),
                ...instFingerings
            ]
        }
    }
    // console.log(inst.name)
    // console.log(inst.fingerings.join(', '))

    inst.notesByGrade = inst.grades.length ? notesByGrade[inst.name] : {};

}

// console.log('fingerings', fingerings)
// console.log(INSTRUMENTS)




function copyInstrumentAs({ fromInstName, newInstProps }) {
    const indexToCopy = INSTRUMENTS.findIndex(i => i.name === fromInstName);
    const instToCopy = INSTRUMENTS[indexToCopy];
    INSTRUMENTS.splice(indexToCopy + 1, 0, {    // stick it in just after the copied instrument
        ...instToCopy,
        useListOf: instToCopy.name,
        nameVariations: undefined,               // don't copy!
        ...newInstProps
    });
};

// nb. copy inserts new inst after the copied inst, so if copying same inst twice then 1st copy will
// end up *after* the 2nd copy!

copyInstrumentAs({
    fromInstName: 'trumpet',
    newInstProps: {
        name: 'cornet',
        displayName: 'Cornet (B♭)',
        nameVariations: ['cornet'],
    }
});

copyInstrumentAs({
    fromInstName: 'trumpet',
    newInstProps: {
        name: 'flugel',
        displayName: 'Flugelhorn',
    }
});

copyInstrumentAs({
    fromInstName: 'baritone',
    newInstProps: {
        name: 'euphonium',
        displayName: 'Euphonium',
        trebleInstName: 'euph-treble',
        tokenDesc: undefined,
    }
});
copyInstrumentAs({
    fromInstName: 'baritone-treble',
    newInstProps: {
        name: 'euphonium-treble',
        displayName: 'Euphonium (Treble)',
        bassInstName: 'euphonium',
        tokenDesc: undefined,
    }
});





export default INSTRUMENTS;