import React from 'react';
import { Navbar, Nav, NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import { setTourToRun } from '../actions/session';

import SearchBar from './SearchBar';
import SortControl from './SortControl';
import Brand from './Brand';
import { PrintIcon, SearchIcon, SettingsIcon, MenuIcon } from '../assets/icons';
import KeysGridToggle from './KeysGridToggle';
import { CSSTransition } from 'react-transition-group';
import Overlay from './Overlay';
import { setShowGrid } from '../actions/settings';
import HelpMenu from './HelpMenu';


export const NavBarMain = ({
    numScalesRetrieved,
    handlePrintRequest,
    search,
    setSearch,
    handleNewSearch,
    // toggleSidebar,
    // sidebarActiveTab,
    setSidebarActiveTab,
    currGroupBy,
    listOfModes,
    showGrid,
    dispatch,
}) => {

    const handleGridClick = () => {
        dispatch(setShowGrid(!showGrid));
    }

    return (

        <div id='header-container'
            className='navbar-backdrop main top print-hide'
        >
            <Navbar
                fixed="top"
                className="navbar navbar-main"
                expand
            >

                <Nav navbar>
                    <Brand />
                </Nav>

                <Nav navbar>
                    <NavItem className="navbar-text app-version d-none d-md-block mr-1 mt-1">
                        <span>v{process.env.REACT_APP_VERSION}</span>
                    </NavItem>
                    <NavItem>
                        <button className="nav-link d-none d-md-block" onClick={handlePrintRequest}>
                            <PrintIcon />
                            <span className="d-none d-xl-inline">&nbsp;Print</span>
                        </button>
                    </NavItem>

                    <HelpMenu
                        numScalesRetrieved={numScalesRetrieved}
                        setTourToRun={setTourToRun}
                        setSidebarActiveTab={setSidebarActiveTab}
                        dispatch={dispatch}
                    />

                    <NavItem>
                        <button
                            id="settings-button"
                            className="nav-link"
                            onClick={() => setSidebarActiveTab('SETTINGS')}
                        >
                            <SettingsIcon />
                        </button>
                    </NavItem>
                </Nav>
            </Navbar >

            {/* <Navbar  // changed to plain div as having this as a navbar messes up the help menu (hover, click don't work)
            */}
            <div
                // fixed="top"
                className="d-flex justify-content-center align-items-center navbar-with-search"
            >
                <SearchBar
                    search={search}
                    setSearch={setSearch}
                    handleNewSearch={handleNewSearch}
                />
            </div>

            <div
                className='navbar-sorting d-none d-md-flex'
            >
                <Nav>
                    <NavItem>
                        <KeysGridToggle
                            pressed={showGrid}
                            onClick={handleGridClick}
                            disabled={!numScalesRetrieved}
                        />
                    </NavItem>
                    <SortControl
                        listOfModes={listOfModes}
                        currGroupBy={currGroupBy}
                        combined
                        disabled={numScalesRetrieved < 2}
                    />
                </Nav>
            </div>
        </div>
    );
};



const NavBarPhone = ({
    numScalesRetrieved,
    search,
    setSearch,
    handleNewSearch,
    currGroupBy,
    listOfModes,
    // showSettings,
    // setShowSettings,
    sidebarActiveTab,
    setSidebarActiveTab,
    toggleSidebar,
    showSearchOnPhone,
    landingPage,
    setShowSearchOnPhone,
    usesPhoneStyleGrid,
    showGrid,
    dispatch,
}) => {

    const handleSearchClick = () => {
        if (usesPhoneStyleGrid) dispatch(setShowGrid(false));
        setSidebarActiveTab(null);
        setShowSearchOnPhone(!showSearchOnPhone);
    }

    const handleGridClick = () => {
        setShowSearchOnPhone(false);
        setSidebarActiveTab(null);
        dispatch(setShowGrid(!showGrid));
    }

    const handleSettingsClick = () => {
        // If there are scales then close search bar (feels like user clicked search, then changed mind)
        // But if no scales (i.e. we're on landing page or 'no scales retrieved') then leave it.
        if (numScalesRetrieved) setShowSearchOnPhone(false);

        dispatch(setShowGrid(false));
        toggleSidebar();
    }

    return (

        <React.Fragment>
            <Navbar
                fixed="bottom"
                className="navbar navbar-backdrop navbar-phone print-hide"
            >
                <Nav
                    className='nav-phone'
                >
                    <NavItem>
                        <KeysGridToggle
                            text
                            disabled={numScalesRetrieved === 0}
                            pressed={showGrid}
                            onClick={handleGridClick}
                        />
                    </NavItem>
                    <NavItem>
                        <button
                            className={`nav-link ${showSearchOnPhone ? 'pressed' : ''}`}
                            onClick={handleSearchClick}
                        >
                            <SearchIcon />
                            <div className='phone-navbar-button-text'>
                                Search
                            </div>
                        </button>
                    </NavItem>
                    <NavItem>
                        <SortControl
                            listOfModes={listOfModes}
                            currGroupBy={currGroupBy}
                            combined
                            text
                            disabled={numScalesRetrieved < 2}
                            tabIndex="-1"
                        />
                    </NavItem>

                    {/* 
                    // Need to address tour properly for phone... (000443)
                    <HelpNavItem
                        numScalesRetrieved={numScalesRetrieved}
                        setTourToRun={setTourToRun}
                        dispatch={dispatch}
                        phone
                    /> */}

                    <NavItem>
                        <button
                            id="settings-button"
                            className={`nav-link ${sidebarActiveTab ? 'pressed' : ''}`}
                            onClick={handleSettingsClick}
                        >
                            <MenuIcon />
                            <div className='phone-navbar-button-text'>
                                More..
                            </div>
                        </button>
                    </NavItem>
                </Nav>
            </Navbar >

            <CSSTransition
                classNames="phone-search-bar"
                in={!!showSearchOnPhone}
                timeout={1000}
                unmountOnExit
            >
                <Navbar           // navbar where the search bar stands on its own in the center
                    fixed="top"
                    className="navbar-with-search phone navbar-backdrop top print-hide"
                >
                    <Nav navbar>
                        <Brand />
                    </Nav>
                    {/* <Nav navbar className='d-none d-md-block'>
                        <button
                            className="nav-link close-button"
                            onClick={() => setShowSearchOnPhone(false)}
                        >
                            <CloseIcon />
                        </button>
                    </Nav> */}
                    <SearchBar
                        showSearchOnPhone={showSearchOnPhone}
                        search={search}
                        setSearch={setSearch}
                        handleNewSearch={handleNewSearch}
                    />
                </Navbar>
            </CSSTransition>

            {showSearchOnPhone && !landingPage &&
                <Overlay
                    className='for-search'
                    dismiss={() => setShowSearchOnPhone(false)}
                />
            }

        </React.Fragment>
    );
}


const NavBar = ({ usesPhoneStyleNavBar, ...props }) => (
    usesPhoneStyleNavBar
        ? <NavBarPhone {...props} />
        : <NavBarMain {...props} />
)


const mapStateToProps = (state) => {
    return {
        tourToRun: state.session.tourToRun,
        showGrid: state.settings.showGrid,
    }
}

export default connect(mapStateToProps)(NavBar);