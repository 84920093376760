import INSTRUMENTS from "../data/instruments";

// Default state - Instrument prefs
// Only one pref at the moment: variety.
// set up inst variety pref for every instrument which has a defaultVariety prop.
// Two stages: 1. retrieve the relevant instruments as an array..
const instrumentPrefsArray = INSTRUMENTS.filter(i => i.hasOwnProperty('defaultVariety'))
    .map(i => ({
        name: i.name,
        prefs: { variety: i.defaultVariety }
    }));

// ..2. convert array to object:
const instrumentPrefs = instrumentPrefsArray.reduce((obj, item) => Object.assign(obj, { [item.name]: item.prefs }), {});
// console.log(instrumentPrefsArray, instrumentPrefs)

// And here's the actual entire default settings:
export const settingsReducerDefaultState = {
    patchDefault: 'acoustic_grand_piano',
    tempo: 80,
    swing: false,
    showFingerings: 'ALL',
    fingeringsAllInstances: true,
    showNoteNames: 'ALL',
    chromaticBeaming: 4,
    instrumentPrefs,
    groupBy: 'TYPE',
    groupByForModes: 'MODES_OF',
    sortScalesBy: 'CHROMATIC',
    defaultInstName: 'treble-clef',
    // navSidebar: 'OPEN',
    showGrid: true,
    nextPrimaryTip: 0,
};

export default (state = settingsReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_DEFAULT_PATCH':
            return {
                ...state,
                patchDefault: action.patchDefault
            };
        case 'SET_TEMPO':
            return {
                ...state,
                tempo: action.tempo
            };
        case 'SET_SWING':
            return {
                ...state,
                swing: action.swing
            };
        case 'SET_SHOW_FINGERINGS':
            return {
                ...state,
                showFingerings: action.showFingerings
            };
        case 'SET_SHOW_NOTE_NAMES':
            return {
                ...state,
                showNoteNames: action.showNoteNames
            };
        case 'SET_FINGERINGS_ALL_INSTANCES':
            return {
                ...state,
                fingeringsAllInstances: action.fingeringsAllInstances
            };
        case 'SET_CHROMATIC_BEAMING':
            return {
                ...state,
                chromaticBeaming: action.chromaticBeaming
            };
        case 'SET_INSTRUMENT_VARIETY':
            return {
                ...state,
                instrumentPrefs: {
                    ...state.instrumentPrefs,
                    [action.instId]: { variety: action.varietyId }
                }
            };
        case 'SET_GROUP_BY':
            return {
                ...state,
                groupBy: action.groupBy
            };
        case 'SET_GROUP_BY_FOR_MODES':
            return {
                ...state,
                groupByForModes: action.groupByForModes
            };
        case 'SET_SORT_SCALES_BY':
            return {
                ...state,
                sortScalesBy: action.sortScalesBy
            };
        case 'SET_DEFAULT_INSTRUMENT':
            return {
                ...state,
                defaultInstName: action.defaultInstName
            };
        case 'SET_SHOW_GRID':
            return {
                ...state,
                showGrid: action.showGrid
            };
        case 'SET_NEXT_PRIMARY_TIP':
            return {
                ...state,
                nextPrimaryTip: action.nextPrimaryTip
            };
        default:
            return state;
    }
}
