import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { setGroupBy, setSortScalesBy, setGroupByForModes } from '../actions/settings';
import { CaretDownIcon, CaretUpIcon, SortIcon } from '../assets/icons';
import { Control } from './ActionControls';
import { groupBys } from '../logic/groupBys';


const GroupSortCombinedDropdown = ({
    combinedDropdownOpen,
    setCombinedDropdownOpen,
    currGroupBy,
    listOfModes,
    text,
    disabled,
    tabIndex,
    handleGroupByClick,
    sortScalesBy,
    dispatch
}) => {
    return (
        <Dropdown
            isOpen={combinedDropdownOpen}
            toggle={() => setCombinedDropdownOpen(!combinedDropdownOpen)}
            direction={text ? 'up' : 'down'}  // slightly kludgey way to determine whether button is on bottom navbar or not
            inNavbar
            tag='span'
        >
            <DropdownToggle
                className={`nav-link ${combinedDropdownOpen ? 'pressed' : ''}`}
                // className={`group-nav group-nav-button nav-link ${combinedDropdownOpen ? 'pressed' : ''}`}
                disabled={disabled}
                tabIndex={tabIndex}
            >
                <SortIcon />
                {text &&
                    <div className='phone-navbar-button-text'>
                        Sort
                    </div>
                }
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem header>Group By</DropdownItem>
                {Object.entries(groupBys).map(([groupById, groupBy]) => {
                    return (
                        !!groupBy &&
                        <Control
                            inDropdown
                            key={groupById}
                            value={groupById}
                            active={(currGroupBy) === groupById}
                            disabled={groupById === 'MODES_OF' && !listOfModes}
                            onClick={handleGroupByClick}
                        >
                            <span>{groupBy.groupByName}</span>
                        </Control>
                    )
                })
                }
                <DropdownItem header>Sort By</DropdownItem>
                <Control
                    inDropdown
                    active={sortScalesBy === 'CHROMATIC'}
                    onClick={() => dispatch(setSortScalesBy('CHROMATIC'))}
                >
                    <span>Chromatically</span>
                </Control>
                <Control
                    inDropdown
                    active={sortScalesBy === 'CIRCLE'}
                    onClick={() => dispatch(setSortScalesBy('CIRCLE'))}
                >
                    <span>Circle of Fifths</span>
                </Control>
            </DropdownMenu>
        </Dropdown>
    );
}

const GroupSortSeparateDropdowns = ({
    groupByDropdownOpen,
    setGroupByDropdownOpen,
    sortByDropdownOpen,
    setSortByDropdownOpen,
    currGroupBy,
    listOfModes,
    handleGroupByClick,
    sortScalesBy,
    dispatch
}) => {
    return (
        <React.Fragment>
            <Dropdown
                isOpen={groupByDropdownOpen}
                toggle={() => setGroupByDropdownOpen(!groupByDropdownOpen)}
                id="groupby-menu"
                tag='span'
            >
                <DropdownToggle className='sorting-menu'>
                    Group by <strong>{groupBys[currGroupBy].groupByName}</strong>&nbsp;
                    {groupByDropdownOpen ? <CaretUpIcon /> : <CaretDownIcon />}
                </DropdownToggle>
                <DropdownMenu>
                    {Object.entries(groupBys).map(([groupById, groupBy]) => {
                        return (
                            !!groupBy &&
                            <Control
                                inDropdown
                                key={groupById}
                                value={groupById}
                                active={(currGroupBy) === groupById}
                                disabled={groupById === 'MODES_OF' && !listOfModes}
                                onClick={handleGroupByClick}
                            >
                                <span>{groupBy.groupByName}</span>
                            </Control>
                        )
                    })
                    }
                </DropdownMenu>
            </Dropdown>

            <Dropdown
                className='ml-3'
                isOpen={sortByDropdownOpen}
                toggle={() => setSortByDropdownOpen(!sortByDropdownOpen)}
                id="sortby-menu"
                tag='span'
            >
                <DropdownToggle className='sorting-menu'>
                    Sort <strong>{sortScalesBy === 'CHROMATIC' ? 'Chromatically' : 'by Circle of 5ths'}&nbsp;</strong>
                    {sortByDropdownOpen ? <CaretUpIcon /> : <CaretDownIcon />}
                </DropdownToggle>
                <DropdownMenu>
                    <Control
                        inDropdown
                        active={sortScalesBy === 'CHROMATIC'}
                        onClick={() => dispatch(setSortScalesBy('CHROMATIC'))}
                    >
                        <span>Chromatically</span>
                    </Control>
                    <Control
                        inDropdown
                        active={sortScalesBy === 'CIRCLE'}
                        onClick={() => dispatch(setSortScalesBy('CIRCLE'))}
                    >
                        <span>Circle of Fifths</span>
                    </Control>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}

const SortControl = (props) => {

    const {
        combined,
        currGroupBy,
        listOfModes,

        dispatch
    } = props;

    const [groupByDropdownOpen, setGroupByDropdownOpen] = useState(false);
    const [sortByDropdownOpen, setSortByDropdownOpen] = useState(false);
    const [combinedDropdownOpen, setCombinedDropdownOpen] = useState(false);
    // const currGroupBy = currGroupByInEffect({ listOfModes, groupByForModes, groupBy: groupBySetting });

    const handleGroupByClick = (e) => {
        const newGroupBy = e.currentTarget.value;
        if (e.currentTarget.value === 'MODES_OF') {
            dispatch(setGroupByForModes(newGroupBy));
        }
        else {
            dispatch(setGroupBy(newGroupBy));
            if (listOfModes) dispatch(setGroupByForModes('DEFER'));
        }
    }

    return (
        combined ?
            (
                <GroupSortCombinedDropdown
                    combinedDropdownOpen={combinedDropdownOpen}
                    setCombinedDropdownOpen={setCombinedDropdownOpen}
                    currGroupBy={currGroupBy}
                    handleGroupByClick={handleGroupByClick}
                    {...props}
                />
            )
            : (
                <GroupSortSeparateDropdowns
                    groupByDropdownOpen={groupByDropdownOpen}
                    setGroupByDropdownOpen={setGroupByDropdownOpen}
                    sortByDropdownOpen={sortByDropdownOpen}
                    setSortByDropdownOpen={setSortByDropdownOpen}
                    currGroupBy={currGroupBy}
                    handleGroupByClick={handleGroupByClick}
                    {...props}
                />
            )
    );
}


const mapStateToProps = (state) => {
    return {
        sortScalesBy: state.settings.sortScalesBy,
    };
};

export default connect(mapStateToProps)(SortControl);