// import React from 'react';
// import { AllIcon } from '../assets/icons';


export const ALL_SCALES = 'all'  // ??? really?

const GRADES =
    [1, 2, 3, 4, 5, 6, 7, 8].map(gradeNum => (
        {
            id: gradeNum,
            url: `grade-${gradeNum}`,
            dropdown: gradeNum,
            pill: gradeNum,
            displayName: `Grade ${gradeNum}`
        }
    ))
        .concat({
            id: ALL_SCALES,
            url: ALL_SCALES,
            dropdown: 'All Scales',
            pill: 'ALL', //<div style={{ marginLeft: '-3px' }}><AllIcon /></div>,
            displayName: 'All Scales'
        })
    ;

export const grades1to8 = [1, 2, 3, 4, 5, 6, 7, 8, ALL_SCALES];



export default GRADES;
