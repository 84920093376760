import { alter, chromaticIndex, enharmonic, stepsAboveMiddleLine } from './noteUtils';
import { getCircleIndex } from './musicStructures';
import { pitchClassJSX } from '../utils/MusicSymbolsFormatting';
import { element } from '../notation/NotationConsts';

import Vex from 'vexflow';



class Note {

    constructor({ pitchClass, octave, clef, octaveShift = 0, scaleDegree = 1, duration = '8', ignoreForStaffWidth = false }) {
        // console.time('note ' + pitchClass + octave)
        this.pitchClass = pitchClass;
        this.octave = octave;
        this.clef = clef;
        this.octaveShift = octaveShift;
        this.scaleDegree = scaleDegree;    // ignores octave; e.g. major scale up/down goes 123456717654321
        this.duration = duration;          // nb. vexflow codes: w h q 8 16.. (whole half quarter 1/8 1/16..)
        // console.timeLog('note ' + pitchClass + octave)
        this._createVfStaveNote();
        // console.timeEnd('note ' + pitchClass + octave)
        this.ignoreForStaffWidth = ignoreForStaffWidth;
        // calcNotePositions won't count it for staff width (extra notes for 12th triads pattern)
    }

    alter({ alteration, showNaturalSign }) {
        this.pitchClass = alter({ note: this, alteration, showNaturalSign }).pitchClass;

        // Must refresh the vexflow StaveNote after an alteration
        this._createVfStaveNote();
    }

    enharmonicFlip() {
        if (['B', 'B#'].includes(this.pitchClass)) this.octave++;          // B3 flips to Cb4, B#3 flips to C4
        else if (['C', 'Cb'].includes(this.pitchClass)) this.octave--;     // Cb4 flips to B3, C4 flips to B#3

        this.pitchClass = enharmonic(this.pitchClass);
        this._createVfStaveNote();   // needs to be refreshed
        return this;
    }

    changeDuration(newDuration) {
        this.duration = newDuration;
        this._createVfStaveNote();
        return this;
    }

    changeClef(newClef) {
        this.clef = newClef;
        this._createVfStaveNote();
        return this;
    }

    changeOctaveShift(newOctaveShift) {
        this.octaveShift = newOctaveShift;
        this._createVfStaveNote();
        return this;
    }

    get SPN() { return `${this.pitchClass.replace(/n$/, '')}${this.octave}` };
    get pitchClassJSX() { return pitchClassJSX(this.pitchClass) }  // with proper sharp/flat signs
    get letter() { return this.pitchClass[0] }                     // Ignore accidental
    get alteration() { return this.pitchClass.substr(1) }

    get chromaticIndex() { return chromaticIndex({ pitchClass: this.pitchClass }) }
    get midi() { return 12 + this.octave * 12 + this.chromaticIndex };
    get circleIndex() { return getCircleIndex(this.pitchClass) }        // Index around circle of 5ths: C=0, G=1,.. F#=Gb=6,.. F=11
    get durationSanitized() { return Vex.Flow.sanitizeDuration(this.duration) }

    get stepsAboveMiddleLine() { return stepsAboveMiddleLine(this.pitchClass, this.octave, this.clef, this.octaveShift) };
    // where notehead sits on the staff, 0 = middle line, 1 = third space from bottom, 4 = top line, 6 = 1st leger line

    // Lowest point of note in staff step units. Takes account of notehead 'height' and stem
    // but not accidental any more (000051).
    // Assumes stem direction is default (ie. middle line or higher => stem down)
    get bottom() {
        return this.stepsAboveMiddleLine - Math.max(
            this.stepsAboveMiddleLine >= 0 ? element.STEM.height : 0,         // stem if stem down
            element.NOTEHEAD.height / 2                                       // or half a notehead
        );
    }
    get top() {
        return this.stepsAboveMiddleLine
            + Math.max(
                this.stepsAboveMiddleLine < 0 ? element.STEM.height : 0,    // stem if stem up
                [1, 7].includes(this.scaleDegree) && this.stepsAboveMiddleLine === 2 // allow for stem up from 4th line note
                    ? element.STEM.height                                   // e.g. D maj arp, E maj7 chord
                    : 0,
                element.NOTEHEAD.height / 2                                 // or half a notehead
            );
    }

    _createVfStaveNote() {     // define as internal?
        const { letter, alteration, clef, duration } = this;

        let vfStaveNote = new Vex.Flow.StaveNote({
            clef,
            octave_shift: this.octaveShift,
            keys: [`${letter}${alteration}/${this.octave}`],
            duration,
            auto_stem: true
        });

        if (this.alteration) vfStaveNote.addAccidental(0, new Vex.Flow.Accidental(alteration));
        this.vfStaveNote = vfStaveNote;
    }

}

export default Note;
