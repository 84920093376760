// ??? better:
// o rework this as 'bullet types', cos captureBullets uses it like that!
//    i.e. major != chromatic even though they have the same suffix (none)
// o shorten the ids possibly.. cos they end up as bullet filenames...? meh..
// BUT SHOULD TEST PROPERLY BEFORE MAKING SUCH CHANGES


export const bulletSuffixes = {
    major: {},
    minor: { char: 'm' },
    dom7: { char: '7' },
    diminished: {
        char: '°',
        className: 'diminished',   // specific positioning for the symbol
    },
    augmented: {
        char: '+',
        className: 'augmented',
    },
    halfDiminished: {
        char: '\uE871',
        className: 'half-diminished music-symbol',
    },
    chromatic: {}
}
