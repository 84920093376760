import React, { useState, useEffect, useRef } from 'react';
import { Menu, MenuItem as RbtMenuItem } from 'react-bootstrap-typeahead';
import scrollIntoView from 'scroll-into-view-if-needed';

import { isTabletOrPhone } from '../utils/miscUtils';
import {
    SearchIcon, CaretDownIcon, CaretUpIcon,
    //  ExampleIcon, SpacerIcon
} from '../assets/icons';

const MenuItem = ({ active, ...otherProps }) => {
    const itemRef = useRef(null);
    useEffect(() => {
        if (active) scrollIntoView(itemRef.current, { scrollMode: 'if-needed', block: 'nearest', inline: 'nearest' });
    }, [active]);
    return (
        <div
            ref={itemRef}
        >
            <RbtMenuItem
                active={active}
                {...otherProps}
            />
        </div>
    )
}

const InputWithSuggestions = ({
    placeholder = 'Search for a scale',
    showSearchOnPhone,
    search,
    setSearch,
    suggestions,
    handleSubmit: superHandleSubmit,
}) => {

    // const [menuOpen, setMenuOpen] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => setMenuOpen(!menuOpen);
    const [activeItem, setActiveItem] = useState(-1);

    const handleSubmit = (e, label) => {
        // On mobile, want to hide the onscreen keyboard on submit...
        if (window.innerHeight < 450) {             // 450 because iPhone 5 is 568 double-pixels tall I think.
            // If screen height is really small then *guess* that it's because the keyboard is showing,
            // so move focus out (which hides the keyboard).
            // Don't know how to better detect this (but anyway, we're only doing a blur, sooo...)
            inputElt.current.blur();
        }
        superHandleSubmit(e, label);
    }

    const inputElt = useRef(null);

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            setActiveItem(-1);
            setMenuOpen(false);
            handleSubmit(e, suggestions[activeItem]?.label);
        }
        else if (e.key === 'ArrowDown') {
            setMenuOpen(true);
            const nextEnabledItemIndex = suggestions.findIndex((sugg, i) => i > activeItem && !sugg.disabled)
            if (!!suggestions[nextEnabledItemIndex]) {
                setActiveItem(nextEnabledItemIndex);
            }
            e.preventDefault();
        }
        else if (e.key === 'ArrowUp') {
            if (activeItem > -1) {
                // Find the previous item on the suggestions list which is not disabled.
                // FindIndex retrieves *first* index which fits so have to do everything on a reversed array:
                const reversedIndex = suggestions.slice().reverse().findIndex((sugg, i) => (i > (suggestions.length - activeItem - 1)) && !sugg.disabled)
                const prevEnabledItemIndex = reversedIndex === -1 ? -1 : suggestions.length - reversedIndex - 1;
                setActiveItem(prevEnabledItemIndex);
            }
            e.preventDefault();
        }
        else if (e.key === 'Escape') {
            setMenuOpen(false);
            setActiveItem(-1);
        }
    }

    // set focus appropriately - i.e. not on phone on initial arrival (i.e. don't show onscreen keyboard when 
    // they just arrived here, they have to click the search bar)
    // FYI showSearchOnPhone is set to 'SHOW_BUT_NO_FOCUS' initially; 'true' indicates a click on the search toolbar button.
    // timeout is to avoid visual blip of cursor when search bar slides in.
    useEffect(() => {
        const t = setTimeout(() => {
            if (!isTabletOrPhone() || showSearchOnPhone === true) inputElt.current.focus();
        }, 10);
        return function tidyup() { clearTimeout(t) }
    }, [showSearchOnPhone]);


    return (

        <div
            id='search-bar-input'
            className="rbt has-aux"
            tabIndex="-1"
        >
            <input
                ref={inputElt}
                className="rbt-input-main form-control rbt-input"
                autoComplete="off"
                placeholder={placeholder}
                type="text"
                aria-autocomplete="both"
                aria-expanded="false"
                aria-haspopup="listbox"
                aria-controls='menu-options'
                role="combobox"
                value={activeItem > -1 ? suggestions[activeItem].label : search.text}
                // autoFocus={!isTabletOrPhone() || showSearchOnPhone === true}
                onClick={() => { if (suggestions.length) setMenuOpen(true) }}
                onBlur={() => { setMenuOpen(false) }}
                onChange={e => {
                    setSearch({ text: e.target.value });
                    setActiveItem(-1);
                    setMenuOpen(true);
                }}
                onKeyDown={handleKeyDown}
            />
            {!!search.text &&
                <div className="rbt-aux">
                    <button
                        type="button"
                        aria-label="Clear"
                        className="close rbt-close"
                        tabIndex={-1}
                        onClick={() => {
                            setSearch({ text: '' });
                            setActiveItem(-1);
                            setMenuOpen(false);
                            inputElt.current.focus();
                        }}
                    >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Clear</span>
                    </button>
                </div>
            }
            <div className='rbt-embed-left'>
                <button
                    type="submit"
                    tabIndex={-1}
                    onClick={handleSubmit}
                >
                    <SearchIcon style={{ verticalAlign: '-10%' }} />
                </button>
            </div>
            <div className='rbt-embed-right'>
                <button
                    type='button'
                    onClick={e => toggleMenu()}
                    onMouseDown={e => e.preventDefault()}     // Prevent input from losing focus.
                    tabIndex={-1}
                >
                    {menuOpen ? <CaretUpIcon style={{ verticalAlign: '0%' }} /> : <CaretDownIcon />}
                </button>
            </div>
            {menuOpen &&
                <div
                    aria-label="menu-options"
                    className="rbt-menu dropdown-menu show"
                    role="listbox"
                >
                    {suggestions[0] ? suggestions
                        .map((suggestion, index) => {
                            return (
                                suggestion.menuHeader ?
                                    <React.Fragment key={suggestion.menuHeader}>
                                        <Menu.Divider />
                                        <Menu.Header>{suggestion.menuHeader}</Menu.Header>
                                    </React.Fragment>
                                    : (
                                        <MenuItem               // ??? no doubt I should make my own
                                            key={index}
                                            option={suggestion.label}
                                            disabled={suggestion.disabled}
                                            position={index}
                                            active={index === activeItem}
                                            className={index === activeItem ? 'active' : ''}
                                            // className={'condensed ' + (index === activeItem ? 'active' : '')}
                                            onClick={e => {
                                                // console.log('414', e.currentTarget.value);  // ??? tried to define this outside, but no 'value' so awkward..
                                                setSearch({ tokens: [], text: suggestion.label });
                                                setMenuOpen(false);
                                                setActiveItem(-1);
                                                handleSubmit(e, suggestion.label);
                                            }}
                                        >
                                            {/* {suggestion.type === 'example' ? <ExampleIcon className='icon-menu-item' /> : <SpacerIcon />} 
                                            // lightbulbs look crap - but might put them back when I do icons for previous searches..
                                            */}
                                            <span>{suggestion.label}</span>
                                            <span className='suggestion-desc'>{suggestion.description}</span>
                                            {/* <span className='condensed' style={{ color: '#999', fontSize: '8pt', marginLeft: '2pt' }}>
                                                {suggestion.debug}
                                            </span> */}
                                        </MenuItem>
                                    )
                            );
                        })
                        : (
                            <MenuItem disabled option={''}>
                                No suggestions
                            </MenuItem>
                        )
                    }
                </div>
            }
        </div>


    )
}


export default InputWithSuggestions;
