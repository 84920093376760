import notation, { element } from './NotationConsts';

const {
    VF_STEP,
    CLEF_WIDTH,
    NOTE_SPACE_MIN,
    NOTE_SPACE_MIN_PRINT,
    NOTE_SPACE_MAX,
    NOTE_SPACE_MEDIUM,
    NOTE_SPACE_WW_FINGERINGS,
    NOTE_SPACE_WEIGHTED_THRESHOLD,
    NOTE_ALTERED_WEIGHT,
    ACCIDENTAL_SPACE_X,
    CLEF_CHANGE_SPACE_X,
    LAST_NOTE_BREATHING_SPACE,
    VF_ZOOM,
    VF_TOPLINE_OFFSET,
    CSS_ZOOM,
    CSS_ZOOM_PRINT,
    CSS_ZOOM_PRINT_FOR_LONG_SCALE,
    LONG_SCALE_LENGTH_THRESHOLD,
    AVAILABLE_WIDTH_PRINT_PX,
    MAIN_MARGIN_WIDTH_PX,
    EXTRA_SPACE_BELOW_IN_STEPS,
    BRACE_WIDTH,
    GRAND_STAFF_LOWER_STAFF_OFFSET,
    NOTEHEAD_WIDTH,
    NOTE_CLICK_AREA_WIDTH,
    NOTE_HOVER_WIDTH,
} = notation;

const STEM_UP = 1, STEM_DOWN = -1;  // vexflow constants (but not exported)
const STEM_HEIGHT_VF = element.STEM.height * VF_STEP;
const CLICK_AREA_SPACE_NOTEHEAD = STEM_HEIGHT_VF * 0.85,
    CLICK_AREA_SPACE_STEM = STEM_HEIGHT_VF * 1.5;


// ??? This is currently not used! But could be - see 000189
// export const scaleContainerHeight = ({ tonicPC, octave, range, clef }) => {
//     const tonicYInSteps = stepsAboveMiddleLine({ pitchClass: tonicPC, octave, clef }); // ???391 would need to add in octaveShift now!
//     const topNoteYInSteps = tonicYInSteps + range - 1;
//     const scaleLowestPointInSteps = Math.min(
//         tonicYInSteps - element.STEM.height,
//         element.CLEF[clef].bottom
//     );
//     const scaleHighestPointInSteps = Math.max(
//         topNoteYInSteps + element.STEM.height,
//         element.CLEF[clef].top               // top of clef
//     );

//     const heightInSteps = scaleHighestPointInSteps - scaleLowestPointInSteps;
//     return {
//         inSteps: heightInSteps,
//         inPixels: Math.ceil(heightInSteps * VF_STEP * CSS_ZOOM)
//     };
// }



export const calcNotationMetrics = (scale, scaleStaff2, screenWidth, printing = false, instrument, showFingerings) => {

    const topLineInSteps = 4;  // zero = middle line, so top line note is 4

    function roundToPixel(numToRound) {
        // Ensures that all note stems will be uniform in terms of their horizontal placement.
        // If not the aliasing causes some to appear thicker than others.
        const numToRoundTo = CSS_ZOOM;
        return Math.round(numToRound * numToRoundTo) / numToRoundTo;
    }

    // console.log('calcNotationMetrics', scale.tonicPC, scale.renderedType)

    const isGrandStaff = !!scaleStaff2;
    const braceWidth = isGrandStaff ? BRACE_WIDTH : 0;
    const adjustForFingeringDiagrams = showFingerings && instrument.family === 'Woodwind' && instrument.fingering;

    let noteSpaceMin = NOTE_SPACE_MIN;
    let availableWidthForSystemVF = (screenWidth - MAIN_MARGIN_WIDTH_PX * 2) / VF_ZOOM / CSS_ZOOM;

    let cssZoomPrint = CSS_ZOOM_PRINT;
    const scaleLength = scale.notes.filter(n => !n.ignoreForStaffWidth).length;

    if (printing) {
        if (scaleLength >= LONG_SCALE_LENGTH_THRESHOLD) cssZoomPrint = CSS_ZOOM_PRINT_FOR_LONG_SCALE;  // shrink long scales for print
        noteSpaceMin = NOTE_SPACE_MIN_PRINT;
        availableWidthForSystemVF = AVAILABLE_WIDTH_PRINT_PX / cssZoomPrint;
    }

    let noteSpaceX;
    if (adjustForFingeringDiagrams) {
        noteSpaceX = instrument.noteSpaceForFingerings || NOTE_SPACE_WW_FINGERINGS;
    }
    else {
        // Work out spacing - squash notes together a bit to make them fit, but not too much.
        // Nb. doesn't take account of extra space for first accidental, if any - that's done below for noteX.
        noteSpaceX = (availableWidthForSystemVF - braceWidth - CLEF_WIDTH) / scaleLength;
        if (noteSpaceX < noteSpaceMin) noteSpaceX = NOTE_SPACE_MEDIUM;    // relax if too squashed (and allow scroll)
        else if (noteSpaceX > NOTE_SPACE_MAX) noteSpaceX = NOTE_SPACE_MAX;  // everything fits with normal spacing
    }

    const vexflow = {
        staff: {
            width: roundToPixel(CLEF_WIDTH + noteSpaceX * scaleLength),
            height: roundToPixel(scale.heightInSteps * VF_STEP),
            x: braceWidth,
            y: VF_TOPLINE_OFFSET + (scale.top - topLineInSteps) * VF_STEP - 0.5,
            lineWidth: printing ? 1 : 1 / CSS_ZOOM,
            cssZoomPrint
        }
    };

    if (isGrandStaff) {
        vexflow.staff2 = {
            width: vexflow.staff.width,
            height: roundToPixel(scaleStaff2.heightInSteps * VF_STEP),
            x: vexflow.staff.x,
            y: vexflow.staff.y + GRAND_STAFF_LOWER_STAFF_OFFSET,
        };
    }

    const systemHeight = isGrandStaff ?
        (scale.top - scaleStaff2.bottom + EXTRA_SPACE_BELOW_IN_STEPS) * VF_STEP + GRAND_STAFF_LOWER_STAFF_OFFSET
        : (scale.top - scale.bottom + EXTRA_SPACE_BELOW_IN_STEPS) * VF_STEP;

    vexflow.system = {
        x: 0,
        width: braceWidth + CLEF_WIDTH + noteSpaceX * scaleLength + 1,  // + 1 to avoid slight clipping of end barline (rounding error somewhere?)
        height: systemHeight,
    };

    const stavesHeight = isGrandStaff ?
        8 * VF_STEP + GRAND_STAFF_LOWER_STAFF_OFFSET + 1
        : 8 * VF_STEP + 1;

    // vexflow-div is scaled down on screen using CSS - but then the containing element doesn't shrink to fit.
    // Therefore added a container div so can set the height and width exactly right (i.e. taking
    // account of the CSS scaling).
    const screen = {
        container: {
            width: Math.ceil(vexflow.system.width * CSS_ZOOM),
            height: Math.ceil(vexflow.system.height * CSS_ZOOM)
        },
        system: {
            top: (vexflow.staff.y - VF_TOPLINE_OFFSET) * CSS_ZOOM,
            height: stavesHeight * CSS_ZOOM,
        }
    }
    const print = {
        container: {
            width: Math.ceil(vexflow.system.width * cssZoomPrint),
            height: Math.ceil(vexflow.system.height * cssZoomPrint)
        }
    }

    // console.log('grand', vexflow, screen);

    vexflow.notes = [];
    vexflow.notesStaff2 = [];
    screen.notes = [];
    screen.notesStaff2 = [];
    print.notes = [];
    print.notesStaff2 = [];

    // I.e. noteSpaceX above is to calc width of staff, this is for actual position of notes.
    // Difference is merely if the first note has an accidental - but I think this is fine.
    // I.e. I want one octave scales to have exactly same staff width, 
    // and I want space between clef and first 'thing' (notehead or accidental) to be fixed.
    // E.g. see tpt grade 2 Bb maj and D maj.

    // screen.notes.x and y are positions in real pixel terms (for note halos).
    // vexflow.notes.x is the x position in vexflow's terms (don't need y: vexflow handles that).
    // Difference is that zero x in vexflow is a nicely positioned note after the clef, so for screen we have to 
    // add clef width.

    // Now I'm doing the scaling (CSS_ZOOM) here too.

    // Horizontal adjustments so that stems look elegant (from trial and error)
    // NB. these values are good on my monitor at CSS_ZOOM = 0.8 - but I suspect they should be
    // somehow calculated *from* the zoom amount, if I ever allow that to change..
    const adjustX = {
        [STEM_UP]: 0.2,
        [STEM_DOWN]: 0.6
    };
    let noteX = scale.notes[0].alteration === '' ? 0 : ACCIDENTAL_SPACE_X;
    if (adjustForFingeringDiagrams) noteX += noteSpaceX / 4;

    const numClefChanges = scale.notes.filter((n, i) => n.clefChange || scaleStaff2?.notes[i].clefChange).length;
    // ???324 perhaps default scaleStaff2 to {notes: []}

    const availableSpace = vexflow.staff.width - CLEF_WIDTH - LAST_NOTE_BREATHING_SPACE - noteX
        - CLEF_CHANGE_SPACE_X * numClefChanges
        ;

    const noteSpacing = availableSpace / scale.notes.length;
    // console.log('grand-clef', scale.notationElements, scaleStaff2.notationElements);

    const noteWidthWeights = scale.notes.map(note => noteSpacing < NOTE_SPACE_WEIGHTED_THRESHOLD && note.alteration ? NOTE_ALTERED_WEIGHT : 1);
    // ???324 ???395 if grand staff lower scale notes are different from upper scale (i.e. not just an octave transposition)
    // then I'll need to amend this to take in to account *either* top or bottom note having an accidental i guess

    // const noteWidthWeights = scale.notes.map(note => {
    //     let weight = (noteSpacing < NOTE_SPACE_WEIGHTED_THRESHOLD && note.alteration) ? NOTE_ALTERED_WEIGHT : 1;
    //     weight *= (1 / note.durationSanitized) * 8;
    //     return weight;
    // });

    const totalWeights = noteWidthWeights.reduce((a, b) => a + b);

    scale.notes.forEach((note, i) => {

        const vfStaveNote = note.vfStaveNote;
        const noteStaff2 = scaleStaff2?.notes[i];

        noteX += (note.clefChange || !!noteStaff2?.clefChange) ? CLEF_CHANGE_SPACE_X : 0;

        vexflow.notes.push({
            x: roundToPixel(noteX) + adjustX[vfStaveNote.getStemDirection()]
        });

        const noteMetrics = metricsForNote({ note, noteX, isTopStaff: true, isBottomStaff: !isGrandStaff });
        screen.notes.push(noteMetrics.screen);
        print.notes.push(noteMetrics.print);

        if (isGrandStaff) {
            vexflow.notesStaff2.push({
                x: roundToPixel(noteX) + adjustX[noteStaff2.vfStaveNote.getStemDirection()]
            });
            const noteStaff2Metrics = metricsForNote({ note: scaleStaff2.notes[i], noteX, isTopStaff: false, isBottomStaff: true });
            screen.notesStaff2.push(noteStaff2Metrics.screen);
            print.notesStaff2.push(noteStaff2Metrics.print);
        }

        noteX += availableSpace * noteWidthWeights[i + 1] / totalWeights;  // allow extra room for next note if it has an accidental
    });

    function metricsForNote({ note, noteX, isTopStaff, isBottomStaff }) {
        // noteX is the x coord in vexflow terms
        // (i.e. like pixels, but not including clef/brace offset, nor zoom)
        // I'll do all the calcs in these units and apply zoom at the last step..

        const x = (noteX + NOTEHEAD_WIDTH / 2 + braceWidth + CLEF_WIDTH);
        const middleLineY = isTopStaff ? scale.top * VF_STEP : scale.top * VF_STEP + GRAND_STAFF_LOWER_STAFF_OFFSET;
        const y = middleLineY - note.stepsAboveMiddleLine * VF_STEP;

        const stemDirection = note.vfStaveNote.getStemDirection();

        // Click area vertical:
        // - single staff: from very top to very bottom
        // - top staff: from very top to a bit under the note (whether stem up or down)
        // - bottom staff: from a bit above the note to very bottom.
        const clickAreaTop = isTopStaff          // i.e. top staff of grand staff OR single staff
            ? 0        // very top
            : y - (stemDirection === STEM_UP ? CLICK_AREA_SPACE_STEM : CLICK_AREA_SPACE_NOTEHEAD)  // for bottom staff: note y minus a bit
            ;
        const clickAreaBottom = isBottomStaff    // i.e. bottom staff OR single staff
            ? 200           // entire vertical space (more than)
            : y + (stemDirection === STEM_UP ? CLICK_AREA_SPACE_NOTEHEAD : CLICK_AREA_SPACE_STEM)  // for top staff: note y plus a bit
            ;

        const screen = {
            x: x * CSS_ZOOM,
            y: y * CSS_ZOOM,
            clickArea: {
                left: (x - NOTE_CLICK_AREA_WIDTH / 2) * CSS_ZOOM,
                top: clickAreaTop * CSS_ZOOM,
                width: NOTE_CLICK_AREA_WIDTH * CSS_ZOOM,
                height: (clickAreaBottom - clickAreaTop) * CSS_ZOOM,
            },
            hoverHighlight: {
                left: (NOTE_CLICK_AREA_WIDTH - NOTE_HOVER_WIDTH) / 2 * CSS_ZOOM,
                top: (y - NOTE_HOVER_WIDTH / 2 - clickAreaTop) * CSS_ZOOM,
                height: NOTE_HOVER_WIDTH * CSS_ZOOM,
                width: NOTE_HOVER_WIDTH * CSS_ZOOM
            },
        };

        const print = {
            x: x * cssZoomPrint,
            y: y * cssZoomPrint
        };

        return { screen, print };
    }



    return { vexflow, screen, print };
}
