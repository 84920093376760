import React from 'react';


function UnderConstruction() {

  return (
    <div className={`wip-container stripy-border`}>
      <div className='stripy-border-inside'>
        <div className='wip-content'>
          <span className='mr-2' role='img' aria-label='warning'>⚠️</span>This application is a work in progress!
        </div>
      </div>
    </div>
  );
}

export default UnderConstruction;
