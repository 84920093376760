import { spellSimple, spellForDirection } from "./noteUtils";

export const CIRCLE_5THS = [
    "Fbb", "Cbb", "Gbb", "Dbb", "Abb", "Ebb", "Bbb",
    "Fb", "Cb", "Gb", "Db", "Ab", "Eb", "Bb",
    "F", "C", "G", "D", "A", "E", "B",
    "F#", "C#", "G#", "D#", "A#", "E#", "B#",
    'F##', 'C##', 'G##', 'D##', 'A##', 'E##', 'B##'
];


const indexOfC = CIRCLE_5THS.indexOf('C');

export const circle5thsSlice = (fromPitch, toPitchOrHowMany) => {
    // Pass in from pitch 
    // and 'to', which can either be another pitch, or can be a number - as in how many pitches to return.

    const fromIndex = CIRCLE_5THS.indexOf(fromPitch);
    const toIndex = typeof toPitchOrHowMany === 'number'
        ? fromIndex + toPitchOrHowMany
        : CIRCLE_5THS.indexOf(toPitchOrHowMany) + 1;
    return CIRCLE_5THS.slice(fromIndex, toIndex);
}


// not CIRCLE_5THS array index, but adjusted so that C is index zero.
// I.e.: C=0, G=1,.. F#=6, Gb=-6,..., F=-1
export const getPitchByCircleIndex = circleIndex => {
    let i = indexOfC + circleIndex;
    if (i < 0 || i >= CIRCLE_5THS.length) {
        console.warn(`Note out of range of CIRCLE_5THS (circle index: ${circleIndex}, array index: ${i})`);
        i += i < 0 ? 12 : -12;
    }
    return CIRCLE_5THS[i];
}

// returns 'C @ zero'-based index, not the straightahead array index!
export const getCircleIndex = pitchClass => {
    const i = CIRCLE_5THS.indexOf(pitchClass);
    if (i < 0) throw new Error(`${pitchClass} is not in CIRCLE_5THS`);
    return i - indexOfC;
}

export const transposeByFifths = (pitchClass, fifths) => (getPitchByCircleIndex(getCircleIndex(pitchClass) + fifths));



// intervals, w.r.t. CIRCLE_5THS
export const INTERVALS = {
    // dU: { interval: -7 },
    PU: { interval: 0 },
    aU: { interval: 7 },
    m2nd: { interval: -5 },
    M2nd: { interval: 2 },
    a2nd: { interval: 9 },
    m3rd: { interval: -3 },
    M3rd: { interval: 4 },
    d4th: { interval: -8 },
    P4th: { interval: -1 },
    a4th: { interval: 6 },
    d5th: { interval: -6 },
    P5th: { interval: 1 },
    a5th: { interval: 8 },
    // d6th: { interval: -11 },
    m6th: { interval: -4 },
    M6th: { interval: 3 },
    d7th: { interval: -9 },
    m7th: { interval: -2 },
    M7th: { interval: 5 },
    P8th: { interval: 0 },
    // chromPassing: { interval: 'CPT', ignoreForChords: true }  // possibly replace this with 'directionify(m2nd)' but whatever.. 000340
};


// returns interval in semitones between 0 and 11. E.g. diminished unison returns 11 not -1.
export const intervalInSemitones = (interval) => {
    if (typeof interval !== 'number') throw new Error(`Unknown interval ${interval} - Pass in a circle of 5ths-type interval number`);
    const isOdd = n => (!!(n % 2));
    return (interval + (isOdd(interval) ? 18 : 12)) % 12;
}


export function simplify(interval) {
    return {
        ...interval,
        spelling: 'SIMPLIFY',
        respell: spellSimple,
    }
}
// ???340 the spelling string is not actually doing anything now - but perhaps it's helpful for tracing bugs?

// Also familiarify? (i.e. fixed set of 12 spellings)

export function directionify(interval) {
    return {
        ...interval,
        spelling: 'DIRECTION',
        respell: spellForDirection,
        // respell: ({ pitchClass, direction }) => {
        //     let res = spellSimple({ pitchClass });
        //     if ((direction === 'ASC' && res[1] === 'b')
        //         || (direction === 'DESC' && res[1] === '#')) {
        //         return enharmonic(res);
        //     }
        //     else return res;
        // }
    }
}

export function ignoreForChords(interval) {
    return {
        ...interval,
        ignoreForChords: true
    }
};

export function makePassing(interval) {
    return ignoreForChords(directionify(interval));
}

// ??? not sure of sensible way to do this - i.e. problem that the asc/desc ones are a level down
// ???340 This is kind of 'almost' obsolete now.. just used in buildScaleList to set up the 'by tonic' groupBy
export const CHROMATIC_SPELLING = {
    familiar: ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "G#", "A", "Bb", "B"],
    // directionBased: {
    //     asc: ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"],
    //     desc: ["C", "B", "Bb", "A", "Ab", "G", "Gb", "F", "E", "Eb", "D", "Db"],
    // }
}



