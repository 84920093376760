import React from 'react';
import { connect } from 'react-redux';
import { setTempo, setShowFingerings, setShowNoteNames, setChromaticBeaming, setInstrumentVariety, setDefaultInstName, setSwing } from '../actions/settings';

import INSTRUMENTS from '../data/instruments';

import OptionControl from './OptionControl';



const SettingsPane = ({ settings, dispatch }) => {

    const {
        defaultInstName,
        // patchDefault,
        tempo,
        swing,
        showNoteNames,
        showFingerings,
        chromaticBeaming,
        instrumentPrefs,
        // fingeringsAllInstances
    } = settings;

    const instOptions = INSTRUMENTS.map(inst => ({
        label: inst.displayName,
        // label: convertFlatSharpNaturalToTypeable(inst.displayName),
        value: inst.name,
        family: inst.family
    }))

    return (
        <div>
            {/* <h2>Settings</h2> */}

            {/* <OptionControl
optionName='Default sound'
currValue={patchDefault}
onClick={(e => dispatch(setPatch(e.currentTarget.value)))}
optionsArray={[
{ label: 'Piano', value: 'acoustic_grand_piano' },
{ label: 'Per Inst.', value: 'EACH_INSTRUMENT' },
]}
/> */}

            <h3>Instrument</h3>

            <div className='sb-setting-box'>
                <OptionControl
                    containerClassName='sb-setting-box'
                    dropdownClassName='sidebar-control'
                    optionName='Default instrument'
                    optionStyle='dropdown'
                    currValue={defaultInstName}
                    onClick={(e => dispatch(setDefaultInstName(e.currentTarget.value)))}
                    optionsArray={instOptions}
                    groupByKey='family'
                />
            </div>
            <div className='option-explanation'>
                Affects clef, scale ranges, and sounding pitch (but not timbre at present: everything sounds like a piano).
            </div>

            <h3>Playback</h3>
            <div className='sb-setting-box'>
                <div className='option-name'>Tempo:
            </div>
                {/* <input
className='mr-1' */}
                {/* // value={tempo.toString()}
// onChange={e => dispatch(setTempo(parseInt(e.currentTarget.value)))}
// ???273 need to validate a bit
// or just drop this as an input..
/> */}
                <input
                    type='range'
                    min='30'
                    max='260'
                    step='1'
                    value={tempo.toString()}
                    onChange={(e => {
                        dispatch(setTempo(parseInt(e.currentTarget.value)));
                        // Hacky solution to get tempo to have an immediate effect. I.e. use a global 
                        // variable, which is initially set (in <Router>) to the store value. 000285
                        window.tempo = e.currentTarget.value;
                    })}
                />
                <span className='ml-2'>{tempo} <small>bpm</small></span>
            </div>

            <OptionControl
                containerClassName='sb-setting-box'
                dropdownClassName='sidebar-control'
                optionStyle='dropdown'
                optionName='Play swing or straight'
                currValue={swing}
                onClick={e => {
                    const valueAsBoolean = e.currentTarget.value === 'true' ? true : false;
                    dispatch(setSwing(valueAsBoolean));
                }}
                optionsArray={[
                    { label: 'Straight', value: false },
                    { label: 'Swing', value: true },
                ]}
            />

            <h3>Notation</h3>
            <OptionControl
                containerClassName='sb-setting-box'
                dropdownClassName='sidebar-control'
                optionStyle='dropdown'
                optionName='Chromatic scales beaming'
                currValue={chromaticBeaming}
                onClick={(e => dispatch(setChromaticBeaming(+e.currentTarget.value)))}
                optionsArray={[
                    { label: 'Triplets', value: 3 },
                    { label: 'Fours', value: 4 },
                ]}
                explanationText='(Scales to 12th are always beamed in groups of four)'
            />

            <h3>Note names & fingerings</h3>
            <div className='option-explanation'>
                If 'On' or 'Available' then note names/fingerings can be individually clicked on or off.
</div>

            <OptionControl
                containerClassName='sb-setting-box'
                dropdownClassName='sidebar-control'
                optionName='Note names'
                optionStyle='dropdown'
                currValue={showNoteNames}
                onClick={(e => dispatch(setShowNoteNames(e.currentTarget.value)))}
                optionsArray={[
                    { label: 'On (show all)', value: 'ALL' },
                    { label: 'Available (click each)', value: 'AVAILABLE' },
                    { label: 'Off (saves vertical space)', value: '' },
                ]}
            />

            <OptionControl
                containerClassName='sb-setting-box'
                dropdownClassName='sidebar-control'
                optionName='Fingerings/Slide pos'
                optionStyle='dropdown'
                currValue={showFingerings}
                onClick={(e => dispatch(setShowFingerings(e.currentTarget.value)))}
                optionsArray={[
                    { label: 'On (show all)', value: 'ALL' },
                    { label: 'Available (click each)', value: 'AVAILABLE' },
                    { label: 'Off (saves vertical space)', value: '' },
                ]}
                explanationText='Fingerings are currently only available for most brass and some woodwind instruments.'
            />

            {/* <OptionControl
optionStyle='dropdown'
optionName='By default, show'
currValue={fingeringsAllInstances}
onClick={(value => dispatch(setFingeringsAllInstances(value)))}
optionsArray={[
{ label: 'Ascending', value: false },
{ label: 'Asc + Desc', value: true },
]}
/> */}

            <h4>Instrument varieties (for fingerings)</h4>

            {INSTRUMENTS.filter(i => i.hasOwnProperty('varieties'))
                .map(inst => (
                    <OptionControl
                        key={inst.name}
                        containerClassName='sb-setting-box'
                        dropdownClassName='sidebar-control'
                        optionStyle='dropdown'
                        optionName={inst.displayName}
                        currValue={(instrumentPrefs[inst.name] || {}).variety || inst.defaultVariety}
                        onClick={(e => dispatch(setInstrumentVariety(
                            { instId: inst.name, varietyId: e.currentTarget.value }
                        )))}
                        optionsArray={Object.entries(inst.varieties).map(([varietyId, varietyProps]) =>
                        ({
                            label: varietyProps.displayName,
                            value: varietyId
                        })
                        )}
                    />
                ))
            }

            {/* can't think of succinct & clear way to describe this next one... */}

            {/* <OptionControl
optionName='Repeat fingerings'
currValue={showFingerings}
onClick={(value => dispatch(setShowFingerings(value)))}
optionsArray={[
{ label: '1st instance', value: false },
{ label: 'All instances', value: true },
]}
/> */}

        </div>

    );
}

const mapStateToProps = (state) => {
    return {
        settings: state.settings
    }
}

export default connect(mapStateToProps)(SettingsPane);

