import { intervalInSemitones, INTERVALS } from './musicStructures';

const { m3rd, M3rd, P4th, d5th, P5th, a5th, M6th, d7th, m7th, M7th } = INTERVALS;


export const CHORDS = {
    // NB. order is important cos first triad found for scale type will be labelled it's 'default' triad
    // (see chordsForScaleType below..)
    // So e.g. for altered we should get aug triad, not diminished one.

    MAJ: {
        displayName: 'Major triad',
        shortName: 'Maj triad',
        structure: [M3rd, P5th],
        class: 'TRIAD'
    },
    AUG: {
        displayName: 'Augmented triad',
        shortName: 'Aug triad',
        structure: [M3rd, a5th],
        class: 'TRIAD'
    },
    MIN: {
        displayName: 'Minor triad',
        shortName: 'Min triad',
        structure: [m3rd, P5th],
        class: 'TRIAD'
    },
    DIM: {
        displayName: 'Diminished triad',
        shortName: 'Dim',
        structure: [m3rd, d5th],
        class: 'TRIAD'
    },
    MAJ7: {
        displayName: 'Major 7th',
        shortName: 'Maj 7',
        structure: [M3rd, P5th, M7th],
        class: '7TH_CHORD'
    },
    DOM7: {
        displayName: 'Dominant 7th',
        shortName: 'Dom 7',
        structure: [M3rd, P5th, m7th],
        class: '7TH_CHORD'
    },
    SUS4: {
        displayName: 'Dom. 7th Sus 4',
        shortName: 'Dom 7 sus',
        structure: [P4th, P5th, m7th],
        mustHaveIntervals: [M3rd],       // stop sus4 being associated with e.g. dorian (cos that feels weird)
    },
    AUG7: {
        displayName: 'Dom. 7th aug.',
        shortName: 'Dom 7 aug',
        structure: [M3rd, a5th, m7th],
        class: '7TH_CHORD'
    },
    AUGMAJ7: {
        displayName: 'Major 7th aug.',
        shortName: 'Maj 7 aug',
        structure: [M3rd, a5th, M7th],
        class: '7TH_CHORD'
    },
    MAJ6: {
        displayName: 'Major 6th',
        shortName: 'Maj 6',
        structure: [M3rd, P5th, M6th]
    },
    MIN7: {
        displayName: 'Minor 7th',
        shortName: 'Min 7',
        structure: [m3rd, P5th, m7th],
        class: '7TH_CHORD'
    },
    MIN6: {
        displayName: 'Minor 6th',
        shortName: 'Min 6',
        structure: [m3rd, P5th, M6th]
    },
    MINMAJ7: {
        displayName: 'Minor (major 7)',
        shortName: 'Min (maj 7)',
        structure: [m3rd, P5th, M7th],
        class: '7TH_CHORD'
    },
    DIM7: {
        displayName: 'Diminished 7th',
        shortName: 'Dim 7',
        structure: [m3rd, d5th, d7th],
        class: '7TH_CHORD'
    },
    HALFDIM: {
        displayName: 'Half-diminished',
        shortName: 'Half-dim',
        structure: [m3rd, d5th, m7th],
        class: '7TH_CHORD'
    },
};

export const chordClasses = [
    { chordType: 'TRIAD', displayName: 'Tonic triads', shortName: 'Triads' },
    { chordType: '7TH_CHORD', displayName: 'Seventh chords', shortName: '7th chords' },
];


export const getChordNames = (chordType) => {
    const chordObj = CHORDS[chordType] || {};

    let res = { displayName: chordObj.displayName, shortName: chordObj.shortName };
    if (!res.displayName) {
        const chordClassObj = chordClasses.find(chordClass => chordClass.chordType === chordType);
        res = { displayName: chordClassObj.displayName, shortName: chordClassObj.shortName };
    }

    return res;
}


export const chordsForScaleType = (scaleFormula) => {

    const scaleIntervalsInSemitones = scaleFormula
        // .filter(i => !i.ignoreForChords)            // can't filter out 'ignore' notes (e.g. passing notes) cos then the indexes get 'out'..
        .map(i => i.ignoreForChords ? null : intervalInSemitones(i.interval));  // ..instead record a 'null' interval
    const validChords = [];

    const scaleDegreeOfInterval = (chordInterval) => {
        let indexOfInterval = scaleIntervalsInSemitones.indexOf(intervalInSemitones(chordInterval.interval));
        return indexOfInterval < 0 ? -1 : indexOfInterval + 2;
    }

    let foundTriad = false, found7thChord = false;

    Object.entries(CHORDS).forEach(([chordId, chord]) => {
        const mustHaveIntervals = chord.mustHaveIntervals || [];
        const scaleDegreesInChord = chord.structure.map(chordInterval => scaleDegreeOfInterval(chordInterval));

        if (scaleDegreesInChord.every(d => d > 0)
            && mustHaveIntervals.every(chordInterval => scaleDegreeOfInterval(chordInterval) > 0)) {

            scaleDegreesInChord.unshift(1);  // scale degree 1 always in chord

            const newChord = {
                chordType: chordId,
                scaleDegrees: scaleDegreesInChord
            };

            if (!foundTriad && chord.class === 'TRIAD') {
                newChord.chordType = 'TRIAD';
                newChord.chordId = chordId;
                foundTriad = true;
            }
            if (!found7thChord && chord.class === '7TH_CHORD') {
                newChord.chordType = '7TH_CHORD';
                newChord.chordId = chordId;
                found7thChord = true;
            }

            validChords.push(newChord);
        }
    })

    return validChords;

}
