import React from 'react';
import { LinkToContactForm } from './ContactPane';
import { SCALE_TYPES } from '../logic/scaleTypesData';
import { convertStringToQueryURL, formatAccidentalsJSX } from '../utils/MusicSymbolsFormatting';
import { Link } from 'react-router-dom';


const ValidScaleTypesPane = ({ setSidebarActiveTab }) => {

    return (

        <React.Fragment>

            {/* <h3>Thank you for checking out this app!</h3>
            <p>It's a work in progress, so some things might not work correctly, and some features are not yet fully implemented (e.g. fingerings are not available for all instruments yet), and there are still plenty of features which I would like to add (see below&hellip;)
            </p> */}

            <p>Below is a list of scale types which are available in this app. (Click any to see them in 12 keys.)</p>

            <ul style={{ listStyleType: 'none' }}>
                {
                    SCALE_TYPES
                        .sort((s, t) => s.displayName.localeCompare(t.displayName))
                        .map(scaleType => (
                            <li key={scaleType.id}>
                                <Link
                                    to={convertStringToQueryURL(`${scaleType.displayName}, all keys`)}
                                >
                                    {formatAccidentalsJSX(scaleType.displayName)}
                                </Link>
                            </li>
                        ))
                }
            </ul>

            <p>This app doesn't aim to include every single conceivable scale type, but only those which are in common use (and only those which are based on the western 12-note chromatic scale).</p>
            <p>But if you think the an important scale type is missing then please <LinkToContactForm setSidebarActiveTab={setSidebarActiveTab}>let me know</LinkToContactForm>.</p>


        </React.Fragment >
    )
}


export default ValidScaleTypesPane;