import React from 'react';
import { MenuList, MenuItem, MenuButton, SubMenuItem } from 'react-menu-list';
// import { Dropdown } from 'react-bootstrap';
import { DropdownItem as DropdownItemRS } from 'reactstrap';
import { Link } from 'react-router-dom';

import { convertStringToQueryURL, formatAccidentalsJSX } from '../utils/MusicSymbolsFormatting';
import { isPhonePortrait } from '../utils/miscUtils';
import { useHistory } from 'react-router-dom';

/*
Quite hacky and specific for 'Related scales'

o Should I package up more abstractly, so I could use for all my dropdowns, rather than reactstrap?
  (i.e. mainly so they will be consistent)

o can I reduce repetition - i.e. menu & submenu? (so it will have to be recursive I suppose..)

o positioning on phone: better, but not ideal; nice if didn't cover 1st menu so much..
   - oh that's only on the lower down submenus..?
  (o ..also would be nice if could tap somewhere to close submenu..?)
  http://127.0.0.1/mantisbt/view.php?id=498

o links crawlable http://127.0.0.1/mantisbt/view.php?id=499
*/


const DropdownRML = ({
    // optionName,
    currValue,
    // onClick,
    optionsArray,
    // groupByKey,
    dropdownToggleDefault = 'Select',
    dropdownToggleDefaultShort,
    // explanationText,
    // optionStyle = 'buttons',
    // containerClassName,
    // dropdownClassName,
    ...otherProps
}) => {

    const currOption = optionsArray.find(e => !e.submenu && !e.searchText && (e.value === currValue)) || {};
    let history = useHistory();

    function MenuItemLink({ option }) {
        return (
            // Use Link so that there will be <a> tags for crawlers.
            // And put *around* the menu item so that clicking anywhere on the item will work (not just the text).
            // But selecting via keyboard won't fire the Link, so also use onItemChosen to push to history.
            <Link to={convertStringToQueryURL(option.searchText || option.label)}>
                <MyMenuItem
                    disabled={option.disabled}
                    onItemChosen={(chosenEvent) => {
                        const { byKeyboard } = chosenEvent;
                        if (byKeyboard) {
                            history.push({
                                pathname: `/search`,
                                search: convertStringToQueryURL(option.searchText || option.label, { includePath: false })
                            });
                        }
                    }}
                >
                    {formatAccidentalsJSX(option.label)}
                </MyMenuItem>
            </Link>
        )
    }



    return (
        <MenuButton
            className='action-dropdown dropdown-toggle btn btn-secondary'
            menuZIndex={200}
            menu={
                <MyDropdownBox
                    data-testid='dropdown-rml'
                >
                    <MenuList>
                        {optionsArray.map((option, i) => {
                            if (option.submenu) {
                                return (
                                    <SubMenuItem
                                        key={`${option.label}-${i}`}
                                        style={{ cursor: 'pointer', userSelect: 'none' }}
                                        menuZIndex={200}
                                        className='dropdown-item dd-item submenu-toggle'
                                        highlightedClassName='rml-item-highlight'
                                        // positionOptions={{ position: 'right', vAlign: 'bottom', hAlign: ['left', 'right'], leftBuffer: -25, rightBuffer: -25 }}
                                        positionOptions={{ position: 'right', vAlign: 'top', hAlign: 'right' }}
                                        menu={
                                            <MyDropdownBox
                                                data-testid={`submenu-${option.label.toLowerCase()}`}
                                            >
                                                <MenuList>
                                                    {option.submenu.map((subOption, j) => {
                                                        if (subOption.divider) {
                                                            return <Divider key={`divider|${i}|${j}`} />
                                                        }
                                                        else if (subOption.searchText) {
                                                            return (
                                                                <MenuItemLink
                                                                    key={`${option.label}|${subOption.label}|${j}`}
                                                                    option={subOption}
                                                                />
                                                            );
                                                        }
                                                        else console.warn('[rel] unhandled (sub)option: ', subOption);
                                                        return null;
                                                    }
                                                    )}
                                                </MenuList>
                                            </MyDropdownBox>
                                        }
                                    >
                                        {formatAccidentalsJSX(option.label)}
                                    </SubMenuItem>
                                );
                            }
                            else if (option.divider) {
                                return <Divider key={`divider|${i}`} />
                            }
                            else if (option.searchText) {
                                return (
                                    <MenuItemLink
                                        key={`${option.label}-${i}`}
                                        option={option}
                                    />
                                )
                            }
                            else console.warn('[rel] unhandled option: ', option);
                            return null;
                        })}

                    </MenuList>
                </MyDropdownBox>
            }
        >
            {formatAccidentalsJSX(
                (isPhonePortrait() ? currOption.shortLabel || currOption.label || dropdownToggleDefaultShort
                    : currOption.label)
                || dropdownToggleDefault
            )}
        </MenuButton>
    );
}





function MyDropdownBox({ children, ...props }) {
    return (
        <div
            className='dropdown-menu show'
            {...props}
        >
            {children}
        </div>
    )
}

function MyMenuItem({ disabled, ...props }) {
    console.log('rel props', props)
    return (
        <MenuItem
            style={{ cursor: 'pointer', userSelect: 'none' }}
            className={`dropdown-item dd-item ${disabled ? 'disabled' : ''}`}
            // className={`dd-item ${(currValue === value ? 'active' : '')}`}
            highlightedClassName='rml-item-highlight'
            {...props}
        />
    );
}

function Divider() {
    return <DropdownItemRS divider />
}


export default DropdownRML;