import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useConstant from 'use-constant';
import debounce from 'lodash/debounce';

import { Form } from 'reactstrap';

import { getSuggestions } from '../logic/parseFreeText';
import InputWithSuggestions from './SearchBarInputWithSuggestions';

const SearchBar = ({ showSearchOnPhone, search, setSearch, handleNewSearch, ...otherProps }) => {

    let history = useHistory();

    const [suggestions, setSuggestions] = useState([]);

    const debouncedParse = useConstant(() => debounce(
        async str => await getSuggestions(str,
            (err, results) => { setSuggestions(results) })
        , 200));

    useEffect(() => {
        debouncedParse(search.text);
        return function cleanup() {
            debouncedParse.cancel();
        }
    }
        , [search.text, debouncedParse]
    );

    // console.log('358 sugg:', suggestions)
    // console.log('358 search state:', search)

    const handleSubmit = (e, suggestion) => {

        const userText = suggestion || search.text;  // use param cos might be called direct from menu item (and inputText state will lag behind)

        if (userText) {
            const queryText = `?q=${encodeURIComponent(userText).replace(/%20/g, '+')}`;
            history.push({
                pathname: `/search`,
                search: queryText
            });
            handleNewSearch();
        }

        e.preventDefault();
    };


    return (
        <React.Fragment>
            <Form className='search-bar' onSubmit={handleSubmit} data-testid='search-bar-form'>

                <InputWithSuggestions
                    showSearchOnPhone={showSearchOnPhone}
                    search={search}
                    setSearch={setSearch}
                    suggestions={suggestions}
                    handleSubmit={handleSubmit}
                    {...otherProps}
                />

                {/* <SearchBarRBT
                    search={search}
                    setSearch={setSearch}
                    handleSubmit={handleSubmit}
                /> */}

            </Form>

        </React.Fragment >
    )
}


export default SearchBar;
