

export const imagesPath = (string, folder = 'http://e.angusmoncrieff.com/scale-lookup-img/') => {
    const res = folder
        // + upperFirst(string.toLowerCase())
        + string
            .replace(/[()]/g, '')
            .replace(/#/g, 's')
            .replace(/ /g, '-')
            .replace(/,/g, '')
        + '.png'
        ;
    // console.log(res);
    return res;
}
