export const setPatch = (patch = '') => ({
    type: 'SET_DEFAULT_PATCH',
    patchDefault: patch
});

export const setTempo = (tempo = 80) => ({
    type: 'SET_TEMPO',
    tempo
});

export const setSwing = (swing = true) => ({
    type: 'SET_SWING',
    swing
});

export const setShowFingerings = (showFingerings = true) => ({
    type: 'SET_SHOW_FINGERINGS',
    showFingerings
});

export const setShowNoteNames = (showNoteNames = true) => ({
    type: 'SET_SHOW_NOTE_NAMES',
    showNoteNames
});

export const setFingeringsAllInstances = (fingeringsAllInstances = false) => ({
    type: 'SET_FINGERINGS_ALL_INSTANCES',
    fingeringsAllInstances
});

export const setChromaticBeaming = (chromaticBeaming = 4) => ({
    type: 'SET_CHROMATIC_BEAMING',
    chromaticBeaming
});

export const setInstrumentVariety = ({ instId, varietyId }) => ({
    type: 'SET_INSTRUMENT_VARIETY',
    instId,
    varietyId
});

export const setGroupBy = (groupBy = 'TYPE') => ({
    type: 'SET_GROUP_BY',
    groupBy
});

export const setGroupByForModes = (groupByForModes = 'MODES_OF') => ({
    type: 'SET_GROUP_BY_FOR_MODES',
    groupByForModes
});

export const setSortScalesBy = (sortScalesBy = 'CIRCLE') => ({
    type: 'SET_SORT_SCALES_BY',
    sortScalesBy
});

export const setDefaultInstName = (defaultInstName) => ({
    type: 'SET_DEFAULT_INSTRUMENT',
    defaultInstName
});

export const setShowGrid = (showGrid) => ({
    type: 'SET_SHOW_GRID',
    showGrid
});

export const setNextPrimaryTip = (nextPrimaryTip) => ({
    type: 'SET_NEXT_PRIMARY_TIP',
    nextPrimaryTip
})