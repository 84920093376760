import { CHROMATIC_SPELLING } from './musicStructures';
import { scaleTypeObject } from './scaleTypes';
import { sortByKeyChrom, modesOf, flippedTonic } from './scaleUtils';
import { lowestOctaveForInstrument, highestOctaveBelowStaff, enharmonic, midiPitch, chromaticIndex } from './noteUtils';

import GRADES from '../data/Grades';
import GRADE_DATA from '../data/GradeData';
import { getPreferredOctave } from '../data/Ranges';
import { ScriptLabels } from '../data/ScriptConsts';
import { scaleTypeSets } from '../logic/scaleTypeSets';
import { pitchClassDisplayString } from '../utils/MusicSymbolsFormatting';
import parseSearchParams from './parseSearchParams';
import { uniqueItems } from '../utils/miscUtils';
import { Scale } from './scale';

import { color } from '../utils/DevUtils';

export const buildScaleList = (searchText, defaultInstName, setShowSettings) => {

    console.log(...color('orange', 'buildScaleList'));
    /*
    gradeHeader is built up 'as we go along', depending on when it's convenient to grab what we need.

    gradeHeader shape:

        {
            MIN: { subtypes: ['HAR', 'MEL'] },  // from GRADE_DATA if it's a grade
            MAJ: { allKeys: true },
            groupBys: {
                TYPE: {},
                TONIC: {},
                MODES_OF: {}
            }
        }

    // @@@??? NEED TO IMPLEMENT FOLLOWING
    // maybe a search token like 'All scale types for ABRSM exams'

    if (grade === ALL_SCALES) {

        // Populate list with scales from all basetypes and all preferred tonics.

        gradeHeader[ScaleType.MIN] = { subtypes: [ScaleType.NAT, ScaleType.HAR, ScaleType.MEL] };

        for (const basetype of Object.keys(SCALE_DEFINITIONS)
            .filter(bt => syllabus.ABRSM.includes(bt))  // A temp measure so that the 'ALL SCALES' button doesn't show e.g. major blues scales, but just the ones relevant to the syllabus.
        ) {
            for (const tonic of scaleTypeObject(basetype).tonics.preferred) {
                const startOctaveInfo = startOctave(tonic, 'RANGE_OF_INSTRUMENT');
                addScalesToList({
                    tonic,
                    ...startOctaveInfo,
                    type: basetype,
                    range: 8
                })
            }

            gradeHeader[basetype] = {
                ...gradeHeader[basetype],
                allKeys: true
            };
        }

    }



    */

    let gradeHeader = {}, scalesToDoList = [], scales = [], instrument = {}, grade, search = { tokens: [], text: '' }, searchFeedback = '';

    ({ scalesToDoList, gradeHeader, grade, instrument, search, searchFeedback } = parseSearchParams({ searchText, gradeHeader, defaultInstName, setShowSettings }));

    // console.log('scalesToDoList', scalesToDoList, gradeHeaderV1);

    ({ scales, gradeHeader } = processToDoList({ scalesToDoList, gradeHeader, instrument }));

    // console.log('scales, gradeheader', scales, gradeHeader, instrument);
    // console.log('search ', search)

    return { scales, gradeHeader, grade, instrument, search, searchFeedback };

}


const processToDoList = ({ scalesToDoList, gradeHeader: gradeHeaderIn, instrument, chromaticSpelling = 'familiar', includeDescending = true }) => {

    let gradeHeader = { ...gradeHeaderIn };

    const startOctave = (tonicPC, startOctaveRule) => {

        // All of these first rules are for grades!
        if (!startOctaveRule) {                 // no special instructions
            return { octave: lowestOctaveForInstrument(tonicPC, instrument, 'abrsm') };
        }
        else if (startOctaveRule === ScriptLabels.ONE_ABOVE_LOWEST) {
            return { octave: lowestOctaveForInstrument(tonicPC, instrument, 'abrsm') + 1 };
        }
        else if (startOctaveRule === ScriptLabels.BELOW_STAFF) {
            return { octave: highestOctaveBelowStaff(tonicPC, instrument.clef) }
        }
        else if (startOctaveRule === ScriptLabels.BOTH_UPPER_LOWER) {
            const lowestOctave = lowestOctaveForInstrument(tonicPC, instrument, 'abrsm');
            const message = 'To be learned starting on either octave.';
            return { octave: lowestOctave, validOctaves: [lowestOctave, lowestOctave + 1], message };
        }

        // Following case is currently the 'main' one, which applies to non-grade queries
        else if (startOctaveRule === 'RANGE_OF_INSTRUMENT') {
            return {
                octave: getPreferredOctave({ instrument, tonicPC })
            }
        }
        else
            throw new Error("Unknown startOctave type: " + startOctaveRule);
    }

    const createScaleId = (tonicPC, octave, basetype) => {
        return `${midiPitch({ pitchClass: tonicPC, octave })}-${basetype.toLowerCase()}`;
    }

    const addScalesToList = ({ tonic, startOctave: startOctaveRule, message, type: typeParam, modesOfFlag, range }) => {

        const { octave, validOctaves } = startOctave(tonic, startOctaveRule);

        if (scaleTypeSets.hasOwnProperty(typeParam))
            // it's a set of scale types    
            for (const basetype of scaleTypeSets[typeParam].basetypes) {
                addSingleScale(basetype);
            }
        else if (modesOfFlag) {
            // ??? SO IT WON"T HJANDLE ALL_TYPES AND MODES OF - 
            // fair enough, but must validate properly

            groupByModesOf.groupKeys.push({ tonicPC: tonic, basetype: typeParam });

            console.log('new Scale - flippedBaseScale')
            // For modes set the flipped tonic to the equivalent degree of the flipped base scale.
            // (Else 7th mode of Eb har flips to Ebb instead of C##) 000429
            const flippedBaseTonic = flippedTonic({ tonicPC: tonic, basetype: typeParam });
            const flippedBaseScale = flippedBaseTonic ? new Scale({ tonicPC: flippedBaseTonic, basetype: typeParam }) : undefined;

            const modes = modesOf({ tonicPC: tonic, basetype: typeParam, octave });

            modes.forEach((mode, i) => {
                const { octave, validOctaves } = startOctave(mode.tonicPC, startOctaveRule);
                scales.push({
                    id: createScaleId(mode.tonicPC, mode.octave, mode.basetype),
                    tonicPC: mode.tonicPC,
                    flipTonic: flippedBaseScale ? flippedBaseScale.notes[i].pitchClass : undefined,
                    octave,
                    validOctaves,
                    // message,       
                    basetype: mode.basetype,
                    renderedType: mode.basetype,
                    modeOf: { tonicPC: tonic, basetype: typeParam, modeNum: i + 1 },
                    range,
                    showChord: 'SCALE',
                    chromaticSpelling,
                    includeDescending
                });
            });
        }
        else addSingleScale(typeParam);

        function addSingleScale(basetype) {
            return scales.push({
                id: createScaleId(tonic, octave, basetype),
                tonicPC: tonic,
                flipTonic: flippedTonic({ tonicPC: tonic, basetype }),
                octave,
                validOctaves,   // currently only relevant for grade scales 'from both upper & lower octaves' cf. ???277
                message,
                basetype,
                renderedType: basetype === 'MIN' ? 'HAR' : basetype,
                range: '' + range,
                showChord: 'SCALE',
                chromaticSpelling,
                includeDescending
            });
        }
    }

    let scales = [];

    let groupByType = {
        groupKeys: [],            // list of basetype strings
        addGroupKey: (key) => (key),    // ??? maybe implement this fn so it can ensure correct
        groupId: (key) => `GRP-${key}`,
        groupName: (key) => (scaleTypeObject(key).displayName),
        groupShortName: (key) => (scaleTypeObject(key).shortName),
        message: (key) => (gradeHeader[key]?.message),
        scalesFilter: (key, scaleHeader) => scaleHeader.basetype === key,
        keysGridRow: (key) => scaleTypeObject(key).tonics.preferred.map(tonicPC => ({ id: tonicPC, tonicPC, basetype: key }))
    };
    // keysGridRow is function to generate list of 'potential' scales in the group, even if not all are present
    // each item is a reduced scaleHeader - id (for react keys), tonic and type
    // Somewhat misnamed now that I transposed the grid so that each group is a column, now a row!

    let groupByModesOf;

    if (scalesToDoList.filter(item => item.modesOfFlag === true).length) {
        // set up group by clause
        groupByModesOf = {       // unshift so as to make this the default grouping
            groupKeys: [],            // list of objects: tonicPC + base scale type
            groupId: (key) => `GRP-${key.tonicPC}-${key.basetype}`,
            groupName: (key) => (
                `Modes of ${pitchClassDisplayString(key.tonicPC)} ${scaleTypeObject(key.basetype).displayName}`
            ),
            groupShortName: (key) => (
                `${pitchClassDisplayString(key.tonicPC)} ${scaleTypeObject(key.basetype).abbreviatedName} modes`
            ),
            message: () => (null),
            scalesFilter: (key, scaleHeader) => scaleHeader.modeOf.tonicPC === key.tonicPC && scaleHeader.modeOf.basetype === key.basetype,
            keysGridRow: (key) => { console.log('keysGridRow call to modesOf'); return modesOf(key) }
        };
    }

    let allKeysToDoList = [];
    // console.log('scalesToDoList', scalesToDoList)

    for (let toDoListItem of scalesToDoList) {
        let tonicItem = toDoListItem.tonic;

        if (tonicItem === ScriptLabels.ALL_KEYS) {
            // Record fact that list is to show 'all keys' for this scale type.

            // 000230 ???277
            // Don't bother atm as flip should not be dependent on this (000230)
            // *unless* we're in a grade, and atm grades are switched off anyway. (000277)

            // gradeHeader[toDoListItem.type] = {
            //     ...gradeHeader[toDoListItem.type],
            //     allKeys: true  
            // };

            allKeysToDoList.push(toDoListItem);       // deal with setting up actual scales at the end..
        }

        else if (tonicItem.includes("-")) {                 // it's for a range, like F#-B
            let tonicFrom = tonicItem.split("-")[0];
            let tonicTo = tonicItem.split("-")[1];

            const preferredTonics = scaleTypeObject(toDoListItem.type).tonics.preferred
                .sort((t, u) => sortByKeyChrom(
                    { tonicPC: t, basetype: toDoListItem.type },
                    { tonicPC: u, basetype: toDoListItem.type }
                ));

            let n = preferredTonics.indexOf(tonicFrom);
            if (n < 0) {
                n = preferredTonics.indexOf(enharmonic(tonicFrom));
                if (n < 0) {
                    throw new Error(`'From' pitch(${tonicFrom}) is not in preferred tonics for scale type ${toDoListItem.type} `)
                }
            }
            do {
                const startNote = preferredTonics[n];
                // console.log('RANGE', tonicFrom, n, startNote)

                addScalesToList({             // scale in range like F#-B 
                    ...toDoListItem,
                    tonic: startNote
                })

                n = (n + 1) % 12;
            } while (n !== (preferredTonics.indexOf(tonicTo) + 1) % 12);  // ??? or enharmonic?
        }
        else {                       // It's a specific scale (i.e. a 'normal' one)
            addScalesToList(toDoListItem);
        }
    }

    // Been through to do list, now deal with the 'all other keys' ones
    for (let toDoListItem of allKeysToDoList) {
        const tonics = scales.filter(scale => (scale.basetype === toDoListItem.type))
            .map(scale => scale.tonicPC);

        // make an array of the scale types (might be a set):
        const typesSet = scaleTypeSets.hasOwnProperty(toDoListItem.type) ?
            scaleTypeSets[toDoListItem.type].basetypes
            : [toDoListItem.type];

        for (let basetype of typesSet) {
            for (let tonic of scaleTypeObject(basetype).tonics.preferred) {   // look through list of nicely spelled tonics
                if (!tonics.includes(tonic) && !tonics.includes(enharmonic(tonic))) {
                    addScalesToList({           // An 'all other keys' scale
                        ...toDoListItem,
                        tonic,
                        type: basetype,
                    })
                }
            }
        }
    }

    // Filter out duplicate scales (i.e. same tonic and basetype).
    // They're possible cos you can query 'F major all tonics primary scale types'
    //   - oh that set is not a thing now, but still a good idea to guard against I think.
    // https://stackoverflow.com/a/36744732
    scales = scales.filter((scale, i, self) =>
        i === self.findIndex(t => (
            t.tonicPC === scale.tonicPC && t.basetype === scale.basetype
        ))
    );

    groupByType.groupKeys = uniqueItems(scales.map(s => s.basetype));

    let groupByTonic = {
        // groupKeys: CHROMATIC_SPELLING.familiar.map(   // return all 12 pitches, even if not all are present as tonics of scales in list
        //     (pitchClass1, chromIndex) => ({           // (was for the piano-keys-styling 000256)
        //         chromIndex,
        //         pitchClass1,
        //         pitchClass2: pitchClass1.length > 1 ? enharmonic(pitchClass1) : undefined
        //     })
        // ),
        groupKeys: uniqueItems(scales.map(s => chromaticIndex({ pitchClass: s.tonicPC, ignoreOctave: true })))
            .map(chromIndex => {
                const pitchClass1 = CHROMATIC_SPELLING.familiar[chromIndex];
                return {
                    chromIndex,
                    pitchClass1,
                    pitchClass2: pitchClass1.length > 1 ? enharmonic(pitchClass1) : undefined
                }
            }),
        groupId: (key) => `GRP-${key.chromIndex}`,
        groupName: (key) => (
            `Scales which start on ${pitchClassDisplayString(key.pitchClass1)}`
            + (key.pitchClass2 ? ` or ${pitchClassDisplayString(key.pitchClass2)}` : '')
        ),
        groupShortName: (key) => (
            pitchClassDisplayString(key.pitchClass1)
            + (key.pitchClass2 ? `/${pitchClassDisplayString(key.pitchClass2)}` : '')
        ),
        // groupNavClassName: (key) => (key.pitchClass2 ? 'black-note' : 'white-note'),
        message: () => (null),
        scalesFilter: (key, scaleHeader) => chromaticIndex({ pitchClass: scaleHeader.tonicPC, ignoreOctave: true }) === key.chromIndex,
        keysGridRow: function (key) { return groupByType.groupKeys.map(basetype => ({ id: basetype, tonicPC: key.pitchClass1, basetype })) }
    };

    gradeHeader.groupBys = {
        MODES_OF: groupByModesOf,    // might be undefined if this is not a 'modes of' set
        TYPE: groupByType,
        TONIC: groupByTonic,
    }

    // console.log('scales', scales)

    return { scales, gradeHeader };

};

export const toDoListForGrade = ({ instrument, grade, gradeHeader: gradeHeaderIn }) => {

    const minorMessage = () => {
        const minorRequirement = (GRADE_DATA[instrument.group][grade].minorOptions.requirement);
        switch (minorRequirement) {
            case 'ANY':
                return `For Grade ${grade} learn one type of minor scale for each key.`;
            case 'ALL':
                return `For Grade ${grade} learn both types of minor scale for each key.`;
            default:
                return `Grade ${grade} minor requirement: ${minorRequirement}`;
        }
    }

    let gradeHeader = { ...gradeHeaderIn };

    // set up to do list for the grade
    // i.e. the relevant bit from gradeData, but filtered by subinst

    let listInstrument = instrument.useListOf || instrument.name; // e.g. C Tuba points at Treble clef tuba scales list

    gradeHeader = {
        ...gradeHeader,
        title: instrument.displayNameString || instrument.displayName
            + ': '
            + GRADES.filter(gr => gr.id === parseInt(grade))[0].displayName,
        MIN: {
            subtypes: GRADE_DATA[instrument.group][grade].minorOptions.subtypes,
            message: minorMessage()
        }
    };

    // console.log(`Building scales - inst ${ listInstrument } of group ${ instrument.group } for grade: ${ grade } `);
    const toDoList = GRADE_DATA[instrument.group][grade].scales
        .filter(s =>
            !s.subinst                               // scale is for every inst/subinst so keep it
            || s.subinst === listInstrument          // scale is for *this* subinst so keep it
        );

    return { toDoList, gradeHeader };
}



