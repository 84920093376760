
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useWidthDetector } from '../utils/useWidthDetector';
import { color } from '../utils/DevUtils';

import { sortGroups, sortScalesWithinGroup } from '../logic/scaleUtils';

import GroupHeader from './GroupHeader';
import ScaleCpt from './ScaleCpt';
import FeedbackBar from './FeedbackBar';
import ListHeader from './ListHeader';
import { MetaDecoratorForScales } from './MetaDecorator';


const ScaleList = (props) => {

    const {
        instrument = {},
        grade,
        currGroupBy,
        scaleGroups,
        scaleHeaderList,
        gradeHeader,
        searchFeedback,
        showSearchOnPhone,
        handleScaleAction,
        setShowSettings,
        printing,

        sortScalesBy,
    } = props;

    console.log(...color('hotpink', `ScaleList *** WITH-GROUPS VERSION ***`, scaleHeaderList));
    // useEffect(() => { console.log(...color('hotpink', `ScaleList INITIAL RENDER *** WITH-GROUPS VERSION ***`)) }, []);

    const groupsCount = scaleGroups.filter(gr => gr.scaleHeaders.length).length;

    const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
    const containerRef = useRef()
    useWidthDetector(containerRef, setDimensions);

    console.log(gradeHeader.pageTitle, 'ttt')
    return (
        <div ref={containerRef}>

            <MetaDecoratorForScales
                {...gradeHeader}
                instrument={instrument}
            />


            <FeedbackBar
                searchFeedback={searchFeedback}
                showSearchOnPhone={showSearchOnPhone}
            />
            {!!scaleHeaderList.length &&
                <ListHeader
                    gradeHeader={gradeHeader}
                    instrument={instrument}
                    scaleGroups={scaleGroups}
                    scaleHeaderList={scaleHeaderList}
                    handleScaleAction={handleScaleAction}
                    setShowSettings={setShowSettings}
                    printing={printing}
                />
            }

            {scaleGroups
                .filter(g => (g.scaleHeaders.length > 0))
                .sort((g, h) => sortGroups(g, h, currGroupBy, sortScalesBy))
                .map((group) => {

                    return (
                        <React.Fragment key={group.id}>
                            <GroupHeader
                                group={group}
                                gradeHeader={gradeHeader}
                                instrument={instrument}
                                handleScaleAction={handleScaleAction}
                                groupsCount={groupsCount}
                            />
                            {/* Don't bother rendering scales if width is zero, cos it will render again anyway once the width is calced. */}
                            {!!dimensions.width &&
                                <ul className="scale-list" >
                                    {group.scaleHeaders
                                        .sort((k, l) => sortScalesWithinGroup(k, l, currGroupBy, sortScalesBy))
                                        .map((scaleHeader, index, shList) => {
                                            // console.log(scaleHeader.groupId, scaleGroupsProp)
                                            return (
                                                <li key={`${instrument.name}-${grade}-${scaleHeader.id}`}>
                                                    <ScaleCpt
                                                        instrument={instrument}
                                                        grade={grade}
                                                        gradeHeader={gradeHeader}
                                                        scaleHeader={scaleHeader}
                                                        handleScaleAction={handleScaleAction}
                                                        // numScales={scaleHeadersProp.length}
                                                        indexInList={index}
                                                        availableWidth={dimensions.width}
                                                        printing={printing}
                                                    />
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            }
                        </React.Fragment>
                    )
                }
                )}

            {!printing &&
                <div className="bottom-spacer"
                // Tall empty area at the bottom of the list so that last scale in list can be scooted to the top of the screen via the keys grid.
                />
            }

        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        sortScalesBy: state.settings.sortScalesBy,
    }
}

export default connect(mapStateToProps)(ScaleList);