import React from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '../assets/icons';
import OptionControl from './OptionControl';
import { Control } from './ActionControls';
import { RANGES, isValidForScaleType, getValidOctaves, isRoomForRangeAbove, isRoomForRangeBelow } from '../data/Ranges';
import { unanimousValueForProp } from '../utils/miscUtils';



const RangeControl = ({ rangeOptions, currValue, ...otherProps }) => {

    return (
        rangeOptions.length > 1 ?
            <OptionControl
                optionStyle='dropdown'
                containerClassName='action-box'
                dropdownClassName='action-dropdown'
                dropdownToggleDefault='Range'
                optionsArray={rangeOptions}
                currValue={currValue}
                // onClick={otherProps.onClick}
                {...otherProps}
            />
            : null
    );
}




export const RangeControlSingle = ({ scaleHeader, instrument, ...otherProps }) => {

    const { tonicPC, octave, range: rangeId, basetype } = scaleHeader;

    const rangeOptions = RANGES
        .filter(range => isValidForScaleType(basetype, range))
        .map(range => {
            let option = {
                value: range.id,
                label: range.fullName,
                shortLabel: range.shortName,
                'data-range': range.id,
            };

            // Is range valid? Plus might need to adjust octave if user clicks this range..
            // ???334 oh maybe this is not nec. now I'm doing in Main..?
            const validOctaves = getValidOctaves({ instrument, range, tonicPC });
            if (validOctaves.lowest === undefined) option = null;               // range fits nowhere on inst from this tonic
            else if (validOctaves.lowest > octave) option['data-octave'] = validOctaves.lowest;   // adjust octave up
            else if (validOctaves.highest < octave) option['data-octave'] = validOctaves.highest; // adjust octave down

            return option;
        })
        .filter(opt => !!opt)
        ;

    const range = RANGES.find(r => r.id === rangeId);
    const enableOctaveUpArrow = isRoomForRangeAbove({ instrument, range, tonicPC, octave: octave + 1 }),
        enableOctaveDownArrow = isRoomForRangeBelow({ instrument, range, tonicPC, octave: octave - 1 });

    return (
        <span>
            <RangeControl
                rangeOptions={rangeOptions}
                currValue={scaleHeader.range}
                {...otherProps}
            />
            <div className='inline-nowrap'  // keep up/down controls together
            >
                <Control
                    name='octave-up'
                    disabled={!enableOctaveUpArrow}
                    data-octave={octave + 1}
                    data-testid='octave-up'
                    {...otherProps}
                >
                    <ArrowUpIcon />
                </Control>
                <Control
                    name='octave-down'
                    disabled={!enableOctaveDownArrow}
                    data-octave={octave - 1}
                    data-testid='octave-down'
                    {...otherProps}
                >
                    <ArrowDownIcon />
                </Control >
            </div>
        </span>
    )
}


export const RangeControlMultiple = ({ scaleHeaders, instrument, ...otherProps }) => {

    const currentRange = unanimousValueForProp(scaleHeaders, 'range');

    const rangeOptions = RANGES
        .map(range => {
            let option = {
                value: range.id,
                label: range.fullName,
                shortLabel: range.shortName,
                'data-range': range.id,
            };

            // Make sure the range fits on at least one of the scales,
            // And is valid for the scale type..
            const isValid = scaleHeaders.some(sh => {
                const { tonicPC, basetype } = sh;
                return isValidForScaleType(basetype, range) && getValidOctaves({ instrument, range, tonicPC }).lowest !== undefined;
            });

            if (!isValid) option = null;    // range fits on none of the scaleHeaders

            return option;
        })
        .filter(opt => !!opt)
        ;

    //???334 is there a way to combine with the single?

    return (
        <RangeControl
            rangeOptions={rangeOptions}
            currValue={currentRange}
            {...otherProps}
        />
    );
}

