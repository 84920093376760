import React from 'react';

import { KeysIcon } from '../assets/icons';

const KeysGridToggle = ({
    disabled,
    pressed,
    text = false,
    onClick,
}) => {


    return (
        <button
            id='grid-toggle'
            className={`nav-link ${pressed ? 'pressed' : ''}`}
            disabled={disabled}
            onClick={onClick}
        >
            <KeysIcon />
            {text &&
                <div className='phone-navbar-button-text'>
                    Jump to..
                </div>
            }
        </button>
    );
}

export default KeysGridToggle;
