import React from 'react';
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// import { MoreIcon } from '../assets/icons';
import { MinorOptions } from './ActionControls';
import { RangeControlMultiple } from './RangeControl';
import { ChordControlMultiple } from './ChordControl';

import { scaleTypeObject } from '../logic/scaleTypes';
import { unanimousValueForProp } from '../utils/miscUtils';


// nb. also used for the action bar for the whole list.
const GroupActionsBar = ({ text, gradeHeader, instrument, scaleHeaders, handleScaleAction }) => {

    // const [groupMenuOpen, setGroupMenuOpen] = useState(false);

    const handleChordClick = (event) => {
        // ??? inconsistent handling of group actions
        // i.e. for chords I pick out the correct scale headers here before sending..
        // but for range, it's sorted out in handleScaleAction, in <Main>
        // But nb. range is more complicated, cos it doesn't nec. ignore headers that don't fit (e.g. ask for 2octs then it will deliver 12th if it can)
        const requestedChordType = event.currentTarget.dataset.showChord;
        const scaleHeadersToUpdate = requestedChordType === 'SCALE'   // requested a chord, or scale?
            ? scaleHeaders                                    // SCALE - so all headers
            : scaleHeaders.filter(                                         // chord - so scale headers..
                sh => scaleTypeObject(sh.renderedType).chords              //    ..whose chords
                    .filter(ch => ch.chordType === requestedChordType)[0]  //    ..include requested chord
            );
        handleScaleAction({
            event,
            scaleHeaderIdsToUpdate: scaleHeadersToUpdate.map(sh => sh.id)
        })
    };

    const handleActionClick = (event) => handleScaleAction({
        event,
        scaleHeaderIdsToUpdate: scaleHeaders.map(sh => sh.id)
    });

    // const toggleMenu = () => {
    //     setGroupMenuOpen(prev => !prev);
    // }

    // const groupMenu = (
    //     <Dropdown
    //         isOpen={groupMenuOpen}
    //         toggle={toggleMenu}
    //         id="scale-menu"
    //         tag='span'
    //     >
    //         {/* <div id="prompt-container" /> */}
    //         <DropdownToggle className='scale-menu'>
    //             <MoreIcon />
    //         </DropdownToggle>
    //         <DropdownMenu right>

    //             {group.basetype === ScaleType.MIN &&
    //                 <MinorOptions
    //                     inDropdown
    //                     subtypes={gradeHeader.MIN.subtypes}
    //                     currentType={allMinorSubtype}
    //                     onClick={handleActionClick}
    //                 />
    //             }
    //             {group.basetype === ScaleType.MIN && < DropdownItem divider />}

    //             {basetype.hasArpeggio &&
    //                 <ArpeggioControl
    //                     inDropdown
    //                     currentArpeggio={allArpeggio}
    //                     onClick={handleActionClick}
    //                 />
    //             }

    //         </DropdownMenu>
    //     </Dropdown>
    // )

    // console.log(group.key)

    return (

        <div className='actions-bar print-hide'>
            {text &&
                <div
                    className='actions-bar-text sentence-case hide-if-last' // hide-if-last to suppress text if no controls render
                >
                    {text}
                </div>
            }

            <MinorOptions
                scaleHeaders={scaleHeaders}
                subtypes={gradeHeader.MIN.subtypes}
                renderedType={unanimousValueForProp(scaleHeaders, 'renderedType')}
                onClick={handleActionClick}
            />

            <RangeControlMultiple
                scaleHeaders={scaleHeaders}
                instrument={instrument}
                onClick={handleActionClick}
            />

            <ChordControlMultiple
                scaleHeaders={scaleHeaders}
                onClick={handleChordClick}
            />

            {/* {groupMenu} */}
        </div>
    )

}


export default GroupActionsBar;