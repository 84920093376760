// what's the 'correct' way to set up these colors/emphases?
// I guess it is this..?


const notation = {
    VF_TOPLINE_OFFSET: -39,
    NOTE_SPACE_MIN: 25,           // don't squash any more than this
    NOTE_SPACE_MIN_PRINT: 15,     // 23,
    NOTE_SPACE_MEDIUM: 31,        // if can't squash entire scale in then reverts to this (with a scrollbar)
    NOTE_SPACE_MAX: 35,
    NOTE_SPACE_WW_FINGERINGS: 50, // if woodwind fingerings then this is the spacing regardless
    NOTE_SPACE_WEIGHTED_THRESHOLD: 27.7,   // Notes get equal space usually, but if more squashed than this then notes w. accidentals get more room
    LAST_NOTE_BREATHING_SPACE: 10,
    NOTE_ALTERED_WEIGHT: 1.3,     // width-weight multiplier for notes w accidentals
    NOTE_CLICK_AREA_WIDTH: 35,    // $$$ MIRRORED IN varsNotationConsts.scss!!
    NOTE_HOVER_WIDTH: 28,
    NOTE_HALO_WIDTH: 8,           // $$$ MIRRORED IN varsNotationConsts.scss!!
    NOTE_VIBRATE_WIDTH: 35,
    CLEF_WIDTH: 43,               // measured from svg
    NOTEHEAD_WIDTH: 12,           // svg of notehead appears to be 9.55 wide, but 12 works better (halos, note names etc.) 🤷🏻‍♂️
    BRACE_WIDTH: 15,              // by trial and error 
    GRAND_STAFF_LOWER_STAFF_OFFSET: 90,
    ACCIDENTAL_SPACE_X: 10,
    CLEF_CHANGE_SPACE_X: 20,      // additional space to allow
    CLEF_CHANGE_OFFSET_X: -33,    // X offset from the first note of the new clef
    MAIN_MARGIN_WIDTH_PX: 10,  // space for empty CSS grid cols on L&R of scale NB. MATCHES CSS FILE!
    VF_STEP: 5,             // 'points' per staff step in vexflow units (seems not pixels cos immune to zoom)
    EXTRA_SPACE_BELOW_IN_STEPS: 3,   // extra scale steps to allow below lowest point of scale
    EXTRA_SPACE_ABOVE_IN_STEPS: 1.5,
    VF_ZOOM: 1,             // Zoom applied within vexflow - not convinced VF zooming entirely works correctly; also weird w print
    CSS_ZOOM: 0.8,     // $$$ MIRRORED IN varsNotationConsts.scss!!  ???324
    // CSS_ZOOM: 1,  
    //        Zoom ('scale') applied on-screen via CSS transform: scale() (applied as inline style by VexFlowCpt)
    //        nb. more than one decimal place will not render staff lines crisply (000166)
    AVAILABLE_WIDTH_PRINT_PX: 992,         // for A4 - determined by trial and error (000145)
    CSS_ZOOM_PRINT: 1,                     // Zoom for print
    CSS_ZOOM_PRINT_FOR_LONG_SCALE: 0.85,   // Zoom more when printing long scales
    LONG_SCALE_LENGTH_THRESHOLD: 40,       // # notes when extra zoom starts (on print). (39 notes = chromatic scale to 12th, and that fits ok.)
    TOP_TEXT_POSITION_VF: 0,               // how high 8va line (VF units - not sure what they mean!)
    BOTTOM_TEXT_POSITION_STEPS: -11.5,   // by trial and error! (I think not configurable in vexflow)
    ALPHA_DISABLED: 0.2
}

// For top text (8va line) convert VF value to my own steps above middle line.
// Derived this expression by trial and error on a couple of values, then solved the simultaneous equations.
notation.TOP_TEXT_POSITION_STEPS = (notation.TOP_TEXT_POSITION_VF * 1.85) + 12;

// All following in staff step units
export const element = {
    NOTEHEAD: { height: 2.6 },       // should be 2 I think, but it gets cropped sometimes.. dunno why
    STEM: { height: 7 },
    BEAM: { thickness: 1 },
    CLEF: {
        treble: { top: 6.4, bottom: -7.3 },   // should be -7
        alto: { top: 0, bottom: 0 },
        tenor: { top: 5, bottom: 0 },         // kind of rough but not too important I think
        bass: { top: 0, bottom: 0 },
    },
    ALTERATION: {
        '#': { above: 3, below: 3.4 },  // looks like it should be 3 below, but...
        '##': { above: 0, below: 0 },   // internally as ##, displayed as x so 0, 0
        'b': { above: 3, below: 1.1 },
        'bb': { above: 3, below: 1.1 },
        'n': { above: 0, below: 0 },
    }
}

export default notation;