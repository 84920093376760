import React from 'react';
import { Link } from 'react-router-dom';

const brandImage = require('../assets/scale-lookup.com.svg');
const brandIcon = require('../assets/brandIcon.svg');

const Brand = () => (
    <Link
        to={`/search`}
        className='brand'
    >
        <img
            src={brandImage}
            alt='scale-lookup.com'
            width='121'
            height='19'
        />
    </Link>
);


export const BrandIcon = ({ className = 'brand-icon', width = 25 }) => (
    <img
        className={className}
        src={brandIcon}
        alt='scale-lookup.com'
        width={width}
    />
)

export default Brand;

