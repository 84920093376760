import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import notation from '../notation/NotationConsts';

const {
    NOTE_HALO_WIDTH,
    CSS_ZOOM
} = notation
    ;


const NoteHalo = ({ x, y, className, noteIsDisabled }) => {

    // console.log('Note-Halo props:', x, y, className, noteIsDisabled)
    // useEffect(() => { console.log('Note-Halo initial') }, []);

    const flipFlopClassRef = useRef(0);

    useEffect(() => { flipFlopClassRef.current = (flipFlopClassRef.current + 1) % 2 });  // flip-flop the class each render
    let flipFlopClassName = 'note-halo-' + flipFlopClassRef.current;

    // console.log(flipFlopClassRef.current, flipFlopClassName)

    const haloStyle = {
        left: x - NOTE_HALO_WIDTH / 2 * CSS_ZOOM,
        top: y - NOTE_HALO_WIDTH / 2 * CSS_ZOOM,
    };

    return (
        <div
            className={`${flipFlopClassName} ${className} ${noteIsDisabled ? 'disabled-note' : ''}`}
            style={haloStyle}
        />
    )
}




const NoteHalos = ({ notePositions, halos, disabledNotes }) => {

    // useEffect(() => { console.log('NOTEHALOS (plural) initial') }, []);
    // console.log('<NoteHalos>', halos, notePositions)

    return (
        <div className='halos-container print-hide'>
            {halos.map(({ noteIndex, className }) => {
                const notePosition = notePositions[noteIndex];
                const noteIsDisabled = disabledNotes.includes(noteIndex);
                return (
                    <NoteHalo
                        key={noteIndex}
                        // key={notePosition.x}
                        x={notePosition.x}
                        y={notePosition.y}
                        className={`${className}`}
                        noteIsDisabled={noteIsDisabled}
                    />
                )
            }
            )}
        </div >
    )
}

export default NoteHalos;
