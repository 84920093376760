import { SCALE_TYPES } from '../logic/scaleTypesData';
import INSTRUMENTS from './instruments';
import { CIRCLE_5THS } from '../logic/musicStructures';
import { scaleTypeSets } from '../logic/scaleTypeSets';
import { convertFlatSharpNaturalToTypeable } from "../utils/MusicSymbolsFormatting";

import { getPitchMatches } from '../logic/parseFreeText';
import { uniqueItems } from '../utils/miscUtils';


// Build the list of tokens for the search box - i.e. including tonics, scale types, etc.

/*
It's an array, each item like this:

{ 
    label:        'Major',        // what user types/sees
    tokenType:    'scaletype',    // how it's interpreted
    groupName:    'Scale Types',  // for grouping in dropdown list  // ??? obsolete atm
    value:        'MAJ'           // url-friendly version of label
}

and added subsequently, automatically:

    labelCleaned

*/

// order A B C D E F G, but then by 'likeliness'
// e.g. enter E, it suggests E Eb E#
//      enter C, it suggests C C# Cb
// i.e. for flat and sharp notes, order by distance from 'middle' natural note (D)
const indexOfD = CIRCLE_5THS.indexOf('D');
const tonicsForSearch = CIRCLE_5THS.slice(CIRCLE_5THS.indexOf('Abb'), CIRCLE_5THS.indexOf('C##') + 1)
    .sort((p, q) => (
        (p.length === 1 ? p.charCodeAt() : Math.abs(CIRCLE_5THS.indexOf(p) - indexOfD) * 100)
        - (q.length === 1 ? q.charCodeAt() : + Math.abs(CIRCLE_5THS.indexOf(q) - indexOfD) * 100)
    ));

let searchTokenList = [
    ...tonicsForSearch.map(pitchClass => ({
        label: pitchClass,
        // label: pitchClassDisplayString(pitchClass),
        tokenType: 'tonic',
        groupName: 'Pitches',
        value: pitchClass.replace(/#/g, 'sh'),
        // filterBy: pitchClass       // so that typing 'Bb' will work
    }))
    ,

    {
        label: 'all tonics',
        tokenType: 'tonic',
        groupName: 'Pitches',
        value: 'ALL',
        nameVariations: ['all keys'],
    }
    ,
    ...SCALE_TYPES
        .map((basetype) => ({
            label: convertFlatSharpNaturalToTypeable(basetype.displayName).toLowerCase(),
            description: basetype.tokenDescription,
            tokenType: 'scaletype',
            groupName: 'Scale Types',
            value: basetype.id,
            matchWeight: basetype.matchWeight,
            nameVariations: basetype.nameVariations
        }))
    ,
    ...Object.entries(scaleTypeSets).map(([type, values]) => ({
        label: values.pluralName.toLowerCase(),
        tokenType: 'scaletype',
        groupName: 'Scale Type Sets',
        value: type,
        matchWeight: values.matchWeight,
        nameVariations: values.nameVariations
    }))
    // ,
    // { label: 'All scale types', tokenType: 'scaletype', groupName: 'Scale Types', value: 'ALL' }
    ,
    {
        label: 'modes of',
        tokenType: 'modes',
        groupName: 'Modes',
        value: 'Y',
        nameVariations: ['modes'],
    }
    ,

    // Removed grades from the list till we have ABRSM permission.

    // ...[1, 2, 3, 4, 5, 6, 7, 8].map(gradeNum => ({
    //     label: `Grade ${gradeNum} (ABRSM)`,
    //     tokenType: 'grade',
    //     groupName: 'Grades',
    //     value: gradeNum + '',
    // }))
    // ,
    ...INSTRUMENTS
        .map(instrument => ({
            label: makePitchesUppercase(                  // keep pitches uppercase (e.g. 'trumpet in Bb')
                convertFlatSharpNaturalToTypeable(instrument.displayName.toLowerCase())
            ),
            description: instrument.tokenDesc,
            tokenType: 'instrument',
            groupName: instrument.family,
            value: instrument.name,
            matchWeight: instrument.matchWeight,
            nameVariations: instrument.nameVariations
        }))
];

// Add in name variations
searchTokenList.forEach(baseToken => {
    if (baseToken.nameVariations) {
        baseToken.nameVariations.forEach(variation => {
            searchTokenList.push({
                label: makePitchesUppercase(variation.toLowerCase()),     // assume they won't contain proper flat/sharp chars
                redirectToLabel: baseToken.label,
            });
        });
    }
});

// set up name variations for all ones like 'dorian b2' --> 'dorian flat 2'
const replaceAccidentalSymbol = (label, accidChar, replaceWord) => {
    const regex = new RegExp(` ${accidChar}([\\d]+)\\b`);
    const nameVariation = label.replace(regex, ` ${replaceWord} $1`);
    if (nameVariation !== label) {
        // console.log('replaceAccidental', nameVariation);
        searchTokenList.push({
            label: nameVariation,
            redirectToLabel: label,
        });
    }
}
searchTokenList.filter(t => t.tokenType === 'scaletype')
    .forEach(baseToken => {
        replaceAccidentalSymbol(baseToken.label, 'b', 'flat');
        replaceAccidentalSymbol(baseToken.label, 'n', 'natural');
        replaceAccidentalSymbol(baseToken.label, '#', 'sharp');
    });



searchTokenList = searchTokenList.map(t => ({
    ...t,
    // label: t.label.toLowerCase(),                 
    // redirectToLabel: t.redirectToLabel?.toLowerCase(),
    labelCleaned: t.label
        .replace(/\//g, ' ')       // replace oblique with space
        .replace(/[()]/g, '')      // cleanup (but preserve '#')
        .toLowerCase()
    ,
}))
    ;


// Dump a list of searchTokenLabels:
// for (let t of searchTokenList) {
//     console.log(t.label);
// }
const commasInSearchTokens = searchTokenList.filter(s => { return /,/.test(s.label) });
if (commasInSearchTokens[0]) console.warn('358 commas in search tokens: ', commasInSearchTokens);

const hypensInSearchTokens = searchTokenList.filter(s => { return /-/.test(s.label) });
if (hypensInSearchTokens[0]) console.warn('358 hyphens in search tokens: ', hypensInSearchTokens);

// console.log('358 most words: ',
//     searchTokenList.map(t => (
//         {
//             label: t.label,
//             split: t.label.match(/(\w+)/g),
//         }
//     ))
//         .sort((a, b) => b.split.length - a.split.length)[0]
// );



// Build array of all the words which are in the tokens.
let tokenWordList = [];
searchTokenList.forEach(searchToken => {
    // console.log(searchToken.labelCleaned.split(/[ /\-()]/));
    tokenWordList.push(...searchToken.labelCleaned.split(/[ /\-()]+/));
});
const circle5thLowerCase = CIRCLE_5THS.map(p => p.toLowerCase());
tokenWordList = tokenWordList
    .filter(w => (!circle5thLowerCase.includes(w)))           // remove pitches
    .sort((w, x) => (w.localeCompare(x)))                     // not required, just neater/easier for debugging
    ;
tokenWordList = uniqueItems(tokenWordList);

// Dump a list of tokenWords:
// console.log('match-words', tokenWordList)
// for (let t of tokenWordList) {
//     console.log(t);
// }


// used when parsing the url query: returns the token object corresponding to the value in the URL query.
// ???370 think this might become obsolete
// i.e. parse free text could just returns tokens - no point in coding to a string, then back again!
//
// ??? If this is dropped then might not need to export getPitchMatches

const getSearchTokenFromValue = ({ value, tokenType }) => {

    // console.log('358a', value, tokenType);

    if (!value) return false;

    let res = [];

    if (tokenType === 'tonic' && value !== 'ALL') {
        res = [getPitchMatches(value.replace(/sh/ig, '#'))[0].tokens[0]];  // ???358 will break on dodgy value
        // ???370 at some point # gets coded to 'sh' so replace
        // bit of a sticking plaster solution till I decide way forwards.. 
    }
    else res = searchTokenList.filter(t => (t.value?.toUpperCase() === value.toUpperCase() && t.tokenType === tokenType));
    // t.value might not exist now cos searchTokenList contains 'redirects' - hence ? in t.value?.toUpperCase()

    if (!res) {
        console.warn(`Can't find search token for tokenType: ${tokenType}, value: ${value}`);
        return false;
    }
    else if (res.length > 1) {
        console.warn(`Found multiple tokens for tokenType: ${tokenType}, value: ${value}`);
        return false;
    }
    else return {
        ...res[0],
        ignored: false
    };
}

function makePitchesUppercase(text) {
    const res = text
        .replace(/(\b)c(\b)/, '$1C$2')
        .replace(/(\b)f(\b)/, '$1F$2')
        .replace(/(\b)bb(\b)/, '$1Bb$2')
        .replace(/(\b)eb(\b)/, '$1Eb$2')
        ;
    // if (res !== text) console.log(text, '-> ', res)

    return res;
}



export { searchTokenList, tokenWordList, getSearchTokenFromValue }