import Note from "./note";
import { getPitchByCircleIndex, getCircleIndex } from "./musicStructures";
import { scaleTypeObject } from '../logic/scaleTypes';
import { alter, pitchIndex } from './noteUtils';
import { flippedTonic } from './scaleUtils';
import notation, { element } from '../notation/NotationConsts';

import Vex from 'vexflow';
import { RANGES } from "../data/Ranges";
import { color } from "../utils/DevUtils";


const ASCENDING = 'ASC', DESCENDING = 'DESC', BOTH = 'BOTH_ASC_DESC';
const CLEF_CHANGE_STEPS_THRESHOLD = 11,     // in steps above (or below) middle line. 6 = 1 leger line, 8 = 2 leger lines
    CLEF_CHANGE_STEPS_OPTIONAL = 7,
    OCTAVE_STEPS_THRESHOLD = 11,            // point at which 8va/8vb lines kick in
    OCTAVE_SHIFT_STEPS_OPTIONAL = 8,
    START_IN_HIGHER_CLEF_STEPS = 2,         // threshold for starting scale in higher clef (2 puts G3 on treble staff)
    START_IN_LOWER_CLEF_STEPS = -10,        // sim. (-10 keeps treble clef low F in treble)
    START_8VA_STEPS = 4,                    // threshold for starting scale with an 8va line
    START_8VB_STEPS = -12;
const {
    EXTRA_SPACE_ABOVE_IN_STEPS,
    EXTRA_SPACE_BELOW_IN_STEPS,
    TOP_TEXT_POSITION_STEPS,
    BOTTOM_TEXT_POSITION_STEPS,
} = notation;


// Just the basic stuff, with minimal note objects (e.g. not vexflow stuff)
export class Scale {

    constructor({
        tonicPC,
        octave = 4,        // default for when just want a scale for reference
        basetype = 'MAJ',
        renderedType,
        clef = 'treble',
        duration = '8',
    }) {
        console.time('SIMPLE Scale constructor ' + tonicPC + basetype)

        this.tonicPC = tonicPC;   // nb. this *always* means start note, including for Dom7s (for example).
        if (typeof octave !== 'number') console.warn(`octave parameter should be a number, but it's a ${typeof octave}`);
        this.octave = +octave;
        this.basetype = basetype;
        this.renderedType = renderedType || basetype;
        if (clef === 'grand') throw new Error('Bad clef - for grand staff, call twice, once for each staff');
        this.clef = clef;
        this.duration = duration;

        this.scaleDefinition = scaleTypeObject(this.basetype);
        // console.timeLog('Scale constructor ' + tonicPC + basetype)

        console.log(...color('#999', 'buildBaseScale..'));
        this.buildBaseScale();
        this.notes = this.baseScale;
        console.log(...color('#999', '...buildBaseScale done'));
        console.timeEnd('SIMPLE Scale constructor ' + tonicPC + basetype)
    }

    buildBaseScale() {

        const { tonicPC, octave, clef, basetype, renderedType, duration, scaleDefinition } = this;

        const scaleFormula = scaleDefinition.formula.slice(0, scaleDefinition.formula.length - 1);

        let tonicCircleIndex = getCircleIndex(tonicPC);
        // let tonicCircleIndex = CIRCLE_5THS.indexOf(tonicPC);
        // if (tonicCircleIndex < 0) tonicCircleIndex = CIRCLE_5THS.indexOf(enharmonic(tonicPC));   // didn't find it - try the enharmonic..
        // if (tonicCircleIndex < 0) {
        //     throw new Error(`Can't find tonic: ${tonicPC} in circle of 5ths`);
        // }
        let scaleDegree = 1;

        const tonicData = {
            pitchClass: tonicPC,
            octave,
            clef,
            scaleDegree,
            duration
        };

        let notesAsc = [tonicData],
            notesDesc = [tonicData];

        // console.timeLog('Build notes ' + tonicPC + basetype)

        let prevPitchClass = tonicPC,
            currOctave = octave;

        for (let intervalAboveTonic of scaleFormula) {

            scaleDegree++;
            let newPitchClass, newPitchClassDesc;
            let newNoteCircleIndex = tonicCircleIndex + intervalAboveTonic.interval;
            let newNoteCircleIndexDesc = newNoteCircleIndex;

            // do any respelling before lookup, as it just might repair a triple sharp or flat.
            if (intervalAboveTonic.respell) {
                newNoteCircleIndex = intervalAboveTonic.respell({ circleIndex: newNoteCircleIndex, direction: 'ASC' });
                newNoteCircleIndexDesc = intervalAboveTonic.respell({ circleIndex: newNoteCircleIndexDesc, direction: 'DESC' });
            }
            newPitchClass = getPitchByCircleIndex(newNoteCircleIndex);
            newPitchClassDesc = getPitchByCircleIndex(newNoteCircleIndexDesc);

            // Increase octave if necessary
            if (pitchIndex[newPitchClass[0]] < pitchIndex[prevPitchClass[0]]) currOctave++;

            const noteData = {
                pitchClass: newPitchClass,
                octave: currOctave,
                clef,
                scaleDegree,
                duration,
            };
            notesAsc.push(noteData);

            notesDesc.push({
                ...noteData,
                pitchClass: newPitchClassDesc || newPitchClass,
            });

            prevPitchClass = newPitchClass;
        }

        // Special cases for melodic and harmonic minors as subtypes of MIN..
        if (basetype === 'MIN' && renderedType === 'MEL') {
            notesAsc = notesAsc.map(note => (
                [6, 7].includes(note.scaleDegree) ? alter({ note, alteration: 1 }) : note  // raise all the 6th & 7th degrees
            ));
        }
        if (basetype === 'MIN' && renderedType === 'HAR') {
            notesAsc = notesAsc.map(note => (
                note.scaleDegree === 7 ? alter({ note, alteration: 1 }) : note  // raise 7th degree
            ));
            notesDesc = notesDesc.map(note => (
                note.scaleDegree === 7 ? alter({ note, alteration: 1 }) : note
            ));
        }

        // console.timeEnd('Build notes ' + tonicPC + basetype)
        this.baseScale = notesAsc;
        this.baseScaleDesc = notesDesc;
    }

    stringify() {
        return (this.notes
            .filter((n, i) => !this.disabledNotes.includes(i))   // exclude disabled notes (for chord testing)
            .map(n => `${n.pitchClass.replace(/n$/, '')}${n.octave}`).join())
        //??? can i do a call() to the SPN method of Note?? (i.e. atm this is repetition more or less)
    }

}


// Full scale class, ready for notation..
export class ScaleForNotation extends Scale {

    constructor({
        range = '8',
        highestPC,                  // alternative to range: specify the highest note..
        highestOctave,              // ..but currently broken! ???211
        pattern,                    // not implemented yet! ???397
        showChord = 'SCALE',
        altClefHigh,
        altClefLow,
        chromaticSpelling = 'familiar',
        includeDescending = true,
        highlightNotes,
        chromaticBeaming = 4,
        ...params
    }) {

        console.time('ScaleForNotation constructor ' + params.tonicPC + params.basetype)

        super(params);  // Set up the basic scale (i.e. minimal note objects)
        // Go through and create all the actual note instances
        this.baseScale = this.baseScale.map(n => new Note(n));
        this.baseScaleDesc = this.baseScaleDesc.map(n => new Note(n));

        this.range = range;
        this.highestPC = highestPC;
        this.highestOctave = highestOctave;
        this.pattern = pattern;
        this.altClefHigh = altClefHigh;
        this.altClefLow = altClefLow;
        this.chromaticSpelling = chromaticSpelling;
        this.includeDescending = includeDescending;
        this.showChord = showChord;
        this.highlightNotes = highlightNotes;

        const { tonicPC, basetype, clef } = this;

        console.log(...color((tonicPC === 'Db' ? 'gold' : 'khaki'), `ScaleForNotation constructor ${tonicPC} ${basetype} ${clef})`));

        // console.time('Scale constructor ' + tonicPC + basetype)

        this.notes = this.doRange();
        this.tonicNote = this.notes[0];
        this.confirmNaturals();
        this.setupClefChanges();

        this.bottomNoteIndex = 0;
        // console.timeLog('Scale constructor ' + tonicPC + basetype)
        this.defaultBeamPattern = (this.renderedType === 'CHR' && this.range !== 12) ? [chromaticBeaming] : [4];
        this.vfBeams = [];
        this.highlights = [];
        this.disabledNotes = [];  // store [in]active flag at scale level so it's not lost on (e.g.) a minor type change

        // this.doPattern();
        this.doChord();
        this.createBeamGroups();

        switch (highlightNotes) {
            case 'scale-type':
                this.highlightNewType();
                break;
            case 'enharmonic-flip':
                this.highlightEnharmonicFlip();
                break;
            default:
                break;
        }

        this.markClefChanges();

        console.timeEnd('ScaleForNotation constructor ' + params.tonicPC + params.basetype)

        console.log(...color('#999', '...ScaleForNotation constructor done'));

    }


    doRange() {

        const { baseScale, baseScaleDesc, range: rangeId, includeDescending } = this;

        const range = RANGES.find(r => r.id === rangeId);
        const formula = range.formula;        // ???211 will break if range not set!
        let notes = [];

        // If range has a formula (like 5th above/below) then use that...
        if (formula) {

            for (let step of formula) {
                const { scaleDegree, octaveAdjust = 0, fromDescScale } = step;
                const sourceNote = fromDescScale ? baseScaleDesc[scaleDegree - 1]
                    : baseScale[scaleDegree - 1]
                    ;
                const newNote = new Note({
                    ...sourceNote,
                    octave: sourceNote.octave + octaveAdjust,
                });

                notes.push(newNote);
            }
        }

        // or if no formula then it's a straight-ahead range..
        else {

            // extend scale if more than one octave.
            // nb. notesDesc is built up ascending, then reversed later.

            const topNoteMidi = baseScale[0].midi + range.semitones;
            // ???211 also handle if highestPC and highestOctave are set instead of range!
            // cf. fingering chart 
            // Or don't - maybe this is kind of obsolete...
            //
            // const topNoteMidi = range ?
            // notesAsc[0].midi + RANGES[range].semitones      
            // : NoteTonal.midi(`${highestPC}${highestOctave}`);

            let i = 0, notesAsc = baseScale, notesDesc = baseScaleDesc;

            while (notesAsc[notesAsc.length - 1].midi < topNoteMidi && i < 999) {

                const sourceNote = baseScale[i],
                    sourceNoteDesc = baseScaleDesc[i];

                let newNote = new Note({
                    ...sourceNote,
                    octave: notesAsc[i].octave + 1,
                });
                notesAsc.push(newNote);

                // Melodic minor means that we can't add a copy of the same note we added to notesAsc
                let newNoteDesc = new Note({
                    ...sourceNoteDesc,
                    octave: notesDesc[i].octave + 1,
                });
                notesDesc.push(newNoteDesc);

                i++;
            }

            // Put the asc and desc parts together
            notes = notesAsc;
            if (includeDescending) notes = notes.concat(notesDesc.reverse().slice(1));

        }

        // Make a note of which note index is the highest and lowest
        this.topNoteIndex = notes.reduce((iTop, n, i, arr) => n.midi > arr[iTop].midi ? i : iTop, 0);
        this.bottomNoteIndex = notes.reduce((iBottom, n, i, arr) => n.midi < arr[iBottom].midi ? i : iBottom, 0);

        // Set last note duration
        const lastNote = notes[notes.length - 1];
        lastNote.changeDuration('q');

        return notes;
    }



    confirmNaturals = () => {
        const { notes } = this;
        // Check for additional natural signs needed
        for (let i = 1; i < notes.length; i++) {     // go through scale (no point in looking at first note)
            if (!notes[i].alteration) {              // is this note an implicit natural? (no b, # or =)
                for (let j = i - 1; j > -1; j--) {                       // go backward thru scale from our note..
                    if (notes[j].letter === notes[i].letter
                        && notes[j].octave === notes[i].octave) {
                        // found note with same letter and same octave
                        if (notes[j].alteration && notes[j].alteration !== 'n') {
                            notes[i].alter({ alteration: 0, showNaturalSign: true })     // it is a # or b so add a natural sign to our (i) note
                        }
                        break;   // regardless of # or b we don't need to go back any further
                    }
                }
            }
        }
    }

    setupClefChanges = () => {

        const { notes, tonicNote } = this;

        // Might need to put entire scale in a new clef:
        // Check for first note already quite high - if so switch to alternative clef (if there is one).
        let steps = tonicNote.stepsAboveMiddleLine;
        if (this.altClefHigh && steps > START_IN_HIGHER_CLEF_STEPS) {
            // e.g. switching bottom staff from bass to treble..
            notes.forEach(n => n.changeClef(this.altClefHigh));

            // main clef becomes the alt one, and low clef becomes the original (main) one
            this.altClefLow = this.clef;
            this.clef = this.altClefHigh;
            this.altClefHigh = undefined;
        }
        // Similar for low - although scales go up, so the threshold is not symmetrical...
        else if (this.altClefLow && steps < START_IN_LOWER_CLEF_STEPS) {
            // e.g. switching top staff from treble to bass..
            notes.forEach(n => n.changeClef(this.altClefLow));

            this.altClefHigh = this.clef;   // ..then if scale goes high enough it should change to treble
            this.clef = this.altClefLow;    // ..main clef is now bass.
            this.altClefLow = undefined;
        }

        // Similarly, check if first note should be in 8va line (or 8vb)..
        steps = tonicNote.stepsAboveMiddleLine;   // recalc in case clef changed
        if (steps > START_8VA_STEPS) {
            notes.forEach(n => n.changeOctaveShift(1));       // i.e. notate with an 8va line
        }
        else if (steps < START_8VB_STEPS) {
            notes.forEach(n => n.changeOctaveShift(-1));       // 8vb
        }

        // Now handle clef changes within the scale:
        if (this.altClefHigh) {
            notes.filter(n => n.stepsAboveMiddleLine > CLEF_CHANGE_STEPS_THRESHOLD)
                .forEach(n => n.changeClef(this.altClefHigh))
                ;
        }
        if (this.altClefLow) {
            notes.filter(n => n.stepsAboveMiddleLine < -CLEF_CHANGE_STEPS_THRESHOLD)
                .forEach(n => n.changeClef(this.altClefLow))
                ;
        }

        // 8va/8vb lines within the scale:
        notes.filter(n => n.stepsAboveMiddleLine > OCTAVE_STEPS_THRESHOLD)
            .forEach(n => n.changeOctaveShift(1))
            ;
        notes.filter(n => n.stepsAboveMiddleLine < -OCTAVE_STEPS_THRESHOLD)
            .forEach(n => n.changeOctaveShift(-1))
            ;

        // nb. notes within a beam group should be cleffed/8va'd as a unit, so createBeamGroups() deals with this..
    }



    highlightNewType() {

        // assume type already changed - so this.renderedType is the new type

        // this.renderedType = newType;
        // this.notes = this.buildNotes();
        let halos = [];

        if (this.renderedType === 'HAR') {
            halos = this.doHighlightNotes({ scaleDegrees: [7], className: 'raised' })
                .concat(this.doHighlightNotes({ scaleDegrees: [6], className: 'default' }));
        }
        else if (this.renderedType === 'MEL') {
            halos = this.doHighlightNotes({ scaleDegrees: [6, 7], direction: ASCENDING, className: 'raised' })
                .concat(this.doHighlightNotes({ scaleDegrees: [6, 7], direction: DESCENDING, className: 'default' }));
        }
        else if (this.renderedType === 'NAT') {
            halos = this.doHighlightNotes({ scaleDegrees: [6, 7], className: 'default' });
        }

        // console.log('highlights: ' + this.notes.map(n => n.highlight).join());

        // this.createBeamGroups();

        return halos;
    }


    highlightEnharmonicFlip() {
        const halos = this.doHighlightNotes({ className: 'flip' });
        this.createBeamGroups();  // ??? don't need now?
        return halos;
    }


    doChord() {

        const { showChord, basetype, renderedType, notes, topNoteIndex, range, duration, defaultBeamPattern, highlightNotes } = this;
        const availableChords = scaleTypeObject(renderedType).chords;
        const requiredChord = availableChords.find(ch => ch.chordType === showChord);

        this.disabledNotes = [];
        this.beamPattern = defaultBeamPattern;

        if (showChord === 'SCALE') {
            return;
        }
        else if (!requiredChord) {
            // Should be caught be handleScaleAction, but just in case..
            console.warn(`There is no chord of type ${showChord} for scale of type ${renderedType} - ignoring.`);
            return;
        }

        const scaleDegreesInChord = requiredChord.scaleDegrees;

        const classicMelodicMinorAscOnly =           // special treatment for chords on melodic minor
            (basetype === 'MIN' && renderedType === 'MEL')                    // classic mel minor
            && (scaleDegreesInChord.includes(6) || scaleDegreesInChord.includes(7));  // chord has 6 or 7


        const calcBeams = ({ notes, scaleDegreesInChord, classicMelodicMinorAscOnly }) => {
            const numNotesPerBeam = scaleDegreesInChord.length;
            let beams = [], beamStartIndex = -1, lastBeamableNoteIndex = -1, numNotesInBeam = 0;

            notes.forEach((note, i) => {
                if (note.durationSanitized < 8) {   // crotchet or bigger: not beamable
                    if (beamStartIndex !== -1) {    // oops there's an unfinished beam (e.g. 7th chord on mel min to 12th)
                        beams.push(lastBeamableNoteIndex - beamStartIndex + 1);
                    }
                    beams.push(1);
                }
                else if (classicMelodicMinorAscOnly && i > notes.length / 2) {   // No beaming on classic melodic minor descending
                    beams.push(1);
                }
                else if (!this.disabledNotes.includes(i)) {   // not disabled => should be beamed
                    if (beamStartIndex === -1) {       // It's the start of a new beam group
                        beamStartIndex = i;
                    }
                    lastBeamableNoteIndex = i;
                    numNotesInBeam++;
                    if (numNotesInBeam === numNotesPerBeam) {
                        // That's a beam completed.
                        beams.push(i - beamStartIndex + 1);
                        beamStartIndex = -1;
                        lastBeamableNoteIndex = -1;
                        numNotesInBeam = 0;
                    }
                }
                else if (beamStartIndex === -1) {
                    // we're not 'mid-beam' and this note is not to be beamed: mark it as a singleton.
                    beams.push(1);
                }
                // (otherwise, it's a disabled note but within a beam: no action, just ignore.)
            });

            return beams;
        }

        const topNote = notes[topNoteIndex];

        if (range === '12') {
            // Chords on scales to a 12th require some special handling..

            if (scaleDegreesInChord.length === 3) {
                // 000048 - insert additional notes for 12th arpeggios pattern
                // repeat last note (insert quaver before final crotchet)
                notes.splice(notes.length - 1, 0,
                    new Note({
                        ...notes[notes.length - 1],
                        duration,
                        ignoreForStaffWidth: true
                    }));

                if (scaleDegreesInChord.includes(topNote.scaleDegree)) {
                    // top note is in the triad so must repeat that too (it's not always, e.g. dim triad on altered)
                    notes.splice(topNoteIndex, 0,
                        new Note({
                            ...topNote,
                            ignoreForStaffWidth: true
                        }));
                }
            }

            if (scaleDegreesInChord.length === 4 && scaleDegreesInChord.includes(notes[topNoteIndex + 1].scaleDegree)) {
                // e.g. half-dim on C altered: don't want to repeat top Gbs on either side of top note of scale (Ab)
                // so disable the second one. 000302
                this.disabledNotes.push(topNoteIndex + 1);
            }

        }

        if (classicMelodicMinorAscOnly) {
            topNote.changeDuration('q');
            this.doPromoteNotes({ scaleDegrees: scaleDegreesInChord, direction: ASCENDING });
        }
        else this.doPromoteNotes({ scaleDegrees: scaleDegreesInChord });


        this.beamPattern = calcBeams({ notes, scaleDegreesInChord, classicMelodicMinorAscOnly });

        if (highlightNotes === 'chord') {
            this.doHighlightNotes({ scaleDegrees: scaleDegreesInChord, className: 'chord', greyOutOthers: true });
        }

    }


    // Was doDisableNotes, but flipped to a positive so easier to understand logic
    doPromoteNotes({
        scaleDegrees = [],
        direction = BOTH
    }) {
        this.notes.forEach((note, index) => {
            const scaleDegreeMatch = (
                !scaleDegrees.length || scaleDegrees.includes(note.scaleDegree)
            );
            const directionMatch = (
                (direction === ASCENDING && index < this.notes.length / 2)
                || (direction === DESCENDING && index > this.notes.length / 2)
                || direction === BOTH
            );

            if (!(scaleDegreeMatch && directionMatch)) {
                this.disabledNotes.push(index);
            }
        })
    }



    doHighlightNotes({
        scaleDegrees = [],
        direction = BOTH,
        className = 'halo-default'
    } = {}) {

        let halos = [];

        this.notes.forEach((note, index) => {
            const scaleDegreeMatch = (
                scaleDegrees.length === 0
                || scaleDegrees.includes(note.scaleDegree)
            );
            const directionMatch = (
                (direction === ASCENDING && index < this.notes.length / 2)
                || (direction === DESCENDING && index > this.notes.length / 2)
                || direction === BOTH
            );

            if (scaleDegreeMatch && directionMatch) {
                halos.push({ noteIndex: index, className });
            }
        })

        this.highlights = [...this.highlights, ...halos];

        return halos;

    }




    get vfStaveNotes() {
        return this.notes.map(n => n.vfStaveNote);
    }


    createBeamGroups() {
        /*
        createBeamGroups builds an array of (mostly) arrays, eg.
            [
                ["C4", "D4", "E4", "F4"],
                ["G4", "A4", "B4", "C5"],
                ["B4", "A4", "G4", "F4"],
                ["E4", "D4"],
                "C4"            // last note not an array (so it won't be beamed)
            ]
        ..Then beams are created for each of the sub-arrays (but not the single notes).
        */
        this.beamGroups = [];

        // if (this.tonicNote.durationSanitized >= 8) { 
        //    ↖︎ removed: In fact the code also affects clef changes in the 'optional zone', so could impact crotchet scales too.

        const { beamPattern, topNoteIndex } = this;
        const topNote = this.notes[topNoteIndex];

        // Sort out beam groups.
        let i = 0, groupCounter = 0;
        while (i < this.notes.length - 1) {          // -1: we'll never beam the last note
            const noteGroup = this.notes.slice(i, i + beamPattern[groupCounter]);

            // Don't allow clef change or 8va/8vb line change within a beamed group -
            // If any note in group has either then they must all have it.
            if (noteGroup.length > 1) {
                const nonStandardClef = noteGroup.find(n => n.clef !== this.clef)?.clef;
                if (nonStandardClef) {
                    noteGroup.forEach(n => n.changeClef(nonStandardClef));
                }

                // Similar for 8va/8vb lines:
                const octaveShift = noteGroup.find(n => !!n.octaveShift)?.octaveShift;
                if (octaveShift) {
                    noteGroup.forEach(n => n.changeOctaveShift(octaveShift));
                }
            }

            // If highest note in scale has clef change or 8va line then we should apply same to groups
            // above the corresponding 'optional' threshold.
            const groupAscending = (i < topNoteIndex);
            // 'pivot' note of group: note to use when deciding whether clef change required.
            //   - If ascending group then it's the last (highest) one;
            //   - If descending group then use the *2nd* one - a slight shimmy to counter the fact that
            //     in the book C-F sometimes changes to treble (Bb maj) and sometimes doesn't (Eb maj desc)

            // ???395 needs work for clef changes down (I should think) and 8vb lines!!
            const pivotNote = (noteGroup.length === 1)
                ? noteGroup[0]
                : groupAscending ? noteGroup[noteGroup.length - 1] : noteGroup[1];

            if (topNote.clef !== this.clef && pivotNote.stepsAboveMiddleLine > CLEF_CHANGE_STEPS_OPTIONAL) {
                noteGroup.forEach(n => n.changeClef(topNote.clef));
            }
            if (topNote.octaveShift === 1 && pivotNote.stepsAboveMiddleLine > OCTAVE_SHIFT_STEPS_OPTIONAL) {
                noteGroup.forEach(n => n.changeOctaveShift(topNote.octaveShift));
            }


            // Now actually set up the beam groups of vexflow notes..
            if (noteGroup.length > 1) {

                const noteGroupVf = noteGroup.map(n => n.vfStaveNote);

                // Figure out and set stems up or down
                const heightAboveMiddle = noteGroup.map(note => note.stepsAboveMiddleLine);
                const highest = Math.max(...heightAboveMiddle),
                    lowest = Math.min(...heightAboveMiddle);
                noteGroupVf.map(vfStaveNote => vfStaveNote.setStemDirection(
                    Math.abs(lowest) > highest
                        ? Vex.Flow.StaveNote.STEM_UP
                        : Vex.Flow.StaveNote.STEM_DOWN
                ))
                this.beamGroups.push(noteGroup);
            }
            // ...or if only one note long then append it standalone - i.e. not in an array
            else {
                this.beamGroups.push(noteGroup[0]);
            }

            i += beamPattern[groupCounter];   // Move on in scale
            groupCounter = (groupCounter + 1) % beamPattern.length;   // Find length of next group
        }

        // Append last note (as a standalone elt, not an array)
        this.beamGroups.push(this.notes[this.notes.length - 1]);

        this.vfBeams = this.beamGroups
            .filter(group => Array.isArray(group))          // notes to be beamed will be in arrays
            .map(group => new Vex.Flow.Beam(group.map(note => note.vfStaveNote)));       // for each group create a Beam
    }


    markClefChanges() {
        let currentClef = this.clef;
        this.notes.forEach(note => {
            note.clefChange = note.clef !== currentClef;
            currentClef = note.clef;
        });
    }


    // height in staff steps  // ???324 check I still need this
    get heightInSteps() {
        return this.top - this.bottom + EXTRA_SPACE_BELOW_IN_STEPS;
    }

    get top() {
        const highest = this.notes[this.topNoteIndex];
        const flippedTop = flippedTonic(this)
            ? new Note(highest).enharmonicFlip().top
            : 0;

        return Math.max(
            highest.top,                               // top of top note
            this.notes[this.topNoteIndex - 1].top,     // top of penultimate note (might be higher if sharp)
            flippedTop,                                // top of flipped top note (to avoid annoying list jumps on flip)
            element.CLEF[this.clef].top,               // top of clef
            -1 + element.STEM.height + element.BEAM.thickness,       // 2nd space note (-1) with an upstem
            this.notes.some(n => n.octaveShift > 0) ? TOP_TEXT_POSITION_STEPS : 0   // space at top for 8va line
        ) + EXTRA_SPACE_ABOVE_IN_STEPS;
    }

    get bottom() {
        const lowest = this.notes[this.bottomNoteIndex];
        const flippedBottom = flippedTonic(this)
            ? new Note(lowest).enharmonicFlip().bottom
            : 0;
        return Math.min(
            lowest.bottom,                            // bottom of lowest note
            // this.notes[2].bottom,                     // bottom of 2nd note
            // ^^ turned off, cos will always allow a little extra room [51]
            flippedBottom,                            // bottom of flipped tonic
            element.CLEF[this.clef].bottom,           // bottom of clef
            0 - element.STEM.height,                   // middle line note with down-stem
            this.notes.some(n => n.octaveShift < 0) ? BOTTOM_TEXT_POSITION_STEPS : 0   // space at bottom for 8vb line
        )
    }


    // all the notes' display attributes (active flag + highlights) concatenated
    // nb. this is for determining new vexflowCpt render, so now that there are halos - instead of previous system of coloring the vf notes themselves - it's just about the disabled notes.
    // 'Key' here means React KEY property - not musical key
    get highlightKey() {
        const key = this.disabledNotes.join();
        //  + '-' + this.notes.map(note => (note.highlight.toString())).join();
        return key;
    }
}
