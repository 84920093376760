import { lowestOctaveForInstrument, highestOctaveForInstrument, midiPitch } from '../logic/noteUtils';
import { scaleTypeObject } from '../logic/scaleTypes';


// turns a plain number in to a step object,
// or leaves a step object alone.
const castAsStepObject = (stepNumOrObject) => {
    return typeof stepNumOrObject === 'object' ? stepNumOrObject
        : { scaleDegree: +stepNumOrObject }
        ;
}

const octaveAdjust = (scaleDegrees, octaveAdjust) => {
    const octaveAdjustSingle = scaleDegree => {
        const stepObj = castAsStepObject(scaleDegree);
        return {
            ...stepObj,
            octaveAdjust,
        }
    }

    if (Array.isArray(scaleDegrees)) {
        return scaleDegrees.map(sd => octaveAdjustSingle(sd));
    }
    else return octaveAdjustSingle(scaleDegrees);
};

const lower = (scaleDegrees) => (octaveAdjust(scaleDegrees, -1));

// specify that this degree of pattern should come from the descending version of the scale.
// I.e. this is for melodic minor patterns.
const desc = scaleDegree => {
    const stepObj = castAsStepObject(scaleDegree);
    return {
        ...stepObj,
        fromDescScale: true
    }
}

const formatFormula = formulaArray => {
    return formulaArray.map(step => castAsStepObject(step));
}


export const RANGES = [
    {
        id: '8',
        fullName: 'One Octave',
        shortName: '1 oct',
        semitones: 12
    },
    // {
    //     id: '5',
    //     fullName: 'Fifth',
    //     shortName: '5th',
    //     formula: formatFormula([1, 2, 3, 4, 5, 4, 3, 2, 1]),
    //     semitones: 7,
    //     isValidForScaleType: basetypeId => scaleTypeObject(basetypeId).formula.length === 7,     // valid for 7-note scales
    // },
    {
        id: 'FIFTH_ABOVE_AND_BELOW',
        fullName: 'Fifth Above/Below',
        shortName: '5th/5th',
        formula: formatFormula([1, 2, 3, 4, 5, 4, 3, 2, 1, ...lower([desc(7), desc(6), 5, 6, 7]), 1]),
        semitones: 7,
        semitonesBelow: 5,
        isValidForScaleType: basetypeId => scaleTypeObject(basetypeId).formula.length === 7,     // valid for 7-note scales
    },
    {
        id: '12',
        fullName: 'A Twelfth',
        shortName: '12th',
        semitones: 19
    },
    {
        id: '15',
        fullName: 'Two Octaves',
        shortName: '2 oct',
        semitones: 24
    },
    {
        id: '22',
        fullName: 'Three Octaves',
        shortName: '3 oct',
        semitones: 36
    },
    {
        id: '29',
        fullName: 'Four Octaves',
        shortName: '4 oct',
        semitones: 48
    },
];

const rangeOneOctave = RANGES.find(r => r.id === '8');

export const isValidForScaleType = (basetypeId, range) => {
    return (range.isValidForScaleType === undefined) ? true
        : range.isValidForScaleType(basetypeId);
}


// export const PATTERNS = {
//     FIFTH_ABOVE_AND_BELOW: {
//         displayName: 'Fifth Above/Below',
//         formula: formatFormula([1, 2, 3, 4, 5, 4, 3, 2, 1, ...lower([7, 6, 5, 6, 7]), 1]),
//     },
// };


// Checks to see if range fits on instrument from given note.
export const isRoomForRange = ({ instrument, range, tonicPC, octave, aboveOrBelow = 'BOTH' }) => {
    const instHighestNoteMidi = midiPitch(instrument.highestNote),
        instLowestNoteMidi = midiPitch(instrument.lowestNote.generic),
        tonicMidi = midiPitch({ pitchClass: tonicPC, octave });

    const currentTonic = {
        spaceAbove: instHighestNoteMidi - tonicMidi,
        spaceBelow: tonicMidi - instLowestNoteMidi
    };

    const headRoom = currentTonic.spaceAbove - range.semitones;
    const baseRoom = currentTonic.spaceBelow - (range.semitonesBelow || 0);

    return aboveOrBelow === 'ABOVE' ? headRoom >= 0
        : aboveOrBelow === 'BELOW' ? baseRoom >= 0
            : headRoom >= 0 && baseRoom >= 0
        ;
}

export const isRoomForRangeAbove = (params) => isRoomForRange({ ...params, aboveOrBelow: 'ABOVE' });
export const isRoomForRangeBelow = (params) => isRoomForRange({ ...params, aboveOrBelow: 'BELOW' });

export const getValidOctaves = ({ instrument, range, tonicPC }) => {

    const lowestOctave = lowestOctaveForInstrument(tonicPC, instrument),
        highestOctave = highestOctaveForInstrument(tonicPC, instrument);

    let validOctaves = [];

    for (let octave = lowestOctave; octave <= highestOctave; octave++) {
        if (isRoomForRange({ instrument, range, tonicPC, octave })) validOctaves.push(octave);
    }

    return {
        lowest: validOctaves[0],
        highest: validOctaves.pop()
    }
}

export const getPreferredOctave = ({ instrument, range = rangeOneOctave, tonicPC }) => {

    const validOctaves = getValidOctaves({ instrument, range, tonicPC });
    let octave = validOctaves.lowest;
    const tonicMidi = midiPitch({ pitchClass: tonicPC, octave });

    const preferredLowestNoteByClef = {         // 'preferred' cos there might not be a choice
        treble: { pitchClass: 'G', octave: 3 },
        alto: { pitchClass: 'C', octave: 3 },
        tenor: { pitchClass: 'C', octave: 3 },    // 🤷🏻‍♂️
        bass: { pitchClass: 'E', octave: 2 },
        grand: { pitchClass: 'G', octave: 3 },   // same as treble (it's for the top staff)
    };

    // check for lowest octave being unreasonably low and fix (if there is a choice)
    if (
        validOctaves.highest > validOctaves.lowest                              // other octaves are available?..
        && tonicMidi < midiPitch(preferredLowestNoteByClef[instrument.clef])    // ..and is start note lower than preferred limits?
    ) octave++;

    return octave;
}