import { pitchClassDisplayString } from '../utils/MusicSymbolsFormatting';
import { ordinalSuffix, rotateLeft } from '../utils/miscUtils';
import { SCALE_TYPES } from './scaleTypesData';
import { bulletSuffixes } from './bulletSuffixes.js';
import { CIRCLE_5THS } from '../logic/musicStructures';
import { chordsForScaleType } from './chordTypes';
import { spellSimple } from './noteUtils';
import memoize from 'lodash/memoize';


function titleStandard({ tonicPC, maxLength = 999 }) {
    // nb. doesn't *force* it to be less than maxLength
    const scaleName = this.displayName.length > maxLength ? this.shortName || this.displayName : this.displayName;
    return (
        `${pitchClassDisplayString(tonicPC)} ${scaleName}`
    );
};



const scaleSuffix = (chordsForScaleType) => {

    const chordIds = chordsForScaleType.map(ch => ch.chordId || ch.chordType);
    // for 'basic' chords, chordType will contain e.g. 'TRIAD' - but chordId is available for actual chord

    if (chordIds.includes('DOM7')) return bulletSuffixes.dom7;
    else if (chordIds.includes('AUG7')) return bulletSuffixes.dom7;
    else if (chordIds.includes('MAJ')) return bulletSuffixes.major;
    else if (chordIds.includes('AUG')) return bulletSuffixes.augmented;
    else if (chordIds.includes('MIN')) return bulletSuffixes.minor;
    else if (chordIds.includes('HALFDIM')) return bulletSuffixes.halfDiminished;
    else if (chordIds.includes('DIM')) return bulletSuffixes.diminished;
}


// Slight improvement from memoizing - 000469
const memoizedScaleTypeObject = memoize(_scaleTypeObject);

export const scaleTypeObject = typeId => {
    const res = memoizedScaleTypeObject(typeId);
    return res;
}


function _scaleTypeObject(typeId) {

    if (!typeId) return undefined;

    let res = SCALE_TYPES.find(p => p.id === typeId);

    if (!res) {
        // hopefully it's an 'anonymous' mode..
        const [parentTypeId, modeNumString] = typeId.split('-m');
        const modeNum = parseInt(modeNumString);
        if (!modeNum) {
            // Don't throw error, just return undefined - same behaviour if this was a simple array lookup
            console.warn(`_scaleTypeObject: Unknown scale typeId ${typeId}`);
            return undefined;
            //                throw new Error(`Unknown scale typeId ${typeId}`);
        }
        // ??? should also validate for a junk parent typeId

        const parentType = _scaleTypeObject(parentTypeId);


        // set up the basics for an anonymous mode
        // const name = `${modeNum}th mode of ${parentType.displayName}`;
        res = {
            id: typeId,
            canonicalTypeId: typeId,
            class: 'MODE',
            displayName: `${modeNum}${ordinalSuffix(modeNum)} mode of ${parentType.displayName}`,
            shortName: `${modeNum}${ordinalSuffix(modeNum)} mode`,
            title: function title({ tonicPC }) {
                return (
                    `${this.displayName}, starting on ${pitchClassDisplayString(tonicPC)}`
                );
            },
            modeOf: parentTypeId,
            modeNum
        }
    }

    if (res.class === 'FUNDAMENTAL') {
        // It's a base scale typeId
        res.sortingIndex = SCALE_TYPES.indexOf(res);
    }
    else if (res.class === 'MODE' || res.class === 'SYNONYM') {  // ???types surely odd to group these together in logic?

        if (res.canonicalTypeId !== res.id) {
            return {
                ..._scaleTypeObject(res.canonicalTypeId),
                ...res,
                title: res.title || titleStandard,          // don't let any base scale name-typeId stuff override synonym
                optionName: res.optionName || res.displayName,     // ??? ..but DRY - same as at bottom of this file!
                shortName: res.shortName || res.displayName,
                pluralName: res.pluralName || res.displayName + 's',
                id: typeId,       // make sure typeId is still the synonym typeId
            }
        }

        let parentType;

        if (res.modeOf) {
            parentType = _scaleTypeObject(res.modeOf);
        }

        // It's a mode, named or not makes no difference from here on..

        const intervalFromParent = res.modeNum === 1 ? 0        // 1st mode => same as parent scale
            : parentType.formula[res.modeNum - 2].interval;   // 2nd mode (e.g. dorian) is first in the array (i.e. index 0), hence the -2.

        res.formula = res.formula || (                       // allow for override (e.g. Altered)
            res.modeNum === 1
                ? parentType.formula                                    // 1st mode => parent's formula
                : rotateLeft(parentType.formula, res.modeNum - 1)       // nth mode => rotate parent's formula...
                    .map(int => ({
                        ...int,
                        interval: int.interval - intervalFromParent,      // ..'rebased' by subtracting nth int of parent
                    }))
        );

        // modes - also a rotation of the parent's, but replace anonymous ones..
        // ??? poss should leave this undefined for anonymous modes?
        // anyway, modes of MAJ-m7 are ["MAJ-m7-m1", "IONIAN", "DORIAN", "PHRYGIAN", "MAJ-m7-m5", "MAJ-m7-m6", "MAJ-m7-m7"] ..!!!
        res.modes = rotateLeft(parentType.modes, res.modeNum - 1)
            .map((modeId, i) => (
                modeId.match(/-m[0-9]+$/)            // ..if it's an anonymous typeId, like 'PAR-m4' (where PAR = parent typeId)
                    ? `${typeId}-m${i + 1}`            // then return anonymous typeId w.r.t. the requested typeId (like TYPE-m3)
                    : modeId                         // but if it's a proper named scale typeId then return that
            ));

        res.relativeMajorDistanceIn5ths = parentType.relativeMajorDistanceIn5ths - intervalFromParent;

        res.relative = res.relative || {
            basetype: parentType.id,
            fifths: intervalFromParent * -1,
            text: `Relative ${parentType.displayName}`
        }

        // for valid (etc.) tonics, transpose each tonic of the parent typeId

        // default tonics for dorian are same as default tonics for major but with each one shuffled round by first interval of major
        //   Db major is ok (ie. in preferred) but Db dorian not (because Cb major is not in preferred)
        //   Ab dorian not
        //   Eb dorian yes
        //   F# major ok F# dorian ok
        //   G# dorian ok
        //   A# dorian not (because G# major is not)

        // 000295: that's ok to a point, but as I favour F# major over Gb major then query on locrian and you'll get E# loc.
        // ø So rule should add: 'but E# is never preferred' (nor Cb etc.)
        //   >> implemented in mapPreferredTonic

        const mapPreferredTonic = pc => {
            const mappedTonic = CIRCLE_5THS[CIRCLE_5THS.indexOf(pc) + intervalFromParent];
            return spellSimple({ pitchClass: mappedTonic });
        }

        const { preferred = [], flippable = false } = parentType.tonics || {};
        res.tonics = {
            // valid: valid.map(pc => CIRCLE_5THS[CIRCLE_5THS.indexOf(pc) + intervalFromParent]),
            preferred: preferred.map(mapPreferredTonic),
            flippable,
        };


        res.sortingIndex = 1000 + parentType.sortingIndex * 12 + res.modeNum - 1;
    }



    // Fill in all the standard stuff
    res.title = res.title || titleStandard;
    res.optionName = res.optionName || res.displayName;
    res.shortName = res.shortName || res.displayName;
    res.abbreviatedName = res.abbreviatedName || res.shortName;
    res.pluralName = res.pluralName || res.displayName + 's';

    res.chords = res.chords || chordsForScaleType(res.formula);
    res.suffix = res.suffix || scaleSuffix(res.chords);    // ??? might as well get other basic stuff like... er... style??

    return res;
}


