import React from 'react';

import { PlayIcon, StopIcon, Spinner } from '../assets/icons';


const PlayControls = (props) => {

    const { scaleId, playingScaleId, handlePlayScale } = props;
    // console.log('Halos: ', halos);

    const isPlaying = playingScaleId === scaleId;
    const handlePlayClick = (event) => handlePlayScale(event);

    return (
        // <span className="XXplay-button">
        <React.Fragment>
            {isPlaying ? (
                <button
                    className='text-button play-button stop'
                    onClick={handlePlayClick}
                    name='stop'
                >
                    <StopIcon /> Stop
                </button>
            )
                : <button
                    className='text-button play-button'
                    onClick={handlePlayClick}
                    name='play'
                    disabled={playingScaleId && playingScaleId !== scaleId}
                >
                    {playingScaleId === 'LOADING_AUDIO' ? <Spinner /> : <PlayIcon />}
                &nbsp;Play
                </button>
            }
        </React.Fragment>
    )
}



export default PlayControls;