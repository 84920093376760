import React from 'react';

const ContactPane = () => {
    return (
        <div className='feedback-form-container' // ???
        >
            <p>Constructive feedback, bug reports and feature requests welcome!</p>
            <p>Use the form below, or join the <LinkToFacebookGroup>facebook group</LinkToFacebookGroup>.</p>
            <form name="test" method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="contact" />
                <label>Name<input type="text" name="name" autoFocus /></label>
                <label>Email<input type="email" name="email" /></label>
                {/* <p>
                    <label>Your Role: <select name="role[]" multiple>
                    <option value="leader">Leader</option>
                    <option value="follower">Follower</option>
                    </select></label>
                </p> */}
                <label>Message<textarea name="message"></textarea></label>
                <label>If you're reporting a problem please include device (e.g. 'iphone 8') and browser (and ideally also OS version and browser version).</label>

                <button
                    className='sidebar-control'
                    type="submit"
                >
                    Send
                    </button>
                {/* <button
                        type="button"
                        className='cancel'
                        onClick={() => setShowFeedbackForm(false)}
                    >
                        Cancel
                    </button> */}
            </form>
        </div>
    )
}

export function LinkToContactForm({ setSidebarActiveTab, children }) {
    return (
        <button
            className='link-like-button'
            onClick={() => setSidebarActiveTab('CONTACT')}
        >
            {children}
        </button>
    )
}



export function LinkToFacebookGroup({ children }) {
    return (
        <a
            href='https://www.facebook.com/groups/scale.lookup.user.group'
            target='_blank'
            rel='noopener noreferrer'
        >
            {children}
        </a>
    );
}

export default ContactPane;
