import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { buildScaleList } from '../logic/buildScaleList';
import { currGroupByInEffect } from '../logic/groupBys';

import NavBar from './NavBar';
import ScaleList from "./ScaleList";
// import NavSidebar from './NavSidebar';
import Sidebar from './Sidebar';
// import SearchExamples from './SearchExamples';
// import LandingPage from './LandingPage';
import WhatYouCanDo, { FullScreenImage } from './WhatYouCanDo';
import FeatureTour from './FeatureTour';
import { Spinner } from '../assets/icons';
import { scaleTypeObject } from '../logic/scaleTypes';
import { RANGES, isValidForScaleType, getValidOctaves } from '../data/Ranges';
import Overlay from './Overlay';
import { isPhone, isTabletOrPhone } from '../utils/miscUtils';
import { setShowGrid } from '../actions/settings';
import Grid from './Grid';

import {
    color
    // , log 
} from '../utils/DevUtils';
import { useMemo } from 'react';

const printExampleFull = require('../assets/print-example-0-3-17.png');


const Main = ({
    groupBy,
    groupByForModes,
    defaultInstName,
    location,
    showGrid,
    dispatch
}) => {

    const [isLoading, setIsLoading] = useState(true);
    console.log(...color('red', '  Main - isLoading: '), isLoading);
    // log('Main - isLoading: ', isLoading);
    // useEffect(() => { console.log(...color('red', `Main INITIAL RENDER`)) }, []);

    const [showSearchOnPhone, setShowSearchOnPhone] = useState('SHOW_BUT_NO_FOCUS');
    const [usesPhoneStyleNavBar] = useState(isTabletOrPhone());
    const [usesPhoneStyleGrid] = useState(isPhone());
    // const [showGridOnPhone] = useState(false);

    const [sidebarActiveTab, setSidebarActiveTab] = useState(null);
    // const [sidebarActiveTab, setSidebarActiveTab] = useState('SETTINGS');
    const setShowSettings = (show) => setSidebarActiveTab(show ? 'SETTINGS' : null);
    const toggleSidebar = () => setSidebarActiveTab(sidebarActiveTab ? null : 'NONE');
    const [showPrintExample, setShowPrintExample] = useState(false);

    // ???
    // We have to do a complicated calculation to derive the list of scales (primarily) based on the searchText.
    // Not sure of best way to handle this.
    //
    // Current version does the calc immediately below on every render, but only really uses the values on the
    // initial render - so that's wasted calculations. And the useMemo doesn't do much cos the searchText does
    // change most (?) renders. Subsequent renders: state is set up by the useEffect.
    // 
    // Thought was to have initial useState set empty values and then only set state from within the useEffect.
    // Then I have to change render so that it doesn't crash if only has those empty values - feels hacky but I guess it's easy enough..
    // ..uh but it doesn't crash if there's no searchtext so just set state vars like that..??
    // But what would be the 'correct' way?
    //
    // prob have to put inst and grade in state?
    // maybe they should be in the grade header? (which should be renamed to listHeader)

    const defaultState = {
        searchFeedback: { errors: [], warnings: [], info: [] },
        gradeHeader: {},
        // gradeHeader: { groupBys: {} },
    };


    const [search, setSearch] = useState({ tokens: [], text: '' });
    // ??? drop search.tokens?
    //  .. maybe.. though perhaps I should keep track of how the text has been parsed (e.g. might want to embellish with underlines etc.)
    // ???372

    const [searchFeedback, setSearchFeedback] = useState(defaultState.searchFeedback);
    const [scaleHeaderList, setScaleHeaderList] = useState([]);
    // a 'scaleHeader' is one set of params which define a scale. 
    // Didn't use 'scale' so as to distinguish from an instance of the Scale class - i.e. all the individual notes etc.
    // package up together?? oh but redux will be coming sooo...
    // const [scaleGroups, setScaleGroups] = useState([]);

    const [gradeHeader, setGradeHeader] = useState(defaultState.gradeHeader);
    const [instrument, setInstrument] = useState({});    // ??? put inside gradeheader
    const [grade, setGrade] = useState(null);

    // console.log('248 Main', isLoading, search.tokens, search.text, scaleHeaderList)


    useEffect(() => {

        if (isLoading) {
            const scaleListObj = buildScaleList(location.search, defaultInstName, setShowSettings);
            console.log(...color('red', 'Main - set state 1'));
            setSearch(scaleListObj.search);
            setScaleHeaderList(scaleListObj.scales);
            setGradeHeader(scaleListObj.gradeHeader);
            setInstrument(scaleListObj.instrument);
            setGrade(scaleListObj.grade);
            setSearchFeedback(scaleListObj.searchFeedback);

            // Hold off resetting isLoading so that the first render doesn't try to do everything (specifically,
            // it won't actually show the scales themselves). Effect will be that user sees the keys grid and the search
            // tokens quickly, plus a loading spinner.
            const t = setTimeout(() => {
                console.log(...color('red', 'Main - set state 2'));
                if (isLoading) setIsLoading(false);
                if (scaleListObj.scales.length) setShowSearchOnPhone(false);
            }, 1);
            return function tidyUp() {
                clearTimeout(t);
            }
        }
    }, [defaultInstName, isLoading, location.search]);

    const listOfModes = !!gradeHeader.groupBys?.MODES_OF;


    // ???perfdec ???233
    // scaleGroups in state commented out below, replaced with useMemo further below..
    // Should do more precise and careful performance comparison!

    // const [currGroupBy, setCurrGroupBy] = useState(currGroupByInEffect({ listOfModes, groupByForModes, groupBy }));
    // const [scaleGroups, setScaleGroups] = useState([]);

    // useEffect(() => {
    //     // scaleGroups will change whenever a new query (obviously), plus whenever user uses Group By dropdown.
    //     if (gradeHeader.groupBys) {

    //         console.log(...color('pink', 'Main - set up groups'));

    //         const groupByObj = gradeHeader.groupBys[newGroupBy];
    //         console.log(...color('red', 'Main - set state 3'));

    //         setCurrGroupBy(newGroupBy);
    //         setScaleGroups(groupByObj.groupKeys
    //             .map(key => (               // create a group for each key (eg. if scaletype, keys might be e.g. MAJ, MIN etc.)
    //                 {
    //                     key,
    //                     id: groupByObj.groupId(key),
    //                     name: groupByObj.groupName(key),
    //                     shortName: groupByObj.groupShortName(key),
    //                     // groupNavClassName: (groupByObj.groupNavClassName || (() => { }))(key),
    //                     message: groupByObj.message(key),
    //                     scaleHeaders: scaleHeaderList.filter(scaleHeader => groupByObj.scalesFilter(key, scaleHeader)),
    //                     keysGridRow: groupByObj.keysGridRow(key)   // ???perfDec think it happens more than once - useEffect?
    //                 }
    //             )));
    //     }
    // }, [gradeHeader.groupBys, groupBy, groupByForModes, listOfModes, scaleHeaderList]);

    let scaleGroups = [];
    const currGroupBy = currGroupByInEffect({ listOfModes, groupByForModes, groupBy });

    scaleGroups = useMemo(() => {
        if (gradeHeader.groupBys) {
            const groupByObj = gradeHeader.groupBys[currGroupBy];
            return (groupByObj.groupKeys
                .map(key => (               // create a group for each key (eg. if scaletype, keys might be e.g. MAJ, MIN etc.)
                    {
                        key,
                        id: groupByObj.groupId(key),
                        name: groupByObj.groupName(key),
                        shortName: groupByObj.groupShortName(key),
                        // groupNavClassName: (groupByObj.groupNavClassName || (() => { }))(key),
                        message: groupByObj.message(key),
                        scaleHeaders: scaleHeaderList.filter(scaleHeader => groupByObj.scalesFilter(key, scaleHeader)),
                        keysGridRow: groupByObj.keysGridRow(key)
                    }
                )));
        }
        else return [];
    },
        [currGroupBy, gradeHeader.groupBys, scaleHeaderList]
    );

    // const [includeDescending] = useState(true);             // ??? am i even using?!
    // const [chromaticSpelling] = useState('familiar');
    const [printing, setPrinting] = useState(false);

    const handleNewSearch = () => {
        setSearchFeedback(defaultState.searchFeedback);
        setScaleHeaderList([]);
        // setScaleGroups([]);
        setGradeHeader(defaultState.gradeHeader);
        setInstrument({});
        setGrade(null);
        setIsLoading(true);
    }




    // Set up print listeners
    useEffect(() => {
        const handleBeforePrint = () => setPrinting(true);
        const handleAfterPrint = () => setPrinting(false);

        const mqlPrintListener = (mql) => {
            if (mql.matches) handleBeforePrint();
            else handleAfterPrint();
        }

        window.addEventListener("beforeprint", handleBeforePrint);
        window.addEventListener("afterprint", handleAfterPrint);

        // Polyfill for Safari. Also fires on Chrome, which means that the printing flag will be set twice there, but that doesn't cause a problem.
        const mediaQueryList = window.matchMedia('print');
        mediaQueryList.addListener(mqlPrintListener);

        return function tidyup() {
            window.removeEventListener("beforeprint", handleBeforePrint);
            window.removeEventListener("afterprint", handleAfterPrint);
            mediaQueryList.removeListener(mqlPrintListener);
        }
    }, []);

    // // scroll listener ???439
    // // o throttle??
    // // o also prob should package up as a custom hook!
    // const [scrollY, setScrollY] = useState(0);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.scrollY < scrollY) { setShowSearchOnPhone(true) }
    //         else if (window.scrollY > scrollY) { setShowSearchOnPhone(false) }
    //         setScrollY(window.scrollY);
    //     }

    //     window.addEventListener('scroll', handleScroll);

    //     return function tidyup() {
    //         window.removeEventListener('scroll', handleScroll);
    //     }
    // }, [scrollY]);

    const isFirefox = window.navigator.userAgent.indexOf('Firefox') !== -1;

    // called from print button on navbar
    const handlePrintRequest = () => {
        // Flag up that it's the button so that useEffect knows it has to call window.print
        // (and the string is truthy so all the existing code to adjust elements for print
        // will continue to work).
        console.log(...color('red', 'Main - set state 4 print'));
        setPrinting('navbar-button');
    }
    useEffect(() => {
        if (printing === 'navbar-button') {
            window.print();
            setPrinting(false);
        }
    }, [printing]);


    const handleScaleAction = ({ event, scaleHeaderIdsToUpdate }) => {
        // Change chord, flip, range etc. for one or more scales.
        // New values for tonic, chord, renderedType, whatever come from event.current.target.dataset
        // See ActionControls.js

        let newScaleValues = Object.assign({}, event.currentTarget.dataset);

        // put number and boolean attributes into corrent type
        if (typeof newScaleValues.octave === 'string') newScaleValues.octave = +newScaleValues.octave;
        // if (typeof newScaleValues.arpeggio === 'string') newScaleValues.arpeggio = (newScaleValues.arpeggio === 'true' ? true : false);

        newScaleValues.actionId = Date.now(); // for distinguishing actions, for halo-clearing
        newScaleValues.highlightNotes = newScaleValues.highlightNotes || null;  // clear old highlights (if no new one)

        updateList(newScaleValues);  // actually update the state

        function updateList(newValues) {
            console.log(...color('red', 'Main - setScaleHeaderList in handleScaleAction'));
            setScaleHeaderList(prevList => {
                const newList = prevList
                    .map(sh => {
                        let overrideValues = {};
                        if (scaleHeaderIdsToUpdate.includes(sh.id)) {

                            if (
                                newValues.renderedType                     // change to renderedType
                                && sh.showChord !== 'SCALE'   // and we were displaying a chord
                                && !newValues.showChord                    // and no change to chord type 
                            ) {
                                // Revert to scale if chord type is invalid for new scale type.
                                const newAvailableChords = scaleTypeObject(newValues.renderedType).chords;
                                const requiredChord = newAvailableChords.filter(ch => ch.chordType === sh.showChord)[0];

                                if (!requiredChord) newValues.showChord = 'SCALE';
                            }

                            // Range change for multiple scales might not be valid for some of the individual scales.
                            if (newValues.range) {
                                const range = RANGES.filter(r => r.id === newValues.range)[0],
                                    tonicPC = newValues.tonicPC || sh.tonicPC,
                                    octave = newValues.octave || sh.octave,
                                    basetype = newValues.basetype || sh.basetype;

                                // Is range valid for scale type?
                                if (!isValidForScaleType(basetype, range)) {
                                    overrideValues.range = sh.range;   // no - ignore the change
                                }
                                else {
                                    // Does range fit?
                                    // Plus might need to adjust octave.. 
                                    const validOctaves = getValidOctaves({ instrument, range, tonicPC });
                                    if (validOctaves.lowest > octave) overrideValues.octave = validOctaves.lowest;   // adjust octave up
                                    else if (validOctaves.highest < octave) overrideValues.octave = validOctaves.highest; // adjust octave down
                                    else if (validOctaves.lowest === undefined) {
                                        // range fits nowhere on inst from this tonic
                                        // Grab the next best range..
                                        // ???421 Needs work: the next best range will fit, but might not fit from the curr. octave!

                                        const bestWeCanDoRange = RANGES.find(r => (
                                            r.semitones === Math.max(...RANGES
                                                .filter(r2 => r2.semitones < range.semitones)
                                                .map(r2 => r2.semitones)
                                            )
                                        ));

                                        overrideValues.range = bestWeCanDoRange.id;
                                    }
                                }
                            }

                            return {
                                ...sh,
                                ...newValues,
                                ...overrideValues,
                            }
                        }
                        else return sh;
                    })
                // console.log('new list: ', newList);
                return newList;
            })
        }
    }

    useEffect(() => {
        // On phones, don't retain 'show grid' setting from previous session - always start with it hidden.
        //
        // (If I used separate state item, like 'showGridOnPhone' then this bit could disappear, but stuff like
        // determining toggle 'pressed' state would be harder, cos ipad has phone-style bottom menu, but desktop-style
        // sidebar.)
        if (usesPhoneStyleGrid) dispatch(setShowGrid(false));
    }, [usesPhoneStyleGrid, dispatch]);

    let classNameForGridWidth = `grid-width-${Math.min(scaleGroups.length, 12)}`;  // limit width to 12 groups

    if (!showGrid) {
        classNameForGridWidth = 'grid-width-0';
    }
    else if (scaleHeaderList.length <= 12) {
        // short lists always a single column regardless of screen size
        classNameForGridWidth = 'grid-width-1';
    }

    // ???perfdec us this useCallback a good idea?
    // only relevant for phones
    const handlePhoneGridClick = useCallback(
        () => {
            // react-scroll handles the navigation, but need this to close the grid (only relevant on phone, where
            // grid is laid on top of scales list, not as a sidebar).
            dispatch(setShowGrid(false));
        },
        [dispatch]);

    return (
        <React.Fragment>

            {/* <MetaDecorator
                title={process.env.REACT_APP_NAME}
                description={'Music scale finder with music notation, note names, and playback, and with fingerings for common instruments.'}
            // title={gradeHeader.pageTitle || process.env.REACT_APP_NAME}
            // description={gradeHeader.metaDescription || 'Music scale finder with music notation, note names, and playback, and with fingerings for common instruments.'}
            />
 */}

            <NavBar
                usesPhoneStyleNavBar={usesPhoneStyleNavBar}
                usesPhoneStyleGrid={usesPhoneStyleGrid}
                handlePrintRequest={handlePrintRequest}
                search={search}
                setSearch={setSearch}
                handleNewSearch={handleNewSearch}
                currGroupBy={currGroupBy}
                listOfModes={listOfModes}
                numScalesRetrieved={(scaleHeaderList.length)}
                toggleSidebar={toggleSidebar}
                // showSettings={showSettings}
                // setShowSettings={setShowSettings}
                // setShowFeedbackForm={setShowFeedbackForm}
                sidebarActiveTab={sidebarActiveTab}
                setSidebarActiveTab={setSidebarActiveTab}
                showSearchOnPhone={showSearchOnPhone}
                setShowSearchOnPhone={setShowSearchOnPhone}
                landingPage={!location.search}
            />


            {usesPhoneStyleGrid &&
                <div id='phone-grid-container'>
                    {showGrid &&
                        <Overlay
                            className='phone-grid-open'
                            dismiss={() => dispatch(setShowGrid(false))}
                        />
                    }
                    <Grid
                        isOpen={showGrid}
                        containerClassName={`phone ${classNameForGridWidth}`}
                        scaleHeaderList={scaleHeaderList}
                        currGroupBy={currGroupBy}
                        scaleGroups={scaleGroups}
                        handleGridClick={handlePhoneGridClick}
                    />
                </div>
            }



            <div id='main-window'>

                {location.search ?
                    <React.Fragment>
                        {!usesPhoneStyleGrid &&
                            <Grid
                                isOpen={showGrid}
                                containerClassName={`${classNameForGridWidth}`}
                                scaleHeaderList={scaleHeaderList}
                                scaleGroups={scaleGroups}
                                currGroupBy={currGroupBy}
                            />
                        }
                        <div
                            id='scale-list-container'
                            className={`scale-list-container ${classNameForGridWidth}`
                                + (sidebarActiveTab ? ' sidebar-open' : '')
                            }
                            style={printing && isFirefox
                                ? {
                                    transformOrigin: "top left",
                                    transform: "scale(0.67)"
                                }
                                : {}
                            }
                        >
                            {isLoading ?
                                <div className='list-header loading-list'><Spinner /> Loading...</div>
                                :
                                <React.Fragment>
                                    <FeatureTour />
                                    <ScaleList
                                        instrument={instrument}
                                        grade={grade}
                                        currGroupBy={currGroupBy}
                                        scaleGroups={scaleGroups}
                                        scaleHeaderList={scaleHeaderList}
                                        gradeHeader={gradeHeader}
                                        handleScaleAction={handleScaleAction}
                                        setShowSettings={setShowSettings}
                                        searchFeedback={searchFeedback}
                                        showSearchOnPhone={showSearchOnPhone}
                                        printing={printing}
                                    // location={location}   // ??? not used?
                                    />
                                </React.Fragment>
                            }
                        </div>

                    </React.Fragment>
                    :    // location.search is falsy
                    <React.Fragment>

                        <WhatYouCanDo
                            setShowSettings={setShowSettings}
                            setShowPrintExample={setShowPrintExample}
                        />
                        {/* <LandingPage
                            setShowSettings={setShowSettings}
                        /> */}
                    </React.Fragment>
                }
            </div>
            {/* <FeedbackForm
                isOpen={showFeedbackForm}
                setShowFeedbackForm={setShowFeedbackForm}
            /> */}
            <Sidebar
                sidebarActiveTab={sidebarActiveTab}
                setSidebarActiveTab={setSidebarActiveTab}
                handlePrintRequest={handlePrintRequest}
            // setShowSettings={setShowSettings}
            />
            {showPrintExample &&
                <FullScreenImage
                    src={printExampleFull}
                    alt='Sample of PDF scale sheet generated by scale-lookup.com'
                    dismiss={() => setShowPrintExample(false)}
                />
            }
        </React.Fragment>
    );
}



const mapStateToProps = (state) => {
    return {
        groupBy: state.settings.groupBy,
        groupByForModes: state.settings.groupByForModes,
        defaultInstName: state.settings.defaultInstName,
        showGrid: state.settings.showGrid,
    };
};

export default connect(mapStateToProps)(Main);

