import React, { useEffect } from 'react';
import {
    BrowserRouter, Switch, Route, Redirect
    // , Link
} from 'react-router-dom';
import { connect } from 'react-redux';

import { initAudio } from '../audio/Audio';
import { NotePlayer } from '../audio/NotePlayer';
// import INSTRUMENTS from '../data/instruments';
import { setAudio, setPlayer, setPlayingScaleId } from '../actions/session';

import Main from './Main';
// import FingeringChart from './FingeringChart';


const Router = ({ dispatch, settings }) => {

    // Set tempo global var for tempo from store.settings 000285
    if (!window.tempo) window.tempo = settings.tempo;

    useEffect(() => {
        const dispatchSetPlayingScaleId = (scaleId) => {
            // ???πππ must I do it like this, or can NotePlayer do the dispatch directly?
            // ??? cf. App.js: how do I do store.dispatch from NotePlayer? How do I 'get' *store*?
            dispatch(setPlayingScaleId(scaleId));
        };

        // At present it seems pointless that audio exists outside NotePlayer - but probably
        // the metronome (to be implemented) will use it, plus I'm not sure how things will
        // work out with different instrument patches...
        const audio = initAudio();
        dispatch(setAudio(audio));
        dispatch(setPlayer(new NotePlayer(audio, dispatchSetPlayingScaleId)));
    }, [dispatch]);



    // ??? this would appear to be obsolete atm..?
    const standardProps = {
    }

    return (

        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    <Redirect to={`/search`} />
                </Route>

                {/* <Route exact path="/fingering-chart">
                    {(props) => <FingeringChart
                        {...props}
                        {...standardProps}
                    />}
                </Route> */}

                {/* Route for search
                */}

                <Route exact path={`/search`} key={`search`}>
                    {(props) => <Main
                        key={window.location.search}  // force new mount if url search changes
                        {...props}
                        {...standardProps}
                    />}
                </Route>

                <Route>
                    <Redirect to={`/search`} />
                </Route>

            </Switch>
        </BrowserRouter >

    )
};

const mapStateToProps = (state) => {
    return {
        settings: state.settings
    }
}

export default connect(mapStateToProps)(Router);

