import React from 'react';
import { Provider } from 'react-redux';
import Router from "./components/Router";
// import MainScan from './zz-dev-utils/MainScan';
import './css/App.scss';
import CookieConsent from 'react-cookie-consent';
import ErrorFallback from './components/ErrorFallback';
import configureStore from './store/configureStore';
import { ErrorBoundary } from 'react-error-boundary';
import MetaDecorator from './components/MetaDecorator';
// import { setShowFingerings, setShowNoteNames } from './actions/settings';
import { imagesPath } from './utils/imagesPath.js';


function App() {

    // console.error('Clearing localStorage!')
    // localStorage.clear();
    const store = configureStore();


    return (

        <ErrorBoundary
            FallbackComponent={ErrorFallback}
        >
            <Provider store={store}>
                <div className="App">

                    <MetaDecorator
                        title={'Search for musical scales | ' + process.env.REACT_APP_NAME}
                        description={'Musical scale finder with interactive music notation, note names, and audio playback, and with fingerings for common instruments.'}
                        ogTitle='Search for musical scales'
                        image={imagesPath('screenshots/_default')}
                    />

                    <Router />
                    {/* <MainScan /> */}
                    <CookieConsent
                        // debug
                        sameSite='lax'
                        containerClasses='cookie-consent-box'
                    >
                        This web app uses cookies to store your settings.
          </CookieConsent>
                </div>
            </Provider>
        </ErrorBoundary>
    );
}

export default App;
