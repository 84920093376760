import React from 'react';
import { connect } from 'react-redux';

import { scaleTypeObject } from '../logic/scaleTypes';
// import OptionControl from './OptionControlSubmenu';
import OptionControl from './DropdownRML';
import { modesOf, sortScales } from '../logic/scaleUtils';
import { SCALE_TYPES_CANONICAL } from '../logic/scaleTypesData';
import { composeSearchText, pitchClassDisplayString } from '../utils/MusicSymbolsFormatting';
import { transposeByFifths } from '../logic/musicStructures';
import { joinPretty } from '../utils/miscUtils';


/*

change texts:
'To related..'??

*/

const RelatedScalesControl = ({ scaleHeader, instrument, onClick, defaultInstName, dispatch, ...otherProps }) => {

    const { tonicPC, basetype, renderedType } = scaleHeader;
    const mainScaleType = scaleTypeObject(basetype);
    const renderedScaleType = scaleTypeObject(renderedType || basetype);
    const instName = instrument.name === defaultInstName ? '' : instrument.name;  // stick inst on searches if it's not default

    let relatedScales = [];

    const { relative } = mainScaleType;
    if (relative) {
        const newTonic = transposeByFifths(tonicPC, relative.fifths);
        relatedScales.push({
            label: `${relative.text || `Relative ${scaleTypeObject(relative.basetype).displayName}`} (${pitchClassDisplayString(newTonic)})`,
            searchText: composeSearchText({ tonicPC: newTonic, basetype: relative.basetype, instName })
        });
        relatedScales.push({ divider: true });
    }

    const modes = modesOf({ ...scaleHeader, errorIfNoModes: false })
        .map(mode => {
            const modeScaleType = scaleTypeObject(mode.basetype);
            return {
                label: modeScaleType.title(mode),
                searchText: composeSearchText({ ...mode, instName }),
                disabled: mode.modeNum === 1
            }
        });
    if (modes.length) {
        modes.push({ divider: true });
        modes.push({
            label: `All modes of ${renderedScaleType.title({ tonicPC, maxLength: 10 })}`,
            searchText: composeSearchText({ tonicPC, renderedType, modesOf: true, instName })
        });
        relatedScales.push(
            {
                label: 'Modes',
                submenu: modes,
            }
        );
    }

    const transpositions = mainScaleType.tonics.preferred
        .sort((k, l) => sortScales({ tonicPC: k, basetype }, { tonicPC: l, basetype }, 'TYPE', 'CHROMATIC'))
        .map(newTonic => ({
            label: mainScaleType.title({ tonicPC: newTonic }),
            searchText: composeSearchText({ tonicPC: newTonic, basetype, instName }),
            disabled: tonicPC === newTonic,
        }));
    if (transpositions.length) {
        transpositions.push({ divider: true });
        transpositions.push({
            label: `${mainScaleType.pluralName} in all keys`,
            searchText: composeSearchText({ basetype, instName }),         // no tonic -> all keys
        });
        relatedScales.push(
            {
                label: 'Transpositions',
                submenu: transpositions,
            }
        );
    };

    setupSubmenuFromScaleTypes({
        label: 'Subsets',
        filterCallback: (candidateScaleType => {
            return (candidateScaleType.formula.length <= renderedScaleType.formula.length)
                && candidateScaleType.formula.every(candInt =>
                    renderedScaleType.formula.some(mainInt =>
                        (mainInt.interval === candInt.interval)
                        && (candInt.ignoreForChords === mainInt.ignoreForChords)  // only match passing notes with passing notes
                    )
                )
        }),
        sortComparison: (tType, uType) => uType.formula.length - tType.formula.length
    });

    setupSubmenuFromScaleTypes({
        label: 'Supersets',
        filterCallback: (candidateScaleType => {
            return (candidateScaleType.formula.length >= renderedScaleType.formula.length)
                && renderedScaleType.formula.every(mainInt =>
                    candidateScaleType.formula.some(candInt =>
                        (candInt.interval === mainInt.interval)
                        && (candInt.ignoreForChords === mainInt.ignoreForChords)  // only match passing notes with passing notes
                    )
                )
        }),
        sortComparison: (tType, uType) => tType.formula.length - uType.formula.length
    });



    // Create a submenu full of scale types which match the given filterCallback.
    // Currently used for subsets and supersets.
    function setupSubmenuFromScaleTypes({ label, filterCallback, sortComparison = () => 0 }) {

        const submenu = SCALE_TYPES_CANONICAL
            .filter(({ id: typeId }) => {
                const candidateScaleType = scaleTypeObject(typeId);
                return filterCallback(candidateScaleType);
            })
            .sort((t, u) => {
                const tType = scaleTypeObject(t.id);
                const uType = scaleTypeObject(u.id);
                return sortComparison(tType, uType);
            })
            .map(({ id: typeId }) => {
                const submenuType = renderedScaleType.canonicalTypeId === typeId ? renderedScaleType : scaleTypeObject(typeId);
                return {
                    label: submenuType.title({ tonicPC }),
                    searchText: composeSearchText({ tonicPC, basetype: submenuType.id, instName }),
                    searchTextNoInst: composeSearchText({ tonicPC, basetype: submenuType.id }),  // handy for 'all of the above'
                    disabled: submenuType.id === renderedType
                }
            });


        if (submenu.length > 1) {
            const allOfTheAbove = {
                label: `All of the above`,
                searchText: joinPretty(submenu.map(option => option.searchTextNoInst)) + (instName ? `, ${instName}` : ''),
            };
            submenu.push({ divider: true });
            submenu.push(allOfTheAbove);

            relatedScales.push({ label, submenu }
            );
        }
    }



    return (
        relatedScales.length
            ? <OptionControl
                optionStyle='dropdown'
                containerClassName='action-box'
                dropdownClassName='action-dropdown'
                optionsArray={relatedScales}
                // currValue={currentChord}
                onClick={onClick}
                dropdownToggleDefault='Related scales'
                // dropdownToggleDefault={<span><RelatedIcon />&nbsp;Related scales</span>}
                dropdownToggleDefaultShort='Related'
                {...otherProps}
            />
            : null
    )
}


const mapStateToProps = (state) => {
    return {
        defaultInstName: state.settings.defaultInstName
    }
}

export default connect(mapStateToProps)(RelatedScalesControl);