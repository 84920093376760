import { getCircleIndex, transposeByFifths } from "./musicStructures";
import { scaleTypeObject } from './scaleTypes';
import { Scale } from './scale';
import { enharmonic, chromaticIndex } from './noteUtils';

import { RANGES } from '../data/Ranges';
import { numberOfCharsInJSX } from '../utils/miscUtils';
import { formatAccidentalsJSX, pitchClassDisplayString } from '../utils/MusicSymbolsFormatting';
import { getChordNames } from './chordTypes';


// If flippable return the flipped tonic else return false
export const flippedTonic = ({ tonicPC, basetype }) => {
    const flippable = scaleTypeObject(basetype).tonics.flippable;
    if (flippable && Math.abs(keySigIndex(tonicPC, basetype)) >= 5) {    //  now allow flip of *anything* beyond 5 flats/sharps, not just 5/6/7
        return enharmonic(tonicPC);
    }
    else return false;
}


export function relMajor(tonicPC, basetype = 'MIN') {
    // Returns the relative major key, E.g.:
    //   MAJ => return given key
    //   MIN => return rel major
    //   DOM7 => return rel major ('tonic' means starting note of the dom7)

    // etc. as defined in scaleTypeObject(basetype).relativeMajorDistanceIn5ths
    // If no relative defined for scale type then returns original tonic.

    const fifthsToRelMajor = scaleTypeObject(basetype).relativeMajorDistanceIn5ths || 0;
    return transposeByFifths(tonicPC, fifthsToRelMajor);
}

// How many sharps in key (-ve num if flats)
export const keySigIndex = (tonicPC, basetype) => {
    const fifthsToRelMajor = scaleTypeObject(basetype).relativeMajorDistanceIn5ths || 0;  // default to parallel major
    return getCircleIndex(tonicPC) + fifthsToRelMajor;
}


const sortByKeyCircle = (scaleHeader1, scaleHeader2) => {
    const sortingIndex = (scaleHeader) => {
        const startListAt = -5;  // start list from 5 flats
        const s = keySigIndex(scaleHeader.tonicPC, scaleHeader.basetype);
        if (s < startListAt) return s + 12;
        else if (s >= startListAt + 12) return s - 12;
        else return s;
    }
    return (sortingIndex(scaleHeader1) - sortingIndex(scaleHeader2));
}

export const sortByKeyChrom = (scaleHeader1, scaleHeader2) => {
    const keySigChromIndex = (scaleHeader) =>
        ((chromaticIndex({ pitchClass: relMajor(scaleHeader.tonicPC, scaleHeader.basetype), ignoreOctave: true })))
    return (keySigChromIndex(scaleHeader1) - keySigChromIndex(scaleHeader2));
}

const sortByScaleType = (scaleHeader1, scaleHeader2) => {
    const sortingIndex = (scaleHeader) => (
        scaleTypeObject(scaleHeader.basetype).sortingIndex
    );
    return sortingIndex(scaleHeader1) - sortingIndex(scaleHeader2);
}


export const sortScalesWithinGroup = (scaleHeader1, scaleHeader2, groupBy, sortScalesBy) => {
    if (groupBy === 'TYPE') {
        return sortScalesBy === 'CHROMATIC' ?
            sortByKeyChrom(scaleHeader1, scaleHeader2)
            : sortByKeyCircle(scaleHeader1, scaleHeader2);
    }
    else if (groupBy === 'TONIC') {
        return sortByScaleType(scaleHeader1, scaleHeader2);
    }
    else {         //  MODES_OF
        return scaleHeader1.modeOf.modeNum - scaleHeader2.modeOf.modeNum;
        // return sortByScaleType(scaleHeader1, scaleHeader2);
    }
}

export const sortScales = (scaleHeader1, scaleHeader2, groupBy, sortScalesBy) => {
    // Sort entire list of scales, i.e. sort into groups, and then sort within those.

    if (groupBy === 'TYPE') {
        const compareByTypeResult = sortByScaleType(scaleHeader1, scaleHeader2);
        const compareByTonicResult = sortScalesBy === 'CHROMATIC' ?
            sortByKeyChrom(scaleHeader1, scaleHeader2)
            : sortByKeyCircle(scaleHeader1, scaleHeader2);

        return compareByTypeResult || compareByTonicResult;
    }
    else if (groupBy === 'TONIC') {
        // ???perfdec is this optimal?
        // feels like I should do with simple calls to chromIndex..??
        // think now is time to set up testing for sorting..
        // ..And by circleIndex too - but beware enharmonicness! I.e. Bbb we want to sort as A I think
        // if make any changes then apply to sortScalesWithinGroup and sortGroups
        // or better still, factor the comparisons out to avoid repetition
        const sh1 = { tonicPC: scaleHeader1.tonicPC, basetype: 'MAJ' },
            sh2 = { tonicPC: scaleHeader2.tonicPC, basetype: 'MAJ' };
        const compareByTonicResult = sortScalesBy === 'CHROMATIC' ?
            sortByKeyChrom(sh1, sh2)
            : sortByKeyCircle(sh1, sh2)
        const compareByTypeResult = sortByScaleType(scaleHeader1, scaleHeader2);
        return compareByTonicResult || compareByTypeResult;
    }
    else {         //  MODES_OF
        const compareParentScalesResult = sortScalesBy === 'CHROMATIC' ?
            sortByKeyChrom(scaleHeader1.modeOf, scaleHeader2.modeOf)
            : sortByKeyCircle(scaleHeader1.modeOf, scaleHeader2.modeOf);
        return compareParentScalesResult || (scaleHeader1.modeOf.modeNum - scaleHeader2.modeOf.modeNum);
    }
}

export const sortGroups = (group1, group2, groupBy, sortScalesBy) => {
    if (groupBy === 'TYPE') {
        // ??? if it's a modes query then probably should order scale types as they are in the modes
        // i.e. if query 'modes of dorian' then group by scale type, dorian should be first
        return scaleTypeObject(group1.key).sortingIndex - scaleTypeObject(group2.key).sortingIndex;
    }
    else if (groupBy === 'TONIC') {
        const quasiScaleHeaderFromGroup = (group) => ({
            tonicPC: group.key.pitchClass1,
            basetype: 'MAJ'
        });
        return sortScalesBy === 'CHROMATIC' ?
            group1.key.chromIndex - group2.key.chromIndex
            : sortByKeyCircle(quasiScaleHeaderFromGroup(group1), quasiScaleHeaderFromGroup(group2));       // CIRCLE
    }
    else {         // MODES_OF
        return sortScalesBy === 'CHROMATIC' ?
            sortByKeyChrom(group1.key, group2.key)
            : sortByKeyCircle(group1.key, group2.key);
    }
}


export const modesOf = ({ tonicPC, renderedType, basetype, octave = 4, errorIfNoModes = true }) => {

    // const type = basetype;
    const type = renderedType || basetype;

    if (!type) throw new Error(`No basetype supplied to modesOf`);

    console.log('new Scale - modesOf')
    const baseScale = new Scale({
        tonicPC,
        octave,
        basetype: type,
        range: '8',
    });

    const modeTypes = scaleTypeObject(type).modes;

    if (modeTypes === false && errorIfNoModes) {
        throw new Error(`Scale type ${type} does not have any modes.`)
    }

    const modes = [];

    for (let i = 0; i < modeTypes.length; i++) {
        const mode = {
            tonicPC: baseScale.notes[i].pitchClass.replace(/n$/, ''),
            octave: baseScale.notes[i].octave,
            basetype: modeTypes[i],
            modeOf: {                              // modeOf.modeNum needed for ordering keysGridRows
                // tonicPC,                        // <-- (not actually needed anywhere)
                // basetype,                       //     - " -
                modeNum: i + 1
            },
            modeNum: i + 1
        };
        mode.id = `${mode.tonicPC}-${mode.basetype}`
        modes.push(mode);
    }

    return modes;
}


export const scaleFullTitleJSX = ({ tonicPC, renderedType, range: rangeId, showChord, narrowScreen = false }) => {

    const scaleTypeDefn = scaleTypeObject(renderedType);
    let title = scaleTypeDefn.title({ tonicPC, renderedType });

    if (showChord && showChord !== 'SCALE') {
        const { chordId, chordType } = scaleTypeDefn.chords.filter(ch => (ch.chordType === showChord))[0];
        const displayName = getChordNames(chordId || chordType).displayName;
        title += ` \u2013 ${pitchClassDisplayString(tonicPC)} ${displayName}`;
    }

    if (rangeId && rangeId !== '8') {
        const range = RANGES.find(r => r.id === rangeId) || {};      // '|| {}' - allow for non-standard ranges (fingering chart)
        title += narrowScreen && numberOfCharsInJSX(title) > 25 ?    // if narrow screen and long title..
            `\u00A0(${range.shortName.toLowerCase()})`                               // ..then show range abbreviated (e.g. '2 oct')
            : `\u00A0(${range.fullName.toLowerCase()})`;
        // nb. numbers are a little arbitrary - essentially they cause dom7 titles (which are long) to get abbreviated range on a phone
    }

    return formatAccidentalsJSX(title);
}
