import React from 'react';


const ErrorFallback = (props) => (
    <div className='error-fallback'>
        <div style={{ fontSize: '3rem' }}>
            {`\u26A0`}
        </div>
        <div style={{ fontSize: '1.2rem' }}>
            Oops.. something went wrong..
            </div>
    </div>
);

export default ErrorFallback;