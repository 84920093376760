import React from 'react';
import { LinkToContactForm, LinkToFacebookGroup } from './ContactPane';

const AboutPane = ({ setSidebarActiveTab }) => {

    return (

        <React.Fragment>

            <h3>Thank you for checking out this app!</h3>
            <p>It's a work in progress, so some things might not work correctly, and some features are not yet fully implemented (e.g. fingerings are not available for all instruments yet), and there are still plenty of features which I would like to add (see below&hellip;)
            </p>

            <p>At the moment this app is a reference tool, but I think it could become more, with e.g. more features for learning and practising.</p>
            <p>I want it to be as useful as possible to students and teachers of music, so please tell me how I can improve it, either through the <LinkToContactForm setSidebarActiveTab={setSidebarActiveTab}>contact form</LinkToContactForm> or join the <LinkToFacebookGroup>facebook group</LinkToFacebookGroup>.</p>
            <h3>Forthcoming features (maybe!)</h3>
            <ul>
                <li>
                    <strong>Save a scale list</strong>, and retain details such as range, note names, fingerings, and <strong>share a list</strong> with others (e.g. a music teacher could share a list with a student).
                </li>
                <li>
                    <strong>Music theory</strong> - more information and explanations of scale types, clefs, related scales, etc.
                </li>
                <li>
                    Facility to look up <strong>chord symbols</strong>
                </li>
                <li>
                    <strong>More look up features</strong> - e.g. find scales which fit a particular chord, scales which contain certain pitches, see the differences between two scales, etc.
                </li>
                <li>
                    <strong>Fingerings for more instruments</strong> - e.g. piano, guitar, clarinet, etc.
                </li>
                <li>
                    <strong>Guitar/bass tablature</strong>
                </li>
                <li>
                    <strong>More notation options</strong> - e.g. use a key sig, note durations, triplets, choose clef, etc.
                </li>
                <li>
                    An <strong>integrated metronome</strong>
                </li>
                <li>
                    Scale <strong>patterns</strong> - such as scales in 3rds (simultaneous 3rds for piano, plus 'melodic' 3rds, like 132435...), piano contrary motion, etc.
                </li>
                <li>
                    <strong>'Learn a scale'</strong> facility, to take you through the process of learning a scale step-by-step.
                </li>
            </ul>
            <p>...If you'd find any of these useful, or if you would like to request a feature, then please <LinkToContactForm>let me know</LinkToContactForm>!
            </p>
            <h3>About me</h3>
            <p>My name is <a href="http://www.angusmoncrieff.com">Angus Moncrieff</a> and I'm a trumpet player, brass teacher, composer/arranger and software developer. I graduated summa cum laude from Berklee College of Music, majoring in Jazz Composition and Film Scoring.</p>

            <div className='credits'>
                <h3>Credits</h3>
                {/* <p>This project has been created in <a href="https://reactjs.org/">React</a>.</p> */}
                <p>Music notation is done with <a href="http://vexflow.com">Vexflow</a> by <a href="https://github.com/0xfe">0xfe</a>.</p>
                <p>Woodwind fingerings adapted from <a href="https://github.com/eduardomourar/fingering-diagram">this musescore plugin</a>.</p>
                <p>A massive <span className='fancy-text'><Stars />thank you<Stars /></span> to all the musicians who have given me feedback! <FistBump /></p>
            </div>
        </React.Fragment >
    )
}

function Stars() {
    return (
        <span role='img' aria-label='stars emoji'>
            ✨
        </span>
    )
}

function FistBump() {
    return (
        <span role='img' aria-label='fistbump emoji'>
            👊
        </span>
    )
}


export default AboutPane;