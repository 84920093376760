import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { HomeIcon, HelpIcon, InfoIcon, ContactIcon, FacebookIcon, TypesIcon } from '../assets/icons';
import { Link } from 'react-router-dom';

const HelpMenu = ({ numScalesRetrieved, setTourToRun, setSidebarActiveTab, dispatch, phone = false }) => {

    const [helpMenuOpen, setHelpMenuOpen] = useState(false);

    return (
        <Dropdown
            isOpen={helpMenuOpen}
            toggle={() => setHelpMenuOpen(!helpMenuOpen)}
            direction='down'
            // inNavbar
            nav
        >
            <DropdownToggle
                // id="help-button" // ??? this is just for tour
                className={`nav-link ${helpMenuOpen ? 'pressed' : ''}`}
            // tabIndex={tabIndex}
            >
                <HelpIcon />&nbsp;Help
                {/* {phone ? (
                    <div className='phone-navbar-button-text'>
                        Help
                    </div>
                )
                    : ( */}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem>
                    <Link to={`/`}>
                        <HomeIcon /> What can I do here?
                    </Link>
                </DropdownItem>
                {numScalesRetrieved ? (
                    <DropdownItem
                        onClick={e => dispatch(setTourToRun('MAIN_TOUR'))}
                        disabled={!numScalesRetrieved}
                    >
                        <HelpIcon /> Take the tour
                    </DropdownItem>
                )
                    : null
                }
                <DropdownItem
                    onClick={() => { setSidebarActiveTab('SCALE_TYPES') }}
                >
                    <TypesIcon /> List of scale types
                </DropdownItem>
                <DropdownItem
                    onClick={() => { setSidebarActiveTab('ABOUT') }}
                >
                    <InfoIcon /> About
                </DropdownItem>
                <DropdownItem
                    onClick={() => { setSidebarActiveTab('CONTACT') }}
                >
                    <ContactIcon /> Contact
                </DropdownItem>
                <DropdownItem
                // onClick={() => { setSidebarActiveTab('CONTACT') }}
                >
                    <a
                        href='https://www.facebook.com/groups/scale.lookup.user.group'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <FacebookIcon /> Facebook Group
                    </a>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}


export default HelpMenu;